import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment'

import EligibilityErrorsAPIClient from '../../../api/eligibility-errors';

import AppNav from '../../../components/AppNav';
import BaseModal from '../../../components/BaseModal';
import Dropdown from '../../../components/Dropdown';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Header from '../../../components/Header';
import NoResults from '../../../components/NoResults';
import SearchInput from '../../../components/SearchInput';
import Spinner from '../../../components/Spinner';
import TotalResults from '../../../components/TotalResults';

import { useAPIPagination } from '../../../hooks/pagination';
import useDebounce from '../../../hooks/useDebounce';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import { EligibilityError } from '../../../types/eligibility-errors';

import {
  practiceConstants,
  queryStringParams,
  timezoneAbbreviation,
} from '../../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_H_MMA,
  FORMAT_MM_DD_YYYY,
  FORMAT_YYYY_MM_DD,
  PER_PAGE,
  ROUTES,
} from '../../../utils/constants';

import EligibilityErrorsList from './List';
import './index.scss';

interface Props extends RouteComponentProps { }

const EligibilityErrors = (props: Props) => {
  const api = new EligibilityErrorsAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className, defaultEmployer, employers } = practiceConstants();

  // State
  const [showModal, setShowModal] = useState(false);
  const [selectedError, setSelectedError] = useState<EligibilityError>();

  // Filter states
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value == query[QueryKeys.Company]
  ))[0] || defaultEmployer);
  const [isDescending, setIsDescending] = useState(
    query[QueryKeys.Order] ? query[QueryKeys.Order] == 'desc' : true
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );

  // Dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');
  const [users, setUsers] = useState<EligibilityError[]>([]);

  // Pagination
  const [to, setTo] = useState(0);
  const [from, setFrom] = useState(0);
  const [total, setTotal] = useState(0);

  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.Company]: employer.value,
    [QueryKeys.Order]: isDescending ? 'desc' : 'asc',
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Search]: debouncedSearchTerm,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchEligibilityErrors(queryString)
      .then(response => {
        const [list, i, j, t] = response;

        setIsFetching(false);
        setUsers(list);
        setFrom(i);
        setTo(j);
        setTotal(t);
      })
      .catch(() => {
        setIsFetching(false);
        setUsers([]);
        setFrom(0);
        setTo(0);
        setTotal(0);
      });
  }, [currentPage, isDescending, employer, debouncedSearchTerm, startDate, endDate])

  const onChangeText = text => {
    setIsFetching(true);
    setCurrentPage(0);
    setSearchFilter(text);
  }

  const renderFilters = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={2}>
          <h3 className="primary-title">Filters</h3>
        </Col>
        <Col xs={12} lg={7} className="controls">
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={item => {
              setCurrentPage(0);
              setEmployer(item);
            }} />
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Error Date"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0])
              setEndDate(dates[dates.length - 1])
            }}
            onStartDateSelection={date => {
              setCurrentPage(0);
              setStartDate(date);
            }}
            onEndDateSelection={date => {
              setCurrentPage(0);
              setEndDate(date);
            }}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          to={to}
          from={from}
        />
        <EligibilityErrorsList
          currentPage={currentPage}
          isDescending={isDescending}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={pagedUsers}
          onRowSelect={error => {
            setShowModal(true);
            setSelectedError(error)
          }}
          setIsDescending={setIsDescending}
          setIsFetching={setIsFetching}
          setCurrentPage={setCurrentPage} />
      </>
    )
  }

  const renderModalContent = () => (
    <>
      <label className="info" style={{ marginBottom: 5 }}>Error Message</label>
      <p className="content">
        {selectedError && selectedError.message}
      </p>
    </>
  )

  const renderModalFields = () => (
    <>
      <div className="row bottom-border">
        <div className="column">
          <label className="info" style={{ marginBottom: 5 }}>First Name</label>
          <input
            disabled
            className="ClassDetails-input"
            type="text"
            defaultValue={selectedError && selectedError.firstName} />
        </div>
        <div className="column">
          <label className="info" style={{ marginBottom: 5 }}>Last Name</label>
          <input
            disabled
            className="ClassDetails-input"
            type="text"
            defaultValue={selectedError && selectedError.lastName} />
        </div>
        <div className="column">
          <label className="info" style={{ marginBottom: 5 }}>Email</label>
          <input
            disabled
            className="ClassDetails-input"
            type="text"
            defaultValue={selectedError && selectedError.email} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label className="info" style={{ marginBottom: 5 }}>Error Date</label>
          <input
            disabled
            className="ClassDetails-input"
            type="text"
            defaultValue={selectedError && moment(selectedError.date).format(FORMAT_MM_DD_YYYY)} />
        </div>
        <div className="column">
          <label className="info" style={{ marginBottom: 5 }}>Error Time</label>
          <input
            disabled
            className="ClassDetails-input"
            type="text"
            defaultValue={selectedError &&
              `${moment(selectedError.date).format(FORMAT_H_MMA)} ${timezoneAbbreviation(selectedError.date)}`
            } />
        </div>
        <div className="column">
          <input disabled className="ClassDetails-input none" />
        </div>
      </div>
    </>
  )

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          title="Eligibility Errors"
          titleLg={6}>
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Participant Name"
            onChangeText={onChangeText} />
        </Header>

        <BaseModal
          lg
          confirmTitle="Ok"
          footerButton={<div style={{ width: 1 }} />}
          title="Error Details"
          toDisplay={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={() => setShowModal(false)}>
          <div className="EligibilityErrors-modalContainer">
            {renderModalFields()}
            {renderModalContent()}
          </div>
        </BaseModal>

        {renderFilters()}
        {(isFetching && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> :
          renderList()
        }
      </Container>
    </>
  )
}

export default EligibilityErrors;
