import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { defaults, Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import _ from 'lodash';
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faCalendarDay,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SignupAnalyticsAPIClient from '../../../api/signup-analytics';

import AppNav from '../../../components/AppNav';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import NoResults from '../../../components/NoResults';
import Spinner from '../../../components/Spinner';

import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import { SignupTrackingFilter as Filter } from '../../../types/signup-tracking';
import {
  SignupAnalyticsData,
  SignupAnalyticsViewMode as ViewMode
} from '../../../types/signup-analytics';

import {
  practiceConstants,
  queryStringParams,
  tooltipComponent,
  hasEligibilityCheck,
} from '../../../utils';
import {
  FORMAT_MMMM_D,
  FORMAT_MMMM_D_YYYY,
  FORMAT_DDDD,
  FORMAT_MMM,
  BASE_URL_INGENUITY,
  FORMAT_MMMM,
  FORMAT_DDD,
  FORMAT_D,
  FORMAT_YYYY,
  FORMAT_YYYY_MM_DD,
  FORMAT_MMMM_YYYY,
  INFO_QUIZ,
  INFO_ELIGIBLE_GREEN,
  INFO_SCHEDULED,
  INFO_SIGNEDUP,
  INFO_LANDING,
  ROUTES,
  CLIENT_SHARECARE,
} from '../../../utils/constants';

import './index.scss';

const moment = extendMoment(require('moment'));

const BLUE = '#5BD8FC';
const RED = '#FC8476';
const GREEN = '#8FE6A4';
const YELLOW = '#FED88B';
const ORANGE = '#FAB387';

const STEPS = [
  Filter.Landing,
  Filter.Quiz,
  Filter.Eligibility,
  Filter.Scheduler,
  Filter.SignedUp
];
const ALL_TIME = { title: ViewMode.AllTime.toString(), value: 'all' };
const VIEW_MODES = [
  { title: ViewMode.Day.toString(), value: 'day' },
  { title: ViewMode.Week.toString(), value: 'week' },
  { title: ViewMode.Month.toString(), value: 'month' },
  { title: ViewMode.Year.toString(), value: 'year' },
  ALL_TIME
];

interface Props extends RouteComponentProps { }

export default function SignupAnalytics(props: Props) {
  const api = new SignupAnalyticsAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const { className, defaultEmployer, employers } = practiceConstants();
  const initial: SignupAnalyticsData = {
    total: 0,
    groups: [{
      total: 0,
      landing: 0,
      quiz: 0,
      eligible: 0,
      scheduled: 0,
      signedUp: 0,
      fromDate: new Date(),
      toDate: new Date()
    }]
  };

  // Filter states
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value == query[QueryKeys.Company]
  ))[0] || defaultEmployer);
  const [viewMode, setViewMode] = useState(VIEW_MODES.filter(e => (
    e.value == query[QueryKeys.GroupBy]
  ))[0] || VIEW_MODES[0]);
  const [navigationDate, setNavigationDate] = useState(
    query[QueryKeys.Date] ? new Date(query[QueryKeys.Date]) : new Date()
  );
  const [isLandingEnabled, setIsLandingEnabled] = useState(true);
  const [isQuizEnabled, setIsQuizEnabled] = useState(true);
  const [isEligibleEnabled, setIsEligibleEnabled] = useState(true);
  const [isScheduledEnabled, setIsScheduledEnabled] = useState(true);
  const [isSignedUpEnabled, setIsSignedUpEnabled] = useState(true);

  // Dataset states
  const [chartData, setChartData] = useState(initial);
  const [hasData, setHasData] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const isAllTime = viewMode.value == ALL_TIME.value;
  const isSharecare = employer.value == CLIENT_SHARECARE.value;
  const toDisplayEligible = hasEligibilityCheck(employer.value);
  const queryString = useQueryString({
    [QueryKeys.Company]: employer.value,
    [QueryKeys.GroupBy]: viewMode.value,
    [QueryKeys.Date]: (
      isAllTime ? undefined : moment(navigationDate).format(FORMAT_YYYY_MM_DD)
    ),
  });

  useEffect(() => {
    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsLandingEnabled(!isSharecare);
    setIsQuizEnabled(!isSharecare);
    setIsEligibleEnabled(!isSharecare);
    setIsScheduledEnabled(!isSharecare);
    setIsEligibleEnabled(toDisplayEligible);
  }, [employer])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchSignupAnalytics(
      employer.value,
      isAllTime ? undefined : viewMode.value,
      isAllTime ? undefined : navigationDate
    )
      .then(response => {
        setHasData(true);
        setIsFetching(false);
        setChartData(response);
      })
      .catch(() => {
        setIsFetching(false);
        setHasData(false);
      });
  }, [employer, viewMode, navigationDate])

  const getData = (mode: string) => {
    let datasets: any[] = [];
    let landing = 0;
    let quiz = 0;
    let eligible = 0;
    let scheduled = 0;
    let signedUp = 0;
    const stack = 'stack';
    const { groups: g, allTimeGroups } = chartData;
    const groups: any[] = g || allTimeGroups!;

    if (isLandingEnabled) {
      const data = groups.map(item => item.landing);

      landing = data.reduce((prev, curr) => prev + curr);

      datasets.push({
        label: Filter.Landing,
        stack,
        data,
        backgroundColor: ORANGE,
      });
    }

    if (isQuizEnabled) {
      const data = groups.map(item => item.quiz);

      quiz = data.reduce((prev, curr) => prev + curr);

      datasets.push({
        label: Filter.Quiz,
        stack,
        data,
        backgroundColor: YELLOW,
      });
    }

    if (isEligibleEnabled) {
      const data = groups.map(item => item.eligible);

      eligible = data.reduce((prev, curr) => prev + curr);

      datasets.push({
        label: Filter.Eligibility,
        stack,
        data,
        backgroundColor: GREEN,
      });
    }

    if (isScheduledEnabled) {
      const data = groups.map(item => item.scheduled);

      scheduled = data.reduce((prev, curr) => prev + curr);

      datasets.push({
        label: Filter.Scheduler,
        data,
        stack,
        backgroundColor: RED,
      });
    }

    if (isSignedUpEnabled) {
      const data = groups.map(item => item.signedUp);

      signedUp = data.reduce((prev, curr) => prev + curr);

      datasets.push({
        label: Filter.SignedUp,
        data,
        stack,
        backgroundColor: BLUE,
      });
    }

    return {
      data: { labels: getLabels(mode), datasets },
      landing,
      quiz,
      eligible,
      scheduled,
      signedUp
    };
  }

  const getLabels = (mode: string) => {
    const { groups, allTimeGroups } = chartData;

    if (!groups)  {
      return allTimeGroups!.map(item => item.title);
    }

    switch (mode) {
      case ViewMode.Day: {
        const dates: Moment[] = groups.map(item => moment(item.fromDate))
        const labels: string[] = [];

        for (let index = 0; index < dates.length; index++) {
          const date = dates[index];

          labels.push(date.format(FORMAT_DDD));
        }

        return labels;
      }

      case ViewMode.Week: {
        const labels: string[] = [];

        for (let index = 0; index < groups.length; index++) {
          const group = groups[index];
          const first = moment(group.fromDate);
          const last = moment(group.toDate);
          const label = `${first.format(FORMAT_MMMM_D)} - ${last.month() != first.month() ? last.format(FORMAT_MMMM_D) : last.format(FORMAT_D)}`;

          labels.push(label);
        }

        return labels;
      }

      case ViewMode.Month: {
        const labels: string[] = [];

        for (let index = 0; index < groups.length; index++) {
          const group = groups[index];
          const first = moment(group.fromDate);
          const label = first.format(FORMAT_MMM);

          labels.push(label);
        }

        return labels;
      }

      case ViewMode.Year: {
        const labels: string[] = [];

        for (let index = 0; index < groups.length; index++) {
          const group = groups[index];
          const first = moment(group.fromDate);
          const label = first.format(FORMAT_YYYY);

          labels.push(label);
        }

        return labels;
      }

      default:
        return [];
    }
  }

  const onNavigate = (type: 'forward' | 'backward') => {
    let date = new Date();
    const isForward = type == 'forward';

    switch (viewMode.title) {
      case ViewMode.Day:
        date = (isForward ?
          moment(navigationDate).add(7, 'days').toDate() :
          moment(navigationDate).subtract(7, 'days').toDate());

        break;

      case ViewMode.Week:
        date = (isForward ?
          moment(navigationDate).add(1, 'month').toDate() :
          moment(navigationDate).subtract(1, 'month').toDate());

        break;

      case ViewMode.Month:
        date = (isForward ?
          moment(navigationDate).add(1, 'year').toDate() :
          moment(navigationDate).subtract(1, 'year').toDate());

        break;

      case ViewMode.Year:
        date = (isForward ?
          moment(navigationDate).add(6, 'year').toDate() :
          moment(navigationDate).subtract(6, 'year').toDate());

        break;

      default:
        break;
    }

    setIsFetching(true);
    setNavigationDate(date);
  }

  const renderFilters = () => {
    const checkboxStyle = { display: 'flex', alignItems: 'center' };
    const groupStyle = { marginBottom: 0, justifyContent: 'flex-end' };
    const values = [isLandingEnabled, isQuizEnabled, isEligibleEnabled, isScheduledEnabled, isSignedUpEnabled];
    const setters = [setIsLandingEnabled, setIsQuizEnabled, setIsEligibleEnabled, setIsScheduledEnabled, setIsSignedUpEnabled];

    return (
      <div className={`filters-section ${className}`}>
        <Row>
          <Col xs={12} lg={3}>
            <h3 className="primary-title">Filters</h3>
          </Col>
          <Col xs={12} lg={6} className="controls">
            <Form.Group as={Row} style={groupStyle}>
              <label className="info primary">
                Finished up to:
              </label>

              {STEPS.map((step: Filter, index: number) => {
                if ((!toDisplayEligible && step === Filter.Eligibility) ||
                    (isSharecare && step !== Filter.SignedUp)) {
                  return <div key={index.toString()} />
                }

                return (
                  <div
                    key={index.toString()}
                    className="checkbox-group"
                    style={checkboxStyle}
                  >
                    <input
                      checked={values[index]}
                      type="checkbox"
                      name="filter"
                      value={step}
                      onChange={() => setters[index](!values[index])}
                      id={step}
                    />
                    <label className="info" htmlFor={step}>{step}</label>
                  </div>
                )
              })}
            </Form.Group>
          </Col>
          <Col>
            <Dropdown
              items={VIEW_MODES}
              selectedItem={viewMode}
              title="Filter"
              onItemSelection={(item) => {
                setIsFetching(true);
                setViewMode(item)
              }} />
          </Col>
        </Row>
      </div>
    )
  }

  const renderEmployer = () => (
    <div className={`employer-section border-bottom ${className}`}>
      <Row>
        <Col xs={12} lg={3}>
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={item => setEmployer(item)}
          />
        </Col>
      </Row>
    </div>
  )

  const renderGraph = () => {
    const { data, landing, quiz, eligible, scheduled, signedUp } = getData(viewMode.title);
    const chartOptions: ChartOptions = {
      animation: {
        easing: 'easeInOutQuad',
        duration: 500
      },
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          }
        }],
        yAxes: [{
          ticks: { display: false },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }]
      },
      tooltips: {
        displayColors: false,
      }
    };

    // Set defaults
    defaults['global']['defaultFontFamily'] = 'Open Sans';

    return (
      <div className="SignupAnalytics-graphContainer">
        {renderNavigator()}

        <div className="graph">
          {isFetching ?
            <div className="center">
              <Spinner />
            </div> :
            <>
              {chartData.total == 0 ?
                <div className="center">
                  <label>No Data</label>
                </div> :
                <Bar
                  data={data}
                  height={100}
                  legend={{ display: false }}
                  options={chartOptions}
                />
              }
            </>
          }
        </div>

        <div className={`SignupAnalytics-legendContainer ${className}`}>
          <div className="group">
            {!isFetching && isLandingEnabled && chartData.groups && !isSharecare &&
              renderLegend(ORANGE, Filter.Landing, INFO_LANDING, landing)}
            {!isFetching && isQuizEnabled && !isSharecare &&
              renderLegend(YELLOW, Filter.Quiz, INFO_QUIZ, quiz)}
            {!isFetching && isEligibleEnabled && toDisplayEligible && !isSharecare &&
              renderLegend(GREEN, Filter.Eligibility, INFO_ELIGIBLE_GREEN, eligible)}
            {!isFetching && isScheduledEnabled && !isSharecare &&
              renderLegend(RED, Filter.Scheduler, INFO_SCHEDULED, scheduled)}
            {!isFetching && isSignedUpEnabled &&
              renderLegend(BLUE, Filter.SignedUp, INFO_SIGNEDUP, signedUp)}
          </div>
          <div>
            <div className="SignupAnalytics-legend total">
              {!isFetching &&
                <>
                  <FontAwesomeIcon className="primary" icon={faChartBar} />
                  <strong className="primary">&nbsp;Total ({chartData.total})</strong>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderLegend = (
    backgroundColor: string,
    label: string,
    tooltip: string,
    value: number,
  ) => (
    tooltipComponent(tooltip, (
      <div className="SignupAnalytics-legend">
        <div className="color" style={{ backgroundColor }} />
        <strong>{label}</strong>
        <label>&nbsp;({value})</label>
      </div>
    ))
  )

  const renderListBody = () => {
    const { groups, allTimeGroups } = chartData;

    if (groups) {
      return groups.map((item, index: number) => {
        let labels: string[] = [];
        const fromDate = moment(item.fromDate);
        const toDate = moment(item.toDate);

        switch (viewMode.title) {
          case ViewMode.Day:
            labels = [fromDate.format(FORMAT_DDDD), fromDate.format(FORMAT_MMMM_D_YYYY)];

            break;

          case ViewMode.Week: {
            const label = `
              ${fromDate.format(FORMAT_MMMM_D)} -
              ${toDate.month() != fromDate.month() ? toDate.format(FORMAT_MMMM_D) : toDate.format('D')}`;

            if (label) {
              labels = [`Week ${index + 1}`, label];
            }

            break;
          }

          case ViewMode.Month:
            labels = [fromDate.format(FORMAT_MMMM)];

            break;

          case ViewMode.Year:
            labels = [fromDate.format(FORMAT_YYYY)];

            break;

          default:
            break;
        }

        if (labels.length > 0) {
          return (
            <tr key={index}>
              {labels.map((label, i) => <td key={i.toString()}>{label}</td>)}

              {isLandingEnabled && !isSharecare && <td>{item.landing}</td>}
              {isQuizEnabled && !isSharecare && <td>{item.quiz}</td>}
              {isEligibleEnabled && !isSharecare && toDisplayEligible && <td>{item.eligible}</td>}
              {isScheduledEnabled && !isSharecare && <td>{item.scheduled}</td>}
              {isSignedUpEnabled && <td>{item.signedUp}</td>}
              <td>{item.total}</td>
            </tr>
          )
        }
      });
    } else if (allTimeGroups) {
      return allTimeGroups.map((item, index: number) => (
        <tr key={index}>
          <td>{item.title}</td>

          {isQuizEnabled && !isSharecare && <td>{item.quiz}</td>}
          {isEligibleEnabled && !isSharecare && toDisplayEligible && <td>{item.eligible}</td>}
          {isScheduledEnabled && !isSharecare && <td>{item.scheduled}</td>}
          {isSignedUpEnabled && <td>{item.signedUp}</td>}
          <td>{item.total}</td>
        </tr>
      ))
    }

    return <></>
  }

  const renderList = () => {
    if (!hasData) {
      return <NoResults />
    }

    return (
      <Table className="data-list">
        <thead>
          <tr>
            <th>
              <FontAwesomeIcon icon={faCalendar} />

              {viewMode.title}
            </th>

            {(viewMode.title == ViewMode.Day || viewMode.title == ViewMode.Week) &&
              <th>
                <FontAwesomeIcon icon={faCalendarDay} />
                Date
              </th>
            }

            {chartData.groups && isLandingEnabled && !isSharecare &&
              tooltipComponent(INFO_LANDING, <th>{Filter.Landing}</th>, 'left')}
            {isQuizEnabled && !isSharecare &&
              tooltipComponent(INFO_QUIZ, <th>{Filter.Quiz}</th>, 'left')}
            {isEligibleEnabled && !isSharecare && toDisplayEligible &&
              tooltipComponent(INFO_ELIGIBLE_GREEN, <th>{Filter.Eligibility}</th>, 'left')}
            {isScheduledEnabled && !isSharecare &&
              tooltipComponent(INFO_SCHEDULED, <th>{Filter.Scheduler}</th>, 'left')}
            {isSignedUpEnabled &&
              tooltipComponent(INFO_SIGNEDUP, <th>{Filter.SignedUp}</th>, 'left')}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {renderListBody()}
        </tbody>
      </Table>
    )
  }

  const renderNavigator = () => {
    let label = 'All Time';
    const { groups } = chartData;

    if (groups) {
      const first = groups[0];
      const last = groups[groups.length - 1];

      switch (viewMode.title) {
        case ViewMode.Day:
          label = `
            ${moment(first.fromDate).format(FORMAT_MMMM_D)} -
            ${moment(last.fromDate).format(FORMAT_MMMM_D_YYYY)}`;

          break;

        case ViewMode.Week:
          label = moment(navigationDate).format(FORMAT_MMMM_YYYY);

          break;

        case ViewMode.Month:
          label = moment(navigationDate).format(FORMAT_YYYY);

          break;

        case ViewMode.Year:
          label = `
            ${moment(first.fromDate).format(FORMAT_YYYY)} -
            ${moment(last.fromDate).format(FORMAT_YYYY)}`;

          break;

        default:
          break;
      }
    }

    return (
      <div className={`SignupAnalytics-navigatorContainer ${className}`}>
        {groups &&
          <button
            className="SignupAnalytics-navButton"
            disabled={isFetching || !hasData}
            onClick={() => onNavigate('backward')}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        }

        <label>{(!isFetching && hasData) && label}</label>

        {groups &&
          <button
            className="SignupAnalytics-navButton"
            disabled={isFetching || !hasData}
            onClick={() => onNavigate('forward')}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        }
      </div>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Signup Analytics" />

        {renderFilters()}
        {renderEmployer()}

        <span className="info">
          *Based upon UTC time
        </span>

        {renderGraph()}

        {isFetching ?
          <div className="center-container">
            <Spinner />
          </div> :
          renderList()
        }
      </Container>
    </>
  )
}
