import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from '../api/superuser';

import { fetchClasses, Types } from '../redux/actions/SuperuserHome';

export function* fetchClassesSaga(): Generator {
  try {
    const client = new API();
    const response = yield call(client.fetchClasses);

    // @ts-ignore
    yield put(fetchClasses.success(response));
  } catch(err) {
    yield put(fetchClasses.failure(err));
  }
}

export default [
  takeLatest(Types.FETCH_CLASSES, fetchClassesSaga)
]
