import React, { useEffect } from 'react';
import LoaderSpinner from 'react-loader-spinner';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { practiceConstants } from '../../utils';

interface Props {
  toDisplay: boolean;
}

export default function Loader(props: Props) {
  const { toDisplay } = props;

  useEffect(() => {
    if (toDisplay) {
      disableBodyScroll();
    }

    return () => enableBodyScroll();
  })

  return (
    <div className="Loader-div">
      <div className="Loader">
        <LoaderSpinner
          type="Oval"
          color={practiceConstants().primaryColor}
          height={100}
          width={100} />
      </div>
    </div>
  )
}
