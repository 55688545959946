import { toast } from 'react-toastify';
import axios from 'axios';

import { EligibilityError } from '../types/eligibility-errors';

import { practiceConstants } from '../utils';

import APIClient, { autoClose } from '.';

export default class ConsumerPaymentsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchEligibilityErrors = async (
    queryString: string,
  ): Promise<[EligibilityError[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/eligibility-errors${queryString}`;
        const response = await this.instance.get(url);
        const { from, to, total } = response.data.meta;
        const results = response.data.data.map((i: any): EligibilityError => ({
          firstName: i.first_name,
          lastName: i.last_name,
          email: i.email,
          date: new Date(`${i.error_date} ${i.error_time}`),
          message: i.error_message,
        }));

        resolve([results, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
