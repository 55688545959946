import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RouteComponentProps } from 'react-router';

import MonthlyInvoicesAPIClient from '../../../api/monthly-invoices';

import AppNav from '../../../components/AppNav';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import NoResults from '../../../components/NoResults';
import Spinner from '../../../components/Spinner';
import TotalResults from '../../../components/TotalResults';

import { useAPIPagination } from '../../../hooks/pagination';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import { DietitianPaymentData } from '../../../types/dietitian-payments';

import { practiceConstants, queryStringParams } from '../../../utils';
import {
  BASE_URL_INGENUITY,
  PER_PAGE,
  ROUTES,
} from '../../../utils/constants';

import MonthlyInvoiceList from './List';

interface Props extends RouteComponentProps {}

export default function MonthlyInvoices(props: Props) {
  const PERIOD_FILTER = [
    { title: 'Month', value: 'month' },
    { title: 'Year', value: 'year' },
  ];
  const VIEW_FILTER = [
    { title: 'Milestone', value: 'milestone' },
    { title: 'Session', value: 'session' },
  ];

  const api = new MonthlyInvoicesAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className, defaultEmployer } = practiceConstants();

  // Filter states
  const [employer, setEmployer] = useState(defaultEmployer);
  const [periodFilter, setPeriodFilter] = useState(PERIOD_FILTER.filter(e => (
    e.value == query[QueryKeys.Period]
  ))[0] || PERIOD_FILTER[0]);

  // Dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [users, setUsers] = useState<DietitianPaymentData[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.Company]: employer.value,
    [QueryKeys.Period]: periodFilter.value,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchMonthlyInvoices(queryString)
      .then(response => {
        const [list, i, j, t] = response;

        setUsers(list);
        setTotal(t);
        setFrom(i);
        setTo(j);
        setIsFetching(false);
      })
      .catch(() => {
        setUsers([]);
        setTotal(0);
        setFrom(0);
        setTo(0);
        setIsFetching(false);
      });
  }, [currentPage, periodFilter])

  const renderFilters = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={1}>
          <h3 className="primary-title">Filters</h3>
        </Col>
        <Col xs={12} lg={10} className="controls">
          {/* <Dropdown
              disabled={employers.length <= 1}
              items={employers}
              selectedItem={employer}
              title="Employer"
              onItemSelection={(item) => {
                setCurrentPage(0);
                setEmployer(item);
              }} /> */}
          <Dropdown
            items={PERIOD_FILTER}
            selectedItem={periodFilter}
            title="Payment Period"
            onItemSelection={(item) => {
              setCurrentPage(0);
              setPeriodFilter(item);
            }} />
          {/* <Dropdown
              disabled={true}
              items={VIEW_FILTER}
              selectedItem={viewFilter}
              title="View"
              onItemSelection={(item) => {
                setCurrentPage(0);
                setViewFilter(item);
              }} /> */}
        </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <MonthlyInvoiceList
          currentPage={currentPage}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={pagedUsers}
          type={VIEW_FILTER[0].value}
          setCurrentPage={setCurrentPage}
          setIsFetching={setIsFetching}
          setUsers={setUsers}
          viewOnly={periodFilter.value == 'year'}
          onItemSelection={(item: DietitianPaymentData) => {
            const { id, period } = item;
            const { pathname, search } = props.location;
            const prevPath = `${pathname}${search}`;
            const payload = {
              id,
              period,
              company: employer.value,
              prevPath
            };
            const root = ROUTES.SuperuserMonthlyInvoiceDetails;
            const urlId = id;
            const url = root
              .replace(':id', urlId)
              .replace(':period', payload.period);

            props.history.push(url, payload);
          }}
        />
      </>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title={"Monthly Invoice"}>
        </Header>

        {renderFilters()}

        {(isFetching && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> :
          renderList()
        }
      </Container>
    </>
  );
}
