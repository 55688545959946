import {
  call,
  put,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import API from '../api/superuser';

import {
  createClass,
  deleteClass,
  fetchClassDetails,
  searchParticipants,
  searchDietitian,
  updateClass,

  Types,
} from '../redux/actions/SuperuserClassDetails';

export function* createClassDetailsSaga(
  action: ReturnType<typeof createClass.request>
): Generator {
  try {
    const client = new API();
    const response = yield call(client.createClass, action.payload)

    // @ts-ignore
    yield put(createClass.success(response));
  } catch (error) {
    yield put(createClass.failure(error));
  }
}

export function* deleteClassDetailsSaga(
  action: ReturnType<typeof deleteClass.request>
): Generator {
  try {
    const client = new API();
    const response = yield call(client.deleteClass, action.payload)

    // @ts-ignore
    yield put(createClass.success(response));
  } catch (error) {
    yield put(createClass.failure(error));
  }
}

export function* fetchClassDetailsSaga(
  action: ReturnType<typeof fetchClassDetails.request>
): Generator {
  try {
    const client = new API();
    const response = yield call(client.fetchClassDetails, action.payload);

    // @ts-ignore
    yield put(fetchClassDetails.success(response));
  } catch (error) {
    yield put(fetchClassDetails.failure(error));
  }
}

export function* searchDietitianSaga(
  action: ReturnType<typeof searchDietitian.request>
): Generator {
  try {
    const client = new API();
    const response = yield call(client.searchDietitians, action.payload);

    // @ts-ignore
    yield put(searchDietitian.success(response));
  } catch (error) {
    yield put(searchDietitian.failure(error));
  }
}

export function* searchParticipantsSaga(
  action: ReturnType<typeof searchParticipants.request>
): Generator {
  try {
    const client = new API();
    const response = yield call(client.searchParticipants, action.payload);

    // @ts-ignore
    yield put(searchParticipants.success(response));
  } catch (error) {
    yield put(searchParticipants.failure(error));
  }
}

export function* updateClassDetailsSaga(
  action: ReturnType<typeof updateClass.request>
): Generator {
  try {
    const client = new API();
    const response = yield call(client.updateClass, action.payload)

    // @ts-ignore
    yield put(updateClass.success(response));
  } catch (error) {
    yield put(updateClass.failure(error));
  }
}

export default [
  takeLatest(Types.FETCH_CLASS_DETAILS, fetchClassDetailsSaga),
  takeLatest(Types.CREATE_CLASS, createClassDetailsSaga),
  takeLatest(Types.SEARCH_DIETITIAN, searchDietitianSaga),
  takeLatest(Types.SEARCH_PARTICIPANTS, searchParticipantsSaga),
  takeLatest(Types.UPDATE_CLASS, updateClassDetailsSaga),
  takeLeading(Types.DELETE_CLASS, deleteClassDetailsSaga),
]
