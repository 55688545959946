import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import moment from 'moment';
// import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faTable } from '@fortawesome/free-solid-svg-icons';
import { Subscription, Observable } from 'rxjs';

import {
  FetchWeightLossTrackingRequest,
  WeightLossTrackingPayload,
} from '../../../api/weight-loss';

import AppNav from '../../../components/AppNav';
import Header from '../../../components/Header';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Dropdown from '../../../components/Dropdown';
import NoResults from '../../../components/NoResults';
import Pagination from '../../../components/Pagination';
import TotalResults from '../../../components/TotalResults';
import SearchInput from '../../../components/SearchInput';
import Spinner from '../../../components/Spinner';

import { useAPIPagination } from '../../../hooks/pagination';
import useDebounce from '../../../hooks/useDebounce';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import {
  practiceConstants,
  queryStringParams,
  scrollToNode,
} from '../../../utils';
import {
  PER_PAGE,
  ROUTES,
  FORMAT_YYYY_MM_DD,
} from '../../../utils/constants';
import { WeightLossAnalyticsData } from '../../../utils/types';

import ParticipantCard from './ParticipantCard';
import ParticipantChart from './ParticipantChart';

import './index.scss';

interface Props extends RouteComponentProps {}

export default function WeightLossTracking(props: Props) {
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className, defaultEmployer, employers } = practiceConstants();

  // Filter states
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value === query[QueryKeys.Company]
  ))[0] || defaultEmployer);
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );
  const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');

  // Dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [users, setUsers] = useState<WeightLossAnalyticsData[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  let fetchSubscription: Subscription;
  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.Company]: employer.value,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Search]: debouncedSearchTerm,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      fetchSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (fetchSubscription) {
      fetchSubscription.unsubscribe();
    }

    setIsFetching(true);
    props.history.replace(queryString);

    const request = new Observable((observer) => {
      const payload: WeightLossTrackingPayload = {
        company: employer.value,
        page: currentPage + 1,
      }

      if (startDate) {
        payload.from = startDate;
      }

      if (endDate) {
        payload.to = endDate;
      }

      if (debouncedSearchTerm) {
        payload.search = debouncedSearchTerm;
      }

      return new FetchWeightLossTrackingRequest(payload, observer);
    });

    fetchSubscription = request.subscribe((response: any) => {
      const { items, total, to, from } = response;

      setIsFetching(false);
      setUsers(items);
      setFrom(from);
      setTo(to);
      setTotal(total);
    }, () => {
      setIsFetching(false);
      setUsers([]);
      setFrom(0);
      setTo(0);
      setTotal(0);
    });
  }, [employer, startDate, endDate, currentPage, debouncedSearchTerm])

  const onChangeText = text => {
    setIsFetching(true);
    setCurrentPage(0);
    setSearchFilter(text);
  }

  const onChangePage = (page: number) => {
    scrollToNode('WeightLossTracking-header');
    setCurrentPage(page);
  }

  // Detect hash
  const hash = window.location.hash;
  const defaultTab = hash === '#charts' ? 'charts' : 'cards';

  const renderFilters = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={2}>
          <h3 id="WeightLossTracking-header" className="primary-title">
            Filters
          </h3>
        </Col>
        <Col xs={12} lg={8} className="controls">
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={item => {
              setCurrentPage(0);
              setEmployer(item);
            }} />
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Landing Date"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0]);
              setEndDate(dates[dates.length - 1]);
            }}
            onStartDateSelection={date => {
              setCurrentPage(0);
              setStartDate(date);
            }}
            onEndDateSelection={date => {
              setCurrentPage(0);
              setEndDate(date);
            }}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />

          <div className={`weightloss-tracking__views-toggle ${className}`}>
            <div className="Dropdown-infoLabel">View</div>
            <Nav>
              <Nav.Item>
                <Nav.Link href="#cards" eventKey="cards">
                  <FontAwesomeIcon icon={faTable} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#charts" eventKey="charts">
                  <FontAwesomeIcon icon={faChartLine} />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    </div>
  )

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Weight Loss Tracking">
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Participant Name"
            onChangeText={(text, event) => {
              if (event) {
                event.persist();
                onChangeText(text);
              }
            }} />
        </Header>

        <Tab.Container defaultActiveKey={defaultTab} mountOnEnter>
          {renderFilters()}

          {(isFetching && users.length == 0) ?
            <div className="center-container">
              <Spinner />
            </div> : (
            <>
              <TotalResults
                total={total}
                from={from}
                to={to}
              />
              <Tab.Content
                className={className}
                style={{ opacity: isFetching ? 0.5 : 1 }}
              >
                <Tab.Pane eventKey="cards">
                  {users.length > 0
                    ? <>
                        {users.map((u, i) => <ParticipantCard key={i} participant={u} />)}

                        <Pagination
                          currentPage={currentPage}
                          pages={numPages}
                          onChangePage={onChangePage}
                        />
                      </>
                    : <NoResults />}
                </Tab.Pane>
                  <Tab.Pane eventKey="charts">
                    {users.length > 0
                      ? <>
                        {users.map((u, i) => <ParticipantChart key={i} participant={u} />)}

                        <Pagination
                          currentPage={currentPage}
                          pages={numPages}
                          onChangePage={onChangePage}
                        />
                      </>
                      : <NoResults />}
                  </Tab.Pane>
              </Tab.Content>
            </>
          )}
        </Tab.Container>
      </Container>
    </>
  );
}
