import React, { useState, useEffect }  from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCaretDown,
  faCaretUp,
  faClipboard,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../components/Pagination';
import SuperuserClassRow from '../../components/SuperuserClassRow';

import usePagination from '../../hooks/pagination';

import { store } from '../../redux';
import { storePage } from '../../redux/actions/SuperuserHome';

import { scrollToNode } from '../../utils';
import { PER_PAGE } from '../../utils/constants';
import { ClassItem, ClassSort } from '../../utils/types';

import NoResults from '../NoResults';

type Props = Readonly<{
  classes: ClassItem[];
  isDescending: boolean;
  sortKey: ClassSort;
  onRowClick: (classDetails: ClassItem) => void;
  setIsDescending: (key: ClassSort, isDescending: boolean) => void;
}>

export default function SuperuserClassList(props: Props) {
  const initialSortDict = {
    [ClassSort.DateTime]: true,
    [ClassSort.Day]: true,
    [ClassSort.Size]: true,
    [ClassSort.Dietitian]: true,
  };
  const {
    classes,
    onRowClick,
    setIsDescending,
  } = props;

  const [sortKey, setSortKey] = useState(props.sortKey);
  const [filteredClasses, setFilteredClasses] = useState(classes);
  const [sortDict, setSortDict] = useState({
    ...initialSortDict,
    [props.sortKey]: props.isDescending
  });

  // Pagination
  const [
    currentPage,
    numPages,
    pagedClasses,
    setCurrentPage
  ] = usePagination(filteredClasses, PER_PAGE, sortKey, sortDict[sortKey]);

  useEffect(() => {
    if (currentPage !== store.getState().superuserHome.currentPage) {
      setCurrentPage(store.getState().superuserHome.currentPage);
    }
  }, [])

  if (classes !== filteredClasses) {
    setCurrentPage(0);
    setFilteredClasses(classes);
  }

  const onSortColumn = (key: ClassSort) => {
    const value = !sortDict[key];

    setCurrentPage(0);
    setIsDescending(key, value);
    setSortKey(key);
    setSortDict({ ...sortDict, [key]: value });
  }

  const renderColumnHeader = (key: ClassSort, icon: IconDefinition) => (
    <th
      className={`sort${key == sortKey ? ' enabled' : ''}`}
      onClick={() => onSortColumn(key)}
    >
      <FontAwesomeIcon icon={icon} />
      {key}
      <FontAwesomeIcon className="left" icon={sortDict[key] ? faCaretDown : faCaretUp} />
    </th>
  )

  return (pagedClasses && pagedClasses.length > 0 ?
    <>
      <Table className="data-list">
        <thead>
          <tr>
            <th>ID</th>
            {renderColumnHeader(ClassSort.DateTime, faCalendar)}
            {renderColumnHeader(ClassSort.Day, faCalendarDay)}
            {renderColumnHeader(ClassSort.Size, faUsers)}
            {renderColumnHeader(ClassSort.Dietitian, faClipboard)}
            <th>
              <FontAwesomeIcon icon={faCalendarCheck} />
              Status
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pagedClasses.map((item: ClassItem, index: number) => (
            <SuperuserClassRow
              key={item.id}
              index={index}
              classDetails={item}
              onActionClick={onRowClick} />
          ))}
        </tbody>
      </Table>
      <div className="pagination-wrap">
        <Pagination
          currentPage={currentPage}
          pages={numPages}
          onChangePage={p => {
            scrollToNode('SuperuserClass-row0');
            store.dispatch(storePage(p));
            setCurrentPage(p)
          }}
        />
      </div>
    </>
    :
    <NoResults subject="classes" />
  )
}
