import React, { useState, ReactNode, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import LinkedInTag from 'react-linkedin-insight';

import AppNav from '../../components/AppNav';

import { store } from '../../redux';
import { logout } from '../../redux/actions/Authentication';

import { isEligibleForSignup, quizModalComponent } from '../../utils';
import { ANALYTICS } from '../../utils/constants';
import { fbPixelTrackPageViewEvent } from '../../utils/tracking';
import { ClassDayOption, ClassTimeOption } from '../../utils/types';

import './index.scss';
import DaySelector from './DaySelector';
import ClassSelect from './ClassSelect';
import TimeSelector from './TimeSelector';

export default function ParticipantWizard() {
  const [step, setStep] = useState(0);
  const [day, setDay] = useState(ClassDayOption.WEEKDAY);
  const [time, setTime] = useState(ClassTimeOption.MORNING);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const [isValid] = isEligibleForSignup(2);

    // TODO: Logout existing user; Does not reflect immediately unless page is reloaded
    store.dispatch(logout());

    if (!isValid) {
      setShowModal(true);

      return;
    }

    fbPixelTrackPageViewEvent();

    // Initialize LinkedIn Insight
    LinkedInTag.init(ANALYTICS.Insight);
  })

  let component: ReactNode;

  switch (step) {
    case 0:
      component = (
        <DaySelector
          onDaySelect={(d) => {
            setDay(d);
            setStep(1);
          }}
        />
      );
      break;
    case 1:
      component = (
        <TimeSelector
          onTimeSelect={(t) => {
            setTime(t);
            setStep(2);
          }}
        />
      );
      break;
    case 2:
      component = (
        <ClassSelect
          day={day}
          time={time}
        />
      );
      break;
  }

  return (
    <>
      <AppNav hideMenu />
      <Container>
        {showModal && quizModalComponent(showModal)}
        {component}
      </Container>
    </>
  );
}
