type GroupData = {
  total: number;
  landing: number;
  quiz: number;
  eligible: number;
  scheduled: number;
  signedUp: number;
  fromDate: Date;
  toDate: Date;
}

type AllTimeData = {
  title: string;
  total: number;
  quiz: number;
  eligible: number;
  scheduled: number;
  signedUp: number;
}

export type SignupAnalyticsData = {
  total: number;
  groups?: GroupData[];
  allTimeGroups?: AllTimeData[];
  landing?: number;
}

export enum SignupAnalyticsViewMode {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  AllTime = 'All Time',
  Year = 'Year',
}
