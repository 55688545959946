import React from 'react';

import NoResultsImage from '../../assets/images/no-results.png';

import './index.scss';

interface Props {
  subject?: string;
}

export default function NoResults(props: Props) {
  const subject = props.subject || 'users';

  return (
    <div className="NoResults-container">
      <img
        src={NoResultsImage}
        alt="No results"
        className="illustration"
      />
      <label>
        We do not currently have any {subject} that meet this criteria.<br />
        Please select a new criteria to see other {subject}.
      </label>
    </div>
  );
}
