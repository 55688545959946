import round from 'lodash-es/round';

/**
 * Formats a decimal value to a USD currency.
 *
 * @param num decimal value
 */
export const formatCurrency = (num: number | string, hasDecimalPoints: boolean | undefined = true) => {
  let options: any = { style: 'currency', currency: 'USD' };

  if (!hasDecimalPoints) {
    options = {
      ...options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  }

  return num.toLocaleString(undefined, options);
}

/**
 * Formats a decimal value into a readable percent string.
 *
 * @param val decimal value
 * @param formatOnly boolean to determine if value is already in percentage
 * @param precision number of decimals
 */
export function formatPercent(val: number | null, formatOnly = false, precision = 2) {
  if (val !== null) {
    if (formatOnly) {
      return `${round(val, precision)}%`;
    }

    const percent = round(val * 100, precision);

    return `${percent}%`;
  }

  return '';
}

/**
 * Format a decimal value into a readable number string.
 *
 * @param num decimal or string value
 */
export const formatNumber = (num: number | string) => {
  let val = num;

  if (typeof val == "number") {
    val = val.toString();
  }

  return val.replace(/(.)(?=(\d{3})+$)/g, '$1,');
}
