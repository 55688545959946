import React, { ReactNode } from 'react';

import Pagination from '../Pagination';
import usePagination from '../../hooks/pagination';

type Props = Readonly<{
  currentPage: number;
  items: any[],
  perPage?: number;
  renderItem: (item: any) => ReactNode;
  onChangePage: (page: number) => void;
}>

export default function Paginate(props: Props) {
  const { currentPage, items, perPage, renderItem, onChangePage } = props;
  const [page, numPages, pagedItems, setPage] = usePagination(items, perPage || 5);

  if (currentPage !== page) {
    setPage(currentPage);
  }

  return (
    <>
      <div className="paginate__items">
        {pagedItems.map(i => renderItem(i))}
      </div>
      <div className="paginate__pagination pagination-wrap">
        <Pagination
          currentPage={page}
          pages={numPages}
          onChangePage={p => {
            setPage(p);
            onChangePage(p);
          }}
        />
      </div>
    </>
  );
}
