import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { RouteComponentProps } from 'react-router';
import {
  faUserTimes,
  faUserCheck,
  faUsers,
  faMale,
  faFemale,
  faWeight,
  faRulerVertical,
  faTrophy,
  faClipboardCheck,
  faTimes,
  faCheck,
  faMoneyCheckAlt,
  faCalendarWeek,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import classnames from 'classnames';
import _ from 'lodash';

import EmployerAnalyticsAPIClient from '../../../api/employer-analytics';

import AppNav from '../../../components/AppNav';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';

import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import {
  EmployerPreDiabetesStats,
  EmployerEnrollmentStats,
  EmployerMilestoneStats,
  EmployerEligibilityStats,
  EmployerClaimsStats,
  EmployerWeightLossStats,
} from '../../../types/employer-analytics';

import {
  downloadCSV,
  practiceConstants,
  queryStringParams,
  timezoneAbbreviation,
} from '../../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_MM_DD_YYYY,
  FORMAT_MMM_YYYY,
  FORMAT_MMMM,
  FORMAT_YYYY_MM_DD,
  ROUTES,
  US_STATES_SELECTIONS,
  YEARS_FILTER,
} from '../../../utils/constants';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from '../../../utils/formatting';
import { ExportType as StatType, } from '../../../utils/types';

import EmployerStatCard, { EmployerStatCardData, EmployerStatColumn } from './Card';
import ChartModal, { RED, GREEN, YELLOW, BLUE } from './ChartModal';
import ExportModal from './ExportModal';
import EmployerWeightLossStatCard from './WeightLoss';

import './index.scss';

interface Props extends RouteComponentProps { }

export default function EmployerAnalytics(props: Props) {
  const api = new EmployerAnalyticsAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const { className, defaultEmployer, employers, primaryColor } = practiceConstants();
  const months = moment.months().map(item => ({ title: item, value: item.toLowerCase() }));

  // Filter States
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value == query[QueryKeys.Company]
  ))[0] || defaultEmployer);
  const [month, setMonth] = useState(months.filter(item => (
    item.value == (query[QueryKeys.Month] || moment(new Date()).format(FORMAT_MMMM).toLowerCase())
  ))[0]);
  const [year, setYear] = useState(YEARS_FILTER.filter(e => (
    e.value == query[QueryKeys.Year]
  ))[0] || YEARS_FILTER[0]);
  const [startWeightLossDate, setStartWeightLossDate] = useState(
    query[QueryKeys.FromDate] ? new Date(query[QueryKeys.FromDate]) :
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endWeightLossDate, setEndWeightLossDate] = useState(
    query[QueryKeys.ToDate] ? new Date(query[QueryKeys.ToDate]) : new Date()
  );

  // States
  const [lastUpdated, setLastUpdated] =  useState<Date>();
  const [lastUpdatedWeightLoss, setLastUpdatedWeightLoss] =  useState<Date>();
  const [selectedData, setSelectedData] = useState<EmployerStatCardData>();
  const [toDisplayModal, setToDisplayModal] = useState(false);
  const [toDisplayExportModal, setToDisplayExportModal] = useState(false);

  // Dataset states
  const [hasStats, setHasStats] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isExportingWeightLoss, setIsExportingWeightLoss] = useState(false);
  const [isFetchingWeightLoss, setIsFetchingWeightLoss] = useState(true);
  const [preDiabetesStats, setPreDiabetesStats] = useState<EmployerPreDiabetesStats[]>();
  const [enrollmentStats, setEnrollmentStats] = useState<EmployerEnrollmentStats>();
  const [milestoneStats, setMilestoneStats] = useState<EmployerMilestoneStats[]>();
  const [eligibilityStats, setEligibilityStats] = useState<EmployerEligibilityStats[]>();
  const [claimsStats, setClaimsStats] = useState<EmployerClaimsStats[]>();
  const [weightLossStats, setWeighLossStats] = useState<EmployerWeightLossStats>();

  const queryString = useQueryString({
    [QueryKeys.Company]: employer.value,
    [QueryKeys.Month]: month.value,
    [QueryKeys.Year]: year.value,
    [QueryKeys.FromDate]: moment(startWeightLossDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: moment(endWeightLossDate).format(FORMAT_YYYY_MM_DD),
  });

  useEffect(() => {
    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchEmployerAnalytics(employer.value, month.value, year.value)
      .then(response => {
        setTimeout(() => {
          const [pds, er, ms, es, cr] = response;

          setIsFetching(false);
          setPreDiabetesStats(pds);
          setEnrollmentStats(er);
          setMilestoneStats(ms);
          setClaimsStats(cr);
          setEligibilityStats(es);
          setLastUpdated(new Date());
          setHasStats(true);
        }, 250);
      })
      .catch(() => {
        setIsFetching(false);
        setPreDiabetesStats(undefined);
        setEnrollmentStats(undefined);
        setMilestoneStats(undefined);
        setClaimsStats(undefined);
        setEligibilityStats(undefined);
        setHasStats(false);
      })
  }, [employer, month, year])

  useEffect(() => {
    setIsFetchingWeightLoss(true);
    props.history.replace(queryString);
    api.fetchEmployerWeightLoss(employer.value, startWeightLossDate, endWeightLossDate)
      .then(response => {
        const wl = response;

        setIsFetchingWeightLoss(false);
        setWeighLossStats(wl);
        setLastUpdatedWeightLoss(new Date());
      })
      .catch(() => {
        setIsFetchingWeightLoss(false);
        setWeighLossStats(undefined);
      })
  }, [employer, startWeightLossDate, endWeightLossDate])

  const onExportWeightLoss = () => {
    setIsExportingWeightLoss(true);
    api.exportEmployerWeightLoss(employer.value, startWeightLossDate, endWeightLossDate)
      .then(response => {
        setIsExportingWeightLoss(false);
        downloadCSV(response.data, 'weight-outcomes.csv');
      })
      .catch(() => {
        setIsExportingWeightLoss(false);
      })
  }

  const renderCard = (data: EmployerStatCardData) => (
    <EmployerStatCard
      data={data}
      disabled={isFetching}
      onClick={data => {
        setSelectedData(data);
        setToDisplayModal(true);
      }}
      onExportClick={data => {
        setSelectedData(data);
        setToDisplayExportModal(true);
      }}
    />
  )

  const renderFilters = () => (
    <div className={`filters-section border-bottom ${className}`}>
      <Row>
        <Col xs={12} lg={4}>
          <h3 className="primary-title">
            Filters&nbsp;&nbsp;

              {lastUpdated &&
              <span>
                Last Updated&nbsp;
                  {moment(lastUpdated).format(FORMAT_MM_DD_YYYY)}&nbsp;
                  {timezoneAbbreviation(lastUpdated)}
              </span>
            }
          </h3>
        </Col>
        <Col xs={12} lg={8} className="controls">
          <Row>
            <Dropdown
              disabled={employers.length <= 1}
              items={employers}
              selectedItem={employer}
              title="Employer"
              onItemSelection={item => setEmployer(item)} />
            <Col>
              <div className="month">
                <Dropdown
                  items={months}
                  selectedItem={month}
                  title="Month"
                  onItemSelection={item => setMonth(item)} />
                <Dropdown
                  items={YEARS_FILTER}
                  selectedItem={year}
                  onItemSelection={item => setYear(item)} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

  const renderWeightLossFilters = () => (
    <div className={`filters-section border-bottom ${className}`} style={{ marginTop: 30 }}>
      <Row>
        <Col xs={12} lg={6}>
          <h3 className="primary-title">
            Filters&nbsp;&nbsp;

              {lastUpdatedWeightLoss &&
              <span>
                Last Updated&nbsp;
                  {moment(lastUpdatedWeightLoss).format(FORMAT_MM_DD_YYYY)}&nbsp;
                  {timezoneAbbreviation(lastUpdatedWeightLoss)}
              </span>
            }
          </h3>
        </Col>
        <Col xs={12} lg={4} className="controls date">
          <Row>
            <Col>
              <DateDropdownGroup
                currentEndDate={endWeightLossDate}
                currentStartDate={startWeightLossDate}
                isInstant
                label="Landing Date"
                onDatesSelection={dates => {
                  setStartWeightLossDate(dates[0])
                  setEndWeightLossDate(dates[dates.length - 1])
                }}
                onStartDateSelection={date => setStartWeightLossDate(date)}
                onEndDateSelection={date => setEndWeightLossDate(date)}
                onResetDatesSelection={() => {
                  setStartWeightLossDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
                  setEndWeightLossDate(new Date());
                }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

  const renderPreDiabetesQuizResults = () => {
    if (!preDiabetesStats) {
      return;
    }

    const stats  = preDiabetesStats;
    const current = stats[stats.length -1];
    const data: EmployerStatColumn[] = [{
      icon: faUsers,
      header: 'Times Quiz Taken',
      value: formatNumber(current.total),
      hasHighLow: true,
      isDanger: false,//preDiabetesStats.timesQuizTaken < preDiabetesStats.goalTimesQuizTaken,
      subtitle: '',//'Goal: ',
      subValue: ''//formatNumber(preDiabetesStats.goalTimesQuizTaken)
    }, {
      icon: faUserTimes,
      header: 'High Risk',
      value: formatNumber(current.highRisk),
      hasHighLow: true,
      isDanger: true,
      subtitle: formatPercent(current.highRiskPercentage, true, 0),
      subValue: ' of quiz results'
    }, {
      icon: faUserCheck,
      header: 'Low Risk',
      value: formatNumber(current.lowRisk),
      hasHighLow: true,
      isDanger: false,
      subtitle: formatPercent(current.lowRiskPercentage, true, 0),
      subValue: ' of quiz results'
    }];

    return renderCard({
      type: StatType.PreDiabetes,
      columns: data,
      month,
      year,
      chartData: {
        labels: stats.map(item => moment(item.fromDate).format(FORMAT_MMM_YYYY)),
        datasets: [{
          label: 'High Risk',
          data: stats.map(item => item.highRisk),
          backgroundColor: RED,
        }, {
          label: 'Low Risk',
          data: stats.map(item => item.lowRisk),
          backgroundColor: GREEN,
        }]
      },
      exportItems: [
        { title: 'High Risk', value: 'high' },
        { title: 'Low Risk', value: 'low' },
      ]
    });
  }

  const renderEnrollmentResults = () => {
    if (!enrollmentStats) {
      return;
    }

    const stats = enrollmentStats;
    const data: EmployerStatColumn[] = [{
      icon: faUserTimes,
      header: 'High Risk',
      value: formatNumber(enrollmentStats.highRisk),
      hasHighLow: true,
      isDanger: true,
      subtitle: 'Total No.',
      subValue: '',
    }, {
      icon: faMale,
      header: 'Male',
      value: formatNumber(stats.male),
      hasHighLow: false,
      subtitle: formatPercent(
        stats.malePercentage,
        true,
        0
      ),
      subValue: ' of quiz results'
    }, {
      icon: faFemale,
      header: 'Female',
      value: formatNumber(stats.female),
      hasHighLow: false,
      subtitle: formatPercent(
        stats.femalePercentage,
        true,
        0
      ),
      subValue: ' of quiz results'
    }, {
      icon: faWeight,
      header: 'Ave. Weight',
      value: formatNumber(stats.aveWeight),
      valueSuffix: 'lbs',
      hasHighLow: true,
      isDanger: stats.aveWeight > stats.goalAveWeight,
      subtitle: 'Goal: ',
      subValue: `${stats.goalAveWeight}lbs`
    }, {
      icon: faWeight,
      header: 'Ave. BMI',
      value: formatNumber(stats.aveBMI),
      valueSuffix: stats.aveBMI > stats.goalMaxAveBMI ? '(obese)' : undefined,
      hasHighLow: true,
      isDanger: stats.aveBMI > stats.goalMaxAveBMI || stats.aveBMI < stats.goalMinAveBMI,
      subtitle: 'Goal: ',
      subValue: `${stats.goalMinAveBMI} - ${stats.goalMaxAveBMI}`
    }, {
      icon: faRulerVertical,
      header: 'Ave. Height',
      value: stats.aveHeight,
      hasHighLow: false,
      subtitle: 'Ave. Height ',
      subValue: 'of High Risk'
    }];

    return renderCard({
      type: StatType.Enrollment,
      columns: data,
      month,
      year,
      chartData: {
        labels: _.range(10).map(index =>
          Object.keys(stats.topTenStates).map(item => (
            US_STATES_SELECTIONS.filter(state => state.value == item)[0].title
          ))[index] || ''
        ),
        datasets: [{
          label: '',
          data: Object.keys(stats.topTenStates).map(key => stats.topTenStates[key]),
          backgroundColor: primaryColor,
        }]
      },
      statesData: stats.states,
      exportItems: [
        { title: 'Male', value: 'male' },
        { title: 'Female', value: 'female' },
      ]
    });
  }

  const renderMilestoneSummary = () => {
    if (!milestoneStats) {
      return;
    }

    const stats = milestoneStats;
    const current = stats[stats.length - 1];
    const data: EmployerStatColumn[] = [{
      icon: faTrophy,
      header: 'Milestone 1',
      value: formatNumber(current.milestoneOne),
      hasHighLow: true,
      isDanger: false, //milestoneStats.milestoneOne < milestoneStats.totalMilestoneOne,
      subtitle: '',//Total: ',
      subValue: ''//formatNumber(milestoneStats.totalMilestoneOne),
    }, {
      icon: faTrophy,
      header: 'Milestone 2',
      value: formatNumber(current.milestoneTwo),
      hasHighLow: true,
      isDanger: false,//milestoneStats.milestoneTwo < milestoneStats.totalMilestoneTwo,
      subtitle: '',//Total: ',
      subValue: ''//formatNumber(milestoneStats.totalMilestoneTwo),
    }, {
      icon: faTrophy,
      header: 'Milestone 3',
      value: formatNumber(current.milestoneThree),
      hasHighLow: true,
      isDanger: false,//milestoneStats.milestoneThree < milestoneStats.totalMilestoneThree,
      subtitle: '',//Total: ',
      subValue: ''//formatNumber(milestoneStats.totalMilestoneThree),
    }, {
      icon: faTrophy,
      header: 'Milestone 4',
      value: formatNumber(current.milestoneFour),
      hasHighLow: true,
      isDanger: false,//milestoneStats.milestoneFour < milestoneStats.totalMilestoneFour,
      subtitle: '',//'Total: ',
      subValue: ''//formatNumber(milestoneStats.totalMilestoneFour),
    }];

    return renderCard({
      type: StatType.Milestone,
      columns: data,
      month,
      year,
      chartData: {
        labels: stats.map(item => moment(item.fromDate).format(FORMAT_MMM_YYYY)),
        datasets: [{
          label: 'Milestone 1',
          data: stats.map(item => item.milestoneOne),
          backgroundColor: GREEN,
        }, {
          label: 'Milestone 2',
          data: stats.map(item => item.milestoneTwo),
          backgroundColor: RED,
        }, {
          label: 'Milestone 3',
          data: stats.map(item => item.milestoneThree),
          backgroundColor: YELLOW,
        }, {
          label: 'Milestone 4',
          data: stats.map(item => item.milestoneFour),
          backgroundColor: BLUE,
        }]
      },
      exportItems: [
        { title: 'Milestone 1', value: '1' },
        { title: 'Milestone 2', value: '2' },
        { title: 'Milestone 3', value: '3' },
        { title: 'Milestone 4', value: '4' },
      ]
    });
  }

  const renderClaimsReport = () => {
    if (!claimsStats) {
      return;
    }

    const stats = claimsStats;
    const current = stats[stats.length - 1];
    const data: EmployerStatColumn[] = [{
      icon: faMoneyCheckAlt,
      header: 'Total Paid',
      value: formatCurrency(current.total, false),
      hasHighLow: true,
      isDanger: false,
      noIcon: true,
      subtitle: '',
      subValue: `To Fruit Street by ${current.provider}`,
    }, {
      icon: faMoneyCheckAlt,
      header: 'Milestone 1',
      value: formatCurrency(current.milestoneOne, false),
      hasHighLow: false,
      subtitle: '',
      subValue: 'Total Collected',
    }, {
      icon: faMoneyCheckAlt,
      header: 'Milestone 2',
      value: formatCurrency(current.milestoneTwo, false),
      hasHighLow: false,
      subtitle: '',
      subValue: 'Total Collected',
    }, {
      icon: faMoneyCheckAlt,
      header: 'Milestone 3',
      value: formatCurrency(current.milestoneThree, false),
      hasHighLow: false,
      subtitle: '',
      subValue: 'Total Collected',
    }, {
      icon: faMoneyCheckAlt,
      header: 'Milestone 4',
      value: formatCurrency(current.milestoneFour, false),
      hasHighLow: false,
      subtitle: '',
      subValue: 'Total Collected',
    }];

    return renderCard({
      type: StatType.Claims,
      columns: data,
      month,
      year,
      chartData: {
        labels: stats.map(item => moment(item.fromDate).format(FORMAT_MMM_YYYY)),
        datasets: [{
          label: 'Milestone 1',
          data: stats.map(item => item.milestoneOne),
          backgroundColor: 'transparent',
        }, {
          label: 'Milestone 2',
          data: stats.map(item => item.milestoneTwo),
          backgroundColor: 'transparent',
        }, {
          label: 'Milestone 3',
          data: stats.map(item => item.milestoneThree),
          backgroundColor: 'transparent',
        }, {
          label: 'Milestone 4',
          data: stats.map(item => item.milestoneFour),
          backgroundColor: 'transparent',
        }]
      },
      exportItems: [
        { title: 'Milestone 1', value: '1' },
        { title: 'Milestone 2', value: '2' },
        { title: 'Milestone 3', value: '3' },
        { title: 'Milestone 4', value: '4' },
      ]
    });
  }

  const renderEligibilitySummary = () => {
    if (!eligibilityStats) {
      return;
    }

    const stats = eligibilityStats;
    const current = stats[stats.length - 1];
    const data: EmployerStatColumn[] = [{
      icon: faClipboardCheck,
      header: 'Eligibility Checks',
      value: formatNumber(current.total),
      hasHighLow: false,
      subtitle: 'No. of Times',
      subValue: '',
    }, {
      icon: faTimes,
      header: 'Failed Eligibility',
      value: formatNumber(current.failed),
      hasHighLow: true,
      isDanger: true,
      noIcon: true,
      subtitle: formatPercent(current.failedPercentage, true, 0),
      subValue: ' of failed Eligibility Checks',
    }, {
      icon: faCheck,
      header: 'Successful Eligibility',
      value: formatNumber(current.success),
      hasHighLow: true,
      isDanger: false,
      noIcon: true,
      subtitle: formatPercent(current.successPercentage, true, 0),
      subValue: ' of successful Eligibility Checks',
    }];

    return renderCard({
      type: StatType.Eligibility,
      columns: data,
      month,
      year,
      chartData: {
        labels: stats.map(item => moment(item.fromDate).format(FORMAT_MMM_YYYY)),
        datasets: [{
          label: 'Failed Eligibility',
          data: stats.map(item => item.failed),
          backgroundColor: RED,
        }, {
          label: 'Successful Eligibility',
          data: stats.map(item => item.success),
          backgroundColor: GREEN,
        }]
      },
      exportItems: [
        { title: 'Failed Eligibility', value: 'failed' },
        { title: 'Successful Eligibility', value: 'successful' },
      ]
    });
  }

  const renderWeightLoss = () => {
    if (!weightLossStats) {
      return;
    }

    const data: EmployerStatColumn = {
      icon: faUsers,
      header: 'Participants',
      value: weightLossStats.participants.toString(),
      hasHighLow: false,
      subtitle: '',
      subValue: '',
    };
    const list: EmployerStatColumn[] = weightLossStats.weights.map((item: (number | null), index: number) => ({
      icon: faCalendarWeek,
      header: index == 0 ? 'Week 4' : ((index + 1) * 4).toString(),
      value: item ? item.toString() : '-',
      hasHighLow: false,
      subtitle: '',
      subValue: ''
    }))

    return (
      <div style={{ paddingBottom: 30 }}>
        <EmployerWeightLossStatCard
          data={{
            columns: [data, ...list],
            type: StatType.WeightLoss,
            start_date: startWeightLossDate,
            end_date: endWeightLossDate
          }}
          disabled={isFetching}
          isExporting={isExportingWeightLoss}
          isFetching={isFetchingWeightLoss}
          onClick={data => {
            setSelectedData(data);
            setToDisplayModal(true);
          }}
          onExportClick={onExportWeightLoss}
        />
      </div>
    )
  }

  return (
    <>
      <AppNav />

      <Container className={classnames(
        'superuser padding-bottom',
        className,
      )}>
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          title="Employer Analytics"
          titleLg={6} />

        {(!_.isUndefined(selectedData) && toDisplayModal) &&
          <ChartModal
            headerData={selectedData}
            toDisplay={toDisplayModal}
            onClose={() => setToDisplayModal(false)}
          />
        }

        {(selectedData && toDisplayExportModal) &&
          <ExportModal
            data={{
              employer,
              date: new Date(`${month.title}/01/${year.title}`),
              items: selectedData.exportItems,
            }}
            title={selectedData.type}
            toDisplay={toDisplayExportModal}
            onClose={() => setToDisplayExportModal(false)}
          />
        }

        {renderFilters()}
        {(isFetching && !hasStats) ?
          <div className="center-container">
            <Spinner />
          </div> :
          <div style={{ opacity: isFetching ? 0.5 : 1  }}>
            {renderPreDiabetesQuizResults()}
            {renderEnrollmentResults()}
            {renderMilestoneSummary()}
            {renderClaimsReport()}
            {renderEligibilitySummary()}
          </div>
        }

        {renderWeightLossFilters()}
        {(isFetchingWeightLoss && !weightLossStats) ?
          <div className="center-container">
            <Spinner />
          </div> : renderWeightLoss()
        }
      </Container>
    </>
  )
}
