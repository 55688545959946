import {
  ActionType,
  createAction,
  createAsyncAction,
} from 'typesafe-actions';

import {
  ClassItem,
  CreateClassPayload,
  Dietitian,
  Participant,
  UpdateClassPayload,
  SearchPayload,
 } from '../../utils/types';

export enum Types {
  FETCH_CLASS_DETAILS = 'FETCH_CLASS_DETAILS',
  FETCH_CLASS_DETAILS_SUCCESS = 'FETCH_CLASS_DETAILS_SUCCESS',
  FETCH_CLASS_DETAILS_ERROR = 'FETCH_CLASS_DETAILS_ERROR',
  CREATE_CLASS = 'CREATE_CLASS',
  CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS',
  CREATE_CLASS_ERROR = 'CREATE_CLASS_ERROR',
  DELETE_CLASS = 'DELETE_CLASS',
  DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS',
  DELETE_CLASS_ERROR = 'DELETE_CLASS_ERROR',
  CLEAR_CLASS_DETAILS = 'CLEAR_CLASS_DETAILS',
  SEARCH_DIETITIAN = 'SEARCH_DIETITIAN',
  SEARCH_DIETITIAN_SUCCESS = 'SEARCH_DIETITIAN_SUCCESS',
  SEARCH_DIETITIAN_ERROR = 'SEARCH_DIETITIAN_ERROR',
  SEARCH_PARTICIPANTS = 'SEARCH_PARTICIPANTS',
  SEARCH_PARTICIPANTS_SUCCESS = 'SEARCH_PARTICIPANTS_SUCCESS',
  SEARCH_PARTICIPANTS_ERROR = 'SEARCH_PARTICIPANTS_ERROR',
  UPDATE_CLASS = 'UPDATE_CLASS',
  UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS',
  UPDATE_CLASS_ERROR = 'UPDATE_CLASS_ERROR',
}

export type ReducerActionType = ActionType<
  | typeof fetchClassDetails
  | typeof clearForm
  | typeof createClass
  | typeof deleteClass
  | typeof searchDietitian
  | typeof searchParticipants
  | typeof updateClass
>;

export const fetchClassDetails = createAsyncAction(
  Types.FETCH_CLASS_DETAILS,
  Types.FETCH_CLASS_DETAILS_SUCCESS,
  Types.FETCH_CLASS_DETAILS_ERROR,
)<string, ClassItem, string>();

export const createClass = createAsyncAction(
  Types.CREATE_CLASS,
  Types.CREATE_CLASS_SUCCESS,
  Types.CREATE_CLASS_ERROR,
)<CreateClassPayload, string, string>();

export const deleteClass = createAsyncAction(
  Types.DELETE_CLASS,
  Types.DELETE_CLASS_SUCCESS,
  Types.DELETE_CLASS_ERROR,
)<string, string, string>();

export const clearForm = createAction(Types.CLEAR_CLASS_DETAILS, resolve => {
  return (retain: boolean) => resolve({ retain });
});

export const searchDietitian = createAsyncAction(
  Types.SEARCH_DIETITIAN,
  Types.SEARCH_DIETITIAN_SUCCESS,
  Types.SEARCH_DIETITIAN_ERROR,
)<SearchPayload, Dietitian[], string>();

export const searchParticipants = createAsyncAction(
  Types.SEARCH_PARTICIPANTS,
  Types.SEARCH_PARTICIPANTS_SUCCESS,
  Types.SEARCH_PARTICIPANTS_ERROR,
)<SearchPayload, Participant[], string>();

export const updateClass = createAsyncAction(
  Types.UPDATE_CLASS,
  Types.UPDATE_CLASS_SUCCESS,
  Types.UPDATE_CLASS_ERROR,
)<UpdateClassPayload, string, string>();
