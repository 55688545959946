import React from 'react';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { ClassTimeOption } from '../../utils/types';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface Props {
  onTimeSelect: (time: ClassTimeOption) => void;
}

export default function TimeSelector(props: Props) {
  const { onTimeSelect } = props;

  return (
    <div className="wizard centered">
      <h2 className="wizard-title">
        Pick a time of day to video chat with your dietitian
      </h2>
      <p>Do you prefer</p>
      <div className="d-block d-md-none d-lg-none d-xl-none">
        <DropdownButton title="Select time" size="lg" id="daySelect">
          <Dropdown.Item onClick={() => onTimeSelect(ClassTimeOption.MORNING)}>Morning</Dropdown.Item>
          <Dropdown.Item onClick={() => onTimeSelect(ClassTimeOption.AFTERNOON)}>Afternoon</Dropdown.Item>
          <Dropdown.Item onClick={() => onTimeSelect(ClassTimeOption.EVENING)}>Evening</Dropdown.Item>
          <Dropdown.Item onClick={() => onTimeSelect(ClassTimeOption.ANY)}>Any Time</Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="d-none d-md-block d-lg-block d-xl-block">
        <Button
          variant="primary"
          size="lg"
          onClick={() => onTimeSelect(ClassTimeOption.MORNING)}
        >
          morning
        </Button>,
        <Button
          variant="primary"
          size="lg"
          onClick={() => onTimeSelect(ClassTimeOption.AFTERNOON)}
        >
          afternoon
        </Button>,
        <Button
          variant="primary"
          size="lg"
          onClick={() => onTimeSelect(ClassTimeOption.EVENING)}
        >
          evening
        </Button>, or
        <Button
          variant="primary"
          size="lg"
          onClick={() => onTimeSelect(ClassTimeOption.ANY)}
        >
          any time
        </Button>
      </div>
      <p>to participate in class?</p>
    </div>
  );
}
