import {
  ActionType,
  createAsyncAction,
  createAction,
} from 'typesafe-actions';

import { CLIENT_TYPE } from '../../utils/constants';
import { LoginPayload, User } from '../../utils/types';

export enum Types {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGOUT = 'LOGOUT',
  REFRESH_AUTH_TOKENS = 'REFRESH_AUTH_TOKENS',
  SET_USER = 'SET_USER',
  UPDATE_USER = 'UPDATE_USER',
  CLEAR_USER = 'CLEAR_USER',
  SET_CLIENT = 'SET_CLIENT',
}

export type ReducerActionType = ActionType<
  | typeof logout
  | typeof processLogin
  | typeof refreshAuthTokens
  | typeof setUser
  | typeof updateUser
  | typeof clearUser
  | typeof changeClient
>

export const processLogin = createAsyncAction(
  Types.LOGIN,
  Types.LOGIN_SUCCESS,
  Types.LOGIN_FAILURE
)<LoginPayload, { accessToken: string, refreshToken: string, user: User }, string>();

export const logout = createAction(Types.LOGOUT, resolve => {
  return () => resolve();
});

export const refreshAuthTokens = createAction(Types.REFRESH_AUTH_TOKENS, resolve => {
  return (tokens: {}) => resolve(tokens);
});

export const setUser = createAction(Types.SET_USER, (action) =>
  (accessToken: string, refreshToken: string, user: User) =>
    action({ accessToken, refreshToken, user }));

export const updateUser = createAction(Types.UPDATE_USER, (action) => (user: User) => action(user));

export const clearUser = createAction(Types.CLEAR_USER);

export const changeClient = createAction(Types.SET_CLIENT, (action) =>
  (client: CLIENT_TYPE) => action(client));
