import moment from 'moment';

import { FORMAT_YYYY_MM_DD } from '../utils/constants';
import { WeightLossAnalyticsData, WeightLossAnalyticsSummary } from '../utils/types';

import { ApiRequest } from '.';

export interface WeightLossTrackingPayload {
  company: string;
  page: number;
  search?: string;
  from?: Date;
  to?: Date;
}

export interface WeightLossAnalyticsPayload {
  company: string;
  search?: string;
  from?: Date;
  to?: Date;
}

interface WeightLossTrackingResponse {
  items: WeightLossAnalyticsData[];
  total: number;
}

export class FetchWeightLossTrackingRequest extends ApiRequest<WeightLossTrackingResponse> {

  protected async send(payload: WeightLossTrackingPayload) {
    let url = `/weight-tracking?company=${payload.company}&page=${payload.page}`;

    if (payload.search) {
      url += `&search=${encodeURIComponent(payload.search)}`;
    }

    if (payload.to && payload.from) {
      const to = moment(payload.to).format(FORMAT_YYYY_MM_DD);
      const from = moment(payload.from).format(FORMAT_YYYY_MM_DD);

      url += `&from=${from}&to=${to}`;
    }

    return this.get(this.backendApiUrl(url))
      .then((resp) => {
        const items = resp.data.data.map((item: any): WeightLossAnalyticsData => ({
          name: item.participant.name,
          dietitian: item.dietitian.length > 0 ? item.dietitian[0].name : null,
          signupDate: new Date(item.participant.signup_date),
          startingWeight: item.first_weight,
          currentWeight: item.current_weight,
          weekFour: item.weights['4'],
          weekEight: item.weights['8'],
          weekTwelve: item.weights['12'],
          weekSixteen: item.weights['16'],
          weekTwenty: item.weights['20'],
          weekTwentyFour: item.weights['24'],
          weekTwentyEight: item.weights['28'],
          weekThirtyTwo: item.weights['32'],
          weekThirtySix: item.weights['36'],
          weekForty: item.weights['40'],
          weekFortyFour: item.weights['44'],
          weekFortyEight: item.weights['48'],
          weekFiftyTwo: item.weights['52'],
        }));
        const { to, from, total } = resp.data.meta;

        this.observer.next({
          items,
          total,
          to,
          from,
        });

        return resp;
      });
  }

}

export class FetchWeightLossAnalyticsRequest extends ApiRequest<WeightLossAnalyticsSummary> {

  protected async send(payload: WeightLossAnalyticsPayload) {
    let url = `/weight-analytics?company=${payload.company}`;

    return this.get(this.backendApiUrl(url))
      .then((resp) => {
        const result: WeightLossAnalyticsSummary = {
          company: resp.data.company.name,
          numParticipants: resp.data.num_participants,
          weeks: {
            4: resp.data.averages['4'],
            8: resp.data.averages['8'],
            12: resp.data.averages['12'],
            16: resp.data.averages['16'],
            20: resp.data.averages['20'],
            24: resp.data.averages['24'],
            28: resp.data.averages['28'],
            32: resp.data.averages['32'],
            36: resp.data.averages['36'],
            40: resp.data.averages['40'],
            44: resp.data.averages['44'],
            48: resp.data.averages['48'],
            52: resp.data.averages['52'],
          },
        };

        this.observer.next(result);
        return resp;
      })
  }

}
