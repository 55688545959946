import {
  createAction,
  createAsyncAction,
  ActionType,
} from 'typesafe-actions';

import {
  ClassItem,
  ClassSort,
  DropdownItem,
} from '../../utils/types';

export enum Types {
  FETCH_CLASSES = 'FETCH_CLASSES',
  FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS',
  FETCH_CLASSES_FAILURE = 'FETCH_CLASSES_FAILURE',
  FILTER_CLASSES = 'FILTER_CLASSES',
  STORE_PAGE = 'STORE_PAGE',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  SORT_CLASSES = 'SORT_CLASSES',
}

export type ReducerActionType = ActionType<
  | typeof fetchClasses
  | typeof filterClasses
  | typeof storePage
  | typeof clearFilters
  | typeof sortClasses
>

export const fetchClasses = createAsyncAction(
  Types.FETCH_CLASSES,
  Types.FETCH_CLASSES_SUCCESS,
  Types.FETCH_CLASSES_FAILURE
)<undefined, ClassItem[], string>();

export const filterClasses = createAction(Types.FILTER_CLASSES, resolve => {
  return (
    filterDay: DropdownItem,
    filterStatus: DropdownItem,
    searchText: string = '',
    startDate?: Date,
    endDate?: Date,
  ) => resolve({
    filterDay,
    filterStatus,
    searchText,
    startDate,
    endDate,
  });
})

export const storePage = createAction(Types.STORE_PAGE, resolve => (
  (page: number) => resolve(page)
))

export const clearFilters = createAction(Types.CLEAR_FILTERS, resolve => (
  () => resolve()
))

export const sortClasses = createAction(Types.SORT_CLASSES, resolve => (
  (key: ClassSort, isDescending: boolean) => resolve({ key, isDescending })
))
