import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faUser,
  faEnvelope,
  faPhone,
  faGlobe,
  faFileMedical,
  faAngleRight,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CopyButton from '../../../components/CopyButton';
import Pagination from '../../../components/Pagination';

import { tooltipComponent, scrollToNode } from '../../../utils';

import { Doctor } from '../../../types/doctors';

interface Props {
  currentPage: number;
  isFetching?: boolean;
  numPages: number;
  pagedUsers: Doctor[];
  onRowSelect: (doctor: Doctor) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
}

export default function List(props: Props) {
  const renderList = () => (
    <Table className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th><FontAwesomeIcon icon={faUser} />First Name</th>
          <th><FontAwesomeIcon icon={faUser} />Last Name</th>
          <th><FontAwesomeIcon icon={faEnvelope} />Email</th>
          <th><FontAwesomeIcon icon={faPhone} />Phone Number</th>
          <th><FontAwesomeIcon icon={faGlobe} />Website</th>
          <th><FontAwesomeIcon icon={faFileMedical} />Specialty</th>
          <th><FontAwesomeIcon icon={faLink} />Referral Link</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.pagedUsers.map((item: Doctor, index) =>
          <tr
            id={`Doctors-row${index}`}
            className="clickable"
            key={item.id}>
            <td onClick={() => props.onRowSelect(item)}>{item.firstName}</td>
            <td onClick={() => props.onRowSelect(item)}>{item.lastName}</td>
            <td onClick={() => props.onRowSelect(item)}>{item.email}</td>
            <td onClick={() => props.onRowSelect(item)}>{item.phoneNumber}</td>
            <td onClick={() => props.onRowSelect(item)}>{item.website}</td>
            <td onClick={() => props.onRowSelect(item)}>{item.specialty}</td>
            {tooltipComponent(
              item.quizLink,
              <td>
                <CopyButton copyValue={item.quizLink} size="sm" />
              </td>,
              'left'
            )}
            <td className="actions" onClick={() => props.onRowSelect(item)}>
              <div>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('Doctors-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
