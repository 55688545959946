import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faAngleRight,
  faCalendar,
  faEnvelope,
  faUser,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import _ from 'lodash';

import Pagination from '../../../../components/Pagination';

import { EligibilityError } from '../../../../types/eligibility-errors';

import { timezoneAbbreviation, scrollToNode } from '../../../../utils';
import { FORMAT_MM_DD_YYYY, FORMAT_H_MMA } from '../../../../utils/constants';

import './index.scss';

interface Props {
  currentPage: number;
  isDescending: boolean;
  isFetching?: boolean;
  numPages: number;
  pagedUsers: EligibilityError[];
  onRowSelect: (error: EligibilityError) => void;
  setIsDescending: (value: boolean) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
}

export default function List(props: Props) {
  const renderList = () => (
    <Table responsive className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th><FontAwesomeIcon icon={faUser} />Participant</th>
          <th><FontAwesomeIcon icon={faEnvelope} />Email</th>
          <th className="button" onClick={() => props.setIsDescending(!props.isDescending)}>
            <FontAwesomeIcon icon={faCalendar} />Error Date & Time&nbsp;
            <FontAwesomeIcon icon={props.isDescending ? faCaretDown : faCaretUp} />
          </th>
          <th>Error Message</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.pagedUsers.map((error: EligibilityError, index: number) =>
          <tr
            id={`EligibilityErrors-row${index}`}
            className="clickable"
            key={index}
            onClick={() => {
              if (props.isFetching) {
                return;
              }

              props.onRowSelect(error);
            }}>
            <th className="fit padding-right">{`${error.firstName} ${error.lastName}`}</th>
            <td className="fit padding-right">{error.email}</td>
            <td className="date">
              {moment(error.date).format(FORMAT_MM_DD_YYYY)} - {moment(error.date).format(FORMAT_H_MMA)} <strong>{timezoneAbbreviation(error.date)}</strong>
            </td>
            <td className="error">
              {error.message}
            </td>
            <td className="actions">
              <div>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('EligibilityErrors-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
