import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import image from '../../assets/images/404.png';

import AppNav from '../../components/AppNav';
import Button from '../../components/Button';

import { practiceConstants } from '../../utils';
import { ROUTES } from '../../utils/constants';

import './index.scss';

interface Props extends RouteComponentProps {}

export default function RedirectPage(props: Props) {
  const { className, practiceName } = practiceConstants();

  return (
    <>
      <AppNav />
      <div className={`RedirectPage-container ${className}`}>
        <img className="RedirectPage-image" src={image} />
        <label className="RedirectPage-headerLabel">
          Oops! Something went wrong!
          </label>
        <label className="RedirectPage-subLabel">
          Error 404: We can't seem to find the page that you are looking for.
          </label>
        <br />
        <Button
          variant="primary"
          onClick={() => props.history.push(ROUTES.Login)}>
          {practiceName}
        </Button>
      </div>
    </>
  )
}
