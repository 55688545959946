import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import { FetchClaimRequest } from '../../../../api/claims';

import Button from '../../../../components/Button';
import Spinner from '../../../../components/Spinner';

import useAPI from '../../../../hooks/useAPI';

import { Claim } from '../../../../types/claims';
import { Patient } from '../../../../types/patient';
import { MilestoneData } from '../../../../types/milestones';

import { FORMAT_YYYY_MM_DD } from '../../../../utils/constants';
import { formatCurrency } from '../../../../utils/formatting';

import './index.scss';

interface Props {
  participant: MilestoneData;
  milestone: number;
  onClose: () => void;
  onSubmit: (patient: Patient) => void;
}

export default function ClaimErrorModal(props: Props) {
  const { participant, milestone, onClose, onSubmit } = props;

  // States
  const [busy, setBusy] = useState(false);
  const [claim, setClaim] = useState<Claim | undefined>(undefined);
  const [insurance, setInsurance] = useState('');
  const [healthPlanId, setHealthPlanId] = useState('');
  const [amount, setAmount] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [gender, setGender] = useState('');
  const [apartmentNo, setApartmentNo] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  // Set initial form states
  useEffect(() => {
    setInsurance(participant.insurance!);
    setHealthPlanId(participant.healthPlanId!);
    setFirstName(participant.firstName);
    setLastName(participant.lastName);
    setBirthdate(moment(participant.birthdate).format(FORMAT_YYYY_MM_DD));
    setGender(participant.gender);
    setApartmentNo(participant.apartmentNo!);
    setCity(participant.city);
    setState(participant.state);
    setZip(participant.zipCode);
  }, []);

  // Fetch error from API
  useAPI(FetchClaimRequest, (request) => {
    const payload = {
      patientId: participant.id,
      milestone,
    }
    request
      .send(payload)
      .then((claim) => {
        setClaim(claim);
        setAmount(claim.amount);
      });
  }, []);

  // Do not close if busy
  const handleClose = () => {
    if (!busy) {
      onClose();
    }
  };

  return (
    <Modal
      size="lg"
    >
      {!claim
        ? <>
            <Modal.Header>
              <Modal.Title>Claim</Modal.Title>
            </Modal.Header>
            <Modal.Body><Spinner /></Modal.Body>
          </>
        : <Form
            className="claim-form"
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              const patient: Patient = {
                id: participant.id,
                firstName,
                lastName,
              };

              setBusy(true);
              onSubmit(patient);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Pending Approval for Claim - Milestone {milestone} ({formatCurrency(claim.amount)})
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group controlId="insurance">
                    <Form.Label>Insurance Carrier</Form.Label>
                    <Form.Control
                      type="text"
                      value={insurance}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="memberId">
                    <Form.Label>Subscriber Member ID</Form.Label>
                    <Form.Control
                      type="text"
                      value={healthPlanId}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="claimAmount">
                    <Form.Label>Claim Amount</Form.Label>
                      <Form.Control
                        type="text"
                        value={`$ ${amount}`}
                        disabled
                      />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={(e: any) => setFirstName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={(e: any) => setLastName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="dob">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      value={moment(birthdate).format(FORMAT_YYYY_MM_DD)}
                      disabled
                      // onChange={(e: any) => setBirthdate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="gender">
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      type="text"
                      value={gender}
                      disabled
                      // onChange={(e: any) => setGender(e.target.value)}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group controlId="relation">
                    <Form.Label>Patient relationship to Subscriber</Form.Label>
                    <Form.Control type="text" value="Principal" disabled />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="streetAddress">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={apartmentNo}
                      disabled
                      // onChange={(e: any) => setApartmentNo(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      value={city}
                      disabled
                      // onChange={(e: any) => setCity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      value={state}
                      disabled
                      // onChange={(e: any) => setState(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="zip">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      value={zip}
                      disabled
                      // onChange={(e: any) => setZip(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Alert variant="light" className="claim-error">
                <h6>Insurance provider <span className="text-primary">{insurance}</span> has rejected your claim with the following message: {claim.error}</h6>
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={busy}
              >
                Resend Claim
              </Button>
            </Modal.Footer>
          </Form>}
    </Modal>
  );
}
