import { getType } from 'typesafe-actions';

import {
  checkEligibility,
  clearForm,
  ReducerActionType,
} from '../actions/ParticipantEligibility';

export type ReducerStateType = Readonly<{
  isProcessing: boolean;
  isSuccess?: boolean;
  response: { [id: string]: any };
}>

const INITIAL_STATE: ReducerStateType = {
  isProcessing: false,
  response: {},
}

const reducers = (
  state: ReducerStateType = INITIAL_STATE,
  action: ReducerActionType
) => {
  switch (action.type) {
    case getType(checkEligibility.request):
      return {
        ...state,
        isProcessing: true,
        isSuccess: undefined,
        response: {},
      }

    case getType(checkEligibility.success):
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        response: action.payload,
      }

    case getType(checkEligibility.failure):
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        response: action.payload,
      }

    case getType(clearForm):
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default reducers;
