import axios, { AxiosInstance } from 'axios';
import isUndefined from 'lodash-es/isUndefined';
import moment from 'moment';
import { toast } from 'react-toastify';

import getEnvCreds from '../utils/apiconstants';
import {
  BASE_URL,
  BASE_URL_INGENUITY,
  CLASS_STATUS_LIST,
  MESSAGE_ELIGIBILITY_FAILURE,
  MESSAGE_ELIGIBILITY_SUCCESS,
  STRIPE_STATUS as StripeStatus,
} from '../utils/constants';
import { TEST_ALL_PARTICIPANTS } from '../utils/data';
import {
  EligibilityPayload,
  ClassItem,
  Dietitian,
  StripePlan,
  StripeVoucher,
} from '../utils/types';

export default class ParticipantAPIClient {
  private ingenuityInstance: AxiosInstance;

  constructor() {
    this.ingenuityInstance = axios.create({
      baseURL: BASE_URL_INGENUITY,
      timeout: 10000,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
  }

  checkEligibility = (
    params: EligibilityPayload
  ): Promise<{ [id: string]: string }> => new Promise((resolve, reject) => {
    this.ingenuityInstance.post('/eligibility', params)
      .then(() => resolve({ message: MESSAGE_ELIGIBILITY_SUCCESS }))
      .catch((error) => {
        const data = error.response.data;
        let errors = {};
        let message = data['message'] || MESSAGE_ELIGIBILITY_FAILURE;

        for (const key in data['errors']) {
          errors[key] = data['errors'][key][0];
        }

        reject({ errors, message });
      });
  })

  fetchClasses = async (): Promise<ClassItem[]> => (
    new Promise(async (resolve, reject) => {
      try {
        const ENV_CREDS = getEnvCreds();
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-AccountCode': ENV_CREDS.ACCOUNT_CODE,
          'X-ApiKey': ENV_CREDS.API_KEY,
        };
        let client = axios.create({
          baseURL: BASE_URL,
          timeout: 10000,
          headers
        });
        const resp = await client.get('/classes?fields=id,name,min_participants,max_participants,participant_ids,start,end,host_ids');
        const data = resp.data.data;
        const classes: ClassItem[] = data
          .filter((item: any) => item.start < 10000000000) // @todo Remove classes that has very large timestamps
          .map((item: any) => ({
            id: item.id,
            name: item.name,
            date: new Date(item.start * 1000),
            endDate: new Date(item.end * 1000),
            time: { title: '', value: '' },
            participants: item.participant_ids.map((p: any) => ({ id: p, name: '', avatar: '' })), // @todo
            minParticipants: item.min_participants,
            maxParticipants: item.max_participants,
            status: CLASS_STATUS_LIST.Open,
            isScheduled: false,
            dietitianId: item.host_ids[0] || undefined,
          }))
          .sort((item1: ClassItem, item2: ClassItem) => (
            moment(item1.date).isBefore(item2.date) ? -1 : 1
          ));

        const dietitians: Dietitian[] = TEST_ALL_PARTICIPANTS;

        classes.map(c => {
          const dietitian = dietitians.find(d => d.id === c.dietitianId);

          if (!isUndefined(dietitian)) {
            c.dietitian = dietitian;
          }

          return c;
        });

        resolve(classes);
      } catch (error) {
        toast.error(error.response.data.message || error.response.message);
        reject();
      }
    })
  )

  fetchStripePlans = (): Promise<StripePlan[]> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.ingenuityInstance.get('/stripe/plans');
        const data = response.data.data;
        const list: [StripePlan] = data.map((item): StripePlan => ({
          id: item.id,
          amount: item.amount,
          interval: item.interval,
          isActive: item.active,
        }));

        resolve(list);
      } catch (error) {
        toast.error(error.response.data.message || error.response.message);
        reject();
      }
    })
  )

  createSubscription = (id: string, payload: any): Promise<[StripeStatus, string]> => (
    new Promise(async (resolve, reject) => {
      // Handles the ff:
      /**
        status=succeeded - The attempt to pay with the new payment method succeeded. Let the customer know and provision access to your good or service.
        status=requires_payment_method - The attempt to pay with the new payment method failed. Go back to step 1 and collect a new payment method.
        status=requires_action - In order to complete payment, further action is required by the customer. See “Payment requires customer action” below.
       */

      try {
        const url = `/stripe/participants/${id}/payment`;
        const response = await this.ingenuityInstance.post(url, payload);
        const { status, invoice_id } = response.data;

        resolve([status, invoice_id]);
      } catch (error) {
        reject(error.response.data.message || error.response.message);
      }
    })
  )

  reattemptSubscription = (id: string, payload: any): Promise<[StripeStatus, string]> => (
    new Promise(async (resolve, reject) => {
      // Use 4100000000000019 for re-attempt status
      // Use 4000000000000002 for decline status
      // Use 5555555555554444 for MasterCard valid
      try {
        const url = `/stripe/participants/${id}/reattempt-payment`;
        const response = await this.ingenuityInstance.post(url, payload);
        const { status, invoice_id } = response.data;

        resolve([status, invoice_id]);
      } catch (error) {
        reject(error.response.data.message || error.response.message);
      }
    })
  )

  checkVoucherValidity = (id: string) : Promise<StripeVoucher> => (
    new Promise(async (resolve, reject) => {
      // Use N2lLAaa5 for invalid coupon
      try {
        const url = `/stripe/coupons/${id}`;
        const response = await this.ingenuityInstance.get(url);
        const voucher: StripeVoucher = {
          id: response.data.id,
          discountValue: response.data.amount_off || response.data.percent_off,
          name: response.data.name,
          isPercent: response.data.amount_off == null,
          isValid: response.data.valid,
        };

        resolve(voucher);
      } catch (error) {
        reject(error.response.data.message || error.response.message);
      }
    })
  )
}
