import { Subscriber, Observer } from 'rxjs';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CancelTokenSource,
  CancelToken,
  AxiosPromise,
  AxiosError,
} from 'axios';

import { store } from '../redux';
import {
  refreshAuthTokens,
  setUser,
  updateUser
} from '../redux/actions/Authentication';
import { RootState } from '../redux/reducers';

import { dataToModel } from '../utils';
import getEnvCreds from '../utils/apiconstants';
import { BASE_URL, BASE_URL_INGENUITY } from '../utils/constants';
import { User, UserType } from '../utils/types';

type Credentials = {
  username: string,
  password: string
};

type Payload = { [id: string]: any };

export const autoClose = 2000;

export default class APIClient {
  private client: AxiosInstance;
  private state: RootState = store.getState();

  protected cancelSource?: CancelTokenSource;
  protected cancelToken?: CancelToken;

  constructor(baseURL: string = BASE_URL) {
    const { refreshToken } = this.state.authentication;

    this.cancelSource = axios.CancelToken.source();
    this.cancelToken = this.cancelSource.token;

    this.client = axios.create({
      baseURL,
      headers: this.getHeaders(baseURL),
      timeout: 300000,
    });

    this.client.interceptors.response.use((response) => response, error => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;

      // If error is 401
      if ((error.response.status === 401 || error.response.status === 403) &&
          originalRequest &&
          refreshToken &&
          !originalRequest.__retry) {
        originalRequest.__retry = true;

        // Request for a new token
        console.log('Refreshing request...');

        return this.refreshLogin(refreshToken).then(response => {
          const {
            token: accessToken,
            refresh_token: { token: refreshToken }
          } = response.data.data;

          // Update the error config with new token
          originalRequest.headers['X-ApiToken'] = accessToken;

          console.log('Retrying request...');

          store.dispatch(refreshAuthTokens({ accessToken, refreshToken }));

          return this.client(error.config);
        });
      }

      return Promise.reject(error);
    });
  }

  private getHeaders = (baseURL: string) => {
    const { accessToken } = this.state.authentication;
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const ENV_CREDS = getEnvCreds();

    headers['X-AccountCode'] = ENV_CREDS.ACCOUNT_CODE;
    headers['X-ApiKey'] = ENV_CREDS.API_KEY;

    // if (baseURL == BASE_URL) {
    //   headers['X-AccountCode'] = ENV_CREDS.ACCOUNT_CODE;
    //   headers['X-ApiKey'] = ENV_CREDS.API_KEY;
    // }

    if (accessToken) {
      headers['X-ApiToken'] = accessToken;

      // NOTE: Sample invalid token
      // headers['X-ApiToken'] = "2306ade115cce8f72114068f5e698161";
    }
    return headers;
  }

  unsubscribe = () => {
    if (this.cancelSource) {
      console.log('Cancelling request...');

      this.cancelSource.cancel();
    }
  }

  get = (url: string, config?: AxiosRequestConfig) => (
    this.client.get(url, Object.assign({}, {
      cancelToken: this.cancelToken,
    }, config))
  )

  post = (url: string, data?: Payload, config?: AxiosRequestConfig) => (
    this.client.post(url, data, Object.assign({}, {
      cancelToken: this.cancelToken,
    }, config))
  )

  patch = (url: string, data?: Payload, config?: AxiosRequestConfig) => (
    this.client.patch(url, data, Object.assign({}, {
      cancelToken: this.cancelToken,
    }, config))
  )

  delete = (url: string, config?: AxiosRequestConfig) => (
    this.client.delete(url, Object.assign({}, {
      cancelToken: this.cancelToken,
    }, config))
  )

  login = (params: Credentials): Promise<{ user: User }> => (
    new Promise((resolve, reject) => {
      this.client.post('/users/login', params)
        .then(async (response) => {
          let userType: UserType = UserType.PARTICIPANT;
          const {
            token: accessToken,
            user: u,
            user_type,
            user_id,
            refresh_token: { token: refreshToken },
          } = response.data.data;

          if (user_type == "400") {
            if (u.roles) {
              userType = UserType.SUPERUSER;
            } else {
              userType = UserType.DIETITIAN;
            }
          }

          const user: User = dataToModel<User>(u);

          user.id = parseInt(user_id);
          user.type = userType;
          user.isClinicAdmin = user.roles && user.roles['clinic_admin'] && user.roles['clinic_admin'].length > 0;

          store.dispatch(setUser(accessToken, refreshToken, user));

          await this.currentUserInfo()
            .then((info: any) => {
              const account = info.data.data.accounts[0];
              user.account = account.code;

              store.dispatch(updateUser(user));
              resolve({ user });
            })
            .catch(error => {
              if (!axios.isCancel(error)) {
                reject(error.response.data.message || error.response.message);
              }
            })
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            reject(error.response.data.message || error.response.message);
          }
        });
    })
  );

  refreshLogin = (token: string) => {
    const baseURL = BASE_URL;
    const client = axios.create({
      baseURL,
      headers: this.getHeaders(baseURL),
      timeout: 300000,
    });

    return client.post('/tokens/refresh', { refresh_token: token })
  }

  currentUserInfo = () => {
    this.client = axios.create({
      baseURL: BASE_URL,
      headers: this.getHeaders(BASE_URL),
      timeout: 300000,
    });

    return this.client.get('/me?fields=accounts');
  }
}

// @todo Error Handling.
export class ApiRequest<T> extends Subscriber<T> {
  protected observer;
  protected completed: boolean = false;

  protected cancelSource?: CancelTokenSource;
  protected cancelToken?: CancelToken;

  constructor(payload, observer) {
    super(observer);
    this.observer = observer;
    this.cancelSource = axios.CancelToken.source();
    this.cancelToken = this.cancelSource.token;

    this.send(payload)
      .then((res) => {
        this.done();
        return res;
      });
  }

  public unsubscribe() {
    super.unsubscribe();

    if (!this.completed) {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }

      this.completed = true;
    }
  }

  protected send(payload: any): AxiosPromise<any> {
    throw new Error('Implement send() method.');
  }

  protected vseeApiUrl(endpoint: string) {
    return BASE_URL + endpoint;
  }

  protected backendApiUrl(endpoint: string) {
    return BASE_URL_INGENUITY + endpoint;
  }

  protected headers() {
    const { accessToken } = store.getState().authentication;
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    const ENV_CREDS = getEnvCreds();

    headers['X-AccountCode'] = ENV_CREDS.ACCOUNT_CODE;
    headers['X-ApiKey'] = ENV_CREDS.API_KEY;

    if (accessToken) {
      headers['X-ApiToken'] = accessToken;
    }

    return headers;
  }

  protected get(url: string, config: AxiosRequestConfig = {}) {
    return axios
      .get(url, Object.assign({}, {
        headers: this.headers(),
        cancelToken: this.cancelToken,
      }, config))
      .catch<any>(err => this.handleError(err));
  }

  protected post(url: string, body: any, config: AxiosRequestConfig = {}) {
    return axios
      .post(url, body, Object.assign({}, {
        headers: this.headers(),
        cancelToken: this.cancelToken,
      }, config))
      .catch<any>(err => this.handleError(err));
  }

  protected done() {
    this.completed = true;
    this.observer.complete();
  }

  protected handleError(error: AxiosError) {
    this.completed = true;
    if (axios.isCancel(error)) {
      this.observer.complete();
    } else {
      this.observer.error(error);
    }
    throw error;
  }
}
