import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import Spinner from '../../components/Spinner';

import { DropdownItem, PaymentStatus } from '../../utils/types';

import './index.scss';

interface Props {
  processing?: boolean;
  items: DropdownItem[];
  selectedItem: DropdownItem;
  style?: React.CSSProperties;
  title?: string;
  viewOnly?: boolean;
  onItemSelection: (item: DropdownItem) => void;
}

interface State {
  displayMenu: boolean;
}

class PaymentDropdown extends React.Component<Props, State> {
  state: State = {
    displayMenu: false,
  }

  private node: HTMLDivElement | null = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  getBackgroundColor = (status: DropdownItem) => {
    switch (status.value) {
      case PaymentStatus.Paid:
        return "#53CB7A";

      case PaymentStatus.Unpaid:
        return "#EB3939";

      default:
        return "#A2A2A2";
    }
  }

  handleClick = (e) => {
    if (!this.node) {
      return;
    }

    if (!this.node.contains(e.target) && this.state.displayMenu) {
      this.toggleDropdownMenu();
    }
  }

  toggleDropdownMenu = () => {
    const { displayMenu } = this.state;

    this.setState({ displayMenu: !displayMenu })
  }

  onDropdownSelection = (item: DropdownItem) => {
    this.props.onItemSelection(item);
    this.toggleDropdownMenu();
  }

  renderList = (items: DropdownItem[]) => (
    <ul className="MilestoneDropdown-list">
      {
        items.map((item: DropdownItem, index: number) => {
          const { selectedItem } = this.props;
          const isSame: boolean =  item.title === selectedItem.title;
          const props = isSame ? { className: 'active' } : {};

          return (
            <li
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                this.onDropdownSelection(item);
              }}
              {...props}>
              <label>
                {item.title}
              </label>
            </li>
          )
        })
      }
    </ul>
  )

  render() {
    const {
      items,
      processing,
      selectedItem,
      viewOnly,
    } = this.props;
    const { displayMenu } = this.state;
    const onClickHandler = this.toggleDropdownMenu;
    const backgroundColor = this.getBackgroundColor(selectedItem);
    const style = backgroundColor ? { backgroundColor } : {};

    return (
      <div className="MilestoneDropdown-dropdown" ref={node => this.node = node}>
        <div className="MilestoneDropdown-info">
          <div
            className="MilestoneDropdown-button"
            onClick={(e) => {
              if (!viewOnly) {
                e.stopPropagation();
                onClickHandler();
              }
            }}
            style={style}
          >
            {selectedItem.title}

            {(!viewOnly && !processing) &&
              <FontAwesomeIcon
                className="MilestoneDropdown-icon"
                icon={faCaretDown}
                style={{ marginRight: 0 }} />
            }

            {processing && <Spinner color="white" noText />}
          </div>
        </div>

        {displayMenu && this.renderList(items)}
      </div>
    );
  }
}

export default PaymentDropdown;
