import * as React from 'react';
import moment from 'moment';
import Calendar, { OnChangeDateCallback } from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faRedoAlt } from '@fortawesome/free-solid-svg-icons'

import { practiceConstants } from '../../utils';
import {
  FORMAT_DD,
  FORMAT_MM_DD_YYYY,
  FORMAT_MMM_YYYY,
} from '../../utils/constants';

import './index.scss';
import '../../calendar.scss';

interface Props {
  currentStartDate?: Date;
  currentEndDate?: Date;
  isInstant?: boolean;
  label?: string;

  onStartDateSelection: (OnChangeDateCallback) => void;
  onEndDateSelection: (OnChangeDateCallback) => void;
  onResetDatesSelection: () => void;
  onDatesSelection: (dates: Date[]) => void;
}

interface State {
  displayEndCalendar: boolean;
  displayStartCalendar: boolean;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
  selectedDates?: Date[];
}

class DateDropdownGroup extends React.Component<Props, State> {
  state: State = {
    displayEndCalendar: false,
    displayStartCalendar: false,
  }

  private node: HTMLDivElement | null = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node) {
      return;
    }

    if (!this.node.contains(e.target) &&
        (this.state.displayEndCalendar || this.state.displayStartCalendar)) {
      this.setState({ displayStartCalendar: false, displayEndCalendar: false });
    }
  }

  toggleStartDropdownMenu = () => {
    const { displayStartCalendar } = this.state;

    this.setState({
      displayStartCalendar: !displayStartCalendar,
      displayEndCalendar: false,
    });
  }

  toggleEndDropdownMenu = () => {
    const { displayEndCalendar } = this.state;

    this.setState({
      displayEndCalendar: !displayEndCalendar,
      displayStartCalendar: false,
    });
  }

  onChangeStartDate = (date: Date | Date[]) => {
    const selectedStartDate: Date = date as Date;

    this.onStartDateSelection(selectedStartDate);
  }

  onChangeEndDate = (date: Date | Date[]) => {
    const selectedEndDate: Date = date as Date;

    this.onEndDateSelection(selectedEndDate);
  }

  onChangeDates = (date: Date | Date[]) => {
    const { isInstant, onDatesSelection } = this.props;
    const selectedDates = date as Date[];

    this.setState({ selectedDates });

    if (isInstant) {
      onDatesSelection(selectedDates);
      this.toggleEndDropdownMenu();
    }
  }

  onStartDateSelection = (selectedStartDate: Date) => {
    let { selectedEndDate } = this.state;

    if (selectedEndDate && selectedStartDate && selectedStartDate > selectedEndDate) {
      selectedStartDate = selectedEndDate
    }

    this.setState({ selectedStartDate });
    this.props.onStartDateSelection(selectedStartDate || new Date());
    this.toggleStartDropdownMenu();
  }

  onEndDateSelection = (selectedEndDate: Date) => {
    let { selectedStartDate } = this.state;

    if (selectedEndDate && selectedStartDate && selectedEndDate < selectedStartDate) {
      selectedEndDate = selectedStartDate;
    }

    this.setState({ selectedEndDate });
    this.props.onEndDateSelection(selectedEndDate || new Date());
    this.toggleEndDropdownMenu();
  }

  onResetDatesSelection = () => {
    this.setState({ selectedStartDate: undefined, selectedEndDate: undefined });
    this.props.onResetDatesSelection();
    this.setState({
      displayStartCalendar: false,
      displayEndCalendar: false,
      selectedDates: undefined,
    });
  }

  renderStartCalendar = () => (
    <div className="DateDropdownGroup-startContainer">
      <Calendar
        calendarType="US"
        className="reactCalendar"
        formatMonthYear={(locale, date) => (
          moment(date).format(FORMAT_MMM_YYYY)
        )}
        formatShortWeekday={(locale, date) => (
          moment(date).format(FORMAT_DD)
        )}
        onChange={this.onChangeStartDate}
        returnValue="start"
        value={this.state.selectedStartDate || new Date()}
      />
      {/* <button
        className="DateDropdownGroup-calendarButton"
        onClick={this.onStartDateSelection}>
        OK
      </button> */}
    </div>
  )

  renderEndCalendar = () => (
    <div className="DateDropdownGroup-endContainer">
      <Calendar
        calendarType="US"
        className="reactCalendar"
        formatMonthYear={(locale, date) => (
          moment(date).format(FORMAT_MMM_YYYY)
        )}
        formatShortWeekday={(locale, date) => (
          moment(date).format(FORMAT_DD)
        )}
        selectRange
        returnValue="range"
        tileClassName="DateDropdownGroup-day"
        value={this.state.selectedDates || new Date()}
        onChange={this.onChangeDates}
      />

      {!this.props.isInstant &&
        <button
          className="DateDropdownGroup-calendarButton"
          onClick={() => {
            const { onDatesSelection } = this.props;

            if (onDatesSelection) {
              onDatesSelection(this.state.selectedDates || [new Date()]);
              this.toggleEndDropdownMenu();
            }
          }}>
          OK
        </button>
      }
    </div>
  )

  render() {
    const { currentStartDate, currentEndDate, label } = this.props;
    const { displayStartCalendar, displayEndCalendar } = this.state;
    const startDate = currentStartDate ? moment(currentStartDate).format(FORMAT_MM_DD_YYYY) : 'Show All';
    const endDate = currentEndDate ? ` - ${moment(currentEndDate).format(FORMAT_MM_DD_YYYY)}` : '';

    return (
      <div
        className={`DateDropdownGroup-dropdown ${practiceConstants().className}`}
        ref={node => this.node = node}>
        <div className="DateDropdownGroup-info">
          <label className="DateDropdownGroup-infoLabel">
            {label || 'Start Date'}
          </label>
          {/* <div
            className="DateDropdownGroup-button"
            onClick={this.toggleStartDropdownMenu}
            style={{ marginRight: 10 }}>
            {startDate}

            <FontAwesomeIcon
              className="DateDropdownGroup-icon"
              icon={faCalendar} />
          </div> */}
          <div
            className="DateDropdownGroup-button"
            onClick={this.toggleEndDropdownMenu}>
            {startDate}{endDate}

            <FontAwesomeIcon
              className="DateDropdownGroup-icon"
              icon={faCalendar} />
          </div>
          <button
            className="DateDropdownGroup-resetButton"
            onClick={this.onResetDatesSelection} >
            <FontAwesomeIcon
              className="DateDropdown-resetIcon"
              icon={faRedoAlt} />
          </button>
        </div>

        {/* {displayStartCalendar && this.renderStartCalendar()} */}
        {displayEndCalendar && this.renderEndCalendar()}
      </div>
    );
  }
}

export default DateDropdownGroup;
