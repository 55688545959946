import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

import { practiceConstants } from '../utils';
import { FORMAT_YYYY_MM_DD } from '../utils/constants';

import APIClient, { autoClose } from '.';

import {
  EmployerPreDiabetesStats,
  EmployerEnrollmentStats,
  EmployerMilestoneStats,
  EmployerEligibilityStats,
  EmployerClaimsStats,
  EmployerWeightLossStats,
} from '../types/employer-analytics';

export default class EmployerAnalyticsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchEmployerAnalytics = (
    company: string,
    month: string,
    year: string,
  ): Promise<[
    EmployerPreDiabetesStats[],
    EmployerEnrollmentStats,
    EmployerMilestoneStats[],
    EmployerEligibilityStats[],
    EmployerClaimsStats[],
  ]> => (
    new Promise(async (resolve, reject) => {
      try {
        let query = `?company=${company}`;
        const d = new Date(`${month} ${year}`);
        const q = {
          from_date: moment(d),
          to_date: moment(d).endOf('month')
        };

        Object.keys(q).forEach(k => {
          query = `${query}&${k}=${q[k].format(FORMAT_YYYY_MM_DD)}`;
        });

        // Quiz Results
        let response = await this.instance.get(`/employer/quiz-results${query}`);
        let data = response.data
        const pds = data.map((item: any): EmployerPreDiabetesStats => ({
          total: parseInt(item.total),
          highRisk: parseInt(item.high_risk),
          highRiskPercentage: parseFloat(item.high_percent),
          lowRisk: parseInt(item.low_risk),
          lowRiskPercentage: parseFloat(item.low_percent),
          fromDate: new Date(item.from_date),
          toDate: new Date(item.to_date),
        }));

        // Enrollment Rsults
        response = await this.instance.get(`/employer/enrollment-results${query}`);
        data = response.data['enrollment_results'];
        const height = data.ave_height.split('.');
        const er: EmployerEnrollmentStats = {
          highRisk: parseInt(data.high_risk),
          male: parseInt(data.male),
          malePercentage: parseFloat(data.male_percent),
          female: parseInt(data.female),
          femalePercentage: parseFloat(data.female_percent),
          aveWeight: parseFloat(data.ave_weight),
          goalAveWeight: parseFloat(data.weight_goal),
          aveBMI: parseFloat(data.ave_bmi),
          goalMinAveBMI: parseFloat(data.bmi_min),
          goalMaxAveBMI: parseFloat(data.bmi_max),
          aveHeight: `${height[0]}"${height[1] ? `${height[1]}'` : ''}`,
          states: data.states,
          topTenStates: data.top_ten,
        };

        // Milestone Summary
        response = await this.instance.get(`/employer/milestone-summary${query}`);
        data = response.data;
        const ms = data.map((item: any): EmployerMilestoneStats => ({
          total: parseInt(item.total),
          milestoneOne: parseInt(item.milestone_one),
          milestoneTwo: parseInt(item.milestone_two),
          milestoneThree: parseInt(item.milestone_three),
          milestoneFour: parseInt(item.milestone_four),
          fromDate: new Date(item.from_date),
          toDate: new Date(item.to_date),
        }));

        response = await this.instance.get(`/employer/claim-reports${query}`);
        data = response.data;
        const cr = data.map((item: any): EmployerClaimsStats => ({
          total: item.total,
          milestoneOne: parseFloat(item['1']),
          milestoneTwo: parseFloat(item['2']),
          milestoneThree: parseFloat(item['3']),
          milestoneFour: parseFloat(item['4']),
          fromDate: new Date(item.from_date),
          toDate: new Date(item.to_date),
          provider: item.insurance,
        }));

        // Eligibility Summary
        response = await this.instance.get(`/employer/eligibility-summary${query}`);
        data = response.data;
        const es = data.map((item: any): EmployerEligibilityStats => ({
          total: parseInt(item.total),
          failed: parseInt(item.failed),
          failedPercentage: parseFloat(item.failed_percent),
          success: parseInt(item.success),
          successPercentage: parseFloat(item.success_percent),
          fromDate: new Date(item.from_date),
          toDate: new Date(item.to_date),
        }));

        resolve([pds, er, ms, es, cr]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchEmployerWeightLoss = (
    company: string,
    from_date: Date,
    to_date: Date
  ): Promise<EmployerWeightLossStats> => (
    new Promise(async (resolve, reject) => {
      try {
        const search = `?company=${company}&from_date=${moment(from_date).format(FORMAT_YYYY_MM_DD)}&to_date=${moment(to_date).format(FORMAT_YYYY_MM_DD)}`;
        const url = `/employer/weight-outcomes${search}`;
        const response = await this.instance.get(url);
        const data = response.data;
        const item: EmployerWeightLossStats = {
          participants: data.num_participants,
          weights: Object.keys(data.averages).map(item => parseFloat(data.averages[item])),
        };

        resolve(item);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  exportEmployerStatus = (
    company: string,
    endpoint: string,
    key: string,
    value?: string | number[],
    from?: Date,
    to?: Date
  ): Promise<any> => (
      new Promise(async (resolve, reject) => {
        try {
          let url = `/employer/${endpoint}/export?company=${company}`;

          if (value) {
            if (value instanceof Array) {
              for (const item in value) {
                url = `${url}&${key}[]=${value[item]}`;
              }
            } else {
              url = `${url}&${key}=${value}`
            };
          }
          // eslint-disable-next-line no-restricted-globals
          if (status) {
            // eslint-disable-next-line no-restricted-globals
            url = `${url}&status=${status}`;
          }

          if (from) {
            url = `${url}&from_date=${moment(from).format(FORMAT_YYYY_MM_DD)}`;
          }

          if (to) {
            url = `${url}&to_date=${moment(to).format(FORMAT_YYYY_MM_DD)}`;
          }

          const response = await this.instance.get(url);

          resolve(response);
        } catch (error) {
          if (!axios.isCancel(error)) {
            const message = error.response.data.message || error.response.message;

            toast.error(message, { autoClose });
            reject();
          }
        }
      })
    )

  exportEmployerWeightLoss = (
    company: string,
    from?: Date,
    to?: Date
  ): Promise<any> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/employer/weight-outcomes/export?company=${company}`;

        if (from) {
          url = `${url}&from_date=${moment(from).format(FORMAT_YYYY_MM_DD)}`;
        }

        if (to) {
          url = `${url}&to_date=${moment(to).format(FORMAT_YYYY_MM_DD)}`;
        }

        const response = await this.instance.get(url);

        resolve(response);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
