import * as React from 'react';
import { Grid, Col, Row } from 'react-flexbox-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import placeholder from '../../assets/images/placeholder.jpg';

import { searchDietitian } from '../../redux/actions/SuperuserClassDetails';
import { RootState } from '../../redux/reducers';
import { ReducerStateType } from '../../redux/reducers/SuperuserClassDetails';

import { debouncedOnChangeText, practiceConstants } from '../../utils';
import { VALIDATION_NO_DIETITIAN } from '../../utils/constants';
import { Dietitian } from '../../utils/types';

import './index.scss';

type DispatchPropTypes = Readonly<{
  searchDietitian: (query: string, page: number) => void;
}>;

interface Props extends ReducerStateType, DispatchPropTypes {
  dietitian?: Dietitian;
  // dietitians: Dietitian[];
  disabled?: boolean;

  onSelectDietitian: (dietitian: Dietitian) => void;
  onRemoveDietitian: (dietitian: Dietitian) => void;
}

interface State {
  displayDropdown: boolean;
  filteredDietitians: Dietitian[];
  filterText: string;
  isEditable: boolean;
  isInitial: boolean;
  isLastPage: boolean;
  page: number;
  selectedDietitian?: Dietitian;
}

class AddDietitianInput extends React.Component<Props, State> {
  private node: HTMLDivElement | null = null;
  private textInputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      displayDropdown: false,
      filteredDietitians: props.dietitians || [],
      filterText: '',
      isEditable: false,
      isInitial: true,
      isLastPage: false,
      page: 1,
      selectedDietitian: props.dietitian
    };

    document.addEventListener('mousedown', this.handleClick, false);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { dietitian, dietitians } = nextProps;
    const { isInitial } = prevState;

    if (dietitian && isInitial) {
      return {
        ...prevState,
        isInitial: false,
        selectedDietitian: dietitian,
      }
    }

    if (dietitians != prevState.filteredDietitians) {
      let filteredDietitians = dietitians || [];

      if (prevState.page > 1) {
        filteredDietitians = [...prevState.filteredDietitians, ...filteredDietitians];
      }

      return {
        ...prevState,
        filteredDietitians,
        isLastPage: !dietitians || (dietitians && dietitians.length == 0),
      }
    }

    return null;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node) {
      return;
    }

    if (!this.node.contains(e.target) && this.state.displayDropdown) {
      this.toggleDropdown();
    }
  }

  handlePagination = event => {
    let element = event.currentTarget;
    const isLast = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (isLast && !this.props.isSearching && !this.state.isLastPage) {
      const { filterText, page } = this.state;

      this.props.searchDietitian(filterText, page + 1);
      this.setState({ page: page + 1 });
    }
  }

  onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const text = event.target.value;
    const hasFilter = text.length > 0;
    const { dietitians } = this.props;
    let { filteredDietitians } = this.state;

    if (text.length > 0) {
      this.props.searchDietitian(text, 1);
    }

    if (!hasFilter) {
      filteredDietitians = dietitians!;
    } else {
      filteredDietitians = dietitians!.filter((item: Dietitian) => (
        item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1
      ));
    }

    this.setState({
      displayDropdown: text.length > 0,
      filterText: text,
      filteredDietitians,
      page: 1,
    });
  }

  onDropdownSelection = (dietitian: Dietitian) => {
    this.props.onSelectDietitian(dietitian);
    this.setState({
      displayDropdown: false,
      isEditable: false,
      selectedDietitian: dietitian,
    });
  }

  onDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const node = this.textInputRef.current;
    const { selectedDietitian } = this.state;

    if (selectedDietitian) {
      this.props.onRemoveDietitian(selectedDietitian);
    }

    if (node) {
      node.value = "";
    }

    this.setState({
      filteredDietitians: this.props.dietitians!,
      selectedDietitian: undefined,
    });
  }

  onEditButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const node = this.textInputRef.current;

    if (node) {
      node.disabled = false;
      node.focus();
    }

    this.setState({ isEditable: true });
  }

  toggleDropdown = () => {
    this.setState({ displayDropdown: !this.state.displayDropdown });
  }

  renderDietitian = (dietitian: Dietitian) => {
    const { avatar, fullName } = dietitian;

    return (
      <div className="AddDietitian-infoContainer">
        <img
          className="ParticipantRow-image"
          src={avatar || placeholder} />
        <label className="AddDietitian-title">
          {fullName}
        </label>
      </div>
    )
  }

  renderList = (dietitians: Dietitian[]) => {
    const { isSearching } = this.props;
    const { filterText } = this.state;

    let component;

    if (isSearching) {
      component = (
        <li key={-1}>
          <label className="AddParticipant-fetchingRowLabel">Fetching...</label>
        </li>
      );

      if (dietitians.length > 0) {
        component = (
          <>
            {dietitians.map((item: Dietitian, index: number) => (
              <li key={item.id} onClick={() => this.onDropdownSelection(item)}>
                <label>
                  {item.fullName}
                  &nbsp;&nbsp;
                  <label style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {`(${item.email})`}
                  </label>
                </label>
              </li>
            ))}
            {component}
          </>
        )
      }
    } else if (filterText.length > 0) {
      if (dietitians.length == 0) {
        component = <li key={-2}><label>No search results</label></li>;
      } else {
        component = dietitians.map((item: Dietitian, index: number) => {
          const { selectedDietitian } = this.state;
          const isSame = selectedDietitian && item.id === selectedDietitian.id;
          const props = isSame ? { className: 'active' } : {};

          return (
            <li
              key={item.id}
              onClick={() => this.onDropdownSelection(item)}
              {...props}>
              <label>
                {item.fullName}
                &nbsp;&nbsp;
                <label style={{ fontSize: 12, fontStyle: 'italic' }}>
                  {`(${item.email})`}
                </label>
              </label>
            </li>
          )
        });
      }
    }

    return (
      <ul
        className="AddDietitian-dropdownList"
        onScroll={this.handlePagination}
        style={{ width: '34%' }}>
        {component}
      </ul>
    )
  }

  render() {
    const { disabled } = this.props;
    const {
      displayDropdown,
      filterText,
      filteredDietitians,
      isEditable,
      selectedDietitian: dietitian,
    } = this.state;
    const value = dietitian ? dietitian!.fullName : "";

    return (
      <div ref={node => this.node = node}>
        <Grid className={`AddDietitian-dropdown ${practiceConstants().className}`}>
          <label className="AddDietitian-label">
            Dietitian Assigned
          </label>
          <Row middle="xs" className="AddDietitian-container" style={{ width: '35%' }}>
            <Col xs>
              {dietitian && !isEditable ?
                this.renderDietitian(dietitian) :
                <input
                  autoComplete="off"
                  autoFocus={isEditable}
                  defaultValue={value}
                  disabled={!isEditable}
                  className="AddDietitian-input"
                  onChange={debouncedOnChangeText(this.onChangeText, 500)}
                  onFocus={() => this.setState({ displayDropdown: filterText.length > 0 })}
                  placeholder={VALIDATION_NO_DIETITIAN}
                  ref={this.textInputRef}
                  style={{ backgroundColor: 'white' }}
                  type="search" />
              }
            </Col>

            {!disabled && (
              <Col>
                <button
                  className="AddDietitian-button"
                  onClick={this.onEditButtonClick}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  className="AddDietitian-button"
                  onClick={this.onDeleteButtonClick}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </Col>
            )}
          </Row>

          {displayDropdown && this.renderList(filteredDietitians)}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isSearching: state.superuserClassDetails.isSearching,
  dietitians: state.superuserClassDetails.dietitians,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchDietitian: (query: string, page: number) => dispatch(searchDietitian.request({ query, page })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDietitianInput);
