import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Login from './component';
import { User } from '../../utils/types';
import { RootState } from '../../redux/reducers';
import { setUser, clearUser } from '../../redux/actions/Authentication';

const mapStateToProps = (state: RootState) => ({
  user: state.authentication.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUser: (accessToken: string, refreshToken: string, user: User) => dispatch(setUser(accessToken, refreshToken, user)),
  clearUser: () => dispatch(clearUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
