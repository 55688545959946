import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-flexbox-grid';
import { RouteComponentProps } from 'react-router';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import classnames from 'classnames';

import DoctorsAPIClient from '../../../api/doctors';

import AppNav from '../../../components/AppNav';
import BaseModal from '../../../components/BaseModal';
import CopyButton from '../../../components/CopyButton';
import CustomButton from '../../../components/CustomButton';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import TotalResults from '../../../components/TotalResults';

import { useAPIPagination } from '../../../hooks/pagination';

import { Doctor, DoctorParticipant } from '../../../types/doctors';

import { loaderComponent, practiceConstants } from '../../../utils';
import {
  BASE_URL_INGENUITY,
  ROUTES,
  US_STATES_SELECTIONS,
  VALIDATION_REQUIRED,
  VALIDATION_CONFIRM_EMAIL,
  VALIDATION_FORMAT,
  PER_PAGE,
} from '../../../utils/constants';

import DoctorParticipantsList from './List';

import './index.scss';

const TIMEOUT = 2000;

enum FormKeys {
  FirstName = 'first_name',
  LastName = 'last_name',
  MobilePhone = 'phone',
  EmailAddress = 'email_address',
  ConfirmEmailAddress = 'confirm_email_address',
  StreetAddress = 'street_address',
  City = 'city',
  State = 'state',
  ZipCode = 'zip_code',
  Website = 'website',
  MedicalSpecialty = 'med_specialty',
  ReferralUrl = 'referral_url',
}

type ColumnData = {
  capitalize?: boolean;
  key: string;
  label: string;
  placeholder: string;
  mask?: string;
  type: string;
  column?: number;
}

type Response = { title: string, message: string };

interface Props extends RouteComponentProps {}

export default function DoctorDetail(props: Props | any) {
  let labelNode: HTMLLabelElement | null = null;

  const api = new DoctorsAPIClient(BASE_URL_INGENUITY);
  const prevPath = props.location.state['prevPath'];
  const doctorId = props.match.params['id'];
  const { className } = practiceConstants();

  // States
  const [doctor, setDoctor] = useState<Doctor>();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Form States
  const [formData, setFormData] = useState<{ [id: string]: any }>({
    [FormKeys.FirstName]: '',
    [FormKeys.LastName]: '',
    [FormKeys.EmailAddress]: '',
    [FormKeys.MobilePhone]: '',
    [FormKeys.ConfirmEmailAddress]: '',
    [FormKeys.StreetAddress]: '',
    [FormKeys.City]: '',
    [FormKeys.State]: US_STATES_SELECTIONS[0].value,
    [FormKeys.ZipCode]: '',
    [FormKeys.Website]: '',
    [FormKeys.MedicalSpecialty]: '',
    [FormKeys.ReferralUrl]: ''
  });
  const [formErrors, setFormErrors] = useState<{ [id: string]: string }>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(_.isUndefined(doctorId));
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [response, setResponse] = useState<Response | undefined>();
  const [state, setState] = useState(US_STATES_SELECTIONS[0]);
  const [users, setUsers] = useState<DoctorParticipant[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);

  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total);

  useEffect(() => {
    if (doctorId) {
      setIsFetching(true);
      api.fetchDoctorDetails(doctorId)
        .then(response => {
          setDoctor(response);
          populateForm(response);
        })
        .catch(() => {
          setIsFetching(false);
          setDoctor(undefined);
        });
    }
  }, [])

  useEffect(() => {
    if (!doctor) {
      return;
    }

    setIsFetchingList(true);
    api.fetchDoctorParticipants(doctor.id, currentPage + 1)
      .then(response => {
        const [u, i, j, t] = response;

        setIsFetching(false);
        setIsFetchingList(false);
        setUsers(u);
        setFrom(i);
        setTo(j);
        setTotal(t);
      })
      .catch(() => {
        setIsFetching(false);
        setIsFetchingList(false);
        setUsers([]);
        setFrom(0);
        setTo(0);
        setTotal(0);
      });
  }, [doctor, currentPage])

  const isInputInvalid = (key: string) => !_.isEmpty(formErrors[key]) && !isInitial;

  const populateForm = (data: Doctor) => {
    const state = US_STATES_SELECTIONS.filter(u => u.value == data.state)[0];

    setFormData({
      [FormKeys.FirstName]: data.firstName,
      [FormKeys.LastName]: data.lastName,
      [FormKeys.EmailAddress]: data.email,
      [FormKeys.MobilePhone]: data.phoneNumber,
      [FormKeys.ConfirmEmailAddress]: data.email,
      [FormKeys.StreetAddress]: data.streetAddress,
      [FormKeys.City]: data.city,
      [FormKeys.State]: state.value,
      [FormKeys.ZipCode]: data.zip,
      [FormKeys.Website]: data.website,
      [FormKeys.MedicalSpecialty]: data.specialty,
      [FormKeys.ReferralUrl]: data.referralUrl
    });
    setState(state);
  }

  const validateForm = (payload) => {
    let errors = formErrors;
    let isValid = true;
    const optionalFields = [
      FormKeys.Website.toString(),
    ];

    Object.keys(FormKeys).map((k: string) => {
      const key = FormKeys[k];
      const value = payload[key];
      const isConfirmEmail = key == FormKeys.ConfirmEmailAddress;
      let error = !value || value && value.length == 0 ? VALIDATION_REQUIRED : '';
      let regex: RegExp | undefined;

      if (!doctorId && key === 'referral_url') {
        error = '';
      }

      error = optionalFields.includes(key)  ? '' : error;

      switch (key) {
        case FormKeys.EmailAddress:
        case FormKeys.ConfirmEmailAddress:
          const email = payload[FormKeys.EmailAddress];
          const confirmEmail = payload[FormKeys.ConfirmEmailAddress];

          if (isConfirmEmail && (confirmEmail && confirmEmail.length > 0)) {
            error = email != confirmEmail ? VALIDATION_CONFIRM_EMAIL : error;
          }

          regex = /^\S+@\S+$/;

          break;

        case FormKeys.MobilePhone:
          regex = /^(\d{3})[-](\d{3})[-](\d{4})/;

          break;

        case FormKeys.State:
          if (formErrors[key] && formErrors[key].length > 0) {
            error = formErrors[key];
          }

          break;

        case FormKeys.State:
          error = _.isUndefined(state) ? VALIDATION_REQUIRED : '';

          break;

        case FormKeys.ReferralUrl:
          const check = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/;
          if (check.test(value)) {
            error = "No special characters.";
          }

          break;

        default:
          break;
      }

      if (regex) {
        error = value && value.length > 0 && !regex.test(value) ? VALIDATION_FORMAT : error;
      }

      if (error) {
        isValid = false;
      }

      errors[key] = error;
    });

    setFormErrors({ ...formErrors, ...errors });
    setIsInitial(false);

    return isValid;
  }

  const onAssignRef = (ref: HTMLLabelElement | null, key: string) => {
    const error = formErrors[key];

    if (key == FormKeys.FirstName) {
      labelNode = ref;
    }

    if (!error && (labelNode && labelNode == ref)) {
      labelNode = null;
    } else if (error && !labelNode) {
      labelNode = ref;
    }
  }

  const onDeleteRecord = () => {
    if (!doctor) {
      return;
    }

    setShowDeleteModal(false);
    setIsProcessing(true);
    setIsDeleting(true);

    const onThen = response => {
      setIsProcessing(false);
      setResponse(response);
      setTimeout(() => {
        onExitPage();
      }, TIMEOUT);
    };
    const onCatch = () => {
      setIsProcessing(false);
      setResponse(undefined);
    };

    api.deleteDoctorRecord(doctor.id).then(onThen).catch(onCatch);
  }

  const onExitPage = () => {
    props.history.push(prevPath || ROUTES.SuperuserDoctors);
  }

  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const onThen = response => {
      setIsProcessing(false);
      setIsEditing(false);
      setResponse(response);
      setTimeout(() => {
        onExitPage();
      }, TIMEOUT);
    };
    const onCatch = (errors) => {
      setFormErrors({ ...formErrors, ...errors })
      setIsProcessing(false);
      setResponse(undefined);
    };

    if (validateForm(formData)) {
      setIsProcessing(true);

      // Exclude website from payload if empty
      if (formData[FormKeys.Website] === '') {
        delete formData[FormKeys.Website];
      }

      if (isEditing && doctor) {
        api.updateDoctorRecord(doctor.id, formData).then(onThen).catch(onCatch);
      } else {
        api.createDoctorRecord(formData).then(onThen).catch(onCatch);
      }
    }
  }

  const renderCancelModal = () => (
    <BaseModal
      cancelTitle="No"
      title={`Exit ${doctorId ? 'Update' : 'Create'} Record`}
      toDisplay={showCancelModal}
      onCancel={() => setShowCancelModal(false)}
      onClose={() => setShowCancelModal(false)}
      onConfirm={onExitPage}>
      <label className="ModalLabel">
        All unsaved progress will be lost. Continue?
      </label>
    </BaseModal>
  )

  const renderDeleteModal = () => (
    <BaseModal
      cancelTitle="No"
      confirmTitle="Yes"
      title={`Exit Delete Record`}
      toDisplay={showDeleteModal}
      onCancel={() => setShowDeleteModal(false)}
      onClose={() => setShowDeleteModal(false)}
      onConfirm={onDeleteRecord}>
      <label className="ModalLabel">
        All you sure you want to delete this record?
      </label>
    </BaseModal>
  )

  const renderResponseModal = () => (
    <BaseModal
      title={`${isDeleting ? 'Delete' : (doctorId ? 'Update' : 'Create')} Record`}
      toDisplay={!_.isUndefined(response)}
    >
      <label className="ModalLabel">
        {response || ''}
      </label>
    </BaseModal>
  )

  const renderDoctorActions = () => {
    if (isEditing) {
      return (
        <div className="header-actions-section">
          <CustomButton
            title="Cancel"
            onClick={() => setShowCancelModal(true)}
          />
          <CustomButton
            primary
            disabled={isProcessing}
            title={`${doctor ? 'Update' : 'Save'} Doctor`}
            onClick={onSubmit}
          />
        </div>
      )
    }

    return (
      <div className="header-actions-section">
        <CustomButton
          title="Edit"
          onClick={() => setIsEditing(true)}
        />
        <CustomButton
          danger
          title="Delete Record"
          onClick={() => setShowDeleteModal(true)}
        />
      </div>
    )
  }

  const renderFields = (items: (ColumnData | React.ReactNode)[]) => (
    <Row className="Payment-row" start="xs">
      {items.map((columnItem: (ColumnData | React.ReactNode), index: number) => {
        if (!(columnItem as ColumnData).key) {
          return (
            <React.Fragment key={index.toString()}>
              {columnItem}
            </React.Fragment>
          )
        }

        const item = columnItem as ColumnData;
        const value = formData[item.key] || '';
        const inputProps = {
          className: classnames([
            'Payment-input',
            isInputInvalid(item.key) ? 'is-invalid' : '',
            item.capitalize ? 'capitalize' : '',
          ]),
          disabled: !isEditing,
          mask: '',
          name: item.key,
          placeholder: item.placeholder,
          type: item.type,
          value,
          onChange: onInputValueChange,
          autoComplete: 'off',
        }
        const error = formErrors[item.key];

        if (item.mask) {
          inputProps['mask'] = item.mask!;
          inputProps['maskChar'] = '';
        }

        return (
          <Col
            key={index}
            className={item.key != FormKeys.ReferralUrl ? "Payment-column" : undefined}
            xs={12} md={item.column || 12 / items.length}>
              {item.key == FormKeys.ReferralUrl && isEditing ?
                <div className="actions-section no-spaces no-borders">
                  <h3 className="primary-title" >
                    {item.label}&nbsp;&nbsp; <br />

                    <Row>
                      <Col xs={12} lg={12}>
                        <label className="link text-muted">
                          {doctor && doctor.quizLink.substr(0, doctor.quizLink.lastIndexOf("/") + 1)}
                        </label>
                        <InputMask
                          id={item.key}
                          {...inputProps}
                          className="edit-url"
                        />
                      </Col>
                    </Row>
                  </h3>
                </div>
              :
                <label
                  className="Payment-label"
                  htmlFor={item.key}
                  ref={ref => onAssignRef(ref, item.key)}>
                  {item.label}
                </label>
              }
              {item.key != FormKeys.ReferralUrl &&
                <InputMask id={item.key} {...inputProps} />
              }
              <div className="Payment-label is-invalid">
                {error}
              </div>
          </Col>
        )
      })}
    </Row>
  )

  const renderReferralRow = () => {
    const data: ColumnData[] = [
      {
        capitalize: true,
        key: FormKeys.ReferralUrl,
        label: 'Referral Link',
        placeholder: '',
        type: 'text',
        column: 8,
      },
    ];

    return renderFields(data);
  }

  const renderFirstRow = () => {
    const data: ColumnData[] = [
      {
        capitalize: true,
        key: FormKeys.FirstName,
        label: 'First Name',
        placeholder: 'John',
        type: 'text',
        column: 4,
      },
      {
        capitalize: true,
        key: FormKeys.LastName,
        label: 'Last Name',
        placeholder: 'Smith',
        type: 'text',
        column: 4,
      },
      {
        key: FormKeys.MobilePhone,
        label: 'Contact Number',
        placeholder: '000-000-0000',
        type: 'text',
        mask: '999-999-9999'
      },
    ];

    return renderFields(data);
  }

  const renderSecondRow = () => {
    const data: ColumnData[] = [
      {
        key: FormKeys.EmailAddress,
        label: 'Email Address',
        placeholder: 'johnsmith@gmail.com',
        type: 'email'
      },
      {
        key: FormKeys.ConfirmEmailAddress,
        label: 'Confirm Email',
        placeholder: 'johnsmith@gmail.com',
        type: 'email'
      },
      {
        key: FormKeys.Website,
        label: 'Website',
        placeholder: 'https://',
        type: 'text',
        column: 4,
      },
    ];

    return renderFields(data);
  }

  const renderThirdRow = () => {
    const data: (ColumnData[] | React.ReactNode)[] = [
      {
        key: FormKeys.MedicalSpecialty,
        label: 'Medical Specialty',
        placeholder: '',
        type: 'text',
        column: 4,
      },
      {
        key: FormKeys.StreetAddress,
        label: 'Street Address (No PO Boxes)',
        placeholder: 'Street Address',
        type: 'text',
        column: 8,
      },
    ];

    return renderFields(data);
  }

  const renderFourthRow = () => {
    const data: (ColumnData[] | React.ReactNode)[] = [
      {
        key: FormKeys.City,
        label: 'City',
        placeholder: 'City',
        type: 'text',
      },
      (
        <Col className="Eligibility-column" xs={12} md={4}>
          <label className="Eligibility-label">
            State
          </label>
          <Dropdown
            hasSearch
            disabled={!isEditing}
            hasError={isInputInvalid(FormKeys.State)}
            items={US_STATES_SELECTIONS}
            selectedItem={state}
            onItemSelection={item => {
              setState(item);
              setFormData({ ...formData, [FormKeys.State]: item.value });
            }} />
        </Col>
      ),
      {
        key: FormKeys.ZipCode,
        label: 'Zip Code',
        placeholder: '00000',
        type: 'text',
        mask: '99999',
      },
    ];

    return renderFields(data);
  }

  const renderLink = (link: string) => (
    <div className={`actions-section bottom-border ${className}`}>
      <Row>
          <Col xs={12} md={10} lg={10}>
            {isEditing ? renderReferralRow() :
              <h3 className="primary-title">
                Referral Link&nbsp;&nbsp; <br />

                <label className="link text-muted">
                  {link}
                </label>
              </h3>
            }
          </Col>
          <Col xs={12} md={2} lg={2} className="link-actions">
            <CopyButton
              disabled= {isEditing ? true : false}
              type="custom"
              copyValue={link}
            />
          </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (users && users.length == 0) {
      return (
        <div className="empty-section text-muted">
          <label>No participants yet.</label>
        </div>
      )
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <DoctorParticipantsList
          currentPage={currentPage}
          isFetching={isFetchingList}
          numPages={numPages}
          pagedUsers={pagedUsers}
          setIsFetching={setIsFetchingList}
          setCurrentPage={setCurrentPage}
        />
      </>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack={doctorId}
          backHandler={() => {
            if (isEditing) {
              setShowCancelModal(true);
            } else {
              onExitPage();
            }
          }}
          titleLg={6}
          title={doctorId ? 'Doctor Details' : 'Create Doctor Record'}
        >
          {renderDoctorActions()}
        </Header>

        {showCancelModal && renderCancelModal()}
        {showDeleteModal && renderDeleteModal()}
        {!_.isUndefined(response) && renderResponseModal()}
        {isProcessing && loaderComponent()}

        {isFetching ?
          <div className="center-container">
            <Spinner />
          </div> :
          <>
            {doctor && renderLink(doctor.quizLink)}

            <form>
              <div>
                {renderFirstRow()}
                {renderSecondRow()}
                {renderThirdRow()}
                {renderFourthRow()}
              </div>
            </form>

            <div className={`actions-section ${className}`}>
              <Row>
                <Col xs={12} lg={1}>
                  <h3 className="primary-title">Participants</h3>
                </Col>
              </Row>
            </div>

            {renderList()}
          </>
        }
      </Container>
    </>
  )
}
