import React from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faChartLine,
  faCheckCircle,
  faTimes,
  IconDefinition,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import Button from '../../../../components/Button';

import { FORMAT_MMMM_D_YYYY } from '../../../../utils/constants';
import { ExportType as StatType, DropdownItem } from '../../../../utils/types';

import { ExportItem } from '../ExportModal';

import './index.scss';

interface Props {
  data: EmployerStatCardData;
  disabled?: boolean;
  asHeader?: boolean;
  onClick: (
    data: EmployerStatCardData,
    event?: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  onExportClick?: (data: EmployerStatCardData) => void;
}

export type EmployerStatColumn = {
  icon: IconDefinition,
  header: string;
  value: string;
  valueSuffix?: string;
  hasHighLow: boolean;
  isDanger?: boolean;
  noIcon?: boolean;
  subtitle: string;
  subValue: string;
}

export type EmployerStatCardData = {
  columns: EmployerStatColumn[];
  type: StatType;
  chartData?: {
    labels: string[],
    datasets: {
      label: string,
      data: number[],
      backgroundColor: string,
    }[]
  },
  statesData?: { [id: string] : number },
  month?: DropdownItem;
  year?: DropdownItem;
  date?: Date;
  start_date?: Date;
  end_date?: Date;
  exportItems?: ExportItem[];
}

export default function EmployerStatCard(props: Props) {
  const { data, disabled, asHeader, onClick, onExportClick } = props;
  const { columns, type, month, year, date } = data;

  const buttonTitleForType = () => {
    switch (type) {
      case StatType.Enrollment:
        return 'View by State';

      case StatType.Claims:
        return 'View Report';

      default:
        return 'View Trend';
    }
  }

  const titleForType = () => {
    let title = '';
    let substring = '';

    switch (type) {
      case StatType.Enrollment:
        title = asHeader ? 'Enrollees Per State' : type.toString();
      break;
      default:
        title = type.toString();
    }

    if (type == StatType.WeightLoss && date) {
      substring = moment(date).format(FORMAT_MMMM_D_YYYY);
    } else if (month && year) {
      substring = `(${month.title} ${year.title})`
    }

    return `${title}${asHeader ? ` ${substring}` : ''}`;
  }

  return (
    <Table className="EmployerStatCard-container">
      <thead>
        <tr>
          <th colSpan={12}>
            <div className="header">
              <label className="title">{titleForType()}</label>

              {!asHeader ?
                <div className="buttons">
                  <Button
                    disabled={disabled}
                    variant="primary"
                    onClick={() => {
                      if (onExportClick) {
                        onExportClick(data);
                      }
                    }}>
                    <FontAwesomeIcon className="download-icon" icon={faDownload} />
                  </Button>
                  <Button
                    disabled={disabled}
                    variant="primary"
                    onClick={() => onClick(data)}>
                    <FontAwesomeIcon icon={faChartLine} />

                    {buttonTitleForType()}
                  </Button>
                </div> :
                <span className="BaseModal-closeButton" onClick={() => onClick(data)}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              }
            </div>
          </th>
        </tr>
        <tr className="header">
          {columns.map((item, index) => (
            <th key={index.toString()}>
              <strong><FontAwesomeIcon icon={item.icon} />{item.header}</strong>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="main-data">
          {columns.map((item, index) => (
            <td
              key={index.toString()}
              className={item.hasHighLow ? (item.isDanger ? "red" : "green") : "primary"}>
              <div>
                <label>
                  {item.value}{item.valueSuffix && <span>{item.valueSuffix}</span>}
                </label>&nbsp;

                {(item.hasHighLow && !item.noIcon) &&
                  <FontAwesomeIcon icon={item.isDanger ? faExclamationCircle : faCheckCircle} />
                }
              </div>
            </td>
          ))}
        </tr>
        <tr className="sub-data">
          {columns.map((item, index) => (
            <td key={index.toString()}>
              <span>{item.subtitle}</span>
              {item.subValue}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  )
}
