import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

import ConsumerPaymentsAPIClient from '../../../api/consumer-payments';

import AppNav from '../../../components/AppNav';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import NoResults from '../../../components/NoResults';
import SearchInput from '../../../components/SearchInput';
import Spinner from '../../../components/Spinner';
import TotalResults from '../../../components/TotalResults';

import { useAPIPagination } from '../../../hooks/pagination';
import useDebounce from '../../../hooks/useDebounce';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import { ConsumerPaymentUser } from '../../../types/consumer-payments';

import { practiceConstants, queryStringParams } from '../../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_YYYY_MM_DD,
  PER_PAGE,
  ROUTES,
} from '../../../utils/constants';

import ConsumerPaymentList from './List';

import './index.scss';

interface Props extends RouteComponentProps { }

export default function ConsumerPayments(props: Props) {
  const api = new ConsumerPaymentsAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className, employers } = practiceConstants();

  // Filter states
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value == query[QueryKeys.Company]
  ))[0] || employers[0]);
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );
  const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');

  // Dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [users, setUsers] = useState<ConsumerPaymentUser[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.Company]: employer.value,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Search]: debouncedSearchTerm,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchConsumerPayments(queryString)
      .then(response => {
        const [list, i, j, t] = response;

        setUsers(list);
        setFrom(i);
        setTo(j);
        setTotal(t);
        setIsFetching(false);
      })
      .catch(() => {
        setUsers([]);
        setTotal(0);
        setFrom(0);
        setTo(0);
        setIsFetching(false);
      });
  }, [currentPage, employer, startDate, endDate, debouncedSearchTerm])

  const onChangeText = text => {
    setIsFetching(true);
    setCurrentPage(0);
    setSearchFilter(text);
  }

  const renderFilters = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={1}>
          <h3 className="primary-title">Filters</h3>
        </Col>
        <Col xs={12} lg={11} className="controls">
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={item => {
              setCurrentPage(0);
              setEmployer(item);
            }} />
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Landing Date"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0])
              setEndDate(dates[dates.length - 1])
            }}
            onStartDateSelection={date => {
              setCurrentPage(0);
              setStartDate(date);
            }}
            onEndDateSelection={date => {
              setCurrentPage(0);
              setEndDate(date);
            }}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <ConsumerPaymentList
          currentPage={currentPage}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={pagedUsers}
          setCurrentPage={setCurrentPage}
          setIsFetching={setIsFetching}
          onItemSelection={item => {
            const { id } = item;
            const { pathname, search } = props.location;
            const url = ROUTES.SuperuserConsumerPaymentDetails.replace(':id', id)
            const prevPath = `${pathname}${search}`;

            props.history.push(url, { prevPath });
          }}
        />
      </>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Consumer Payment">
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Participant Name, ID"
            onChangeText={onChangeText} />
        </Header>

        {renderFilters()}

        {(isFetching && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> :
          renderList()
        }
      </Container>
    </>
  );
}
