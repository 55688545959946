import React, { useState, useEffect } from 'react';

import moment from 'moment-timezone';
import { match } from 'react-router';
import assign from 'lodash-es/assign';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import isUndefined from 'lodash-es/isUndefined';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {
  faCalendarDay,
  faClock,
  faFileAlt,
  faCheck,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DietitianAPIClient from '../../api/dietitian';

import AppNav from '../../components/AppNav';
import BaseModal from '../../components/BaseModal';
import Button from '../../components/Button';
import DietitianClassCard from '../../components/DietitianClassCard';
import Header from '../../components/Header';
import Participant from '../../components/Participant';
import Spinner from '../../components/Spinner';

import { classItemStatus, practiceConstants } from '../../utils';
import {
  FORMAT_DDDD,
  FORMAT_MM_DD,
  FORMAT_H_MMA,
  ROUTES,
} from '../../utils/constants';
import { ClassItem, Participant as ParticipantType } from '../../utils/types';

import './index.scss';

type Props = Readonly<{
  match: match<{ id: string }>;
  userId: number;
}>

export default function DietitianClass(props: Props) {
  const { match, userId } = props;
  const api = new DietitianAPIClient();

  // Claim States
  const [canClaim, setCanClaim] = useState(true);
  const [classes, setClasses] = useState<ClassItem[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  // Page
  const [item, setItem] = useState<ClassItem | null>(null);
  const [participants, setParticipants] = useState<ParticipantType[]>([]);
  const [isShowingAllParticipants, setIsShowingAllParticipants] = useState(false);

  useEffect(() => {
    api.fetchClasses()
      .then(response => {
        setClasses(response);
        api.fetchClass(match.params.id).then(item => {
          setItem(item);
          setParticipants(item.participants);
        });
      })
      .catch(() => {
        setItem(null);
        setParticipants([]);
      })
  }, [match.params.id]);

  // Callback for claiming a class
  const claimClass = async (item: ClassItem) => {
    let isSame = false;

    for (const classItem of classes) {
      if ((classItem.dietitianId && classItem.dietitianId == userId) &&
        (classItem.date.getTime() == item.date.getTime())) {
        isSame = true;

        setCanClaim(false);

        break;
      }
    }

    if (isSame) {
      return;
    }

    const response = api.claimClass(item.id);

    setIsProcessing(true);
    response
      .then(() => {
        item!.dietitianId = userId;

        setItem(assign({}, item, {
          dietitianId: userId,
          status: classItemStatus(item!),
        }));
        setIsProcessing(false);
        toast.success('Class claimed.');
      })
      .catch(error => {
        setIsProcessing(false);
        toast.error(error.response.data.message);
      });

    return response;
  };

  // Callback for unclaiming class
  const unclaimClass = async (item: ClassItem) => {
    const response = api.unclaimClass(item.id);

    setIsProcessing(true);
    response
      .then(() => {
        item!.dietitianId = undefined;

        setItem(assign({}, item, {
          dietitianId: undefined,
          status: classItemStatus(item!),
        }));
        setClasses(classes.map(c => c.id == item.id ? item : c));
        setIsProcessing(false);
        toast.success('Class unclaimed.');
      })
      .catch(error => {
        setIsProcessing(false);
        toast.error(error.response.data.message);
      });

    return response;
  };

  return (
    <>
      <AppNav />
      <Container className="superuser Container-padding-bottom">
        {(!canClaim && item) &&
          <BaseModal
            title={`${moment(item.date).format(FORMAT_DDDD)} - ${moment(item.date).format(FORMAT_H_MMA)}`}
            toDisplay={!canClaim}
            confirmTitle="Ok"
            onConfirm={() => setCanClaim(true)}>
            <label className="ModalLabel">
              You already have a class on this day and time. Please choose another class.
            </label>
          </BaseModal>
        }

        {item !== null
          ?
            <>
              <Header
                titleXs={12}
                titleMd={8}
                titleLg={8}
                title={item !== null ? item.name! : 'Class'}
                className="class-detail-header"
              >
                <LinkContainer to={ROUTES.DietitianClasses}>
                  <Button variant="primary">Go back to class list</Button>
                </LinkContainer>
              </Header>
              <section className="class-detail-section">
                <DietitianClassCard
                  busy={isProcessing}
                  item={item}
                  claimed={!isUndefined(item.dietitianId)}
                  // claimed={!isUndefined(item.dietitianId) ? item.dietitianId === userId : false}
                  onClaim={claimClass}
                  onUnclaim={unclaimClass}
                />
              </section>

              <section className={`class-detail-section ${practiceConstants().className}`}>
                <h4 className="class-detail-sectiontitle">Participants for the Class</h4>
                {participants.length > 0
                  ?
                    <>
                      <Row className={isShowingAllParticipants ? 'class-detail-participants show-all' : 'class-detail-participants'}>
                        {participants.map(p =>
                          <Col key={p.id} xs={12} md={6} lg={4}>
                            <Participant participant={p}>
                              <OverlayTrigger
                                overlay={<Tooltip id={`toolip-view-participant-${p.id}`}>View Profile</Tooltip>}
                              >
                                <button
                                  type="button"
                                  className="class-detail-participantaction"
                                  onClick={() => { window.open(`https://portal.fruitstreet.com/providers/patient/${p.id}`) }}
                                >
                                  <FontAwesomeIcon icon={faUser} />
                                </button>
                              </OverlayTrigger>
                              {/* <button type="button" className="class-detail-participantaction">
                                <FontAwesomeIcon icon={faComment} />
                              </button> */}
                            </Participant>
                          </Col>
                        )}
                    </Row>
                    <Row>
                      <Col
                        className="class-detail-msgallparticipants"
                        xs={{ span: 12, order: 2 }}
                        md={{ span: 6, order: 1}}
                      >
                        {/* {participants.length > 0
                          ? <Button variant="dark"><FontAwesomeIcon icon={faComment} /> Message Participants</Button>
                          : null} */}
                      </Col>
                      <Col
                      className="class-detail-allparticipants"
                        xs={{ span: 12, order: 1 }}
                        md={{ span: 6, order: 2 }}
                      >
                        {participants.length > 0 && !isShowingAllParticipants
                          ? <Button variant="primary" onClick={() => setIsShowingAllParticipants(true)}>See all</Button> : null}
                      </Col>
                    </Row>
                  </>
                : <div className="text-muted">No participants yet.</div>}
              </section>

              <section className="class-detail-section">
                <h4 className="class-detail-sectiontitle">Class Schedule</h4>
                <div className="class-detail-schedule">
                  <Table className="class-detail-schedule" responsive>
                    <thead>
                      <tr>
                        <th className="class-detail-schedule-id">Session No.</th>
                        <th className="class-detail-schedule-name">
                          <FontAwesomeIcon icon={faFileAlt} />
                          Session Name
                        </th>
                        <th>
                          <FontAwesomeIcon icon={faCalendarDay} />
                          Date
                        </th>
                        <th>
                          <FontAwesomeIcon icon={faCalendarDay} />
                          Day
                        </th>
                        <th>
                          <FontAwesomeIcon icon={faClock} />
                          Time
                        </th>
                        <th>
                          <FontAwesomeIcon icon={faCheck} />
                          Present
                        </th>
                        <th>
                          <FontAwesomeIcon icon={faTimes} />
                          Absent
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.sessions!.map((session, index) =>
                        <tr key={session.id}>
                          <th>{index + 1}</th>
                          <th className="class-detail-schedule-name">
                            {session.name !== '' ? session.name : <em>No session name</em>}
                          </th>
                          <td>{moment(session.date).format(FORMAT_MM_DD)}</td>
                          <td>{moment(session.date).format(FORMAT_DDDD)}</td>
                          <td>
                            {moment(session.date).format(FORMAT_H_MMA)}
                            &nbsp;
                            {moment.tz.zone(moment.tz.guess())!.abbr(session.date.getTime())}
                          </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* <div className="pagination-wrap">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Last />
                  </Pagination>
                </div> */}
              </section>
            </>
          : <Spinner text="Fetching class details..." className="class-detail-spinner" />}
      </Container>
    </>
  )
}
