import React, { useState, SyntheticEvent } from 'react';
import keys from 'lodash-es/keys';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import isFunction from 'lodash-es/isFunction';
import isUndefined from 'lodash-es/isUndefined';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
    FitbitStatus,
    MilestoneStatus,
    PurpleCarrotStatus,
    ScaleStatus,
} from '../../types/milestones';

import { practiceConstants } from '../../utils';
import { CLIENT_TYPE } from '../../utils/constants';
import { formatCurrency } from '../../utils/formatting';

import './index.scss';

interface Props {
    id: string;
    value: string;
    disabled?: boolean;
    paymentValue?: number;
    type?: 'fitbit' | 'scale' | MilestoneStatus | undefined;
    onChange: (status: MilestoneStatus) => Promise<void>;
    onErrorClick?: () => void;
}

// const readonlyStatuses = [
//   // MilestoneStatus.Denied,
//   MilestoneStatus.NotAchieved,
//   // MilestoneStatus.Achieved,
//   // MilestoneStatus.Claimed,
//   // MilestoneStatus.Paid,
//   // MilestoneStatus.Rejected,
//   MilestoneStatus.Error,
//   MilestoneStatus.Denied,
//   // MilestoneStatus.Adjudicated,
// ];
const readonlyStatuses = [
    MilestoneStatus.Denied,
    MilestoneStatus.NotAchieved,
    MilestoneStatus.Achieved,
    MilestoneStatus.Claimed,
    MilestoneStatus.Paid,
    MilestoneStatus.PaidInFull,
    MilestoneStatus.Rejected,
    MilestoneStatus.Error,
    MilestoneStatus.Denied,
    MilestoneStatus.Adjudicated,
    MilestoneStatus.Acknowledged,
];

export default function MilestoneDropdown(props: Props) {
    const { id, value, disabled, onChange, type, onErrorClick } = props;
    const isReadOnly = readonlyStatuses.includes(value as MilestoneStatus);

    const [busy, setBusy] = useState(false);

    const statusMap = () => {
        switch (type) {
            case 'fitbit':
                return {
                    [FitbitStatus.NotIssued]: 'Not Issued',
                    [FitbitStatus.Issued]: 'fitbit Issued',
                    ...((practiceConstants()
                            .practiceName == CLIENT_TYPE.ShareCare)
                        && {[PurpleCarrotStatus.Issued]: 'Purple Carrot Issued'}),
                    [FitbitStatus.CurrentlyInactive]: 'Currently Inactive',
                    [FitbitStatus.Connected]: 'Connected',
                }
            case 'scale':
                return {
                    [ScaleStatus.Submitted]: 'Submitted',
                    [ScaleStatus.Delivered]: 'Delivered',
                    [ScaleStatus.Fulfilled]: 'Fulfilled',
                    [ScaleStatus.NotIssued]: 'Not Issued',
                }
            case MilestoneStatus.Adjudicated:
            case MilestoneStatus.Acknowledged:
            case MilestoneStatus.Achieved:
            case MilestoneStatus.Claimed:
            case MilestoneStatus.Paid:
            case MilestoneStatus.PaidInFull:
            case MilestoneStatus.Rejected:
                return {
                    [MilestoneStatus.Adjudicated]: 'Adjudicated',
                    [MilestoneStatus.Acknowledged]: 'Acknowledged',
                    [MilestoneStatus.Achieved]: 'Achieved',
                    [MilestoneStatus.Claimed]: 'Claim Submitted',
                    [MilestoneStatus.Paid]: 'Paid',
                    [MilestoneStatus.PaidInFull]: 'Paid in Full',
                    [MilestoneStatus.Rejected]: 'Rejected',
                }
            default:
                return {
                    [MilestoneStatus.NotAchieved]: 'Not Achieved',
                    [MilestoneStatus.Adjudicated]: 'Adjudicated',
                    [MilestoneStatus.Acknowledged]: 'Acknowledged',
                    [MilestoneStatus.Achieved]: 'Achieved',
                    [MilestoneStatus.Claimed]: 'Claim Submitted',
                    [MilestoneStatus.Paid]: 'Paid',
                    [MilestoneStatus.PaidInFull]: 'Paid in Full',
                    [MilestoneStatus.Rejected]: 'Rejected',
                    [MilestoneStatus.Error]: 'Error',
                    [MilestoneStatus.Denied]: 'Denied',
                }
        }
    }

    if (value === MilestoneStatus.Error) {
        return (
            <Button
                size="sm"
                className="milestone-error-button"
                variant="danger"
                // disabled={disabled}
                disabled
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    if (!isUndefined(onErrorClick)) {
                        onErrorClick();
                    }
                }}>
                Error
            </Button>
        );
    }

    const getStatus = () => {
        const s = statusMap()[value];
        const { paymentValue } = props;

        if (paymentValue) {
            return `${s} (${formatCurrency(paymentValue)})`;
        }

        return s;
    }

    return (
        <Dropdown
            className={classnames(
                'milestone-dropdown',
                `milestone-dropdown--${(type == 'scale' || type == 'fitbit') ? 'others' : value}`,
                { 'milestone-dropdown--busy': busy },
                practiceConstants().className
            )}
            onToggle={(_, e: SyntheticEvent<Dropdown, Event>) => {
                if (isFunction(e.stopPropagation)) {
                    e.stopPropagation();
                }
            }}
        >
            <Dropdown.Toggle
                id={id}
                size="sm"
                disabled={disabled || busy || isReadOnly}
            >
                <label className={busy ? "busy" : ""}>{getStatus()}</label>

                {!disabled && value !== MilestoneStatus.Error && !isReadOnly ?
                    <FontAwesomeIcon
                        icon={busy ? faSpinner : faCaretDown}
                        spin={busy} /> :
                    null
                }
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
                {keys(statusMap()).map(key => (
                    <Dropdown.Item
                        key={key}
                        eventKey={key}
                        onSelect={(newStatus: any) => {
                            if (busy || value === newStatus) {
                                return;
                            }

                            setBusy(true);
                            onChange(newStatus).then(() => {
                                setBusy(false);
                            });
                        }}
                    >
                        {statusMap()[key]}
                    </Dropdown.Item>))
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}
