import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

import { BASE_URL, BASE_URL_INGENUITY } from '../utils/constants';

const CancelToken = axios.CancelToken;

export default class ApiRequest {
  
  /**
   * Cancel token source.
   */
  protected source: CancelTokenSource;

  /**
   * Set to TRUE if a request has been sent.
   */
  protected started: boolean = false;

  /**
   * Constructor.
   * Sets initial class properties.
   */
  constructor() {
    this.source = CancelToken.source();
  }

  /**
   * The method that will perform the request.
   */
  public send(payload?: any) {
    throw new Error('Implement send() method.');
  }

  /**
   * Sends a GET request.
   * 
   * @param url request url
   * @param config optional config
   */
  protected async get(url: string, config?: AxiosRequestConfig) {
    return this.request('get', url, undefined, config);
  }

/**
 * Sends a POST request.
 *
 * @param url request url
 * @param body post data
 * @param config optional config
 */
  protected async post(url: string, body?: any, config?: AxiosRequestConfig) {
    return this.request('post', url, body, config);
  }

  
  /**
   * Sends a PATCH request.
   *
   * @param url request url
   * @param body post data
   * @param config optional config
   */
  protected async patch(url: string, body?: any, config?: AxiosRequestConfig) {
    return this.request('patch', url, body, config);
  }

  /**
   * Generic request method.
   *
   * @param method request method
   * @param url request url
   * @param data payload
   * @param config optional config
   */
  protected async request(method: 'get' | 'post' | 'patch', url: string, data?: any, config?: AxiosRequestConfig) {
    const defaults: AxiosRequestConfig = {
      url,
      method,
      data,
      cancelToken: this.source.token,
    };
    const conf = Object.assign(defaults, config);

    if (this.started) {
      // this.source.cancel();
    }

    this.started = true;
    return axios
      .request(conf);
      // @todo error handling
  }

  /**
   * Cancel the request.
   */
  public cancel() {
    this.source.cancel();
  }

  /**
   * Returns endpoint with the VSee API URL prefix.
   * 
   * @param endpoint endpoint URI
   */
  protected vseeApiUrl(endpoint: string) {
    return BASE_URL + endpoint;
  }

  /**
   * Returns endpoint with the Laravel backend API URL prefix.
   *
   * @param endpoint endpoint URI
   */
  protected backendApiUrl(endpoint: string) {
    return BASE_URL_INGENUITY + endpoint;
  }
}
