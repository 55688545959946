import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

import ContractsAPIClient from '../../api/contracts';

import AppNav from '../../components/AppNav';
import Button from '../../components/Button';
import DateDropdownGroup from '../../components/DateDropdownGroup';
import Header from '../../components/Header';
import NoResults from '../../components/NoResults';
import SearchInput from '../../components/SearchInput';
import Spinner from '../../components/Spinner';
import TotalResults from '../../components/TotalResults';

import { useAPIPagination } from '../../hooks/pagination';
import useDebounce from '../../hooks/useDebounce';
import { useQueryString, QueryKeys } from '../../hooks/useURLQuery';

import { Contract } from '../../types/contracts';

import { practiceConstants, queryStringParams } from '../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_YYYY_MM_DD,
  PER_PAGE,
  ROUTES,
} from '../../utils/constants';

import ContractsList from './List';

interface Props extends RouteComponentProps {}

export default function Contracts(props: Props) {
  const api = new ContractsAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className } = practiceConstants();

  // Dataset states
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  // Filter states
  const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedContracts,
    setCurrentPage
  ] = useAPIPagination(contracts, PER_PAGE, total, queryPage);

  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Search]: debouncedSearchTerm,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchContracts(queryString)
      .then(response => {
        const [list, i, j, t] = response;

        setIsFetching(false);
        setContracts(list);
        setFrom(i);
        setTo(j);
        setTotal(t);
      })
      .catch(() => {
        setIsFetching(false);
        setContracts([]);
        setFrom(0);
        setTo(0);
        setTotal(0);
      })
  }, [currentPage, startDate, endDate, debouncedSearchTerm])

  const onChangeText = text => {
    setIsFetching(true);
    setCurrentPage(0);
    setSearchFilter(text);
  }

  const renderFilters = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={4}>
          <Button
            variant="primary"
            onClick={() => {
              const { pathname, search } = props.location;
              const prevPath = `${pathname}${search}`;

              props.history.push(ROUTES.SuperuserCreateContractDetails, { prevPath });
            }}
          >
            Create New Contract
          </Button>
        </Col>
        <Col xs={12} lg={5} className="controls">
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Contract Start"
            onDatesSelection={dates => {
              setStartDate(dates[0])
              setEndDate(dates[dates.length - 1])
            }}
            onStartDateSelection={date => setStartDate(date)}
            onEndDateSelection={date => setEndDate(date)}
            onResetDatesSelection={() => {
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (pagedContracts && pagedContracts.length == 0) {
      return <NoResults subject="contracts" />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <ContractsList
          currentPage={currentPage}
          isFetching={isFetching}
          numPages={numPages}
          pagedContracts={pagedContracts}
          setCurrentPage={setCurrentPage}
          setIsFetching={setIsFetching}
          onItemSelection={item => {
            const { id } = item;
            const { pathname, search } = props.location;
            const url = ROUTES.SuperuserUpdateContractDetails.replace(':id', id);
            const prevPath = `${pathname}${search}`;

            props.history.push(url, { prevPath });
          }}
        />
      </>
    )
  }

  return (
    <>
      <AppNav />
      <Container className={`superuser padding-bottom ${className}`}>
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Contracts">
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Company Name"
            onChangeText={onChangeText} />
        </Header>

        {renderFilters()}

        {(isFetching && contracts.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> :
          renderList()
        }
      </Container>
    </>
  )
}
