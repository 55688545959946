import { getType } from 'typesafe-actions';

import { ClassItem, Participant, Dietitian } from '../../utils/types';

import {
  clearForm,
  createClass,
  fetchClassDetails,
  searchParticipants,
  updateClass,

  ReducerActionType,
  searchDietitian,
  deleteClass,
} from '../actions/SuperuserClassDetails';

export type ReducerStateType = Readonly<{
  classDetails?: ClassItem;
  isLoading?: boolean;
  isProcessing?: boolean;
  isSearching: boolean;
  isSuccess?: boolean;
  message?: string;
  dietitians?: Dietitian[];
  participants?: Participant[];
}>

const INITIAL_STATE: ReducerStateType = {
  isLoading: false,
  isProcessing: false,
  isSearching: false,
  message: '',
  dietitians: [],
  participants: [],
}

const reducers = (state: ReducerStateType = INITIAL_STATE, action: ReducerActionType) => {
  switch (action.type) {
    case getType(fetchClassDetails.request):
      return {
        ...state,
        isLoading: true,
      }

    case getType(fetchClassDetails.success):
      return {
        ...state,
        classDetails: action.payload,
        isLoading: false,
      }

    case getType(fetchClassDetails.failure):
      return {
        ...state,
        message: action.payload,
        isLoading: false,
      }

    case getType(createClass.request):
      return {
        ...state,
        isProcessing: true,
      }

    case getType(createClass.success):
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        message: action.payload,
      }

    case getType(createClass.failure):
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        message: action.payload,
      }

    case getType(deleteClass.request):
      return {
        ...state,
        isProcessing: true,
      }

    case getType(deleteClass.success):
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        message: action.payload,
      }

    case getType(deleteClass.failure):
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        message: action.payload,
      }

    case getType(clearForm):
      return {
        ...INITIAL_STATE,
        classDetails: action.payload.retain ? state.classDetails : undefined,
      };

    case getType(searchDietitian.request):
      return {
        ...state,
        isSearching: true,
      }

    case getType(searchDietitian.success):
      return {
        ...state,
        isSearching: false,
        dietitians: action.payload,
      }

    case getType(searchDietitian.failure):
      return {
        ...state,
        isSearching: false,
        dietitians: [],
      }

    case getType(searchParticipants.request):
      return {
        ...state,
        isSearching: true,
      }

    case getType(searchParticipants.success):
      return {
        ...state,
        isSearching: false,
        participants: action.payload,
      }

    case getType(searchParticipants.failure):
      return {
        ...state,
        isSearching: false,
        participants: [],
      }

    case getType(updateClass.request):
      return {
        ...state,
        isProcessing: true,
      }

    case getType(updateClass.success):
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        message: action.payload,
      }

    case getType(updateClass.failure):
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        message: action.payload,
      }

    default:
      return state;
  }
}

export default reducers;
