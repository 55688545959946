import React from 'react';
import entries from 'lodash-es/entries';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faCalendar,
  faCalendarMinus,
  faClipboard,
  faWeight,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import {
  weekMap,
  FORMAT_MM_DD_YYYY,
  VALIDATION_NO_DIETITIAN
} from '../../../../utils/constants';
import { formatPercent } from '../../../../utils/formatting';
import { WeightLossAnalyticsData } from '../../../../utils/types';

import './index.scss';

interface Props {
  participant: WeightLossAnalyticsData;
}

export default function ParticipantCard(props: Props) {
  const { participant: patient } = props;

  const weeks: number[] = [];

  for (let i = 4; i <= 52; i += 4) {
    weeks.push(i);
  }

  return (
    <Card className="weightloss-card">
      <Card.Body>
        <ul className="weightloss-card__info">
          <li>
            <FontAwesomeIcon icon={faUser} />

            {patient.name}
          </li>
          <li>
            <FontAwesomeIcon icon={faCalendar} />

            {moment(patient.signupDate).format(FORMAT_MM_DD_YYYY)}
          </li>
          <li>
            <FontAwesomeIcon icon={faClipboard} />

            {patient.dietitian || <span>{VALIDATION_NO_DIETITIAN}</span>}
          </li>
          <li>
            <FontAwesomeIcon icon={faWeight} />
            <strong>Start</strong>&nbsp;

            {patient.startingWeight ? `${patient.startingWeight}lbs` : <span>No data</span>}
          </li>
          <li className="primary">
            <strong>Current</strong>&nbsp;

            {patient.currentWeight ? `${patient.currentWeight}lbs` : <span>No data</span>}
          </li>
        </ul>
        <Table className="weightloss-card__table">
          <thead>
            <tr>
              {weeks.map(w => (
                <th scope="col" key={w}>
                  {w === 4 ? <FontAwesomeIcon icon={faCalendarMinus} /> : null}
                  {w}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {entries(weekMap).map((entry) => {
                const [num, key] = entry;
                const hasValue = patient[key] !== null;

                return (
                  // NOTE: Since actual percentage values are returned
                  <td key={num} className={hasValue ? "" : "empty"}>
                    {hasValue ? formatPercent(patient[key], true) : '-'}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
