import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import placeholder from '../../assets/images/placeholder.jpg';

import { Participant } from '../../utils/types';

import './index.scss';

interface Props {
  disabled?: boolean;
  sm?: boolean;
  participant: Participant;

  onClick?: (participant: Participant) => void;
}

class ParticipantRow extends React.Component<Props> {
  render() {
    const { disabled, onClick, participant, sm } = this.props;
    const { avatar, fullName: name } = participant;

    return (
      <div className={`ParticipantRow-container${sm ? 'Sm' : ''}`}>
        <img
          className={`ParticipantRow-image${sm ? 'Sm' : ''}`}
          src={avatar || placeholder} />
        <label className={`ParticipantRow-title${sm ? 'Sm' : ''}`}>
          {name}
        </label>
        {(onClick && !disabled) && (
          <button
            className="ParticipantRow-deleteButton"
            onClick={(e) => {
              e.preventDefault();
              onClick(participant)
            }}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
      </div>
    );
  }
}

export default ParticipantRow;
