import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Table from 'react-bootstrap/Table';
import {
  faUser,
  faEnvelope,
  faCalendar,
  faNotesMedical
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Pagination from '../../../../components/Pagination';

import { DoctorParticipant } from '../../../../types/doctors';

import { scrollToNode } from '../../../../utils';
import { FORMAT_MM_DD_YYYY } from '../../../../utils/constants';

interface Props {
  currentPage: number;
  isFetching?: boolean;
  numPages: number;
  pagedUsers: DoctorParticipant[];
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
}

export default function List(props: Props) {
  const displayRiskAssessment = (risk: string) => (
    risk[0].toUpperCase() + risk.slice(1).toLowerCase()
  )

  const renderList = () => (
    <Table className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th><FontAwesomeIcon icon={faUser} />First Name</th>
          <th><FontAwesomeIcon icon={faUser} />Last Name</th>
          <th><FontAwesomeIcon icon={faEnvelope} />Email</th>
          <th><FontAwesomeIcon icon={faCalendar} />Date</th>
          <th><FontAwesomeIcon icon={faNotesMedical} />Risk</th>
        </tr>
      </thead>
      <tbody>
        {props.pagedUsers.map((item: DoctorParticipant, index) =>
          <tr id={`DoctorParticipants-row${index}`} key={index}>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.email}</td>
            <td>{moment(item.date).format(FORMAT_MM_DD_YYYY)}</td>
            <td>
              <label className={`risk ${item.riskAssessment}`}>
                {displayRiskAssessment(item.riskAssessment)}
              </label>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('DoctorParticipants-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
