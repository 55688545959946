import * as React from 'react';
import moment from 'moment-timezone';
import { isEqual as isEqualDate } from 'date-fns';
import { CalendarTileProperties } from 'react-calendar';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { isHoliday, timezoneAbbreviation } from '../../utils';
import {
  FORMAT_DDDD,
  FORMAT_MM_DD,
  FORMAT_H_MMA,
} from '../../utils/constants';
import { Session, Participant } from '../../utils/types';

import DateDropdown from '../DateDropdown';
import ParticipantsModal from '../ParticipantsModal';

import './index.scss';

interface Props {
  disabled: boolean;
  index: number;
  sessionDates: Date[];
  sessionDetails: Session;

  onSessionDateChanged: (session: Session) => void;
}

interface State {
  toShowAttendeesModal: boolean;
  toShowAbsentModal: boolean;
  toShowDateModal: boolean;
  updatedSession: Session;
}

class ClassSessionRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      toShowAttendeesModal: false,
      toShowAbsentModal: false,
      toShowDateModal: false,
      updatedSession: props.sessionDetails
    }
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.sessionDetails.date.getTime() != prevState.updatedSession.date.getTime()) {
      return {
        ...prevState,
        isInitial: false,
        updatedSession: nextProps.sessionDetails,
      }
    }

    return null;
  }

  getStyles = (details: Session) => ({
    border: { borderBottom: '1px solid #CCCCCC' },
    label: { color: '#34495E' },
    row: { height: '100%' },
  })

  handleDateChange = (date: Date) => {
    const updatedSession = this.state.updatedSession;

    updatedSession.date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      updatedSession.date.getHours(),
      updatedSession.date.getMinutes());

    this.setState({ updatedSession });
    this.props.onSessionDateChanged(updatedSession);
  }

  joinParticipantNames = (participants?: Participant[]) => {
    if (participants) {
    // if (true) {
      const names = participants.map((item: Participant) => item.fullName);
      // const names = ['Some User', 'User Two', 'User Three', 'User Four', 'User Five'];

      return `(${names.length}) ${names.join(', ')}`;
      // return `(${names.length}) ${names.join(', ')}`;
    }

    return ' ';
  }

  toggleAbsentModal = () => (
    this.setState({ toShowAbsentModal: !this.state.toShowAbsentModal })
  );

  toggleAttendeesModal = () => (
    this.setState({ toShowAttendeesModal: !this.state.toShowAttendeesModal })
  );

  toggleCalendarModal = () => (
    this.setState({ toShowDateModal: !this.state.toShowDateModal })
  );

  renderClassDateButton = (session: Session) => (
    this.props.disabled ? moment(session.date).format(FORMAT_MM_DD) :
    <DateDropdown
      top
      availableDates={session.availableRange}
      currentDate={session.date}
      dateFormat={FORMAT_MM_DD}
      disabled={this.props.disabled}
      hasButton={!this.props.disabled}
      icon={faEdit}
      style={{
        borderWidth: 0,
        backgroundColor: 'transparent',
        color: '#34495E',
        fontSize: 14,
        height: 19,
        marginTop: 0,
        marginLeft: -10,
      }}
      onDateSelection={(date: Date | Date[]) => this.handleDateChange(date as Date)}
      tileDisabled={(props: CalendarTileProperties & { activeStartDate: Date }) => {
        const { sessionDates } = this.props;
        let isDisabled =  sessionDates
          .filter(d => isEqualDate(d, props.date) || isHoliday(moment(props.date)))
          .length > 0;
        return isDisabled;
      }}
    />
  )

  renderParticipantsData = (participants: string, onClick: () => void) => (
    <td className="ClassSessionRow-participantsButton" onClick={onClick}>
      {participants.length > 0 && participants}
    </td>
  )

  render() {
    const { index } = this.props;
    const {
      toShowAbsentModal,
      toShowAttendeesModal,
      updatedSession: details,
    } = this.state;
    const name = details.name;
    const date = moment(details.date).format(FORMAT_MM_DD);

    return (
      <tr>
        {(toShowAttendeesModal && details.attendees) && (
          <ParticipantsModal
            onClose={() => this.toggleAttendeesModal()}
            participants={details!.attendees}
            title={`Attendees for ${date} - ${name}`}
            toShowModal={toShowAttendeesModal} />
        )}

        {(toShowAbsentModal && details.absent) && (
          <ParticipantsModal
            onClose={() => this.toggleAbsentModal()}
            participants={details!.absent}
            title={`Absent for ${date} - ${name}`}
            toShowModal={toShowAbsentModal} />
        )}

        <th>{index + 1}</th>
        <th>{name}</th>
        <td>{this.renderClassDateButton(details)}</td>
        <td style={{ width: 120 }}>{moment(details.date).format(FORMAT_DDDD)}</td>
        <td style={{ width: 120 }}>
          <label style={{ marginBottom: 0 }}>
            {moment(details.date).format(FORMAT_H_MMA)}&nbsp;

            <label style={{ fontWeight: 'bold', marginBottom: 0 }}>
              {timezoneAbbreviation(details.date)}
            </label>
          </label>
        </td>

        {this.renderParticipantsData(
          this.joinParticipantNames(details.attendees),
          () => this.toggleAttendeesModal())}
        {this.renderParticipantsData(
          this.joinParticipantNames(details.absent),
          () => this.toggleAbsentModal())}
      </tr>
    )
  }
}

export default ClassSessionRow;
