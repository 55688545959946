import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

import APIClient, { autoClose } from '.';

import { SignupAnalyticsData } from '../types/signup-analytics';

import { practiceConstants } from '../utils';
import { FORMAT_YYYY_MM_DD, RISK } from '../utils/constants';

export default class SignupAnalyticsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchSignupAnalytics = (
    company: string,
    groupBy?: string,
    date?: Date,
  ): Promise<SignupAnalyticsData> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/signup-tracking/stats?company=${company}`;

        if (groupBy) {
          url = `${url}&group_by=${groupBy}`;
        }

        if (date) {
          url = `${url}&date=${moment(date).format(FORMAT_YYYY_MM_DD)}`;
        }

        const response = await this.instance.get(url);
        const data = response.data;
        const { groups, high_risk: high, low_risk: low, finished_landing: landing } = data;
        const signupAnalytics: SignupAnalyticsData = {
          total: data.total,
        };

        if (groups) {
          signupAnalytics.groups = groups.map(item => ({
            total: item.total,
            landing: item.finished_landing,
            quiz: item.finished_quiz,
            eligible: item.finished_eligibility,
            scheduled: item.finished_scheduler,
            signedUp: item.finished_signup,
            fromDate: moment(item.from_date).utc(),
            toDate: moment(item.to_date).utc(),
          }));
        } else if (high && low && landing) {
          signupAnalytics.allTimeGroups = [{
            title: RISK.High,
            total: high.count,
            quiz: high.finished_quiz,
            eligible: high.finished_eligibility,
            scheduled: high.finished_scheduler,
            signedUp: high.finished_signup,
          }, {
            title: RISK.Low,
            total: low.count,
            quiz: low.finished_quiz,
            eligible: low.finished_eligibility,
            scheduled: low.finished_scheduler,
            signedUp: low.finished_signup,
          }];
        }

        resolve(signupAnalytics);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
