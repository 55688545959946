import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Observable, Subscription } from 'rxjs';
import Container from 'react-bootstrap/Container';
import { formatPercent } from '../../../utils/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek, faUsers } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import {
  FetchWeightLossAnalyticsRequest,
  FetchWeightLossTrackingRequest,
  WeightLossAnalyticsPayload,
  WeightLossTrackingPayload,
} from '../../../api/weight-loss';

import AppNav from '../../../components/AppNav';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import NoResults from '../../../components/NoResults';
import SearchInput from '../../../components/SearchInput';
import Spinner from '../../../components/Spinner';
import TotalResults from '../../../components/TotalResults';
import WeightLossAnalyticsList from '../../../components/WeightLossTrackingList';

import { useAPIPagination } from '../../../hooks/pagination';
import useDebounce from '../../../hooks/useDebounce';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import { practiceConstants, queryStringParams } from '../../../utils';
import { PER_PAGE, ROUTES, FORMAT_YYYY_MM_DD } from '../../../utils/constants';
import { WeightLossAnalyticsData, WeightLossAnalyticsSummary } from '../../../utils/types';

import './index.scss';

interface Props extends RouteComponentProps { }

export default function WeightLossAnalytics(props: Props) {
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className, defaultEmployer, employers } = practiceConstants();

  // Filter states
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value == query[QueryKeys.Company]
  ))[0] || defaultEmployer);
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );
  const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');

  // Dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingAnalytics, setIsFetchingAnalytics] = useState(true);
  const [analytics, setAnalytics] = useState<WeightLossAnalyticsSummary>();
  const [users, setUsers] = useState<WeightLossAnalyticsData[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  let fetchAnalyticsSubscription: Subscription;
  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.Company]: employer.value,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Search]: debouncedSearchTerm,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    if (fetchAnalyticsSubscription) {
      fetchAnalyticsSubscription.unsubscribe();
    }
  },[])

  useEffect(() => {
    if (fetchAnalyticsSubscription) {
      fetchAnalyticsSubscription.unsubscribe();
    }

    setIsFetchingAnalytics(true);
    props.history.replace(queryString);

    const request = new Observable((observer) => {
      const payload: WeightLossAnalyticsPayload = {
        company: employer.value,
      };

      if (startDate) {
        payload.from = startDate;
      }

      if (endDate) {
        payload.to = endDate;
      }

      if (debouncedSearchTerm) {
        payload.search = debouncedSearchTerm;
      }

      return new FetchWeightLossAnalyticsRequest(payload, observer);
    });

    fetchAnalyticsSubscription = request.subscribe((response: any) => {
      setIsFetchingAnalytics(false);
      setAnalytics(response);
    }, () => {
      setIsFetchingAnalytics(false);
      setAnalytics(undefined);
    });
  }, [employer, startDate, endDate, debouncedSearchTerm]);

  let fetchSubscription: Subscription;

  useEffect(() => {
    if (fetchSubscription) {
      fetchSubscription.unsubscribe();
    }

    setIsFetching(true);
    props.history.replace(queryString);

    const request = new Observable((observer) => {
      const payload: WeightLossTrackingPayload = {
        company: employer.value,
        page: currentPage + 1,
      };

      if (startDate) {
        payload.from = startDate;
      }

      if (endDate) {
        payload.to = endDate;
      }

      if (debouncedSearchTerm) {
        payload.search = debouncedSearchTerm;
      }

      return new FetchWeightLossTrackingRequest(payload, observer);
    });

    fetchSubscription = request.subscribe((response: any) => {
      const { items, total, to, from } = response;

      setIsFetching(false);
      setUsers(items);
      setTotal(total);
      setFrom(from);
      setTo(to);
    }, () => {
      setIsFetching(false);
      setUsers([]);
      setTotal(0);
      setFrom(0);
      setTo(0);
    });
  }, [employer, startDate, endDate, debouncedSearchTerm, currentPage])

  const onChangeText = text => {
    setIsFetching(true);
    setCurrentPage(0);
    setSearchFilter(text);
  }

  const renderFilters = () => (
    <div className={`filters-section border-bottom ${className}`}>
      <Row>
        <Col xs={12} lg={2}>
          <h3 className="primary-title">Filters</h3>
        </Col>
        <Col xs={12} lg={10} className="controls">
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={item => {
              setCurrentPage(0);
              setEmployer(item);
            }} />
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Landing Date"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0])
              setEndDate(dates[dates.length - 1])
            }}
            onStartDateSelection={date => setStartDate(date)}
            onEndDateSelection={date => setEndDate(date)}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderInfo = () => (
    <div className={className}>
      <div className="weightloss-analytics">
        {analytics ?
          <>
            <header
              className="weightloss-analytics__info"
              style={{ opacity: isFetchingAnalytics ? 0.5 : 1 }}>
              <Row>
                <Col className="weightloss-analytics__employer-wrap" md={9}>
                  <h2 className="weightloss-analytics__employer">{analytics.company}</h2>
                </Col>
                <Col className="weightloss-analytics__participants" md={3}>
                  <FontAwesomeIcon icon={faUsers} /> Participants
                  <div className="weightloss-analytics__num-participants">
                    {analytics.numParticipants}
                  </div>
                </Col>
              </Row>
            </header>
            <Table className="weightloss-analytics__stats">
              <thead>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={faCalendarWeek} />&nbsp;
                    4
                  </th>
                  <th>8</th>
                  <th>12</th>
                  <th>16</th>
                  <th>20</th>
                  <th>24</th>
                  <th>28</th>
                  <th>32</th>
                  <th>36</th>
                  <th>40</th>
                  <th>44</th>
                  <th>48</th>
                  <th>52</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: analytics.weeks[4] ? 'left' : 'center'}}>
                    {analytics.weeks[4] ? formatPercent(analytics.weeks[4], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[8] ? 'left' : 'center'}}>
                    {analytics.weeks[8] ? formatPercent(analytics.weeks[8], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[12] ? 'left' : 'center'}}>
                    {analytics.weeks[12] ? formatPercent(analytics.weeks[12], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[16] ? 'left' : 'center'}}>
                    {analytics.weeks[16] ? formatPercent(analytics.weeks[16], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[20] ? 'left' : 'center'}}>
                    {analytics.weeks[20] ? formatPercent(analytics.weeks[20], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[24] ? 'left' : 'center'}}>
                    {analytics.weeks[24] ? formatPercent(analytics.weeks[24], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[28] ? 'left' : 'center'}}>
                    {analytics.weeks[28] ? formatPercent(analytics.weeks[28], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[32] ? 'left' : 'center'}}>
                    {analytics.weeks[32] ? formatPercent(analytics.weeks[32], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[36] ? 'left' : 'center'}}>
                    {analytics.weeks[36] ? formatPercent(analytics.weeks[36], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[40] ? 'left' : 'center'}}>
                    {analytics.weeks[40] ? formatPercent(analytics.weeks[40], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[44] ? 'left' : 'center'}}>
                    {analytics.weeks[44] ? formatPercent(analytics.weeks[44], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[48] ? 'left' : 'center'}}>
                    {analytics.weeks[48] ? formatPercent(analytics.weeks[48], true) : '-'}
                  </td>
                  <td style={{ textAlign: analytics.weeks[52] ? 'left' : 'center'}}>
                    {analytics.weeks[52] ? formatPercent(analytics.weeks[52], true) : '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </> :
          <div className="center">
            {isFetchingAnalytics ? <Spinner /> : <label>No Data</label>}
          </div>
        }
      </div>
    </div>
  )

  const renderList = () => {
    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <WeightLossAnalyticsList
          currentPage={currentPage}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={users}
          setIsFetching={setIsFetching}
          setCurrentPage={setCurrentPage}
        />
      </>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Weight Loss Analytics">
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Participant"
            onChangeText={onChangeText} />
        </Header>

        {renderFilters()}
        {renderInfo()}

        {(isFetching && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> : renderList()
        }
      </Container>
    </>
  );
}
