import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faBuilding,
  faCalendar,
  faFileMedical,
  faUser,
  faCaretDown,
  faCaretUp,
  faIdCard,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Pagination from '../../../../../components/Pagination';

import { DietitianPaymentSessionData } from '../../../../../types/dietitian-payments';

import { timezoneAbbreviation, scrollToNode } from '../../../../../utils';
import { FORMAT_MM_DD_YYYY, FORMAT_H_MMA } from '../../../../../utils/constants';

import './index.scss';

interface Props {
  currentPage: number;
  isDescending: boolean;
  isFetching?: boolean;
  numPages: number;
  pagedSessions: any[];
  setIsDescending: (value: boolean) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setSessions: (value: React.SetStateAction<DietitianPaymentSessionData[]>) => void;
}

export default function List(props: Props) {
  const {
    isDescending,
    isFetching,
    pagedSessions,
    setIsDescending,
  } = props;
  const renderList = () => (
    <Table
      className="DietitianPaymentDetails-list"
      style={{ opacity: isFetching ? 0.5 : 1 }}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faUser} />
            Class Name
          </th>
          <th>
            <FontAwesomeIcon icon={faUser} />
            Session Name
          </th>
          <th className="button fit padding-right" onClick={() => setIsDescending(!isDescending)}>
            <FontAwesomeIcon icon={faCalendar} />Session Start&nbsp;
            <FontAwesomeIcon icon={isDescending ? faCaretDown : faCaretUp} />
          </th>
          <th>
            <FontAwesomeIcon icon={faCalendar} />
            Session End
          </th>
        </tr>
      </thead>
      <tbody>
        {pagedSessions.map((session: DietitianPaymentSessionData, index: number) =>
          <tr id={`DietitianPaymentSessions-row${index}`} key={index}>
            <td className="fit padding-right">{session.className}</td>
            <td className="fit padding-right">{session.sessionName}</td>
            <td className="fit padding-right">
              {moment(session.startDate).format(FORMAT_MM_DD_YYYY)} - {moment(session.startDate).format(FORMAT_H_MMA)} <strong>{timezoneAbbreviation(session.startDate)}</strong>
            </td>
            <td className="fit padding-right">
              {moment(session.endDate).format(FORMAT_MM_DD_YYYY)} - {moment(session.endDate).format(FORMAT_H_MMA)} <strong>{timezoneAbbreviation(session.endDate)}</strong>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('DietitianPaymentSessions-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
