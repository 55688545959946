import { toast } from 'react-toastify';

import { MilestoneData } from '../types/milestones';

import ApiRequest from './api-request';

export class FetchMilestonesRequest extends ApiRequest {
  async send(queryString: string): Promise<{
    items: MilestoneData[],
    total: number,
    to: number,
    from: number
  }> {
    const url = `/milestone-tracking${queryString}`;

    return this
      .get(this.backendApiUrl(url))
      .then((resp) => {
        const total = resp.data.meta.total;
        const to = resp.data.meta.to;
        const from = resp.data.meta.from;
        const items = resp.data.data.map((item: any): MilestoneData => ({
          id: item.participant.id,
          classId: '',
          participantId: item.participant.id,
          participantExternalId: item.participant.external_id,
          firstName: item.participant.first_name,
          lastName: item.participant.last_name,
          email: item.participant.email,
          signupDate: new Date(item.participant.signup_date),
          healthPlanId: item.participant.health_plan_id || '',
          insurance: item.participant.insurance || '',
          milestoneOne: item.milestone_one,
          milestoneOneDate: item.achieved_date_one && new Date(item.achieved_date_one),
          paymentOne: parseFloat(item.payment_one),
          deviceScale: item.device_scale,
          milestoneTwo: item.milestone_two,
          milestoneTwoDate: item.achieved_date_two && new Date(item.achieved_date_two),
          paymentTwo: parseFloat(item.payment_two),
          deviceFitbit: item.device_fitbit,
          milestoneThree: item.milestone_three,
          milestoneThreeDate: item.achieved_date_three && new Date(item.achieved_date_three),
          paymentThree: parseFloat(item.payment_three),
          milestoneFour: item.milestone_four,
          milestoneFourDate: item.achieved_date_four && new Date(item.achieved_date_four),
          paymentFour: parseFloat(item.payment_four),
          birthdate: new Date(),
          gender: '',
          address: '',
          apartmentNo: '',
          city: '',
          state: '',
          zipCode: '',
          dietitian: '',
          sponsorId: item.sponsor_id,
        }));

      return { items, total, to, from };
    })
    .catch(error => {
      toast.error(error.response.data.message || error.response.message);

      return { items: [], total: 0, to: 0, from: 0 }
    })
  }
}
