import { getType } from 'typesafe-actions';
import moment from 'moment';

import {
  CLASS_STATUS,
  CLASS_STATUS_FILTER,
  DAYS,
  DAYS_FILTER,
  FORMAT_DDDD,
} from '../../utils/constants';
import {
  ClassItem,
  ClassSort,
  DropdownItem,
} from '../../utils/types';

import {
  clearFilters,
  fetchClasses,
  filterClasses,
  storePage,
  sortClasses,
  ReducerActionType,
} from '../actions/SuperuserHome';

export type ReducerStateType = Readonly<{
  classes: ClassItem[];
  filteredClasses: ClassItem[];
  errorMessage: string;
  isLoading: boolean;
  filterDay: DropdownItem,
  filterStatus: DropdownItem,
  searchText: string;
  startDate?: Date;
  endDate?: Date;
  currentPage: number;
  sortKey: ClassSort;
  isDescending: boolean;
}>

const INITIAL_FILTERS_STATE = {
  searchText: '',
  filterDay: DAYS_FILTER[DAYS_FILTER.length - 1],
  filterStatus: CLASS_STATUS_FILTER[CLASS_STATUS_FILTER.length - 1],
  currentPage: 0,
}

const INITIAL_STATE: ReducerStateType = {
  classes: [],
  filteredClasses: [],
  errorMessage: '',
  isDescending: true,
  isLoading: true,
  sortKey: ClassSort.DateTime,
  ...INITIAL_FILTERS_STATE,
}

const reducers = (
  state: ReducerStateType = INITIAL_STATE,
  action: ReducerActionType
) => {
  switch (action.type) {
    case getType(fetchClasses.request):
      return {
        ...state,
        isLoading: true,
      }

    case getType(fetchClasses.success):
      return {
        ...state,
        classes: action.payload,
        filteredClasses: filteredClasses(
          action.payload,
          {
            filterDay: state.filterDay,
            filterStatus: state.filterStatus,
            searchText: state.searchText,
            startDate: state.startDate,
            endDate: state.endDate,
          },
          state.sortKey,
          state.isDescending
        ),
        isLoading: false,
      }

    case getType(fetchClasses.failure):
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      }

    case getType(filterClasses):
      return {
        ...state,
        ...action.payload,
        filteredClasses: filteredClasses(
          state.classes,
          action.payload,
          state.sortKey,
          state.isDescending
        ),
      }

    case getType(storePage):
      return {
        ...state,
        currentPage: action.payload,
      }

    case getType(clearFilters):
      return {
        ...state,
        ...INITIAL_FILTERS_STATE
      }

    case getType(sortClasses): {
      const { key: sortKey, isDescending } = action.payload;

      return {
        ...state,
        sortKey,
        isDescending,
        filteredClasses: sortClassesByKey(
          state.filteredClasses,
          sortKey,
          isDescending,
        ),
      }
    }

    default:
      return state;
  }
}

const filteredClasses = (
  classes: ClassItem[],
  payload,
  sortKey: ClassSort,
  isDescending: boolean
) => {
  const {
    filterDay: fd,
    filterStatus: fs,
    searchText,
    startDate,
    endDate,
  } = payload;

  const f = classes.filter((item: ClassItem) => {
    const filterDay = fd.value;
    const filterStatus = fs.value;
    const day = moment(item.date).format(FORMAT_DDDD);
    const isWeekends = day == DAYS.Saturday || day == DAYS.Sunday;
    let condition = false;

    // Remove classes with invalid dates
    if (isNaN(item.date.getTime()) || isNaN(item.endDate.getTime())) {
      return condition;
    }

    // Filter by date
    if (filterDay == DAYS.WeekendsOnly) {
      condition = day == DAYS.Saturday || day == DAYS.Sunday;
    } else if (filterDay == DAYS.WeekdaysOnly) {
      condition = !isWeekends
    } else if (filterDay == DAYS.All) {
      condition = true;
    } else {
      condition = filterDay == day;
    }

    // Filter by status
    if (filterStatus != CLASS_STATUS.All) {
      condition = condition && filterStatus == item.status.value;
    }

    // Filter by search keyword
    if (searchText.length > 0) {
      const s = searchText.toLowerCase();
      const dietitians = item.dietitians || [];
      const searchCondition = (
        item.name.toLowerCase().indexOf(s) > -1 ||
        item.participants.filter(user => (
          user.fullName.toLowerCase().includes(s) ||
          user.email.toLowerCase().includes(s)
        )).length > 0 ||
        dietitians!.filter(dietitian => (
          dietitian.fullName.toLowerCase().includes(s) ||
          dietitian.email.toLowerCase().includes(s)
        )).length > 0
      );

      condition = condition && searchCondition;
    }

    // Filter by date range
    if (startDate && endDate) {
      const eDate = moment(endDate).endOf('day').toDate();
      condition = condition && moment(item.date).isBetween(startDate, eDate, 'day', '[]');
    } else if (startDate) {
      condition = condition && moment(item.date).isSameOrAfter(startDate);
    } else if (endDate) {
      const eDate = moment(endDate).endOf('day').toDate();
      condition = condition && moment(item.date).isSameOrBefore(eDate);
    }

    return condition;
  });

  return sortClassesByKey(f, sortKey, isDescending);
}

const sortClassesByKey = (
  classes: ClassItem[],
  key: ClassSort,
  isDescending: boolean
) => {
  switch (key) {
    case ClassSort.DateTime:
      return classes.sort((a, b) => {
        if (isDescending) {
          return moment(b.date).isSameOrBefore(a.date) ? -1 : 1;
        }

        return moment(b.date).isSameOrBefore(a.date) ? 1 : -1;
      });

    case ClassSort.Day:
      return sortClassesByKey(classes, ClassSort.DateTime, isDescending)
        .sort((a, b) => {
          if (isDescending) {
            return moment(b.date).weekday() - moment(a.date).weekday();
          }

          return moment(a.date).weekday() - moment(b.date).weekday();
        });

    case ClassSort.MaxSize:
      return classes.sort((a, b) => {
        if (isDescending) {
          return b.maxParticipants - a.maxParticipants;
        }

        return a.maxParticipants - b.maxParticipants;
      });

    case ClassSort.Size:
      return sortClassesByKey(classes, ClassSort.MaxSize, isDescending)
        .sort((a, b) => {
          if (isDescending) {
            return b.participants.length - a.participants.length;
          }

          return a.participants.length - b.participants.length;
        });

    case ClassSort.Dietitian:
      return classes.sort((a, b) => {
        if (a.dietitian && b.dietitian) {
          if (isDescending) {
            return b.dietitian.fullName < a.dietitian.fullName ? -1 : 1;
          }

          return b.dietitian.fullName < a.dietitian.fullName ? 1 : -1;
        } else if (a.dietitian && !b.dietitian) {
          return isDescending ? 1 : -1;
        } else if (b.dietitian && !a.dietitian) {
          return isDescending ? -1 : 1;
        } else {
          return -1;
        }
      });

    default:
      return classes;
  }
}

export default reducers;
