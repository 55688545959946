import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faUser,
  faCalendar,
  faFileInvoiceDollar,
  faCashRegister,
  faDollarSign,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Pagination from '../../../../components/Pagination';

import { ConsumerPaymentUser } from '../../../../types/consumer-payments';

import { scrollToNode } from '../../../../utils';
import { FORMAT_MM_DD_YYYY } from '../../../../utils/constants';
import { formatCurrency } from '../../../../utils/formatting';

interface Props {
  currentPage: number;
  isFetching?: boolean;
  numPages: number;
  pagedUsers: any[];
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  onItemSelection: (item: ConsumerPaymentUser) => void;
}

export default function List(props: Props) {
  const renderList = () => (
    <Table className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faUser} />
            Participant
          </th>
          <th>
            <FontAwesomeIcon icon={faCalendar} />
            Landing Date
          </th>
          <th>
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
            Payment Option
          </th>
          <th>
            <FontAwesomeIcon icon={faCashRegister} />
            Payment Method
          </th>
          <th>
            <FontAwesomeIcon icon={faDollarSign} />
            Amount Paid to Date
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.pagedUsers.map((user: ConsumerPaymentUser, index: number) =>
          <tr
            id={`ConsumerPayment-row${index}`}
            className="clickable"
            key={`${user.id}-${index}`}
            onClick={() => {
              if (props.isFetching) {
                return;
              }

              props.onItemSelection(user);
            }}>
            <td>{`${user.firstName} ${user.lastName}`}</td>
            <td>{moment(user.signupDate).format(FORMAT_MM_DD_YYYY)}</td>
            <td>{user.paymentOption}</td>
            <td>{user.paymentMethod}</td>
            <td>{formatCurrency(user.balance)}</td>
            <td className="actions">
              <div>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('ConsumerPayment-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
