import { Participant } from './types';

export const TEST_ALL_PARTICIPANTS: Participant[] = [
  {
    id: 5,
    email: "francisrogers@pearlesex.com",
    username: "francisrogers@pearlesex.com",
    vseeid: "4192f057-0780-4658-9983-d3544b4bcc35",
    fullName: "Rena Holloway",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 4,
    email: "renaholloway@pearlesex.com",
    username: "renaholloway@pearlesex.com",
    vseeid: "f9cdaa78-789c-45e0-b454-9e5583ce51e7",
    fullName: "Roslyn Figueroa",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 10,
    email: "roslynfigueroa@pearlesex.com",
    username: "roslynfigueroa@pearlesex.com",
    vseeid: "7297c9ea-7e38-4caa-bb83-81fa6c173191",
    fullName: "Rebecca Knight",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 8,
    email: "rebeccaknight@pearlesex.com",
    username: "rebeccaknight@pearlesex.com",
    vseeid: "8245bfff-aef5-4830-be2d-bf4af4928df3",
    fullName: "Snyder Joseph",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 10,
    email: "snyderjoseph@pearlesex.com",
    username: "snyderjoseph@pearlesex.com",
    vseeid: "fb794ab9-1f20-470d-aa6d-4b3068d174b4",
    fullName: "Larsen Garcia",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 1,
    email: "larsengarcia@pearlesex.com",
    username: "larsengarcia@pearlesex.com",
    vseeid: "5df5a53e-a785-4dcf-834a-6323d4df5496",
    fullName: "Roxanne Bowen",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 4,
    email: "roxannebowen@pearlesex.com",
    username: "roxannebowen@pearlesex.com",
    vseeid: "a41b22c9-3c55-48c7-b84b-30e7f746c975",
    fullName: "Trevino Middleton",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 10,
    email: "trevinomiddleton@pearlesex.com",
    username: "trevinomiddleton@pearlesex.com",
    vseeid: "34d5d078-e68b-4f46-b1a9-3966bf5032a5",
    fullName: "Cathryn Farmer",
    avatar: "http://placehold.it/32x32"
  },
  {
    id: 4,
    email: "cathrynfarmer@pearlesex.com",
    username: "cathrynfarmer@pearlesex.com",
    vseeid: "8b42e8ad-0eea-4322-a55f-bca8d7509033",
    fullName: "Solis Allison",
    avatar: "http://placehold.it/32x32"
  }
]
