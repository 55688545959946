import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faCalendar,
  faUsers,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Pagination from '../../../components/Pagination';

import { Contract } from '../../../types/contracts';

import { scrollToNode } from '../../../utils';
import { FORMAT_MM_DD_YYYY } from '../../../utils/constants';
import { formatNumber } from '../../../utils/formatting';

interface Props {
  currentPage: number;
  isFetching?: boolean;
  numPages: number;
  pagedContracts: Contract[];
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  onItemSelection: (item: Contract) => void;
}

export default function List(props: Props) {
  const renderList = () => (
    <Table className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faUsers} />
            Company Name
          </th>
          <th className="fixed-200">
            <FontAwesomeIcon icon={faCalendar} />
            Contract Started
          </th>
          <th className="fixed-200">
            <FontAwesomeIcon icon={faUsers} />
            Total Participants
          </th>
          <th className="fixed-200">
            <FontAwesomeIcon icon={faUsers} />
            Active Classes
          </th>
          <th className="fixed-200">
            <FontAwesomeIcon icon={faUsers} />
            Finished Classes
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {props.pagedContracts.map((item: Contract, index: number) => (
          <tr
            id={`Contracts-row${index}`}
            className="clickable"
            key={item.id}
            onClick={() => {
              if (props.isFetching) {
                return;
              }

              props.onItemSelection(item);
            }}>
            <td><strong>{item.name}</strong></td>
            <td>{moment(item.date).format(FORMAT_MM_DD_YYYY)}</td>
            <td>{formatNumber(item.totalParticipants)}</td>
            <td>{formatNumber(item.activeClasses)}</td>
            <td>{formatNumber(item.finishedClasses)}</td>
            <td className="actions">
              <div><FontAwesomeIcon icon={faAngleRight} /></div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('Contracts-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
