import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { Grid, Col, Row } from 'react-flexbox-grid';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import LinkedInTag from 'react-linkedin-insight';
import { Dispatch } from 'redux';
import _ from 'lodash';
import classnames from 'classnames';

import APIClient from '../../api/superuser';

import AppNav from '../../components/AppNav';
import BaseModal from '../../components/BaseModal';
import CustomButton from '../../components/CustomButton';
import Dropdown from '../../components/Dropdown';

import { store } from '../../redux';
import { logout } from '../../redux/actions/Authentication';
import { checkEligibility, clearForm } from '../../redux/actions/ParticipantEligibility';
import { RootState } from '../../redux/reducers';
import { ReducerStateType } from '../../redux/reducers/ParticipantEligibility';

import {
  isEligibleForSignup,
  loaderComponent,
  quizModalComponent,
} from '../../utils';
import {
  ROUTES,
  SESSION_KEY_ELIGIBILITY,
  SESSION_KEY_STAGE_TRACKING,
  US_STATES_SELECTIONS,
  VALIDATION_CONFIRM_EMAIL,
  VALIDATION_FORMAT,
  VALIDATION_REQUIRED,
  BASE_URL_INGENUITY,
  ANALYTICS,
  SESSION_KEY_URL_ORIGIN,
  URL_ORIGIN_WALGREENS,
} from '../../utils/constants';
import { fbPixelTrackPageViewEvent } from '../../utils/tracking';
import { DropdownItem, EligibilityPayload } from '../../utils/types';

import './index.scss';

enum FormKeys {
  FirstName = 'first_name',
  LastName = 'last_name',
  MobilePhone = 'mobile_phone',
  EmailAddress = 'email_address',
  ConfirmEmailAddress = 'confirm_email_address',
  Birthdate = 'birthdate',
  StreetAddress = 'street_address',
  ApartmentUnitNo = 'apartment_unit_no',
  City = 'city',
  State = 'state',
  ZipCode = 'zip_code',
  MemberID = 'member_id',
}

type ColumnData = {
  key: string;
  label: string;
  placeholder: string;
  mask?: string;
  type: string;
  column?: number;
  disabled?: boolean;
}

type DispatchPropTypes = Readonly<{
  checkEligibility: (payload) => void;
  clearForm: () => void;
}>;

interface Props extends RouteComponentProps, ReducerStateType, DispatchPropTypes {}

interface State {
  formData: { [id: string]: string },
  formErrors: { [id: string]: string },
  isInitial: boolean;
  selectedState: DropdownItem,
  showModal: boolean;
}

class ParticipantEligibility extends React.Component<Props, State> {
  private labelNode: HTMLLabelElement | null = null;

  state: State = {
    formData: { state: US_STATES_SELECTIONS[0].value },
    formErrors: {},
    isInitial: true,
    selectedState: US_STATES_SELECTIONS[0],
    showModal: false,
  }

  constructor(props) {
    super(props);

    // Logout existing user
    store.dispatch(logout());
  }

  componentDidMount() {
    const [isValid, quizAnswers] = isEligibleForSignup(1);
    const origin = sessionStorage.getItem(SESSION_KEY_URL_ORIGIN);

    if (!isValid || origin != URL_ORIGIN_WALGREENS) {
      this.setState({ showModal: true });

      return;
    }

    if (_.isEmpty(quizAnswers)) {
      return;
    }

    fbPixelTrackPageViewEvent();

    // Initialize LinkedIn Insight
    LinkedInTag.init(ANALYTICS.Insight);

    const firstName = quizAnswers.name;
    const lastName = quizAnswers.lastName;
    const formData = {
      ...this.state.formData,
      [FormKeys.EmailAddress]: quizAnswers.email,
      [FormKeys.FirstName]: firstName,
      [FormKeys.LastName]: lastName,
    };

    this.setState({ formData });
  }

  componentDidUpdate() {
    let hasChanges = false;
    const { formErrors } = this.state;

    Object.keys(formErrors).map((key: string) => {
      const errors = this.props.response['errors'];

      if (errors) {
        const value = errors[key];

        if (value && value.length > 0 && value != formErrors[key]) {
          formErrors[key] = value;
          hasChanges = true;
        }
      }
    });

    if (hasChanges) {
      this.setState({ formErrors });
    } else {
      this.prepareNextStep();
    }
  }

  isInputInvalid = (key: string) => {
    const { formErrors, isInitial } = this.state;

    return !_.isEmpty(formErrors[key]) && !isInitial
  }

  prepareNextStep = async () => {
    const { isSuccess } = this.props;

    if (isSuccess && isSuccess == true) {
      const client = new APIClient(BASE_URL_INGENUITY);
      const data = sessionStorage.getItem(SESSION_KEY_STAGE_TRACKING) || '';

      await client.updateUserSignupTracking(data, { finished_eligibility: true });

      setTimeout(() => {
        this.props.clearForm();
        sessionStorage.setItem(SESSION_KEY_ELIGIBILITY, JSON.stringify(this.state.formData));
        this.props.history.push(ROUTES.ParticipantWizard);
      }, 2000);
    }
  }

  onAssignRef = (ref: HTMLLabelElement | null, key: string) => {
    const error = this.state.formErrors[key];

    if (key == FormKeys.FirstName) {
      this.labelNode = ref;
    }

    if (!error && (this.labelNode && this.labelNode == ref)) {
      this.labelNode = null;
    } else if (error && !this.labelNode) {
      this.labelNode = ref;
    }
  }

  onEligibilityResponse = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.props.clearForm();
    this.prepareNextStep();
  }

  onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formData } = this.state;
    const { name, value } = event.target;

    formData[name] = value;

    this.setState({ formData });
  }

  onStateSelection = (item: DropdownItem) => {
    const { formData } = this.state;

    formData['state'] = item.value;

    this.setState({
      formData,
      selectedState: item,
    })
  }

  onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const trackingId = sessionStorage.getItem('trackingId');
    const payload = Object.assign(this.state.formData, {
      participant_id: trackingId,
    });

    if (this.validateForm(payload)) {
      this.props.checkEligibility(payload);
    } else {
      setTimeout(() => {
        if (this.labelNode) {
          this.labelNode.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 250);
    }
  }

  validateForm = (payload) => {
    let isValid = true;
    const { formErrors } = this.state;
    const optionalFields = [
      FormKeys.ApartmentUnitNo.toString(),
      FormKeys.MemberID.toString(),
      FormKeys.State.toString(),
    ];

    Object.keys(FormKeys).map((k: string) => {
      const key = FormKeys[k];
      const value = payload[key];
      const isConfirmEmail = key == FormKeys.ConfirmEmailAddress;
      let error = !value || value && value.length == 0 ? VALIDATION_REQUIRED : '';
      let regex: RegExp | undefined;

      error = optionalFields.includes(key) ? '' : error;

      switch (key) {
        case FormKeys.EmailAddress:
        case FormKeys.ConfirmEmailAddress:
          const email = payload[FormKeys.EmailAddress];
          const confirmEmail = payload[FormKeys.ConfirmEmailAddress];

          if (isConfirmEmail && (confirmEmail && confirmEmail.length > 0)) {
            error =  email != confirmEmail ? VALIDATION_CONFIRM_EMAIL : error;
          }

          regex = /^\S+@\S+$/;

          break;

        case FormKeys.MobilePhone:
          regex = /^(\d{3})[-](\d{3})[-](\d{4})/;

          break;

        case FormKeys.ZipCode:
          regex = /^\d{5}$/;

          break;
        default:
          break;
      }

      if (regex) {
        error = value && value.length > 0 && !regex.test(value) ? VALIDATION_FORMAT : error;
      }

      if (error) {
        isValid = false;
      }

      formErrors[key] = error;
    });

    this.setState({ formErrors, isInitial: false });

    return isValid;
  }

  renderHeader = () => (
    <div>
      <h1 className="Eligibility-headerLabel">
        Check your Health Insurance Coverage
      </h1>

      <div className="Eligibility-header">
        <strong className="emphasis">Fruit Street’s Diabetes Prevention Program </strong>
        is covered by your health insurance.
        Please enter the following information including your Health Plan ID number
        located on the back of your insurance card so that we can make sure your
        health insurance plan is active before signing up.
        If you have a question about this, call Fruit Street customer service at&nbsp;
        <strong className="emphasis">
          <a href="tel:1 (347) 621-6662">1 (347) 621-6662</a>
        </strong>.
      </div>
    </div>
  )

  renderFields = (data: (ColumnData | React.ReactNode)[]) => (
    <Row className="Eligibility-row" between="xs">
      {data.map(((columnItem: (ColumnData | React.ReactNode), index: number) => {
        if (!(columnItem as ColumnData).key) {
          return (
            <React.Fragment key={index.toString()}>
              {columnItem}
            </React.Fragment>
          )
        }

        const item = columnItem as ColumnData;
        const value = this.state.formData[item.key] || '';
        const inputProps = {
          className: classnames([
            'Eligibility-input',
            this.isInputInvalid(item.key) ? 'is-invalid' : '',
          ]),
          onChange: this.onInputValueChange,
          mask: '',
          name: item.key,
          placeholder: item.placeholder,
          type: item.type,
          value,
          autoComplete: 'off',
        }
        const error = this.state.formErrors[item.key];

        if (item.mask) {
          inputProps['mask'] = item.mask;
          inputProps['maskChar'] = '';
        }

        if (item.disabled) {
          inputProps['disabled'] = item.disabled;
        }

        return (
          <Col
            key={index}
            className="Eligibility-column"
            xs={12} md={item.column || 12 / data.length}>
            <label
              htmlFor={item.key}
              className="Eligibility-label"
              ref={ref => this.onAssignRef(ref, item.key)}>
              {item.label}
            </label>
            <InputMask id={item.key} {...inputProps} />
            <div className="Eligibility-label is-invalid">
              {error}
            </div>
          </Col>
        )
      }))}
    </Row>
  )

  renderFirstRow = () => {
    const items: ColumnData[] = [
      {
        key: FormKeys.FirstName,
        label: 'First Name',
        placeholder: 'John',
        type: 'text'
      },
      {
        key: FormKeys.LastName,
        label: 'Last Name',
        placeholder: 'Smith',
        type: 'text'
      },
      {
        key: FormKeys.MobilePhone,
        label: 'Mobile Number',
        placeholder: '000-000-0000',
        type: 'text',
        mask: '999-999-9999'
      },
    ];

    return this.renderFields(items)
  }

  renderSecondRow = () => {
    const items: ColumnData[] = [
      {
        key: FormKeys.EmailAddress,
        label: 'Email Address',
        placeholder: 'johnsmith@gmail.com',
        type: 'email',
        disabled: true,
      },
      {
        key: FormKeys.ConfirmEmailAddress,
        label: 'Confirm Email',
        placeholder: 'johnsmith@gmail.com',
        type: 'email'
      },
      {
        key: FormKeys.Birthdate,
        label: 'Date of Birth',
        placeholder: 'yyyy-mm-dd',
        mask: '9999-99-99',
        type: 'text'
      },
    ];

    return this.renderFields(items);
  }

  renderThirdRow = () => {
    const items: ColumnData[] = [
      {
        key: FormKeys.StreetAddress,
        label: 'Street Address (No PO Boxes)',
        placeholder: 'Street Address',
        type: 'text',
        column: 8
      },
      {
        key: FormKeys.ApartmentUnitNo,
        label: 'Apartment Unit No.',
        placeholder: 'Unit No.',
        type: 'text',
        column: 4
      }
    ];

    return this.renderFields(items);
  }

  renderFourthRow = () => {
    const items: (ColumnData | React.ReactNode)[] = [
      {
        key: FormKeys.City,
        label: 'City',
        placeholder: 'City',
        type: 'text'
      },
      (
        <Col className="Eligibility-column" xs={12} md={4}>
          <label className="Eligibility-label">
            State
          </label>
          <Dropdown
            hasSearch
            items={US_STATES_SELECTIONS}
            selectedItem={this.state.selectedState}
            onItemSelection={this.onStateSelection} />
        </Col>
      ),
      {
        key: FormKeys.ZipCode,
        label: 'Zip Code',
        placeholder: '00000',
        type: 'text',
        mask: '99999',
      }
    ];

    return this.renderFields(items);
  }

  renderMemberID = () => (
    <div className="Eligibility-header">
      <Grid className="Eligibility-memberIdForm">
        <Row between="xs" top="xs">
          <Col
            className="Eligibility-column"
            xs={12}
            md={4}
            style={{ paddingBottom: 20 }}>
            <label htmlFor={FormKeys.MemberID} className="Eligibility-label">
              Health Plan Member ID
            </label>
          <input
              autoComplete="off"
              className={classnames([
                'Eligibility-input',
                this.isInputInvalid(FormKeys.MemberID) ? 'is-invalid' : '',
              ])}
              id={FormKeys.MemberID}
              name={FormKeys.MemberID}
              onChange={this.onInputValueChange}
              placeholder="000000000"
              type="text" />
            <div className="Eligibility-label is-invalid">
              {this.state.formErrors[FormKeys.MemberID]}
            </div>
          </Col>
          <Col
            className="Eligibility-column"
            xs={12}
            md={7}
            style={{ paddingBottom: 0 }}>
            <ul>
              <li>This ID Number appears on your <strong className="emphasis">Health Card</strong></li>
              <li>Your card must have listed it as <strong className="emphasis">Subscriber ID</strong></li>
              <li>This is NOT the <strong className="emphasis">Group Number</strong></li>
            </ul>
          </Col>
        </Row>
      </Grid>
    </div>
  )

  renderFooter = () => (
    <div className="Eligibility-header">
      By clicking CHECK COVERAGE, I acknowledge that I have received&nbsp;
      <a href="https://www.fruitstreet.com/wp-content/uploads/2019/01/Fruit-Street-Notice-of-Privacy-Practices.pdf" target="_blank">
        Fruit Street’s Notice of Privacy.
      </a>
      <br />
      <br />
      I understand that I am enrolling in a Diabetes Prevention Program offered as a covered benefit by my healthcare insurance plan.
      I understand that taking part means I will submit protected health information and that my privacy will be protected as required by applicable laws.
    </div>
  )

  render () {
    const { isProcessing, isSuccess, response } = this.props;
    const hasResponse = isSuccess != undefined && response['message'];
    const showResponseModal = hasResponse && _.isEmpty(response['errors']);
    const hasSuccessResponse = hasResponse && isSuccess! == true;
    const props = !hasSuccessResponse ? {
      confirmTitle: 'Ok',
      onConfirm: this.onEligibilityResponse
    } : {};

    return (
      <>
        <AppNav hideMenu />

        <Container className="Eligibility-container">
          {this.state.showModal && quizModalComponent(this.state.showModal)}
          {showResponseModal &&
            <BaseModal
              {...props}
              title="Checking Coverage"
              toDisplay={showResponseModal}>
              <label className="ModalLabel">
                {response['message']}
              </label>
            </BaseModal>
          }

          {isProcessing && loaderComponent()}
          {this.renderHeader()}

          <form>
            <div className="Eligibility-form">
              {this.renderFirstRow()}
              {this.renderSecondRow()}
              {this.renderThirdRow()}
              {this.renderFourthRow()}
              {this.renderMemberID()}
            </div>
          </form>

          {this.renderFooter()}

          <div className="Eligibility-submitFooter">
            <CustomButton
              primary
              className="Eligibility-submitButton"
              isLoading={isProcessing}
              title="Check Coverage"
              onClick={this.onSubmit} />
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => state.participantEligibility;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkEligibility: (payload: EligibilityPayload) => dispatch(checkEligibility.request(payload)),
  clearForm: () => dispatch(clearForm()),
} as DispatchPropTypes);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantEligibility);
