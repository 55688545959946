import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import _ from 'lodash';
import classnames from 'classnames';

import { RootState } from '../../redux/reducers';
import { searchParticipants } from '../../redux/actions/SuperuserClassDetails';
import { ReducerStateType } from '../../redux/reducers/SuperuserClassDetails';

import { debouncedOnChangeText, practiceConstants } from '../../utils';
import { Participant } from '../../utils/types';

import './index.scss';

type DispatchPropTypes = Readonly<{
  searchParticipants: (query: string, page: number) => void;
}>;

interface Props extends ReducerStateType, DispatchPropTypes {
  adding?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  addedParticipants: Participant[];

  onAddParticipant: (participant: Participant) => void;
}

interface State {
  displayDropdown: boolean;
  filterText: string;
  filteredParticipants: Participant[];
  isLastPage: boolean;
  page: number;
}

class AddParticipantInput extends React.Component<Props, State> {
  private node: HTMLDivElement | null = null;
  private textInputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      displayDropdown: false,
      filterText: '',
      filteredParticipants: props.participants!,
      isLastPage: false,
      page: 1,
    };

    document.addEventListener('mousedown', this.handleClick, false);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { participants }  = nextProps;

    // if ((nextProps.participants != prevState.filteredParticipants) && prevState.filterText.length == 0) {
    if (participants != prevState.filteredParticipants) {
      let filteredParticipants =  participants || [];

      if (prevState.page > 1) {
        filteredParticipants = [...prevState.filteredParticipants, ...filteredParticipants];
      }

      filteredParticipants = filteredParticipants.filter((item) => (
        !nextProps.addedParticipants.map(item => item.id).includes(item.id)
      ));

      return {
        ...prevState,
        filteredParticipants,
        isLastPage: !participants || (participants && participants.length == 0),
      }
    }

    return null;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node) {
      return;
    }

    if (!this.node.contains(e.target) && this.state.displayDropdown) {
      this.toggleDropdown();
    }
  }

  handlePagination = event => {
    let element = event.currentTarget;
    const isLast = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (isLast && !this.props.isSearching && !this.state.isLastPage) {
      const { filterText, page } = this.state;

      this.props.searchParticipants(filterText, page + 1);
      this.setState({ page: page + 1 });
    }
  }

  onButtonClick = () => {
    const node = this.textInputRef.current;

    if (node) {
      node.focus();
    }
  }

  onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const text = event.target.value;
    const hasFilter = text.length > 0;
    const { participants } = this.props;
    let { filteredParticipants } = this.state;

    if (text.length > 0) {
      this.props.searchParticipants(text, 1);
    }

    if (!hasFilter) {
      filteredParticipants = participants!;
    } else {
      filteredParticipants = participants!.filter((item: Participant) => (
        item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1
      ));
    }

    this.setState({
      displayDropdown: text.length > 0,
      filterText: text,
      filteredParticipants,
      page: 1,
    });
  }

  onDropdownSelection = (participant: Participant) => {
    const node = this.textInputRef.current;

    if (node) {
      node.value = "";
    }

    this.props.onAddParticipant(participant);
    this.setState({
      displayDropdown: false,
      filterText: '',
      isLastPage: false,
      page: 1,
    });
  }

  toggleDropdown = () => {
    this.setState({ displayDropdown: !this.state.displayDropdown });
  }

  renderList = (participants: Participant[]) => {
    const { isSearching } = this.props;
    const { filterText } = this.state;
    let component;

    if (isSearching) {
      component = (
        <li key={-1} className="AddParticipant-fetchingRowLabel">Fetching...</li>
      );

      if (participants.length > 0) {
        component = (
          <>
            {participants.map((item: Participant, index: number) => (
              <li key={item.id} onClick={() => this.onDropdownSelection(item)}>
                <label>
                  {item.fullName}
                  &nbsp;&nbsp;
                  <label style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {`(${item.email})`}
                  </label>
                </label>
              </li>
            ))}
            {component}
          </>
        )
      }
    } else if (filterText.length > 0) {
      if (participants.length == 0) {
        component = <li key={-2}><label>No search results</label></li>;
      } else {
        component = participants.map((item: Participant, index: number) => (
          <li key={item.id} onClick={() => this.onDropdownSelection(item)}>
            <label>
              {item.fullName}
              &nbsp;&nbsp;
              <label style={{ fontSize: 12, fontStyle: 'italic' }}>
                {`(${item.email})`}
              </label>
            </label>
          </li>
        ));
      }
    }

    return (
      <ul className="AddParticipantInput-dropdown" onScroll={this.handlePagination}>
        {component}
      </ul>
    )
  }

  render() {
    const { adding, disabled, errorMessage } = this.props;
    const {
      displayDropdown,
      filterText,
      filteredParticipants,
    } = this.state;

    return (
      <>
        <div
          className={classnames([
            'AddParticipantInput-container',
            practiceConstants().className,
            errorMessage ? 'is-invalid' : ''
          ])}
          ref={node => this.node = node}
          style={{ marginBottom: errorMessage ? 5 : '1rem' }}
        >
          <Row middle="xs">
            <Col xs>
              <input
                autoComplete="off"
                className="AddParticipantInput-searchInput"
                disabled={disabled || adding}
                onChange={debouncedOnChangeText(this.onChangeText, 500)}
                onFocus={() => this.setState({ displayDropdown: filterText.length > 0 })}
                placeholder="Add New Participants"
                ref={this.textInputRef}
                type="search" />
            </Col>
            <Col>
              {adding ? (
                <FontAwesomeIcon
                  spin
                  className="AddParticipantInput-button"
                  icon={faSpinner}
                />
              ) : (
                <button
                  className="AddParticipantInput-button"
                  type="button"
                  onClick={this.onButtonClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
            </Col>
          </Row>

          {displayDropdown && this.renderList(filteredParticipants)}
        </div>

        {errorMessage && (
          <div className="AddParticipantInput-error">
            {errorMessage}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isSearching: state.superuserClassDetails.isSearching,
  participants: state.superuserClassDetails.participants,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchParticipants: (query: string, page: number) => dispatch(searchParticipants.request({ query, page })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddParticipantInput);
