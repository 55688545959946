import React, { useState, FormEvent, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import queryString from 'query-string';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import { RouteComponentProps } from 'react-router';
import { FormControlProps } from 'react-bootstrap/FormControl';

import API from '../../api';

import logo from '../../assets/images/logo.png';

import Button from '../../components/Button';

import { toggleZendeskScript } from '../../utils';
import { ROUTES, VERSION } from '../../utils/constants';
import { User, UserType } from '../../utils/types';

import './index.scss';

interface Props extends RouteComponentProps {
  user?: User,
  setUser: (accessToken: string, refreshToken: string, user: User) => void;
  clearUser: () => void;
}

export default function Login(props: Props) {
  const { history, location, user, clearUser } = props;

  // Clear user if there is one
  useEffect(() => {
    if (user && !busy) {
      clearUser();
      history.replace(`${ROUTES.Login}?message=${encodeURIComponent('You have been logged-out.')}`);
      toggleZendeskScript();
    }
  }, [user]);

  // States
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState('');

  // Login callback
  const submit = (e: FormEvent<HTMLFormElement>) => {
    const api = new API();

    e.preventDefault();
    setBusy(true);
    setError('');
    api.login({ username, password })
      .then((result) => {
        let path: ROUTES;

        setError('');

        switch (result.user.type) {
          case UserType.SUPERUSER:
            path = ROUTES.SuperuserDashboard;
            break;
          case UserType.DIETITIAN:
            path = ROUTES.DietitianClasses;
            break;
          default:
            path = ROUTES.ParticipantWizard;
            break;
        }

        history.push(path);
        toggleZendeskScript();
      })
      .catch((error) => {
        setPassword('');
        setError(error);
        setBusy(false);
      });
  };

  return (
    <Container>
      <Row>
        <Col className="login__col">
          <Card className="login__card">
            <Card.Body>
              <img src={logo} alt="Fruit Street" className="login__logo" />

              {error
                ? <Alert variant="danger" className="text-center">
                    <strong>{error}</strong>
                  </Alert>
                : null}

              {location.search
                ? <Alert variant="info" className="text-center">
                    <strong>{queryString.parse(location.search).message}</strong>
                  </Alert>
                : null}

              <Form onSubmit={submit}>
                <Form.Group controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={username}
                    disabled={busy}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setUsername(e.currentTarget.value) }}
                    required
                    autoFocus
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={password}
                    disabled={busy}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPassword(e.currentTarget.value) }}
                    required
                  />
                </Form.Group>
                <div className="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    loading={busy}
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="version">
        {process.env.NODE_ENV !== 'production' && 'Staging '}Version {VERSION}
      </div>
    </Container>
  );
}
