import { toast } from 'react-toastify';
import axios from 'axios';

import APIClient, { autoClose } from '.';

import { UserData } from '../types/signup-tracking';

import { practiceConstants } from '../utils';

export default class SignupTrackingAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchSignupTracking = (
    queryString: string,
    isUnique: boolean,
    isDropOff?: boolean,
  ): Promise<[UserData[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/signup-tracking${queryString}`;

        if (isUnique) {
          url = `${url}&unique=${isUnique ? 1 : 0}`;
        }

        if (isDropOff) {
          url = `${url}&drop_off=${isDropOff ? 1 : 0}`;
        }

        const response = await this.instance.get(url);
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const list = data.map((i: any): UserData => ({
          id: i.id,
          name: i.name,
          email: i.email,
          phone: i.phone,
          eligible: i.eligible,
          hasFinishedLanding: i.finished_landing,
          hasFinishedQuiz: i.finished_quiz,
          hasFinishedEligibility: i.finished_eligibility,
          hasFinishedScheduler: i.finished_scheduler,
          hasFinishedSignedUp: i.finished_signup,
          date: i.date,
          isLowRisk: i.risk_assessment == 'low',
          lastQuizItem: i.last_quiz_item,
        }));

        resolve([list, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
