export enum SignupTrackingFilter {
  Landing = 'Landing',
  Quiz = 'Quiz',
  Eligibility = 'Eligible',
  Scheduler = 'Scheduled',
  SignedUp = 'Enrolled',
  All = 'All',
}

export type SignupTrackingParams = {
  company: string;
  search: string;
  step: string;
  risk: string;
  page: number;
  from?: Date;
  to?: Date;
  isUnique: boolean;
}

export type UserData = {
  id: string;
  name: string;
  email: string;
  date: Date;
  phone: string;
  eligible?: boolean;
  hasFinishedLanding?: boolean;
  hasFinishedQuiz?: boolean;
  hasFinishedEligibility?: boolean;
  hasFinishedScheduler?: boolean;
  hasFinishedSignedUp?: boolean;
  isLowRisk?: boolean;
  lastQuizItem: string;
}
