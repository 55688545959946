import axios from 'axios';
import {
  call,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import API from '../api';

import {
  processLogin,
  setUser,
  Types,
} from '../redux/actions/Authentication';

import getEnvCreds from '../utils/apiconstants';

function setAxiosHeaders(token: string) {
  const ENV_CREDS = getEnvCreds();

  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['X-AccountCode'] = ENV_CREDS.ACCOUNT_CODE;
  axios.defaults.headers.common['X-ApiKey'] = ENV_CREDS.API_KEY;

  if (token) {
    axios.defaults.headers.common['X-ApiToken'] = token;
  } else {
    delete axios.defaults.headers.common['X-ApiToken'];
  }
}

function refreshAxiosHeaders() {
  const ENV_CREDS = getEnvCreds();

  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['X-AccountCode'] = ENV_CREDS.ACCOUNT_CODE;
  axios.defaults.headers.common['X-ApiKey'] = ENV_CREDS.API_KEY;
}

export function* processLoginSaga(
  action: ReturnType<typeof processLogin.request>
): Generator {
  try {
    const client = new API();
    const response: any = yield call(client.login, action.payload);

    yield put(processLogin.success(response));
  } catch (error) {
    yield put(processLogin.failure(error));
  }
}

export function* setUserSaga(action: ReturnType<typeof setUser>) {
  const { accessToken } = action.payload;

  setAxiosHeaders(accessToken);
}

export function* updateUserSaga() {
  refreshAxiosHeaders();
}

export function* logoutUserSaga() {
  delete axios.defaults.headers.common['X-ApiToken'];
}

export function* rehydrateAuthSaga(action: any) {
  if (action.key === 'authentication' && action.payload) {
    const { accessToken } = action.payload;

    setAxiosHeaders(accessToken);
  }
}

export default [
  takeLatest(Types.LOGIN, processLoginSaga),
  takeEvery(Types.SET_USER, setUserSaga),
  takeEvery(Types.UPDATE_USER, updateUserSaga),
  takeEvery(Types.LOGOUT, logoutUserSaga),
  takeEvery('persist/REHYDRATE', rehydrateAuthSaga),
]
