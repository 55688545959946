import { all } from 'redux-saga/effects';

import authenticationSagas from './Authentication';
import participantEligibilitySagas from './ParticipantEligibility';
import superuserClassDetailsSagas from './SuperuserClassDetails';
import superuserHomeSagas from './SuperuserHome';

export default function* rootSaga() {
  yield all([
    ...authenticationSagas,
    ...participantEligibilitySagas,
    ...superuserClassDetailsSagas,
    ...superuserHomeSagas
  ])
}
