import { getType } from 'typesafe-actions';

import {
  clearFilters,
  storeFilters,
  storePage,
  ReducerActionType,
} from '../actions/Dietitian';

import { CLASS_STATUS } from '../../utils/constants';

export type ReducerStateType = Readonly<{
  filterDay: string,
  filterTime: string,
  filterStatus: string,
  searchText: string;
  startDate?: Date;
  endDate?: Date;
  currentPage: number;
}>

const startDate = new Date();

startDate.setHours(0, 0, 0, 0);

const INITIAL_STATE = {
  searchText: '',
  filterDay: '',
  filterTime: '',
  filterStatus: CLASS_STATUS.All.toString(),
  startDate,
  currentPage: 0,
}

const reducers = (
  state: ReducerStateType = INITIAL_STATE,
  action: ReducerActionType
) => {
switch (action.type) {
    case getType(clearFilters):
      return {
        ...state,
        ...INITIAL_STATE
      }

    case getType(storeFilters):
      return {
        ...state,
        ...action.payload,
      }

    case getType(storePage):
      return {
        ...state,
        currentPage: action.payload,
      }

    default:
      return state;
  }
}

export default reducers;
