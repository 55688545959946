import React from 'react';
import { toast } from 'react-toastify';
import BsButton from 'react-bootstrap/Button';

import CustomButton from '../CustomButton';

import './index.scss';

type Props = {
  copyValue: string;
  buttonText?: string;
  size?: 'sm' | 'lg';
  disabled?: boolean;
  successMessage?: string;
  type?: 'default' | 'custom';
}

export default function Button(props: Props) {
  const {
    type,
    buttonText,
    copyValue,
    successMessage,
    size,
    disabled
  } = props;
  const defaultText = 'Copy Link';

  const onCopyLink = (value: string) => {
    var tempInput = document.createElement('input') as HTMLInputElement;

    tempInput.style.position = 'absolute';
    tempInput.style.left = '-1000px';
    tempInput.style.top = '-1000px';

    tempInput.value = value;

    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    toast.success(successMessage || 'Copied link.');
  }

  const renderDefault = () => {
    return (
      <BsButton
        size={size}
        className={`Copy-button ${size || ''}`}
        variant={'primary'}
        disabled={disabled || false}
        onClick={() => onCopyLink(copyValue)}
      >
        {buttonText || defaultText}
      </BsButton>
    );
  }

  const renderCustom = () => {
    return (
      <CustomButton
        primary
        title={buttonText || defaultText}
        disabled={disabled}
        onClick={() => onCopyLink(copyValue)}
      />
    );
  }

  return (
    <>
      {!type || (type && type === 'default') ? renderDefault() : renderCustom()}
    </>
  );
}
