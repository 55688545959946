import React from 'react';
import BsPagination from 'react-bootstrap/Pagination';

import { practiceConstants } from '../../utils';

import './index.scss';

type Props = {
  pageArr: number[];
  currentPerPage: number;
  onChangePerPage: (page: number) => void;
}

export default function PerPageSelect(props: Props) {
  const { pageArr, currentPerPage, onChangePerPage } = props;

  return (
    <div className={practiceConstants().className}>
      <BsPagination className="perpage-wrap">
        {pageArr.map(p =>
          <BsPagination.Item
            key={p}
            disabled={p === currentPerPage}
            className={currentPerPage === p ? 'page-item--current' : ''}
            onClick={(e) => {
              e.preventDefault();
              onChangePerPage(p);
            }}
          >
            {p}
          </BsPagination.Item>
        )}
      </BsPagination>
    </div>
  );
}
