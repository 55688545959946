import React, { useState } from 'react';
import _ from 'lodash';

import ContractsAPIClient from '../../../../api/contracts';

import BaseModal from '../../../../components/BaseModal';
import Dropdown from '../../../../components/Dropdown';

import { BASE_URL_INGENUITY, VALIDATION_REQUIRED } from '../../../../utils/constants';
import { DropdownItem } from '../../../../utils/types';

import { ContractData, ContractMilestone } from '../../../../types/contracts';

import './index.scss';

interface Props {
  id: string;
  contract?: ContractData;
  milestone?: ContractMilestone;
  toDisplay: boolean;
  onCancel?: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onClose?: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onConfirm: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    data: ContractMilestone,
  ) => void;
}

enum FormKeys {
  Description = 'description',
  ClaimAmount = 'claims_amount',
  DietitianPayment = 'dietitian_fee',
}

export default function AddMilestoneModal(props: Props) {
  const api = new ContractsAPIClient(BASE_URL_INGENUITY);
  const { contract, milestone } = props;
  const types = [
    { title: 'Percentage', value: 'percentage' },
    { title: 'Exact Amount', value: 'amount' },
  ];

  // States
  const [description, setDescription] = useState(milestone ? milestone.description : '');
  const [claimAmount, setClaimAmount] = useState<number>(milestone ? milestone.claimAmount : 0);
  const [dietitianPayment, setDietitianPayment] = useState<number>(
    milestone ? milestone.dietitianPayment : 0
  );
  const [type, setType] = useState<DropdownItem>(
    types[!milestone || (milestone && milestone.dietitianPayment <= 1) ? 0 : 1]
  );

  const [formErrors, setFormErrors] = useState<{ [id: string]: string }>({});
  const [isInitial, setIsInitial] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const getCalculatedDietitianPayment = () => (
    dietitianPayment <= 1 ? dietitianPayment * 100 : dietitianPayment
  )

  const getDietitianPayment = () => {
    const value = dietitianPayment;

    if (type.value == 'amount') {
      return value <= 1 ? value * 100 : value;
    } else {
      return value > 1 ? value / 100 : value;
    }
  }

  const isInputInvalid = (key: string) => !_.isEmpty(formErrors[key]) && !isInitial;

  const validateForm = (payload) => {
    let errors = formErrors;
    let isValid = true;
    const optionalFields = [FormKeys.DietitianPayment];

    Object.keys(FormKeys).map((k: string) => {
      const key = FormKeys[k];
      const value = payload[key];
      let error = '';

      error = optionalFields.includes(key) ? '' : error;

      switch (key) {
        case FormKeys.Description:
          error = !value || (value && value.length == 0) ? VALIDATION_REQUIRED : '';

          break;

        case FormKeys.ClaimAmount:
          error = value == 0 ? VALIDATION_REQUIRED : '';

          if (key == FormKeys.DietitianPayment) {
            if (value > 0 && (value >= payload[FormKeys.ClaimAmount])) {
              error = 'Must be smaller than Claim Amount';
            }
          }

          break;

        default:
          break;
      }

      if (error) {
        isValid = false;
      }

      errors[key] = error;
    });

    setFormErrors({ ...formErrors, ...errors });
    setIsInitial(false);

    return isValid;
  }

  const onAddMilestone = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const data = {
      [FormKeys.Description]: description,
      [FormKeys.ClaimAmount]: claimAmount,
      [FormKeys.DietitianPayment]: getDietitianPayment(),
    };

    if (!validateForm(data) || !contract) {
      return;
    }

    setIsProcessing(true);

    let payload = { company: contract.id, milestones: { [props.id]: data } };

    if (milestone) {
      let m = {};

      for (let i = 0; i < contract.milestones.length; i++) {
        const item = contract.milestones[i];

        m[item.id] = {
          [FormKeys.Description]: item.description,
          [FormKeys.ClaimAmount]: item.claimAmount,
          [FormKeys.DietitianPayment]: item.dietitianPayment,
        }
      }

      payload = { ...payload, milestones: { ...m, ...payload.milestones } };
    }

    const result = milestone ?
      api.updateContract(contract.id, payload) :
      api.createContractMilestone(payload);

    result.then(() => {
      const newMilestone: ContractMilestone = {
        id: props.id,
        description,
        claimAmount,
        dietitianPayment: data[FormKeys.DietitianPayment]
      };

      setIsProcessing(false);
      setDescription('');
      setClaimAmount(0);
      setDietitianPayment(0);
      setType(types[0]);
      props.onConfirm(event, newMilestone);
    })
    .catch(() => {
      setIsProcessing(false);
    });
  }

  return (
    <BaseModal
      {...props}
      lg
      confirmTitle="Ok"
      cancelTitle="Cancel"
      footerButton={<div style={{ width: 1 }} />}
      loading={isProcessing}
      title={milestone ? `Update Milestone ${milestone.id}` : `Add Milestone ${props.id}`}
      onConfirm={onAddMilestone}
    >
      <div className="AddMilestoneModal-body">
        <div className="input-row">
          <div className="input-column">
            <label>Milestone Description</label>
            <input
              autoFocus
              id={FormKeys.Description}
              className={isInputInvalid(FormKeys.Description) ? 'is-invalid' : ''}
              disabled={isProcessing}
              name={FormKeys.Description}
              type="text"
              value={description || ''}
              onChange={e => setDescription(e.target.value)}
            />
            <label className={isInputInvalid(FormKeys.Description) ? 'is-invalid' : ''}>
              {formErrors[FormKeys.Description]}
            </label>
          </div>
        </div>
        <div className="input-row">
          <div id="claim_amount" className="input-column">
            <label>Claim Amount</label>
            <input
              id={FormKeys.ClaimAmount}
              className={isInputInvalid(FormKeys.ClaimAmount) ? 'is-invalid' : ''}
              defaultValue={claimAmount.toString()}
              disabled={isProcessing}
              name={FormKeys.ClaimAmount}
              type="number"
              onChange={e => setClaimAmount(parseFloat(e.target.value))}
            />
            <label className={isInputInvalid(FormKeys.ClaimAmount) ? 'is-invalid' : ''}>
              {formErrors[FormKeys.ClaimAmount]}
            </label>
          </div>
          <div className="input-column">
            <label>Dietitian Payment</label>
            <div className="input-dropdown">
              <input
                id={FormKeys.DietitianPayment}
                className={isInputInvalid(FormKeys.DietitianPayment) ? 'is-invalid' : ''}
                defaultValue={getCalculatedDietitianPayment().toString()}
                disabled
                name={FormKeys.DietitianPayment}
                type="number"
                onChange={e => setDietitianPayment(parseFloat(e.target.value))}
              />
              <Dropdown
                disabled
                items={types}
                selectedItem={type}
                onItemSelection={item => setType(item)}
              />
            </div>
            <label className={isInputInvalid(FormKeys.DietitianPayment) ? 'is-invalid' : ''}>
              {formErrors[FormKeys.DietitianPayment]}
            </label>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
