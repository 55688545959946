import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faCalendar,
  faCalendarWeek,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { scrollToNode } from '../../utils';
import { FORMAT_MM_DD_YYYY } from '../../utils/constants';
import { formatPercent } from '../../utils/formatting';
import { WeightLossAnalyticsData } from '../../utils/types';

import Pagination from '../Pagination';

import './index.scss';

interface Props {
  currentPage: number;
  isFetching?: boolean;
  numPages: number;
  pagedUsers: any[];
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
}

export default function List(props: Props) {
  const renderList = () => (
    <Table
      className={`data-list weightloss-tracking${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faUser} />
            Participant
          </th>
          <th>
            <FontAwesomeIcon icon={faCalendar} />
            Landing Date
          </th>
          <th className="weightloss-tracking__stat">
            <FontAwesomeIcon icon={faCalendarWeek} />
            4
          </th>
          <th className="weightloss-tracking__stat">8</th>
          <th className="weightloss-tracking__stat">12</th>
          <th className="weightloss-tracking__stat">16</th>
          <th className="weightloss-tracking__stat">20</th>
          <th className="weightloss-tracking__stat">24</th>
          <th className="weightloss-tracking__stat">28</th>
          <th className="weightloss-tracking__stat">32</th>
          <th className="weightloss-tracking__stat">36</th>
          <th className="weightloss-tracking__stat">40</th>
          <th className="weightloss-tracking__stat">44</th>
          <th className="weightloss-tracking__stat">48</th>
          <th className="weightloss-tracking__stat">52</th>
        </tr>
      </thead>
      <tbody>
        {props.pagedUsers.map((user: WeightLossAnalyticsData, index: number) =>
          <tr id={`WeightLossAnalytics-row${index}`} key={index}>
            <td className="contain-text-250">
              {user.name}
            </td>
            <td>
              {moment(user.signupDate).format(FORMAT_MM_DD_YYYY)}
            </td>
            <td className={`weightloss-tracking__stat${user.weekFour !== null ? "" : "__empty"}`}>
              {user.weekFour !== null ? formatPercent(user.weekFour, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekEight !== null ? "" : "__empty"}`}>
              {user.weekEight !== null ? formatPercent(user.weekEight, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekTwelve !== null ? "" : "__empty"}`}>
              {user.weekTwelve !== null ? formatPercent(user.weekTwelve, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekSixteen !== null ? "" : "__empty"}`}>
              {user.weekSixteen !== null ? formatPercent(user.weekSixteen, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekTwenty !== null ? "" : "__empty"}`}>
              {user.weekTwenty !== null ? formatPercent(user.weekTwenty, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekTwentyFour !== null ? "" : "__empty"}`}>
              {user.weekTwentyFour !== null ? formatPercent(user.weekTwentyFour, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekTwentyEight !== null ? "" : "__empty"}`}>
              {user.weekTwentyEight !== null ? formatPercent(user.weekTwentyEight, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekThirtyTwo !== null ? "" : "__empty"}`}>
              {user.weekThirtyTwo !== null ? formatPercent(user.weekThirtyTwo, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekThirtySix !== null ? "" : "__empty"}`}>
              {user.weekThirtySix !== null ? formatPercent(user.weekThirtySix, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekForty !== null ? "" : "__empty"}`}>
              {user.weekForty !== null ? formatPercent(user.weekForty, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekFortyFour !== null ? "" : "__empty"}`}>
              {user.weekFortyFour !== null ? formatPercent(user.weekFortyFour, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekFortyEight !== null ? "" : "__empty"}`}>
              {user.weekFortyEight !== null ? formatPercent(user.weekFortyEight, true) : '-'}
            </td>
            <td className={`weightloss-tracking__stat${user.weekFiftyTwo !== null ? "" : "__empty"}`}>
              {user.weekFiftyTwo !== null ? formatPercent(user.weekFiftyTwo, true) : '-'}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('WeightLossAnalytics-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
