import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faClipboard,
  faMoneyCheck,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Pagination from '../../../../components/Pagination';

import { DietitianPaymentData } from '../../../../types/dietitian-payments';

import { scrollToNode } from '../../../../utils';

import DietitianPaymentRow from './Row';

interface Props {
  viewOnly?: boolean;
  currentPage: number;
  isFetching?: boolean;
  isSharecare: boolean;
  numPages: number;
  pagedUsers: DietitianPaymentData[];
  type: string;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setUsers: (value: React.SetStateAction<DietitianPaymentData[]>) => void;
  onItemSelection: (item: DietitianPaymentData) => void;
}

export default function List(props: Props) {
  const { isSharecare, type } = props;

  const renderList = () => {
    const headerByType = () => {
      switch (type) {
        case 'session':
          return (
            <>
              <th>
                <FontAwesomeIcon icon={faMoneyCheck} />
                Period
              </th>
              <th>Sessions</th>
            </>
          );

        default:
          return (
            <>
              <th>
                <FontAwesomeIcon icon={faUsers} />
                Participants
              </th>
              <th>
                <FontAwesomeIcon icon={faMoneyCheck} />
                Period
              </th>
              <th>Milestone 1</th>
              <th>Milestone 2</th>
              <th>Milestone 3</th>
              <th>Milestone 4</th>
            </>
          )
      }
    }

    return (
      <Table className={`data-list${props.isFetching ? ' fetching' : '' }`}>
        <thead>
          <tr>
            <th>
              <FontAwesomeIcon icon={faClipboard} />
              Dietitian
            </th>

            {headerByType()}

            <th>Total</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.pagedUsers.map((user: DietitianPaymentData, index: number) =>
            <DietitianPaymentRow
              key={user.id}
              index={index}
              isSharecare={isSharecare}
              item={user}
              type={type}
              viewOnly={props.viewOnly}
              onPaymentStatusChange={item => {
                props.pagedUsers[index] = item;

                props.setUsers(props.pagedUsers);
              }}
              onSelect={item => {
                if (props.isFetching) {
                  return;
                }

                props.onItemSelection(item);
              }} />
          )}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('DietitianPayment-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
