import { toast } from 'react-toastify';
import axios from 'axios';

import { ConsumerPayment, ConsumerPaymentUser } from '../types/consumer-payments';

import { practiceConstants } from '../utils';

import APIClient, { autoClose } from '.';

export default class ConsumerPaymentsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchConsumerPayments = (
    queryString: string,
  ): Promise<[ConsumerPaymentUser[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/participants/payments${queryString}`;
        const response = await this.instance.get(url);
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const list = data.map((item): ConsumerPaymentUser => ({
          id: item.participant.id,
          firstName: item.participant.first_name,
          lastName: item.participant.last_name,
          signupDate: new Date(item.participant.date),
          paymentOption: item.payment_option,
          paymentMethod: item.payment_method,
          balance: parseFloat(item.total_paid),
        }));

        resolve([list, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          toast.error(error.response.data.message || error.response.message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchConsumerPaymentUserDetails = (id: string): Promise<ConsumerPaymentUser> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/participants/${id}/payments-info`;
        const response = await this.instance.get(url);
        const data = response.data.data;
        const item: ConsumerPaymentUser = {
          id: data.participant.id,
          firstName: data.participant.first_name,
          lastName: data.participant.last_name,
          signupDate: new Date(data.participant.date),
          paymentOption: data.payment_option,
          paymentMethod: data.payment_method,
          balance: parseFloat(data.total_paid),
        };

        resolve(item);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchConsumerPaymentUserPayments = (
    id: string,
    page: number
  ): Promise<[ConsumerPayment[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/participants/${id}/payments?page=${page}`;
        const response = await this.instance.get(url);
        const { data, meta } = response.data;
        const { to, from, total } = meta;
        const items: ConsumerPayment[] = data.map(item => ({
          amount: parseFloat(item.amount_paid),
          date: new Date(item.date),
          status: item.status,
        }));

        resolve([items, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          toast.error(error.response.data.message || error.response.message, { autoClose });
          reject();
        }
      }
    })
  )
}
