import React from 'react';
import entries from 'lodash-es/entries';
import Card from 'react-bootstrap/Card';
import { Line as LineChart } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faCalendar,
  faClipboard,
  faWeight,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { practiceConstants } from '../../../../utils';
import {
  weekMap,
  FORMAT_MM_DD_YYYY,
  VALIDATION_NO_DIETITIAN,
} from '../../../../utils/constants';
import { WeightLossAnalyticsData } from '../../../../utils/types';

interface Props {
  participant: WeightLossAnalyticsData;
}

export default function ParticipantChart(props: Props) {
  const limit = 7;
  const { participant: patient } = props;
  const { primaryColor } = practiceConstants();
  const tickStyle = {
    fontColor: '#34495E',
    fontFamily: 'Open Sans, Helvetica',
    fontSize: 10,
  }

  const weeks: string[] = [];
  const weightLoss: number[] = [];

  entries(weekMap).forEach((pair) => {
    const [num, key] = pair;

    num === '4' ? weeks.push(`Week ${num}`) : weeks.push(num);

    if (patient[key] !== null) {
      weightLoss.push(patient[key]);
    }
  });

  const maxWeightLoss = Math.max(...weightLoss);
  const minWeightLoss = Math.min(...weightLoss);

  return (
    <Card className="weightloss-card">
      <Card.Body>
        <ul className="weightloss-card__info">
          <li>
            <FontAwesomeIcon icon={faUser} />
            {patient.name}
          </li>
          <li>
            <FontAwesomeIcon icon={faCalendar} />
            {moment(patient.signupDate).format(FORMAT_MM_DD_YYYY)}
          </li>
          <li>
            <FontAwesomeIcon icon={faClipboard} />

            {patient.dietitian ||
              <span style={{ fontStyle: 'italic' }}>
                {VALIDATION_NO_DIETITIAN}
              </span>
            }
          </li>
          <li>
            <FontAwesomeIcon icon={faWeight} />
            <strong>Start</strong>&nbsp;

            {patient.startingWeight}lbs
          </li>
          <li className="primary">
            <strong>Current</strong>&nbsp;

            {patient.currentWeight}lbs
          </li>
        </ul>

        <LineChart
          height={200}
          width={1130}
          data={() => {
            // const backgroundColor = gradientForCanvas(canvas, primaryColor, '#ffffff40', 200);
            const backgroundColor = `${primaryColor}40`;

            return {
              labels: weeks,
              datasets: [{
                data: weightLoss,
                backgroundColor,
                borderColor: primaryColor,
                borderWidth: 2,
                pointBackgroundColor: 'white',
                pointBorderColor: primaryColor,
              }],
            }
          }}
          options={{
            responsive: false,
            legend: { display: false },
            elements: {
              line: {
                tension: 0,
              },
              point: {
                backgroundColor: primaryColor,
              },
            },
            scales: {
              xAxes: [{
                ticks: tickStyle,
              }],
              yAxes: [{
                ticks: {
                  min: minWeightLoss < 0 ? (minWeightLoss < (limit * -1) ? minWeightLoss : (limit * -1)) : 0,
                  max: maxWeightLoss > limit ? maxWeightLoss : limit,
                  stepSize: limit / 2,
                  ...tickStyle,
                  callback: (val) => `${val}%`,
                },
                gridLines: { display: false },
              }],
            },
            tooltips: {
              callbacks: {
                title: (item) => {
                  const label = (item[0].xLabel)!.toString();

                  if (label && label.indexOf('Week ') > -1) {
                    return label;
                  }

                  return `Week ${label}`;
                },
                // @ts-ignore TS cannot see value property
                label: (item) => `${item.value}% Weight Loss`,
              },
            },
          }}
        />
      </Card.Body>
    </Card>
  );
}
