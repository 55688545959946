import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import filter from 'lodash-es/filter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import isUndefined from 'lodash-es/isUndefined';
import Container from 'react-bootstrap/Container';

import {ResendClaimRequest} from '../../../api/claims';
import {FetchMilestonesRequest} from '../../../api/milestones';
import SuperuserAPIClient from '../../../api/superuser';

import Button from '../../../components/Button';
import AppNav from '../../../components/AppNav';
import BaseModal from '../../../components/BaseModal';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import SearchInput from '../../../components/SearchInput';
import Spinner from '../../../components/Spinner';
import MilestoneTrackingList from '../../../components/MilestoneTrackingList';
import NoResults from '../../../components/NoResults';
import TotalResults from '../../../components/TotalResults';
import PerPageSelect from '../../../components/PerPageSelect';

import useAPI from '../../../hooks/useAPI';
import {useAPIPagination} from '../../../hooks/pagination';
import useDebounce from '../../../hooks/useDebounce';
import {QueryKeys, useQueryString} from '../../../hooks/useURLQuery';

import {
    FitbitStatus,
    MilestoneData,
    MilestoneStatus,
    PurpleCarrotStatus,
    SharecareSponsorID,
    SponsorID
} from '../../../types/milestones';

import {practiceConstants, queryStringParams, scrollToNode,} from '../../../utils';
import {BASE_URL_INGENUITY, CLIENT_TYPE, PER_PAGE_SELECTIONS, ROUTES} from '../../../utils/constants';

import ClaimErrorModal from './ClaimErrorModal';
import {SharecareSponsorsRequest, OtherSponsorsRequest} from "../../../api/sharecare-sponsors";
import {DropdownItem} from "../../../utils/types";

interface ToDelete {
    id: string;
    name: string
    busy: boolean;
}

interface Props extends RouteComponentProps { }

export default function MilestoneTracking(props: Props) {
    const SHOW_ALL = { title: 'Show All', value: '' };
    const STATUS_FILTERS = [
        { title: 'Not Achieved', value: MilestoneStatus.NotAchieved },
        { title: 'Adjudicated', value: MilestoneStatus.Adjudicated },
        { title: 'Acknowledged', value: MilestoneStatus.Acknowledged },
        { title: 'Achieved', value: MilestoneStatus.Achieved },
        { title: 'Claim Submitted', value: MilestoneStatus.Claimed },
        { title: 'Paid', value: MilestoneStatus.Paid },
        { title: 'Paid in Full', value: MilestoneStatus.PaidInFull },
        { title: 'Rejected', value: MilestoneStatus.Rejected },
        { title: 'Error', value: MilestoneStatus.Error },
        SHOW_ALL
    ];
    const FITBIT_FILTERS = [
        { title: 'Connected', value: FitbitStatus.Connected },
        { title: 'FitBit Issued', value: FitbitStatus.Issued },
        // conditional render for sharecare admins in filter dropdown
        ...(practiceConstants()
            .practiceName == CLIENT_TYPE.ShareCare)
            ? [{ title: 'Purple Carrot Issued', value: PurpleCarrotStatus.Issued }]
            : [],
        { title: 'Not Issued', value: FitbitStatus.NotIssued },
        { title: 'Currently Inactive', value: FitbitStatus.CurrentlyInactive },
        SHOW_ALL
    ];

    const SPONSOR_FILTERS = [
        { title: 'Rhode Island', value: SponsorID.RHODE_ISLAND},
        { title: 'Legacy', value: SponsorID.LEGACY},
        { title: 'Self Pay', value: SponsorID.SELF_PAY},
        { title: 'Walgreens', value: SponsorID.WALGREENS},
        { title: 'Not Issued', value: SponsorID.NotIssued },
        SHOW_ALL
    ];

    const api = new SuperuserAPIClient(BASE_URL_INGENUITY);
    const query = queryStringParams(props.location.search);
    const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
    const { className, defaultEmployer, employers, practiceName } = practiceConstants();

    // Filter states
    const [employer, setEmployer] = useState(employers.filter(e => (
        e.value == query[QueryKeys.Company]
    ))[0] || defaultEmployer);
    const [statusFilterOne, setStatusFilterOne] = useState(STATUS_FILTERS.filter(e => (
        e.value == query[QueryKeys.MilestoneOne]
    ))[0] || SHOW_ALL);
    const [statusFilterTwo, setStatusFilterTwo] = useState(STATUS_FILTERS.filter(e => (
        e.value == query[QueryKeys.MilestoneTwo]
    ))[0] || SHOW_ALL);
    const [statusFilterThree, setStatusFilterThree] = useState(STATUS_FILTERS.filter(e => (
        e.value == query[QueryKeys.MilestoneThree]
    ))[0] || SHOW_ALL);
    const [statusFilterFour, setStatusFilterFour] = useState(STATUS_FILTERS.filter(e => (
        e.value == query[QueryKeys.MilestoneFour]
    ))[0] || SHOW_ALL);
    const [statusFilterFitbit, setStatusFilterFitbit] = useState(FITBIT_FILTERS.filter(e => (
        e.value == query[QueryKeys.Fitbit]
    ))[0] || SHOW_ALL);
    const [statusFilterSponsor, setStatusFilterSponsor] = useState(SPONSOR_FILTERS.filter(e => (
        e.value == query[QueryKeys.SponsorID]
    ))[0] || SHOW_ALL);
    const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');

    // Dataset states
    const [claimAmounts, setClaimAmounts] = useState<{ [id: string]: number }>({});
    const [errorCode, setErrorCode] = useState<number | undefined>();
    const [isAddError, setIsAddError] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [sharecareSponsorFilters, setSharecareSponsorFilters] = useState<DropdownItem[]>([{title: '', value: ''}]);
    const [otherSponsorFilters, setOtherSponsorFilters] = useState<DropdownItem[]>([{title: '', value: ''}]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [patientWithError, setPatientWithError] = useState<{
        patient: MilestoneData, milestone: number
    } | undefined>(undefined);
    const [perPage, setPerPage] = useState(
        query[QueryKeys.Rows] ? +query[QueryKeys.Rows] : PER_PAGE_SELECTIONS[0]
    );
    const [users, setUsers] = useState<MilestoneData[]>([]);

    // Delete states
    const [toDelete, setToDelete] = useState<ToDelete | undefined>(undefined);

    // Pagination
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [
        currentPage,
        numPages,
        pagedUsers,
        setCurrentPage
    ] = useAPIPagination(users, perPage, total, queryPage);

    const debouncedSearchTerm = useDebounce(searchFilter, 500);
    const queryString = useQueryString({
        [QueryKeys.Page]: currentPage + 1,
        [QueryKeys.Company]: employer.value,
        [QueryKeys.Search]: debouncedSearchTerm,
        [QueryKeys.MilestoneOne]: statusFilterOne.value,
        [QueryKeys.MilestoneTwo]: statusFilterTwo.value,
        [QueryKeys.MilestoneThree]: statusFilterThree.value,
        [QueryKeys.MilestoneFour]: statusFilterFour.value,
        [QueryKeys.Fitbit]: statusFilterFitbit.value,
        [QueryKeys.SponsorID]: statusFilterSponsor.value,
        [QueryKeys.Rows]: perPage,
    });

    useEffect(() => {
        setCurrentPage(queryPage);
    }, [])

    // Fetch Sharecare sponsor ids
    useAPI(SharecareSponsorsRequest, (request) => {
        request
            .send()
            .then( result => {
                const { items } = result;
                items.push(SHOW_ALL);

                setSharecareSponsorFilters(items);
            })
    }, []);

    // Fetch other sponsor ids
    useAPI(OtherSponsorsRequest, (request) => {
        request
            .send()
            .then( result => {
                const { items } = result;
                items.push(SHOW_ALL);

                setOtherSponsorFilters(items);
            })
    }, []);

    // Fetch milestones request
    useAPI(FetchMilestonesRequest, (request) => {
        setIsFetching(true);
        props.history.replace(queryString);
        request
            .send(queryString)
            .then((result) => {
                const { items, total: totalItems, to: to, from: from } = result;
                const sortedUsers = items.sort((a, b) => (
                    b.signupDate.getTime() < a.signupDate.getTime() ? -1 : 1
                ));

                setIsFetching(false);
                setUsers(sortedUsers);
                setTotal(totalItems);
                setTo(to);
                setFrom(from);
            })
            .catch(() => {
                setIsFetching(false);
                setUsers([]);
                setTotal(0);
                setTo(0);
                setFrom(0);
            });
    }, [
        employer,
        debouncedSearchTerm,
        statusFilterOne,
        statusFilterTwo,
        statusFilterThree,
        statusFilterFour,
        statusFilterFitbit,
        statusFilterSponsor,
        currentPage,
        perPage
    ]);

    // Callback for changing search query
    const onChangeText = text => {
        setIsFetching(true);
        setCurrentPage(0);
        setSearchFilter(text);
    }

    // Callback for deleting a user tracking record
    const deleteUser = (userId: string) => {
        setToDelete(Object.assign({}, toDelete, { busy: true }));
        api.deleteMilestone(userId)
            .then(() => {
                const updatedUsers = filter(users, u => u.id !== userId);

                setToDelete(undefined);
                setUsers(updatedUsers);
                toast.success('Milestone tracking record deleted.', { autoClose: 3000 });
            })
            .catch(() => {
                setToDelete(undefined);
                toast.success('Failed deleting record.', { autoClose: 3000 });
            });
    };

    const renderFilters = () => {
        const rowStyle = { justifyContent: 'space-between' };

        return (
            <div id="MilestoneTracking-filters" className={`filters-section ${className}`}>
                <Row style={rowStyle}>
                    <Col xs={12} lg={1}>
                        <h3 className="primary-title">Filters</h3>
                    </Col>
                    <Col xs={12} lg={10} className="controls">
                        <Row>
                            <label className="info primary">
                                Milestone:
                            </label>
                            <Col>
                                <Dropdown
                                    items={STATUS_FILTERS}
                                    selectedItem={statusFilterOne}
                                    title="1"
                                    onItemSelection={(item) => {
                                        setCurrentPage(0);
                                        setStatusFilterOne(item);
                                    }} />
                            </Col>
                            <Col>
                                <Dropdown
                                    items={STATUS_FILTERS}
                                    selectedItem={statusFilterTwo}
                                    title="2"
                                    onItemSelection={(item) => {
                                        setCurrentPage(0);
                                        setStatusFilterTwo(item);
                                    }} />
                            </Col>
                            <Col>
                                <Dropdown
                                    items={STATUS_FILTERS}
                                    selectedItem={statusFilterThree}
                                    title="3"
                                    onItemSelection={(item) => {
                                        setCurrentPage(0);
                                        setStatusFilterThree(item);
                                    }} />
                            </Col>
                            <Col>
                                <Dropdown
                                    items={STATUS_FILTERS}
                                    selectedItem={statusFilterFour}
                                    title="4"
                                    onItemSelection={(item) => {
                                        setCurrentPage(0);
                                        setStatusFilterFour(item);
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }

    const renderEmployer = () => (
        <div className={`employer-section ${className}`}>
            <Row>
                <Col lg="auto">
                    <Dropdown
                        style={{whiteSpace: "nowrap", maxWidth: "fit-content"}}
                        items={(practiceName == CLIENT_TYPE.ShareCare) ? sharecareSponsorFilters : otherSponsorFilters}
                        selectedItem={statusFilterSponsor}
                        title="Origin ID"
                        onItemSelection={(item) => {
                            setCurrentPage(0);
                            setStatusFilterSponsor(item);
                        }}
                    />
                </Col>
                <Col lg="auto">
                    <Dropdown
                        style={{whiteSpace: "nowrap", maxWidth: "fit-content"}}
                        items={FITBIT_FILTERS}
                        selectedItem={statusFilterFitbit}
                        title={`fitbit${(practiceName == CLIENT_TYPE.ShareCare) ? '/Purple Carrot' : ''}`}
                        onItemSelection={(item) => {
                            setCurrentPage(0);
                            setStatusFilterFitbit(item);
                        }}
                    />
                </Col>
                <Col xs={6} lg={3}>
                    <Dropdown
                        disabled={employers.length <= 1}
                        items={employers}
                        selectedItem={employer}
                        title="Employer"
                        onItemSelection={(item) => {
                            setCurrentPage(0);
                            setEmployer(item);
                        }}
                    />
                </Col>
            </Row>
        </div>
    )

    const renderList = () => {
        if (pagedUsers && pagedUsers.length == 0) {
            return <NoResults />
        }

        return (
            <>
                <TotalResults
                    total={total}
                    to={to}
                    from={from}
                />
                <Container fluid>
                    <Row>
                        <Col>
                            <MilestoneTrackingList
                                adding={isProcessing}
                                claimAmounts={claimAmounts}
                                currentPage={currentPage}
                                isFetching={isFetching}
                                numPages={numPages}
                                pagedUsers={pagedUsers}
                                setCurrentPage={setCurrentPage}
                                setIsFetching={setIsFetching}
                                onStatusChange={(participantId, milestone, status) => {
                                    return api.updateMilestone(participantId, milestone, status)
                                        .then((newStatus) => {
                                            const index = users.findIndex(u => u.id === participantId);
                                            const newUsers = users.slice(0);

                                            if (index >= 0) {
                                                const statusMap = {
                                                    1: 'milestoneOne',
                                                    2: 'milestoneTwo',
                                                    3: 'milestoneThree',
                                                    4: 'milestoneFour',
                                                    10: 'deviceScale',
                                                    20: 'deviceFitbit',
                                                };

                                                newUsers[index][statusMap[milestone]] = newStatus;
                                                setUsers(newUsers);
                                                toast.success('Successfully updated milestone status.', { autoClose: 2000 });
                                            }
                                        })
                                        .then(() => { });
                                }}
                                onDeleteRow={participant => {
                                    setToDelete({
                                        id: participant.id,
                                        name: `${participant.firstName} ${participant.lastName}`,
                                        busy: false,
                                    });
                                }}
                                onErrorClick={(participant, milestone) => {
                                    setPatientWithError({
                                        patient: participant,
                                        milestone,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
                <PerPageSelect
                    pageArr={PER_PAGE_SELECTIONS}
                    currentPerPage={perPage}
                    onChangePerPage={p => {
                        scrollToNode('MilestoneTracking-filters');
                        setCurrentPage(0);
                        setPerPage(p);
                    }}
                />
            </>
        )
    }

    return (
        <>
            <AppNav />

            <Container fluid={(practiceName == CLIENT_TYPE.ShareCare)} className="superuser padding-bottom">
                <Header
                    hasBack
                    backUrl={ROUTES.SuperuserAnalyticsTracking}
                    titleLg={6}
                    title="Milestone Tracking">
                    <SearchInput
                        defaultValue={searchFilter}
                        placeholder="Type Name, Email, Date, ID, or Insurance Carrier"
                        onChangeText={onChangeText} />
                </Header>

                {renderFilters()}
                {renderEmployer()}

                {/* <AddParticipantInput
          adding={isProcessing}
          addedParticipants={[]}
          onAddParticipant={selection => {
            setIsProcessing(true);
            api.createMilestoneRecord(selection.id, selection.email)
              .then(response => {
                setIsProcessing(false);
                setUsers([response, ...users]);
              })
              .catch(error => {
                setErrorCode(error.response.status);
                setIsAddError(true);
                setIsProcessing(false);
              })
          }} /> */}

                <BaseModal
                    toDisplay={isAddError}
                    title="Add Participant"
                    confirmTitle="Ok"
                    className="class-unclaim-error"
                    onConfirm={() => setIsAddError(false)}>
                    {errorCode == 404 ? (
                            <>
                                <label className="ModalLabel">
                                    It appears that this participant has no tracking information.
                                </label>
                                <p>Please confirm that this participant has answered the quiz, passed eligibility checks
                                    and then contact <a href="mailto:support@fruitstreet.com">support@fruitstreet.com</a></p>
                            </>
                        ) :
                        <>
                            <label className="ModalLabel">
                                The participant that you are trying to add is already on the list.
                                Please choose another participant to add instead.
                            </label>
                        </>
                    }
                </BaseModal>

                {/* --------------------------------------------- Delete modal --------------------------------------------- */}
                <Modal>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete this Record?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete milestone tracking records for
                        <strong>{!isUndefined(toDelete) ? ` ${toDelete.name}` : ''}</strong>?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            disabled={!isUndefined(toDelete) ? toDelete.busy : false}
                            onClick={() => setToDelete(undefined)}>
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            loading={!isUndefined(toDelete) ? toDelete.busy : false}
                            onClick={() => deleteUser(toDelete!.id)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {patientWithError
                    ? <ClaimErrorModal
                        participant={patientWithError.patient}
                        milestone={patientWithError.milestone}
                        onClose={() => setPatientWithError(undefined)}
                        onSubmit={(patient) => {
                            const req = new ResendClaimRequest();
                            req
                                .send({
                                    patientId: patient.id,
                                    milestone: patientWithError.milestone,
                                    patient,
                                })
                                .then(() => {
                                    setPatientWithError(undefined);
                                    toast.success('Claim resubmitted.');
                                });
                        }}
                    />
                    : null}

                {(isFetching && users.length == 0) ?
                    <div className="center-container">
                        <Spinner />
                    </div> :
                    renderList()
                }
            </Container>
        </>
    );
}
