import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import { practiceConstants } from '../utils';
import { FORMAT_MMMM_YYYY } from '../utils/constants';

import {
  DietitianPaymentData,
  DietitianPaymentUserData,
  DietitianPaymentSessionData
} from '../types/dietitian-payments';

import APIClient, { autoClose } from '.';

export default class MonthlyInvoicesAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchMonthlyInvoices = (
    queryString: string
  ): Promise<[DietitianPaymentData[], number, number, number]> => (
      new Promise(async (resolve, reject) => {
        try {
          const url = `/monthly-invoice${queryString}`;
          const response = await this.instance.get(url);
          const { data, meta } = response.data;
          const { from, to, total } = meta;
          const list = data.map((item: any): DietitianPaymentData => {
            const data: DietitianPaymentData = {
              id: item.id,
              name: item.period,
              participants: item.participants,
              period: item.period,
              total: 0,
              status: item.status,
            };

            if (item.dietitian) {
              data['dietitianId'] = item.dietitian.id;
              data['name'] = `${item.dietitian.first_name} ${item.dietitian.last_name}`;
            }

            if (item.milestones) {
              data.milestones = {
                one: item.milestones['1'] || 0,
                two: item.milestones['2'] || 0,
                three: item.milestones['3'] || 0,
                four: item.milestones['4'] || 0,
              };

              data.total = item.milestones.total || 0;
            } else if (item.sessions) {
              data.sessions = { count: item.sessions.count || 0 };
              data.total = item.sessions.total || 0;
            }

            return data;
          })

          resolve([list, from, to, total]);
        } catch (error) {
          if (!axios.isCancel(error)) {
            const message = error.response.data.message || error.response.message;

            toast.error(message, { autoClose });
            reject();
          }
        }
      })
    )

  fetchMonthlyInvoiceInfo = (
    id: string,
    company?: string,
    year?: string,
  ): Promise<DietitianPaymentData> => (
      new Promise(async (resolve, reject) => {
        try {
          const endpoint = 'monthly-invoice';
          let url = `/${endpoint}/${id}`;

          if (company && year) {
            url = `/${endpoint}/${company}/${year}`;
          }

          const response = await this.instance.get(url);
          const item = response.data.data;
          const data: DietitianPaymentData = {
            id: item.id,
            name: year ? item.period : moment(`${item.period}-01`).format(FORMAT_MMMM_YYYY),
            participants: item.participants,
            period: item.period,
            total: 0,
            status: item.status,
          };

          if (item.dietitian) {
            data['dietitianId'] = item.dietitian.id;
            data['name'] = `${item.dietitian.first_name} ${item.dietitian.last_name}`;
          }

          if (item.milestones) {
            data.milestones = {
              one: item.milestones['1'] || 0,
              two: item.milestones['2'] || 0,
              three: item.milestones['3'] || 0,
              four: item.milestones['4'] || 0,
            };

            data.total = item.milestones.total || 0;
          } else if (item.sessions) {
            data.sessions = { count: item.sessions.count || 0 };
            data.total = item.sessions.total || 0;
          }

          resolve(data);
        } catch (error) {
          if (!axios.isCancel(error)) {
            const message = error.response.data.message || error.response.message;

            toast.error(message, { autoClose });
            reject();
          }
        }
      })
    )

  private fetchMonthlyInvoiceInfoList = (
    root: string,
    id: string,
    page: number,
    milestone: number,
    order: string,
    year?: string,
  ): Promise<Promise<AxiosResponse<any>>> => (
      new Promise((resolve, reject) => {
        try {
          const endpoint = 'monthly-invoice';
          const idKey = 'monthly_invoice_id';
          const rootUrl = `/${endpoint}/${root}`;
          let url = `${rootUrl}?${idKey}=${id}&order=${order}&page=${page}`;

          if (milestone > 0) {
            url = `${url}&milestone=${milestone}`;
          }

          if (year) {
            url = `${rootUrl}/${year}?order=${order}&page=${page}`;
          }

          resolve(this.instance.get(url));
        } catch (error) {
          if (!axios.isCancel(error)) {
            const message = error.response.data.message || error.response.message;

            toast.error(message, { autoClose });
            reject();
          }
        }
      })
    )

  fetchMonthlyInvoiceParticipantsList = (
    id: string,
    page: number,
    milestone: number,
    order: string,
    year?: string,
  ): Promise<[DietitianPaymentUserData[], number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.fetchMonthlyInvoiceInfoList(
          'participants',
          id,
          page,
          milestone,
          order,
          year,
        );
        const items = response.data.data.map((item): DietitianPaymentUserData => ({
          id: item.id,
          lastName: item.last_name,
          firstName: item.first_name,
          employer: item.employer,
          insurance: item.insurance,
          achieved: item.achieved && new Date(item.achieved),
          milestone: item.milestone,
          healthPlanId: item.health_plan_id,
          paymentDue: parseFloat(item.payment_due),
        }));

        resolve([items, response.data.meta.total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchMonthlyInvoiceSessionsList = (
    id: string,
    page: number,
    order: string,
    year?: string,
  ): Promise<[DietitianPaymentSessionData[], number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.fetchMonthlyInvoiceInfoList(
          'sessions',
          id,
          page,
          -1,
          order,
          year,
        );
        const items = response.data.data.map((item): DietitianPaymentSessionData => ({
          className: item.class_name,
          sessionName: item.session_name,
          startDate: new Date(item.session_start),
          endDate: new Date(item.session_end),
        }));

        resolve([items, response.data.meta.total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  updateMonthlyInvoiceStatus = async (
    id: string,
    status: string,
    type: string
  ) => {
    return this.instance.patch(`monthly-invoice/${id}`, { status, type });
  }

  exportMonthlyInvoice = (id: string, milestones: boolean[]): Promise<any> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/monthly-invoice/export?monthly_invoice_id=${id}`;

        for (let i = 0; i < milestones.length; i++) {
          if (i == 0) {
            continue;
          }

          if (milestones[i]) {
            url = `${url}&milestone[]=${i}`;
          }
        }

        const response = await this.instance.get(url, { responseType: 'blob' });

        resolve(response.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
