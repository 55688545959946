import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Table from 'react-bootstrap/Table';

import SuperuserAPIClient from '../../../api/superuser';

import { MilestoneData, MilestoneStatus } from '../../../types/milestones';

import { FORMAT_MM_DD_YYYY } from '../../../utils/constants';

import Button from '../../Button';
import Spinner from '../../Spinner';

import styles from './index.module.scss';

interface Props {
  item: MilestoneData
  readOnly?: boolean;
  onDelete: (participant: MilestoneData) => void;
}

export default function ExpandedRow(props: Props) {
  const { item, readOnly, onDelete } = props;
  const api = new SuperuserAPIClient();

  const [isBusy, setIsBusy] = useState(true);
  const [details, setDetails] = useState<MilestoneData>(item);

  useEffect(() => {
    return () => {
      api.unsubscribe();
    }
  }, [])

  // Fetch detailed user info from the API
  useEffect(() => {
    setIsBusy(true);
    api.fetchMilestoneUserInfo(item)
      .then((resp) => {
        setIsBusy(false);
        setDetails(resp);
      })
      .catch(() => {
        setIsBusy(false);
        setDetails(item);
      });
  }, [item]);

  const canDelete =
    item.milestoneOne === MilestoneStatus.NotAchieved &&
    item.milestoneTwo === MilestoneStatus.NotAchieved &&
    item.milestoneThree === MilestoneStatus.NotAchieved &&
    item.milestoneFour === MilestoneStatus.NotAchieved;

  const address = (
    `${details.apartmentNo ? `${details.apartmentNo} ` : ''}${details.address}, ${details.city}, ${details.state}, ${details.zipCode}`
  )

  return (
    <tr>
      <td colSpan={12} className={styles.expandedTableWrap}>
        {isBusy
          ? <Spinner className={styles.spinner} noText />
          : <Table className={styles.table}>
              <tbody className={styles.tbody}>
                <tr>
                  <th className={styles.thMilestone} colSpan={20}>
                    Milestone 1&nbsp;
                    <span>
                      {item.milestoneOneDate ? `${moment(item.milestoneOneDate).format(FORMAT_MM_DD_YYYY)}` : 'No data'}
                    </span>
                    Milestone 2&nbsp;
                    <span>
                      {item.milestoneTwoDate ? `${moment(item.milestoneTwoDate).format(FORMAT_MM_DD_YYYY)}` : 'No data'}
                    </span>
                    Milestone 3&nbsp;
                    <span>
                      {item.milestoneThreeDate ? `${moment(item.milestoneThreeDate).format(FORMAT_MM_DD_YYYY)}` : 'No data'}
                    </span>
                    Milestone 4&nbsp;
                    <span>
                      {item.milestoneFourDate ? `${moment(item.milestoneFourDate).format(FORMAT_MM_DD_YYYY)}` : 'No data'}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th className={styles.cellLabel}>Dietitian Assigned</th>
                  <th className={styles.cellLabel}>Class ID</th>
                  <th className={styles.cellLabel}>Email</th>
                  <th className={styles.cellLabel}>Date of Birth</th>
                  <th className={styles.cellLabel}>Gender</th>
                </tr>
                <tr className={styles.trow}>
                  <td
                    className={styles.cell}
                    style={{
                      maxWidth: 150,
                      whiteSpace: 'pre-wrap',
                    }}>
                    {details.dietitian || '-'}
                  </td>
                  <td className={styles.cell}>{details.classId || '-'}</td>
                  <td className={styles.cell}>{details.email}</td>
                  <td className={styles.cell}>
                    {moment(details.birthdate).format(FORMAT_MM_DD_YYYY)}
                  </td>
                  <td className={styles.cell}>{details.gender}</td>
                </tr>
                <tr>
                  <th className={styles.cellLabel}>Insurance</th>
                  <th className={styles.cellLabel}>Health Plan ID</th>
                  <th className={styles.cellLabel}>Address</th>
                </tr>
                <tr>
                  <td className={styles.cell}>{details.insurance || '-'}</td>
                  <td className={styles.cell}>{details.healthPlanId || '-'}</td>
                  <td
                    className={styles.cell}
                    style={{ maxWidth: 250, whiteSpace: 'pre-wrap' }}>
                    {address}
                  </td>
                </tr>
              </tbody>

              {!readOnly
                ? <tfoot>
                    <tr>
                      <td colSpan={5}>
                        <Button
                          variant="danger"
                          size="sm"
                          // disabled={!canDelete}
                          onClick={() => onDelete(item)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                : null}
            </Table>}
      </td>
    </tr>
  );
}
