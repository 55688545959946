import moment from 'moment';
import axios from 'axios';
import { isUndefined } from 'lodash-es';
import { toast } from 'react-toastify';

import { classItemStatus, practiceConstants } from '../utils';
import {
  CLASS_STATUS_LIST,
  MAX_PARTICIPANTS,
  MIN_PARTICIPANTS,
} from '../utils/constants';
import {
  ClassItem,
  Participant,
  Session,
} from '../utils/types';

import APIClient, { autoClose } from './index';

export default class DietitianAPIClient {
  private instance: APIClient;

  constructor() {
    this.instance = new APIClient(practiceConstants().baseURL);
  }

  fetchClasses = async (): Promise<ClassItem[]> => (
    new Promise(async (resolve, reject) => {
      try {
        const resp = await this.instance.get('/classes.json?fields=id,name,max_participants,participant_ids,start,end,host_ids,max_hosts');
        const data = resp.data.data;
        const classes: ClassItem[] = data
          .filter((item: any) => item.start < 10000000000) // @todo Remove classes that has very large timestamps
          .map((item: any) => {
            const classItem: ClassItem = {
              id: item.id,
              name: item.name,
              date: new Date(item.start * 1000),
              endDate: new Date(item.end * 1000),
              time: {
                title: '',
                value: '',
              },
              participants: item.participant_ids.map((p: any) => ({ id: p, name: '', avatar: '' })), // @todo
              minParticipants: item.min_participants || MIN_PARTICIPANTS, // @todo
              maxParticipants: item.max_participants || MAX_PARTICIPANTS, // @todo
              status: CLASS_STATUS_LIST.Open,
              isScheduled: false,
              dietitianId: item.host_ids[0] || undefined,
              dietitianIds: item.host_ids,
              maxDietitians: item.max_hosts,
            };

            classItem.status = classItemStatus(classItem);

            return classItem;
          })
          .sort((item1: ClassItem, item2: ClassItem) => (
            moment(item1.date).isBefore(item2.date) ? -1 : 1
          ));

        resolve(classes);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchClass = async (classId: string): Promise<ClassItem> => (
    new Promise(async (resolve, reject) => {
      try {
        const resp = await this.instance.get(`/classes/${classId}`);
        const sessions = await this.instance.get(`/classes/${classId}/sessions?field_list=full`);
        const data = resp.data.data;
        const classItem: ClassItem = {
          id: data.id,
          name: data.name,
          date: new Date(data.start * 1000),
          endDate: new Date(data.end * 1000),
          time: {
            title: '',
            value: '',
          },
          participants: data.participants.map((p: any): Participant => ({
            id: p.id,
            vseeid: p.vseeid,
            fullName: p.full_name,
            email: p.email,
            username: p.username,
            avatar: '',
          })),
          sessions: data.sessions.map((session: any, index: number): Session => {
            const date = new Date(session.start * 1000);
            return {
              id: +session.id,
              date,
              // day: format(date, 'dddd'),
              // time: format(date, 'h:mm a'),
              name: !isUndefined(session.name) ? session.name : '',
              // slide: {
              //   classDay: session.start,
              //   number: index + 1,
              //   name: !isUndefined(session.name) ? session.name : '',
              // },
            };
          }),
          minParticipants: data.min_participants || MIN_PARTICIPANTS,
          maxParticipants: data.max_participants || MAX_PARTICIPANTS,
          status: CLASS_STATUS_LIST.Open,
          isScheduled: false,
          dietitianId: data.host_ids[0] || undefined,
        };

        if (sessions.data.data && sessions.data.data.length > 0) {
          classItem.sessions = sessions.data.data
            .map(item => ({
              id: +item.id,
              name: item.name,
              date: new Date(item.start * 1000)
            }))
            .sort((item1: Session, item2: Session) => (
              moment(item1.date).isBefore(item2.date) ? -1 : 1
            ));
        }

        classItem.status = classItemStatus(classItem);

        resolve(classItem);
      } catch (error) {
        if (!axios.isCancel(error)) {
          toast.error(error.response.data.message || error.response.message);
          reject(error);
        }
      }
    })
  )

  claimClass = async (classId: string) => this.instance.post(`/classes/${classId}/join`, {});

  unclaimClass = async (classId: string) => this.instance.post(`/classes/${classId}/leave`, {});
}
