import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import {
  faCalendar,
  faFileInvoiceDollar,
  faCashRegister,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import ConsumerPaymentsAPIClient from '../../../../api/consumer-payments';

import AppNav from '../../../../components/AppNav';
import Button from '../../../../components/Button';
import Header from '../../../../components/Header';
import NoResults from '../../../../components/NoResults';
import Spinner from '../../../../components/Spinner';
import TotalResults from '../../../../components/TotalResults';

import { useAPIPagination } from '../../../../hooks/pagination';

import {
  ConsumerPayment,
  ConsumerPaymentUser,
} from '../../../../types/consumer-payments';

import { practiceConstants } from '../../../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_MM_DD_YYYY,
  PER_PAGE,
} from '../../../../utils/constants';
import { formatCurrency } from '../../../../utils/formatting';

import ConsumerPaymentDetailsList from './List';

interface Props extends RouteComponentProps {}

export default function ConsumerPaymentDetails(props: Props) {
  const api = new ConsumerPaymentsAPIClient(BASE_URL_INGENUITY);
  const userId = props.match.params['id'];
  const { prevPath } = props.location.state as any;

  // Dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingList, setIsFetchingList] = useState(true);
  const [info, setInfo] = useState<ConsumerPaymentUser>();
  const [payments, setPayments] = useState<ConsumerPayment[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedPayments,
    setCurrentPage
  ] = useAPIPagination(payments, PER_PAGE, total);

  useEffect(() => {
    setIsFetching(true);
    api.fetchConsumerPaymentUserDetails(userId)
      .then(response => {
        setIsFetching(false);
        setInfo(response);
      })
      .catch(() => {
        setIsFetching(false);
      })
  }, [])

  useEffect(() => {
    setIsFetchingList(true);
    api.fetchConsumerPaymentUserPayments(userId, currentPage + 1)
      .then(response => {
        const [l, i, j, t] = response;

        setIsFetchingList(false);
        setPayments(l);
        setFrom(i);
        setTo(j);
        setTotal(t);
      })
      .catch(() => {
        setIsFetchingList(false);
        setPayments([]);
        setFrom(0);
        setTo(0);
        setTotal(0);
      })
  }, [currentPage])

  const renderInfo = () => (
    <div className={`${practiceConstants().className} border-top`}>
      {!isFetching && info ?
        <Table className="info-container">
          <thead>
            <tr className="header">
              <td colSpan={20}>
                <div className="row">
                  <label className="title">
                    {info.firstName} {info.lastName}
                  </label>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <FontAwesomeIcon icon={faCalendar} />
                Landing Date
              </th>
              <th>
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                Payment Option
              </th>
              <th>
                <FontAwesomeIcon icon={faCashRegister} />
                Payment Method
              </th>
              <th>
                <FontAwesomeIcon icon={faDollarSign} />
                Amount Paid to Date
              </th>
            </tr>
            <tr>
              <td className="data">
                {moment(info.signupDate).format(FORMAT_MM_DD_YYYY)}
              </td>
              <td className={`data${info.paymentOption ? '' : ' center'}`}>
                {info.paymentOption || '-'}
              </td>
              <td className="data">
                {info.paymentMethod}
              </td>
              <td className="data">
                {formatCurrency(info.balance)}
              </td>
            </tr>
          </tbody>
        </Table>
        :
        <div className="info-container loader">
          <Spinner />
        </div>
      }
    </div>
  )

  const renderList = () => {
    if (pagedPayments && pagedPayments.length == 0) {
      return <NoResults subject="payments" />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <ConsumerPaymentDetailsList
          currentPage={currentPage}
          isFetching={isFetchingList}
          numPages={numPages}
          pagedPayments={pagedPayments}
          setCurrentPage={setCurrentPage}
          setIsFetching={setIsFetchingList}
        />
      </>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header titleLg={6} title="All Payment">
          <LinkContainer to={prevPath}>
            <Button variant="primary">Go back to class list</Button>
          </LinkContainer>
        </Header>

        {renderInfo()}

        {(isFetching && payments.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> :
          renderList()
        }
      </Container>
    </>
  )
}
