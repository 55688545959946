import * as React from 'react';
import { Grid, Col, Row } from 'react-flexbox-grid';

import ParticipantRow from '../ParticipantRow';

import { groupParticipants } from '../../utils';
import { Participant } from '../../utils/types';

import BaseModal from '../BaseModal';

import './index.scss';

const COLUMN = 2;

interface Props {
  onClose: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  participants: Participant[],
  title: string;
  toShowModal: boolean;
}

class ParticipantModal extends React.Component<Props> {
  renderColumns = (list: Participant[]) => (
    list.map((item: Participant, index: number) => (
      <Col
        key={item.id}
        className="ParticipantsModal-column"
        xs={6}>
        <ParticipantRow
          sm
          participant={item} />
      </Col>
    ))
  )

  renderList = (list: Participant[]) => {
    const groupedList = groupParticipants(list, COLUMN);

    return (list.length > 0 ?
      groupedList.map((l: Participant[], index: number) => (
        <Row
          key={index}
          className="ParticipantsModal-row"
          start="xs">
          {this.renderColumns(l)}
        </Row>
      )) : (
        <label className="ParticipantsModal-infoLabel">
          No participants
        </label>
      )
    )
  }

  render() {
    const {
      onClose,
      participants,
      title,
      toShowModal,
    } = this.props;

    return (
      <BaseModal
        confirmTitle="Ok"
        title={title}
        toDisplay={toShowModal}
        onConfirm={onClose}>
        <Grid className="ParticipantsModal-container">
          {this.renderList(participants)}
        </Grid>
      </BaseModal>
    );
  }
}

export default ParticipantModal;
