import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Doughnut, defaults } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  faCheckCircle,
  faTimesCircle,
  faTrophy,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import SuperuserAPIClient from '../../../api/superuser';

import AppNav from '../../../components/AppNav';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import MilestoneTrackingList from '../../../components/MilestoneTrackingList';
import NoResults from '../../../components/NoResults';
import PerPageSelect from '../../../components/PerPageSelect';
import TotalResults from '../../../components/TotalResults';

import { useAPIPagination } from '../../../hooks/pagination';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import {
  MilestoneAnalyticsData,
  MilestoneData,
  MilestoneStatus
} from '../../../types/milestones';

import {
  abbreviateNumber,
  practiceConstants,
  queryStringParams,
  scrollToNode
} from '../../../utils';
import {
  BASE_URL_INGENUITY,
  PER_PAGE,
  PER_PAGE_SELECTIONS,
  ROUTES,
  FORMAT_YYYY_MM_DD,
} from '../../../utils/constants';
import { formatNumber } from '../../../utils/formatting';

import './index.scss';

enum Milestones {
  One = 'Milestone 1',
  Two = 'Milestone 2',
  Three = 'Milestone 3',
  Four = 'Milestone 4',
}

type StatColumnData = {
  color: string;
  icon: IconDefinition;
  title: string;
  value: number;
  percentage?: number;
}

type StatRowData = {
  title: string;
  milestoneOne: number;
  milestoneTwo: number;
  milestoneThree: number;
  milestoneFour: number;
}

interface Props extends RouteComponentProps { }

export default function MilestoneAnalytics(props: Props) {
  const SHOW_ALL = { title: 'Show All', value: '' };
  const STATUS_FILTER = [
    { title: 'Not Achieved', value: MilestoneStatus.NotAchieved },
    { title: 'Adjudicated', value: MilestoneStatus.Adjudicated },
    { title: 'Acknowledged', value: MilestoneStatus.Acknowledged },
    { title: 'Achieved', value: MilestoneStatus.Achieved },
    { title: 'Claim Submitted', value: MilestoneStatus.Claimed },
    { title: 'Paid', value: MilestoneStatus.Paid },
    { title: 'Paid in Full', value: MilestoneStatus.PaidInFull },
    { title: 'Rejected', value: MilestoneStatus.Rejected },
    { title: 'Error', value: MilestoneStatus.Error },
    SHOW_ALL
  ];

  const api = new SuperuserAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const milestones = [Milestones.One, Milestones.Two, Milestones.Three, Milestones.Four];
  const {
    chartColors: colors,
    className,
    defaultEmployer,
    employers,
  } = practiceConstants();

  // Filter states
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value == query[QueryKeys.Company]
  ))[0] || defaultEmployer);
  const [statusFilterOne, setStatusFilterOne] = useState(STATUS_FILTER.filter(e => (
    e.value == query[QueryKeys.MilestoneOne]
  ))[0] || SHOW_ALL);
  const [statusFilterTwo, setStatusFilterTwo] = useState(STATUS_FILTER.filter(e => (
    e.value == query[QueryKeys.MilestoneTwo]
  ))[0] || SHOW_ALL);
  const [statusFilterThree, setStatusFilterThree] = useState(STATUS_FILTER.filter(e => (
    e.value == query[QueryKeys.MilestoneThree]
  ))[0] || SHOW_ALL);
  const [statusFilterFour, setStatusFilterFour] = useState(STATUS_FILTER.filter(e => (
    e.value == query[QueryKeys.MilestoneFour]
  ))[0] || SHOW_ALL);
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );

  // States
  const [claimAmounts, setClaimAmounts] = useState<{ [id: string]: number }>({});
  const [currentEmployer, setCurrentEmployer] = useState(employer);

  // Dataset states
  const initialChartData: MilestoneAnalyticsData = {
    total: 0,
    milestoneOne: 0,
    milestoneTwo: 0,
    milestoneThree: 0,
    milestoneFour: 0
  };
  const [chartData, setChartData] = useState<MilestoneAnalyticsData>();
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingChart, setIsFetchingChart] = useState(true);
  const [perPage, setPerPage] = useState(
    query[QueryKeys.Rows] ? +query[QueryKeys.Rows] : PER_PAGE_SELECTIONS[0]
  );
  const [users, setUsers] = useState<MilestoneData[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, perPage, total, queryPage);

  const milestoneFilterValue = milestone => (
    milestone == SHOW_ALL.value ? undefined : milestone
  )
  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.Company]: employer.value,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.MilestoneOne]: milestoneFilterValue(statusFilterOne.value),
    [QueryKeys.MilestoneTwo]: milestoneFilterValue(statusFilterTwo.value),
    [QueryKeys.MilestoneThree]: milestoneFilterValue(statusFilterThree.value),
    [QueryKeys.MilestoneFour]: milestoneFilterValue(statusFilterFour.value),
    [QueryKeys.Rows]: perPage,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchMilestoneTracking(employer.value, queryString)
      .then(response => {
        const [data, i, j, t, amounts] = response;
        setIsFetching(false);
        setUsers(data);
        setFrom(i);
        setTo(j);
        setTotal(t);
        setClaimAmounts(amounts);
      })
      .catch(() => {
        setIsFetching(false);
        setUsers([]);
        setTotal(0);
        setFrom(0);
        setTo(0);
        setClaimAmounts({});
      })
  }, [
    employer,
    statusFilterOne,
    statusFilterTwo,
    statusFilterThree,
    statusFilterFour,
    currentPage,
    perPage,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setIsFetchingChart(true);
    props.history.replace(queryString);
    api.fetchMilestoneAnalytics(employer.value, startDate, endDate)
      .then(response => {
        setIsFetchingChart(false);
        setChartData(response);
        setCurrentEmployer(employer);
      })
      .catch(() => {
        setIsFetchingChart(false);
        setChartData(initialChartData);
        setCurrentEmployer(employer);
      })
  }, [employer, startDate, endDate])

  const getPercentage = (value: number) => {
    if (chartData) {
      return chartData.total > 0 ? Math.floor((value / chartData.total) * 100) : undefined;
    }

    return undefined;
  }

  const renderFilterChart = () => (
    <div className={`filters-section border-bottom ${className}`}>
      <Row>
        <Col xs={12} lg={2}>
          <h3 className="primary-title">Filter Chart</h3>
        </Col>
        <Col xs={12} lg={8} className="controls">
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={(item) => setEmployer(item)} />
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Quiz Date"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0])
              setEndDate(dates[dates.length - 1])
            }}
            onStartDateSelection={date => setStartDate(date)}
            onEndDateSelection={date => setEndDate(date)}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderFilterList = () => (
    <div id="MilestoneAnalytics-filters" className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={1}>
          <h3 className="primary-title">
            Filters
            </h3>
        </Col>
        <Col
          xs={12}
          lg={10}
          className="controls"
          style={{ marginBottom: 0 }}>
          <Row>
            <label className="info primary">
              Milestone:
              </label>
            <Col>
              <Dropdown
                items={STATUS_FILTER}
                selectedItem={statusFilterOne}
                title="1"
                onItemSelection={(item) => {
                  setCurrentPage(0);
                  setStatusFilterOne(item);
                }} />
            </Col>
            <Col>
              <Dropdown
                items={STATUS_FILTER}
                selectedItem={statusFilterTwo}
                title="2"
                onItemSelection={(item) => {
                  setCurrentPage(0);
                  setStatusFilterTwo(item);
                }} />
            </Col>
            <Col>
              <Dropdown
                items={STATUS_FILTER}
                selectedItem={statusFilterThree}
                title="3"
                onItemSelection={(item) => {
                  setCurrentPage(0);
                  setStatusFilterThree(item);
                }} />
            </Col>
            <Col>
              <Dropdown
                items={STATUS_FILTER}
                selectedItem={statusFilterFour}
                title="4"
                onItemSelection={(item) => {
                  setCurrentPage(0);
                  setStatusFilterFour(item);
                }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

  const renderGraph = () => {
    if (!chartData) {
      return (
        <div className="MilestoneAnalytics-graphContainer">
          {isFetchingChart &&
            <div className="MilestoneAnalytics-centerContainer">
              <Spinner />
            </div>
          }
        </div>
      )
    }

    const datasets = (chartData.total == 0 || chartData.milestoneOne == 0) ? {
      data: [100],
      backgroundColor: [chartData.milestoneOne == 0 ? '#34495E' : '#BBBBBB'],
      // borderColor: ['#AAAAAA'],
      // borderWidth: 1,
    } : {
      data: [
        chartData.milestoneOne,
        chartData.milestoneTwo,
        chartData.milestoneThree,
        chartData.milestoneFour],
      backgroundColor: colors,
      // borderWidth: 0,
    };
    const data = {
      labels: milestones,
      datasets: [datasets],
    };
    const chartOptions: ChartOptions = {
      animation: {
        easing: 'easeInOutQuad',
        duration: 500
      },
      maintainAspectRatio: true,
      responsive: true,
      tooltips: {
        displayColors: false,
      }
    };

    // Set defaults
    defaults['global']['defaultFontFamily'] = 'Open Sans';

    return (
      <div className={`MilestoneAnalytics-graphContainer${isFetchingChart ? ' fetching' : ''}`}>
        <div className="graph">
          <Doughnut
            data={data}
            width={200}
            legend={{ display: false }}
            options={chartOptions} />
          <div className="centerInfo">
            <label style={{ fontSize: chartData.total == 0 ? 22 : 39 }}>
              {abbreviateNumber(chartData.total) || 'No Data'}
            </label>

            {(chartData.total && chartData.total > 0) ? <label>Total</label> : <label />}
          </div>
          <label>Milestone Completion Breakdown</label>
        </div>
        <div className="graphInfo">
          <label className="header">{currentEmployer.title}</label>
          <div className="totalContainer">
            {renderStatColumn({
              color: '#34495E',
              title: 'Total Quiz Results',
              icon: faUsers,
              value: chartData.total,
            }, true)}
            {renderStatColumn({
              color: colors[0],
              title: milestones[0],
              icon: faTrophy,
              value: chartData.milestoneOne,
              percentage: getPercentage(chartData.milestoneOne)
            })}
            {renderStatColumn({
              color: colors[1],
              title: milestones[1],
              icon: faTrophy,
              value: chartData.milestoneTwo,
              percentage: getPercentage(chartData.milestoneTwo)
            })}
            {renderStatColumn({
              color: colors[2],
              title: milestones[2],
              icon: faTrophy,
              value: chartData.milestoneThree,
              percentage: getPercentage(chartData.milestoneThree)
            })}
            {renderStatColumn({
              color: colors[3],
              title: milestones[3],
              icon: faTrophy,
              value: chartData.milestoneFour,
              percentage: getPercentage(chartData.milestoneFour)
            })}
            {/* {renderStatColumn({
              color: '#EB3939',
              title: RISK.High,
              icon: faTimesCircle,
              value: chartData.highRisk,
            }, true)}
            {renderStatColumn({
              color: '#53CB7A',
              title: RISK.Low,
              icon: faCheckCircle,
              value: chartData.lowRisk,
            }, true)} */}
          </div>

          {/* {renderStatsRow("red", {
                title: RISK.High,
                milestoneOne: chartData.milestoneOne,
                milestoneTwo: chartData.milestoneTwo,
                milestoneThree: chartData.milestoneThree,
                milestoneFour: chartData.milestoneFour,
              })} */}
        </div>
      </div>
    )
  }

  const renderList = () => {
    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          to={to}
          from={from}
        />
        <MilestoneTrackingList
          isMilestonesOnly
          viewing
          claimAmounts={claimAmounts}
          currentPage={currentPage}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={pagedUsers}
          setCurrentPage={setCurrentPage}
          setIsFetching={setIsFetching}
        />
        <PerPageSelect
          pageArr={PER_PAGE_SELECTIONS}
          currentPerPage={perPage}
          onChangePerPage={p => {
            scrollToNode('MilestoneAnalytics-filters');
            setCurrentPage(0);
            setPerPage(p);
          }}
        />
      </>
    );
  }

  const renderStatColumn = (data: StatColumnData, noPercentage?: boolean) => (
    <div className="statsColumn">
      <label>
        <FontAwesomeIcon icon={data.icon} />
        &nbsp;&nbsp;{data.title}
      </label>
      <label className="value" style={{ color: data.color }}>
        {formatNumber(data.value)}
      </label>

      {/* {!noPercentage &&
        <label className="percentage">
          {data.percentage ? `${data.percentage}%` : ''}
        </label>
      } */}
    </div>
  )

  const renderStatsRow = (className: string, data: StatRowData) => (
    <div className="statsRow">
      <label style={{ width: 85 }}>
        <FontAwesomeIcon icon={className == "red" ? faTimesCircle : faCheckCircle} />
        &nbsp;&nbsp;{data.title}
      </label>

      <label>
        <FontAwesomeIcon icon={faTrophy} />&nbsp;1&nbsp;&nbsp;
        <label className={className}>{`(${formatNumber(data.milestoneOne)})`}</label>
      </label>

      <label>
        <FontAwesomeIcon icon={faTrophy} />&nbsp;2&nbsp;&nbsp;
        <label className={className}>{`(${formatNumber(data.milestoneTwo)})`}</label>
      </label>

      <label>
        <FontAwesomeIcon icon={faTrophy} />&nbsp;3&nbsp;&nbsp;
        <label className={className}>{`(${formatNumber(data.milestoneThree)})`}</label>
      </label>

      <label>
        <FontAwesomeIcon icon={faTrophy} />&nbsp;4&nbsp;&nbsp;
        <label className={className}>{`(${formatNumber(data.milestoneFour)})`}</label>
      </label>
    </div>
  )

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Milestone Analytics"
        />

        {renderFilterChart()}
        {renderGraph()}
        {renderFilterList()}

        {(isFetching && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> : renderList()
        }
      </Container>
    </>
  )
}
