import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Container from 'react-bootstrap/Container';
import {
  injectStripe,
  Elements,
  ReactStripeElements,
  StripeProvider,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements';
import { RouteComponentProps, withRouter } from 'react-router';
import LinkedInTag from 'react-linkedin-insight';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import _ from 'lodash';

import ParticipantAPIClient from '../../api/participant';

import AppNav from '../../components/AppNav';
import BaseModal from '../../components/BaseModal';
import CustomButton from '../../components/CustomButton';
import Spinner from '../../components/Spinner';

import useDebounce from '../../hooks/useDebounce';

import { store } from '../../redux';
import { logout } from '../../redux/actions/Authentication';

import {
  isEligibleForSignup,
  loaderComponent,
  quizModalComponent,
  stripeModalContentForStatus,
} from '../../utils';
import {
  ANALYTICS,
  QUIZ_URL_GENERAL,
  ROUTES,
  SESSION_KEY_ELIGIBILITY,
  SESSION_KEY_IS_NOT_COMPANY_SPONSORED,
  SESSION_KEY_STAGE_TRACKING,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_STATUS as StripeStatus,
  US_STATES_SELECTIONS,
  SESSION_KEY_URL_ORIGIN,
  URL_ORIGIN_WALGREENS,
  VALIDATION_REQUIRED,
} from '../../utils/constants';
import { formatCurrency } from '../../utils/formatting';
import {
  fbPixelTrackEvent,
  fbPixelTrackPageViewEvent,
  gtmEvent,
} from '../../utils/tracking';
import {
  DropdownItem,
  StripePlan,
  StripeVoucher,
} from '../../utils/types';

import './index.scss';
import './stripe-form.scss';

enum FormKeys {
  CardNumber = 'card_number',
  CardExpiration = 'card_expiration',
  CardCVC = 'card_cvc',
  CardName = 'card_name',
  Voucher = 'voucher',
}

type ColumnData = {
  key: string;
  label: string;
  placeholder: string;
  mask?: string;
  type: string;
  column?: number;
  disabled?: boolean;
}

interface Props extends ReactStripeElements.InjectedStripeProps, RouteComponentProps {}

const MONTHLY_FEE = 19.99;
const ANNUAL_FEE = 499.99;

const ParticipantPayment = (props: Props) => {
  const api = new ParticipantAPIClient();
  const defaultState = US_STATES_SELECTIONS[0];
  const participantId = sessionStorage.getItem(SESSION_KEY_STAGE_TRACKING) || '';

  let labelNode: HTMLLabelElement | null = null;

  // States
  const [formData, setFormData] = useState<{ [id: string]: string }>({
    [FormKeys.CardName]: '',
    state: defaultState.value,
  });

  // Form states
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [formErrors, setFormErrors] = useState<{ [id: string]: string }>({});
  const [isInitial, setIsInitial] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedState, setSelectedState] = useState<DropdownItem>(defaultState);
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  // Stripe states
  const [invoiceId, setInvoiceId] = useState<string | undefined>();
  const [isFetchingPlans, setIsFetchingPlans] = useState(true);
  const [isMonthly, setIsMonthly] = useState(true);
  const [plans, setPlans] = useState<StripePlan[]>();
  const [statusType, setStatusType] = useState<StripeStatus>(StripeStatus.None);

  // Voucher states
  const [isProcessingVoucher, setIsProcessingVoucher] = useState(false);
  const [voucher, setVoucher] = useState<StripeVoucher>();
  const [voucherError, setVoucherError] = useState<String>();
  const [voucherSearch, setVoucherSearch] = useState('');

  const debouncedVoucherSearch = useDebounce(voucherSearch, 500);
  const isVoucherValid = voucher && voucher.isValid;

  useEffect(() => {
    // Logout existing user
    store.dispatch(logout());

    const [isValid, quizAnswers] = isEligibleForSignup(1);
    const origin = sessionStorage.getItem(SESSION_KEY_URL_ORIGIN);

    if (!isValid || origin == URL_ORIGIN_WALGREENS) {
      setShowModal(true);

      return;
    }

    if (_.isEmpty(quizAnswers)) {
      return;
    }

    fbPixelTrackPageViewEvent();
    fbPixelTrackEvent('Lead');

    // Initialize LinkedIn Insight
    LinkedInTag.init(ANALYTICS.Insight);

    setFormData({
      ...formData,
      [FormKeys.CardName]: `${quizAnswers.name} ${quizAnswers.lastName}`,
    })
  }, []);

  useEffect(() => {
    api.fetchStripePlans()
      .then(response => {
        setIsFetchingPlans(false);
        setPlans(response);
      })
      .catch(() => {
        setIsFetchingPlans(false);
        setPlans(undefined);
      })
  }, [])

  useEffect(() => {
    if (debouncedVoucherSearch.length == 0) {
      setVoucher(undefined);
      setVoucherError(undefined);

      return;
    }

    setIsProcessingVoucher(true);
    api.checkVoucherValidity(debouncedVoucherSearch)
      .then(response => {
        setIsProcessingVoucher(false);
        setVoucher(response);
        setVoucherError(!response.isValid ? 'Coupon invalid' : undefined);
      })
      .catch(error => {
        setIsProcessingVoucher(false);
        setVoucher(undefined);
        setVoucherError(error);
      })
  }, [debouncedVoucherSearch])

  const clearForm = () => {
    setFormData({ state: defaultState.value });
    setFormErrors({});
  }

  const discountedValue = (value: number): [number, string | undefined] => {
    let discount = 0;
    let newValue = value;
    let info: string | undefined = undefined;

    if (voucher && voucher.isValid) {
      if (voucher.isPercent) {
        discount = value * (voucher.discountValue / 100);
      } else {
        discount = voucher.discountValue / 100;
      }

      newValue = value - discount;
      info = `${formatCurrency(discount)} per ${isMonthly ? 'month' : 'year'} off`;
    }

    return [newValue, info];
  }

  const isInputInvalid = (key: string) => (
    !_.isEmpty(formErrors[key]) && !isInitial
  )

  const trackSuccessAction = (payload: any) => {
    fbPixelTrackEvent('Purchase', payload);
    gtmEvent(
      `participantpayment${isMonthly ? 'monthly' : 'annualy'}`,
      'participantsubscriptionbutton',
      'participantsubscriptionclick'
    );
  }

  const validateForm = (payload) => {
    let errors = formErrors;
    let isValid = true;

    // validate CardName
    const key = FormKeys.CardName;
    const value = payload[key];
    let error = !value || value && value.length == 0 ? VALIDATION_REQUIRED : '';

    if (error) {
        isValid = false;
    }

    errors[key] = error;
    setFormErrors({ ...formErrors, ...errors });
    setIsInitial(false);
    return isValid && !voucherError;
  }

  const onAssignRef = (ref: HTMLLabelElement | null, key: string) => {
    const error = formErrors[key];

    if (!error && (labelNode && labelNode == ref)) {
      labelNode = null;
    } else if (error && !labelNode) {
      labelNode = ref;
    }
  }

  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  const onVoucherValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setVoucherSearch(value);
  }

  const onNextStep = () => {
    setShowStatusModal(false);
    setTimeout(() => {
      clearForm();
      sessionStorage.setItem(SESSION_KEY_IS_NOT_COMPANY_SPONSORED, '1');
      sessionStorage.setItem(SESSION_KEY_ELIGIBILITY, JSON.stringify(formData));
      props.history.push(ROUTES.ParticipantWizard);
    }, 250);
  }

  const onStateSelection = (item: DropdownItem) => {
    setSelectedState(item);
    setFormData({ ...formData, state: item.value });
  }

  const onSubmit = async () => {
    const trackingId = sessionStorage.getItem('trackingId');
    const payload = Object.assign(formData, {
      participant_id: trackingId,
    });

    if (validateForm(payload) && props.stripe) {
      setIsProcessing(true);

      try {
        const response = await props.stripe.createToken({
          name: formData[FormKeys.CardName],
        });
        const { token } = response;

        if (token && plans) {
          const plan = plans.filter(item => item.interval == (isMonthly ? 'month' : 'year'))[0];
          const purchasePayload = { currency: "USD", value: plan.amount };
          const token_id = token.id;

          if (invoiceId && statusType == StripeStatus.PaymentMethod) {
            const payload = { token_id, invoice_id: invoiceId };

            api.reattemptSubscription(participantId, payload)
              .then(response => {
                const [status, invoiceId] = response;

                setIsProcessing(false);
                setShowStatusModal(true);
                setStatusType(status);
                setInvoiceId(invoiceId);
                setErrorMessage(undefined);

                if (status == StripeStatus.Succeeded) {
                  trackSuccessAction(purchasePayload);
                }
              })
              .catch(error => {
                setErrorMessage(error);
                setIsProcessing(false);
                setShowStatusModal(true);
              });

            return;
          }

          const payload = {
            token_id,
            plan_id: plan.id,
          };

          if (voucher && isVoucherValid) {
            payload['coupon_code'] = voucher.id;
          }

          api.createSubscription(participantId, payload)
            .then(response => {
              const [status, invoiceId] = response;

              setIsProcessing(false);
              setShowStatusModal(true);
              setStatusType(status);
              setInvoiceId(invoiceId);
              setErrorMessage(undefined);

              if (status == StripeStatus.Succeeded) {
                trackSuccessAction(purchasePayload);
              }
            })
            .catch(error => {
              setErrorMessage(error);
              setIsProcessing(false);
              setShowStatusModal(true);
            });
        } else {
          setIsProcessing(false);
          toast.error(response.error!.message, { autoClose: 2000 });
        }
      } catch (error) {
        setIsProcessing(false);
        toast.error(error.response.data.message || error.response.message, { autoClose: 2000 });
      }
    } else {
      setTimeout(() => {
        // TODO: Fix issue of node not being stored
        if (labelNode) {
          labelNode.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else {
          // window.scroll({ top: 100, behavior: 'smooth' });
        }
      }, 250);
    }
  }

  const renderModal = () => {
    const modalProps = {
      confirmTitle: 'Ok',
      onConfirm: statusType == StripeStatus.Succeeded ? onNextStep : () => setShowStatusModal(false),
    };

    return (
      <BaseModal
        className="class-unclaim-error"
        title={`${isMonthly ? 'Monthly' : 'Yearly'} Subscription`}
        toDisplay={true}
        {...modalProps}>
        {stripeModalContentForStatus(statusType, errorMessage)}
      </BaseModal>
    )
  }

  const renderPaymentForm = () => {
    const [value, info] = discountedValue(isMonthly ? MONTHLY_FEE : ANNUAL_FEE);

    return (
      <div className="Payment-header">
        <div className="selection">
          <label className="emphasis bold">
            Choose Payment Option
          </label>
          <form className="radio-selection-form">
            <div className="radio-div">
              <input
                id="radio-plan-monthly"
                checked={isMonthly}
                disabled={isFetchingPlans}
                name="plan"
                type="radio"
                value="monthly"
                onChange={() => setIsMonthly(true)} />
              <label htmlFor="radio-plan-monthly" className="bold">
                <span className="emphasis">{formatCurrency(MONTHLY_FEE)}</span>/Month
              </label>
            </div>
            {/* <div className="radio-div">
              <input
                id="radio-plan-yearly"
                checked={!isMonthly}
                disabled={isFetchingPlans}
                name="plan"
                type="radio"
                value="yearly"
                onChange={() => setIsMonthly(false)} />
              <label htmlFor="radio-plan-yearly" className="bold">
                <span className="emphasis">{formatCurrency(ANNUAL_FEE)}</span>/Year&nbsp;
                <span className="grey">
                  (Save&nbsp;<span className="text">$100</span>&nbsp;if you pay annualy)
                </span>
              </label>
            </div> */}
          </form>
          <label>Subscribe to a&nbsp;
            <span className="emphasis bold">
              {`${isMonthly ? 'Monthly' : 'Yearly'} Subscription`}
            </span>
            &nbsp;of&nbsp;
            <span className="bold">Fruit Street's Weight Loss Program.</span>
            {/* &nbsp;for&nbsp; */}
            {/* <label htmlFor="radio-plan-yearly" className="bold">
              <span className="emphasis">
                {`${isMonthly ? formatCurrency(MONTHLY_FEE) : formatCurrency(ANNUAL_FEE)} Subscription`}
              </span>/{isMonthly ? 'Month' : 'Year'}.&nbsp;
            </label> */}
          </label>
        </div>

        <hr />

        {renderStripeForm()}

        <hr />

        <div className="form">
          <Row
            between="xs"
            start="xs"
            className="StripeForm-footer"
            style={{ paddingBottom: info ? 1 : 23 }}>
                <div className="StripeForm-footer-item">
                  <label className="StripeForm-label" htmlFor={FormKeys.Voucher}>
                    Voucher:
                  </label>
                  <input
                    required
                    autoComplete="off"
                    id={FormKeys.Voucher}
                    className={classnames(
                      'StripeForm-input fixed',
                      voucherError && !isProcessingVoucher && 'is-invalid',
                      isVoucherValid && 'is-valid',
                    )}
                    disabled={isFetchingPlans}
                    type="text"
                    onChange={onVoucherValueChange}/>

                  {isProcessingVoucher ? <Spinner className="spinner" noText /> : (isVoucherValid &&
                    <FontAwesomeIcon className="StripeForm-success" icon={faCheckCircle} />
                  )}
                </div>
                <div className="StripeForm-footer-item">
                  <label className="StripeForm-label" style={{ marginLeft: 20 }}>
                    Total:
                  </label>
                  <div>
                    <label className="info" id="total-value">
                      <span className="value">
                        {formatCurrency(value)}
                      </span>/{isMonthly ? 'Month' : 'Year'}
                    </label>
                  </div>
                </div>
          </Row>
          <Row>
            <Col xs={12} md={8} />
            <Col xs={12} md={4}>
              <div className="StripeForm-footer info">
                {info && <span>Discount: {info}</span>}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderStripeForm = () => {
    const createOptions = {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#c23d4b',
        },
      }
    };

    return (
      <div className="form">
        <label className="emphasis bold">Card Information</label>

        {isProcessing && loaderComponent()}

        {showStatusModal && !isProcessing && renderModal()}

        <div className="StripeForm-container">
          <Row className="StripeForm-row" start="xs">
            {/* <Col className="StripeForm-column" xs={12} md={6}>
              <label
                className="StripeForm-label"
                htmlFor={FormKeys.CardDetails}
                ref={ref => onAssignRef(ref, FormKeys.CardDetails)}>
                Card Details
              </label>
              <div
                className={classnames([
                  'StripeForm-inputContainer',
                  formErrors[FormKeys.CardDetails] ? ' is-invalid' : ''
                ])}>
                <CardElement
                  id={FormKeys.CardDetails}
                  disabled={isFetchingPlans}
                  onChange={onStripeCardChange}
                  {...createOptions}
                />
              </div>
            </Col> */}
            <Col className="StripeForm-column" xs={12} md={3}>
                <label
                    className="StripeForm-label"
                    htmlFor={FormKeys.CardNumber}
                    ref={ref => onAssignRef(ref, FormKeys.CardNumber)}>
                    Card Number
                </label>
                <div
                    className={classnames([
                        'StripeForm-inputContainer',
                        // formErrors[FormKeys.CardNumber] ? 'is-invalid' : ''
                    ])}>
                    <CardNumberElement
                        id={FormKeys.CardNumber}
                        disabled={isFetchingPlans}
                        {...createOptions}
                    />
                </div>
            </Col>
            <Col className="StripeForm-column" xs={6} md={2}>
                <label
                    className="StripeForm-label"
                    htmlFor={FormKeys.CardExpiration}
                    ref={ref => onAssignRef(ref, FormKeys.CardExpiration)}>
                    Expiration
                </label>
                <div
                    className={classnames([
                        'StripeForm-inputContainer',
                        // formErrors[FormKeys.CardExpiration] ? 'is-invalid' : ''
                    ])}>
                    <CardExpiryElement
                        id={FormKeys.CardExpiration}
                        disabled={isFetchingPlans}
                        {...createOptions}
                    />
                </div>
            </Col>
            <Col className="StripeForm-column" xs={6} md={2}>
                <label
                    className="StripeForm-label"
                    htmlFor={FormKeys.CardCVC}
                    ref={ref => onAssignRef(ref, FormKeys.CardCVC)}>
                    CVC
                </label>
                <div
                    className={classnames([
                        'StripeForm-inputContainer',
                        // formErrors[FormKeys.CardCVC] ? 'is-invalid' : ''
                    ])}>
                    <CardCvcElement
                        id={FormKeys.CardCVC}
                        disabled={isFetchingPlans}
                        {...createOptions}
                    />
                </div>
            </Col>
          </Row>

          <Row className="StripeForm-row" between="xs">
            <Col className="StripeForm-column" xs={12} md={7}>
              <label
                className="StripeForm-label"
                htmlFor={FormKeys.CardName}
                ref={ref => onAssignRef(ref, FormKeys.CardName)}>
                Name on Card
              </label>
              <input
                required
                autoComplete="off"
                id={FormKeys.CardName}
                className={classnames([
                  'StripeForm-input',
                  isInputInvalid(FormKeys.CardName) ? 'is-invalid' : '',
                ])}
                disabled={isFetchingPlans}
                key={FormKeys.CardName}
                name={FormKeys.CardName}
                type="text"
                value={formData[FormKeys.CardName]}
                onChange={onInputValueChange}
                placeholder="John Smith"
              />
              <div className="Payment-label is-invalid">
                {formErrors[FormKeys.CardName]}
              </div>
            </Col>
            <a href="https://www.bbb.org/us/ny/new-york/profile/healthcare-management/fruit-street-0121-162334/#sealclick" target="_blank" rel="nofollow">
              <img src="https://seal-newyork.bbb.org/seals/blue-seal-200-42-bbb-162334.png" className="BBB-logo" alt="Fruit Street BBB Business Review" />
            </a>
          </Row>
        </div>
      </div>
    )
  }

  return (
    <>
      <AppNav hideMenu />

      <Container className="Payment-container">
        {showModal && quizModalComponent(showModal, QUIZ_URL_GENERAL)}

        <h1 className="Payment-headerLabel">
          Payment Details
        </h1>

        {renderPaymentForm()}

        <div className="Payment-submitFooter">
          <CustomButton
            primary
            className="Payment-submitButton"
            disabled={isProcessing || isFetchingPlans || !plans || !props.stripe}
            isLoading={isProcessing}
            title="Subscribe to Fruit Street"
            onClick={event => {
              event.preventDefault();

              onSubmit();
            }} />
        </div>
      </Container>
    </>
  )
}

// @ts-ignore
const InjectedStripePayment = withRouter(injectStripe(ParticipantPayment));

const StripeParticipantPayment = () => (
  <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
    <Elements>
      <InjectedStripePayment />
    </Elements>
  </StripeProvider>
)

export default StripeParticipantPayment;
