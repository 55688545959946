import React, { useState } from 'react';

import { withRouter, RouteComponentProps } from 'react-router';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faQuestionCircle,
  faComment,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import placeholder from '../../assets/images/placeholder.jpg';

import { store } from '../../redux';
import { changeClient, clearUser } from '../../redux/actions/Authentication';

import { isAuthenticated, practiceConstants } from '../../utils';
import { CLIENT_TYPE, ROUTES } from '../../utils/constants';
import { UserType } from '../../utils/types';

import './index.scss';

interface Props extends RouteComponentProps {
  hideMenu?: boolean;
}

type LinkItem = { link: string, title: string };

const AppNav = (props: Props) => {
  const { hideMenu } = props;
  const { user } = store.getState().authentication;
  const {
    practiceName,
    logo: practiceLogo,
    primaryColor: color
  } = practiceConstants();

  // States
  const [showMenu, setShowMenu] = useState(false);

  const isActive = (path) => {
    let p = props.location.pathname;

    if (p[p.length - 1] != '/') {
      p = `${p}/`;
    }

    return p.indexOf(path) > -1;
  };

  const isActivePractice = (current, practice) => {
    if (current == practice) {
      return { active: true };
    }

    return {};
  }

  const isSuperuser = user ? user.type == UserType.SUPERUSER : false;

  const isClinicAdmin = isSuperuser && user!.isClinicAdmin;

  const generateLinkItems = (items: LinkItem[]) => (
    <>
      {items.map((item: LinkItem) => {
        const style = isActive(item.link) ? { color } : {};

        return (
          <button
            key={item.link}
            className={`AppNav-button${isActive(item.link) ? ' active' : ''}`}
            style={style}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(item.link);
            }}>
            {item.title}
          </button>
        )
      })}
    </>
  );

  const superuserItems = () => generateLinkItems([
    { link: ROUTES.SuperuserDashboard, title: 'Classes' },
    { link: ROUTES.SuperuserAnalyticsTracking, title: 'Analytics and Tracking' },
    // { link: ROUTES.SuperuserAutoScheduling, title: 'Scheduling' },
  ]);

  const dietitianItems = () => generateLinkItems([
    { link: ROUTES.DietitianClasses, title: 'Dietitian' },
  ]);

  // const dietitianItems = () => (
  //   <>
  //     <Nav.Link
  //       active
  //       href={ROUTES.DietitianClasses}
  //       onSelect={(event) => {
  //         event.preventDefault();
  //         props.history.push(ROUTES.DietitianClasses)
  //       }}>
  //       Dietitian
  //     </Nav.Link>
  //     {/* <Nav.Link href="#">Records</Nav.Link>
  //     <Nav.Link href="#">Goals</Nav.Link>
  //     <Nav.Link href="#">Files</Nav.Link> */}
  //   </>
  // )

  const participantItems = () => (
    <>
      {/* <Nav.Link href={ROUTES.ParticipantClasses} active>Classes</Nav.Link>
      <Nav.Link href="#">Records</Nav.Link>
      <Nav.Link href="#">Goals</Nav.Link>
      <Nav.Link href="#">Files</Nav.Link> */}
    </>
  );

  const linkItems = () => {
    if (!user) {
      return null;
    }

    switch (user.type) {
      case UserType.SUPERUSER:
        return superuserItems();

      case UserType.DIETITIAN:
        return dietitianItems();

      default:
        return participantItems();
    }
  }

  const getPhoneNumber = () => {
    return "(347) 202-1101";

    // if (!user) {
    //   return "(347) 621-6662";
    // }

    // switch (user.type) {
    //   case UserType.SUPERUSER:
    //   case UserType.DIETITIAN:
    //     return "(347) 202-1101";

    //   default:
    //     return "(347) 621-6662";
    // }
  }

  const onSelectClient = (e: any, client: CLIENT_TYPE) => {
    e.preventDefault();
    store.dispatch(changeClient(client));
    window.location.reload();
  }

  const renderPracticeSelection = () => (
    <div className="client-nav">
      <Dropdown>
        <Dropdown.Toggle id="dropdown-custom-1">
          <label className="nav-full-name client">
            {practiceName}
          </label>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-right" style={{ left: 10 }}>
          <Dropdown.Item
            onSelect={(_, e: any) => onSelectClient(e, CLIENT_TYPE.FruitStreet)}
            {...isActivePractice(practiceName, CLIENT_TYPE.FruitStreet)}>
            {CLIENT_TYPE.FruitStreet}
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={(_, e: any) => onSelectClient(e, CLIENT_TYPE.ShareCare)}
            {...isActivePractice(practiceName, CLIENT_TYPE.ShareCare)}>
            {CLIENT_TYPE.ShareCare}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

  return (
    <div className={practiceConstants().className}>
      <nav className={showMenu ? 'app-nav show-menu' : 'app-nav'}>
        <Container className="superuser">
          {!hideMenu
            ? <button
                type="button"
                className="app-nav-toggle"
                onClick={() => setShowMenu(!showMenu)}>
                  <FontAwesomeIcon icon={faBars} />
              </button>
            : <div>&nbsp;</div>}

          <div className="logo">
            <img src={practiceLogo} alt={practiceName} />
          </div>

          {!hideMenu || isAuthenticated()
            ? <>
              <Nav className="app-nav-menu" style={{ marginTop: 5 }}>
                  {/* <Nav.Item className="app-user-nav-mobile-wrap">
                    <Nav className="app-user-nav-mobile" justify>
                      <Nav.Link href="#"><FontAwesomeIcon icon={faQuestionCircle} /></Nav.Link>
                      <Nav.Link href="#"><FontAwesomeIcon icon={faComment} /></Nav.Link>
                      <Nav.Link href="#"><FontAwesomeIcon icon={faBell} /></Nav.Link>
                    </Nav>
                  </Nav.Item> */}

                  {linkItems()}
                </Nav>

                {/* {(isSuperuser && !isClinicAdmin) && renderPracticeSelection()} */}

                {isAuthenticated() &&
                  <div className="user-nav">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-custom-1">
                        <img src={placeholder} alt="img" className="user-nav-img" />
                        <label className="nav-full-name">{user!.fullName}</label>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdown-menu-right" style={{ right: 10 }}>
                        <Dropdown.Item
                          href={ROUTES.Login}
                          onSelect={(k: any, e: any) => {
                            e.preventDefault();
                            store.dispatch(clearUser());
                            window.location.replace(ROUTES.Login);
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                }
              </>
            : <div>&nbsp;</div>}
        </Container>
      </nav>
      <div className="AppNav-tech-support">
        <label className="strong">Customer Service:</label>
        &nbsp;<a href={`tel:${getPhoneNumber()}`}>{getPhoneNumber()}</a>
        &nbsp;<label>or visit our</label>
        <a
          className="primary"
          target="_blank"
          rel="noopener noreferrer"
          href="https://fruitstreet.zendesk.com/hc/en-us">
          Help Center
        </a>
      </div>
    </div>
  );
}

export default withRouter(AppNav);
