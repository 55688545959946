import * as React from 'react';
import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import BaseModal from '../components/BaseModal';
import Loader from '../components/Loader';

import { store } from '../redux';

import {
  BASE_URL,
  BASE_URL_SHARECARE,
  CLASS_STATUS,
  CLASS_STATUS_LIST,
  CLIENT_TYPE,
  FORMAT_H_MMA,
  MESSAGE_QUIZ_VALIDATION,
  SESSION_KEY_ELIGIBILITY,
  SESSION_KEY_QUIZ_ANSWERS,
  SESSION_KEY_CLASS_PREFERENCE,
  SESSION_SLIDES,
  TIME_FILTER,
  QUIZ_URL,
  CLIENT_FRUITSTREET,
  STRIPE_STATUS,
  ZENDESK_FS_KEY,
  ZENDESK_SHARECARE_KEY,
} from './constants';
import {
  ClassItem,
  ClassTimeOption,
  DateRange,
  DropdownItem,
  Participant,
  Session,
  SessionSlide,
  SessionTimestamp,
} from './types';
import { Placement } from 'react-bootstrap/Overlay';
var holidays = require('@date/holidays-us');

const DAYS_OFFSET = 7;

export const isHoliday = (date: Moment) => {
  const year = date.year();
  const holidayDates = [
    moment(new Date(year, 0, 1)), // New Year
    moment(holidays.martinLutherKingDay(year)), // Third Monday of January
    moment(holidays.presidentsDay(year)), // Third Monday of February
    moment(holidays.memorialDay(year)), // Last Monday of May
    moment(new Date(year, 6, 4)), // Independence Day
    moment(holidays.laborDay(year)), // First Monday of September
    moment(holidays.thanksgiving(year)), // Fourth Thursday of November
    moment(holidays.thanksgiving(year)).add(1, 'days'), // Friday after Thanksgiving
    moment(new Date(year, 11, 24)), // Christmas Eve
    moment(new Date(year, 11, 25)), // Christmas Day
    moment(new Date(year, 11, 31)), // New Year's Eve
  ];

  return holidayDates.filter(holidayDate => holidayDate.isSame(date, 'days')).length > 0;
}

export const abbreviateNumber = (number) => {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  // what tier? (determines SI symbol)
  let tier = Math.log10(number) / 3 | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  let suffix = SI_SYMBOL[tier];
  let scale = Math.pow(10, tier * 3);

  // scale the number
  let scaled = number / scale;

  // format number and add suffix
  let formatted = scaled.toFixed(1) + '';

  if (/\.0$/.test(formatted)) {
    formatted = formatted.substr(0, formatted.length - 2);
  }

  return formatted + suffix;
}

export const getClassSessionsByDateAndTime = (date: Date, time: DropdownItem) => {
  let offset: number = 0;
  let hasHoliday = false;
  let prevDate: Moment = moment(date);
  let sessionDate: Moment = moment(date);
  let availableRange: DateRange;

  const sessions = SESSION_SLIDES.map((slide: SessionSlide, index: number) => {
    // Check if date is same with previous to avoid two classes on same date
    // const days = (prevDate.isSame(sessionDate) ? (offset * 2) : offset) + slide.classDay - 1;
    const days = (slide.classDay - 1) + (hasHoliday ? (DAYS_OFFSET * offset) : 0);

    // Add offset to date based on defined interval in SESSION_SLIDES
    sessionDate = moment(date).add({ hours: parseFloat(time.value), days });

    // Move to next week when date is a holiday
    while (isHoliday(moment(sessionDate))) {
      console.log(`Holiday - ${sessionDate}`);

      hasHoliday = true;
      offset += 1;
      sessionDate = sessionDate.add(DAYS_OFFSET, 'days');
    }

    availableRange = [sessionDate.toDate()];
    prevDate = sessionDate.clone();

    return {
      id: index,
      name: slide.name,
      date: sessionDate.toDate(),
      availableRange,
    }
  });

  return calculateSessionRanges(date, sessions);
};

const calculateSessionRanges = (startDate: Date, sessions: Session[]) => (
  sessions.map((item: Session, _: number) => {
    // let ranges: DateRange = {};

    // if (index == 0) {
    //   ranges = [
    //     // startDate,
    //     sessions[index].date,
    //     moment(sessions[index + 1].date).subtract(1, 'days').toDate()
    //   ];
    // } else if (index == sessions.length - 1) {
    //   ranges = [
    //     moment(sessions[index - 1].date).add(1, 'days').toDate()
    //   ];
    // } else {
    //   ranges = [
    //     moment(sessions[index - 1].date).add(1, 'days').toDate(),
    //     moment(sessions[index + 1].date).subtract(1, 'days').toDate(),
    //   ];
    // }

    item.availableRange = [startDate, moment(startDate).add(1, 'year').toDate()];

    return item;
  })
)

export const classItemStatus = (classItem: ClassItem) => {
  const currentDateMoment = moment(new Date());

  if (classItem.dietitianId) {
    return CLASS_STATUS_LIST[CLASS_STATUS.Claimed];
  }

  if (currentDateMoment.isBefore(classItem.date)) {
    if (classItem.participants.length >= classItem.maxParticipants) {
      return CLASS_STATUS_LIST[CLASS_STATUS.Full];
    } else {
      return CLASS_STATUS_LIST[CLASS_STATUS.Open];
    }
  } else if (currentDateMoment.isBetween(classItem.date, classItem.endDate, 'minutes', '[]')) {
    return CLASS_STATUS_LIST[CLASS_STATUS.InProgress];
  } else {
    return CLASS_STATUS_LIST[CLASS_STATUS.Completed];
  }
}

export function dataToModel<T>(data: { [id: string]: any }): T {
  let find = /(\_\w)/g;
  let model = {} as T;
  let convert = function (matches) {
    return matches[1].toUpperCase();
  };

  Object.keys(data).map((key: string) => {
    let newKey = key.replace(find, convert);

    model[newKey] = data[key];
  });

  return model;
}

export const debouncedOnChangeText = (f, delay: number) => {
  const debounced = _.debounce(f, delay);

  return (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    return debounced(event);
  }
}

const downloadLink = (data: any, filename: string) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(data, filename);
  } else {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = url;
    link.style.display = 'none';

    link.setAttribute('download', filename);

    if (typeof link.download === 'undefined') {
      link.setAttribute('target', '_blank');
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}

export const downloadCSV = (data: any, filename: string) => {
  const blob = new Blob([data], { type: 'text/csv' });

  downloadLink(blob, filename);
}

export const downloadPDF = (data: any, filename: string) => {
  downloadLink(data, filename);
}

export const filterParticipants = (allParticipants: Participant[], participants: Participant[]) => {
  const ids: number[] = participants.map((item: Participant) => item.id);

  return allParticipants.filter((item: Participant) => !ids.includes(item.id));
}

export const generateSessionTimestamp = (item: Session, classDetails?: ClassItem) => {
  const start = item.date.getTime() / 1000;
  const end = moment(item.date).add(1, 'hours').toDate().getTime() / 1000;
  const name = item.name;
  const timestamp: SessionTimestamp = { name, start, end }

  if (classDetails) {
    timestamp.id = item.id;
  }

  return timestamp;
}

export const generateSessionTimestamps = (sessions: Session[], classDetails?: ClassItem) => (
  sessions.map((item: Session) => generateSessionTimestamp(item, classDetails))
)

/**
 * Creates a vertical gradient from a canvas
 * @param canvas  Canvas instance to get the context.
 * @param color1  Color at stop 0.
 * @param color2  Color at stop 1.
 * @param height  Height of the gradient.
 */
export const gradientForCanvas = (canvas: any, color1: string, color2: string, height: number) => {
  // @ts-ignore
  const ctx = canvas.getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, height);

  gradient.addColorStop(0, color1);
  gradient.addColorStop(1, color2);

  return gradient;
}

export const groupParticipants = (participants: Participant[], column: number) => {
  let count = 0;
  let groupedList: Participant[][] = [];
  const length = participants.length
  const rowLength = length <= column ? 1 : length / column;

  for (let h = 0; h < rowLength; h++) {
    const offset = length - count;
    const columnLength = offset > column ? column : offset;
    let list: Participant[] = [];

    for (let i = 0; i < columnLength; i++) {
      list.push(participants[count]);

      count += 1;
    }

    groupedList.push(list);
  }

  return groupedList;
}

export const hasEligibilityCheck = (employer: string) => employer === 'walgreens';

export const isAuthenticated = () => !_.isUndefined(store.getState().authentication.user);

export const isEligibleForSignup = (level: number) => {
  if (isAuthenticated()) {
    return [true];
  }

  // Retrieve to sessionStorage
  const qa = sessionStorage.getItem(SESSION_KEY_QUIZ_ANSWERS) || '';
  const e = sessionStorage.getItem(SESSION_KEY_ELIGIBILITY) || '';
  const cp = sessionStorage.getItem(SESSION_KEY_CLASS_PREFERENCE) || '';

  // Parse to dictionary
  const classPreference = cp.length > 0 ? JSON.parse(cp) : {};
  const eligibility = e.length > 0 ? JSON.parse(e) : {};
  const quizAnswers = qa.length > 0 ? JSON.parse(qa) : {};

  let isValid = !_.isEmpty(quizAnswers);

  switch (level) {
    case 2:
      isValid = isValid && !_.isEmpty(eligibility);
      break;

    case 3:
      isValid = isValid && !_.isEmpty(eligibility) && !_.isEmpty(classPreference);
      break;

    default:
      break;
  }

  return [
    isValid,
    quizAnswers,
    eligibility,
    classPreference
  ];
}

export const loaderComponent = () => (
  <Loader toDisplay={true} />
)

type PracticeConstants = {
  baseURL: string;
  chartColors: string[];
  className: string;
  credentials: { ACCOUNT_CODE: string, API_KEY: string };
  defaultEmployer: DropdownItem;
  employers: DropdownItem[];
  logo: string;
  practiceName: CLIENT_TYPE;
  primaryColor: string;
  zendeskKey: string;
}

export const practiceConstants = (): PracticeConstants => {
  // NOTE: FS defaults
  // let chartColors = ['#6BB7FA', '#279AFF', '#0072D6', '#004E93'];
  let chartColors = [
    '#63acf4',
    '#50a2f3',
    '#3d98f2',
    '#3688d9',
    '#3079c1',
    '#2a6aa9',
    '#245b91',
    '#1e4c79',
    '#183c60',
    '#122d48'
  ]
  let credentials = {
    ACCOUNT_CODE: process.env.REACT_APP_VSEE_ACCOUNT_CODE || '',
    API_KEY: process.env.REACT_APP_VSEE_API_KEY || ''
  };
  let logo = require('../assets/images/logo.png').default;
  let baseURL = BASE_URL;
  let defaultEmployer = { title: 'Walgreens', value: 'walgreens' };
  let employers = [
    { title: 'Fruit Street', value: 'fruitstreet' },
    defaultEmployer,
    { title: 'Walgreens Weightloss', value: 'walgreens-weightloss' },
  ];
  let zendeskKey = ZENDESK_FS_KEY;

  const {
    name: practiceName,
    value: className,
    primaryColor,
  } = store.getState().authentication.client || CLIENT_FRUITSTREET;

  switch (practiceName) {
    case CLIENT_TYPE.ShareCare:
      baseURL = BASE_URL_SHARECARE;
      // chartColors = ['#6BFAB7', '#27FF9A', '#00D672', '#00934E'];
      chartColors = [
        '#47c7af',
        '#30c0a5',
        '#1aba9c',
        '#17a78c',
        '#14947c',
        '#12826d',
        '#0f6f5d',
        '#0d5d4e',
        '#0a4a3e',
        '#07372e'
      ];
      credentials = {
        ACCOUNT_CODE: process.env.REACT_APP_VSEE_SHARECARE_ACCOUNT_CODE || '',
        API_KEY: process.env.REACT_APP_VSEE_SHARECARE_API_KEY || '',
      };
      logo = require('../assets/images/sharecare-logo.png').default;
      defaultEmployer = { title: 'Sharecare', value: 'sharecare' };
      employers = [defaultEmployer];
      zendeskKey = ZENDESK_SHARECARE_KEY;

      break;

    default:
      break;
  }

  return {
    practiceName,
    logo,
    primaryColor,
    chartColors,
    credentials,
    className,
    baseURL,
    defaultEmployer,
    employers,
    zendeskKey,
  }
}

export const queryStringParams = query => (
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');

        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';

        return params;
      }, {}
      )
    : {}
)

export const quizModalComponent = (showModal: boolean, url: string = QUIZ_URL) => (
  <BaseModal
    title="Pre-Diabetes Risk Quiz"
    toDisplay={showModal}
    onConfirm={() => window.location.href = url}
    confirmTitle="Proceed">
    <label className="ModalLabel">
      {MESSAGE_QUIZ_VALIDATION}
    </label>
  </BaseModal>
)

export const scrollToNode = (id: string) => {
  const node = document.getElementById(id);

  if (node) {
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}

export const stripeModalContentForStatus = (
  status: STRIPE_STATUS,
  errorMessage: string | undefined
) => {
  const custService = (
    <p className="ModalLabel-info">
      If you need assistance or have questions, please call customer service at <a href="tel:347-202-1101">347-202-1101</a>.
    </p>
  );

  if (errorMessage) {
    return (
      <>
        <h3 className="BaseModal-title">
          {errorMessage}
        </h3>
        <br />

        {custService}
      </>
    )
  }

  switch (status) {
    case STRIPE_STATUS.Succeeded:
      return (
        <>
          <h3 className="BaseModal-title">
            You have successfully subscribed to <strong>Fruit Street Weight Loss Program</strong>.
          </h3>
          <br />

          {custService}
        </>
      )

    case STRIPE_STATUS.PaymentMethod:
      return (
        <>
          <h3 className="BaseModal-title">
            Failed to process subscription with current card details. Please try another card to retry subscription.
          </h3>
          <br />

          {custService}
        </>
      )

    case STRIPE_STATUS.Action:
      return (
        <>
          <h3 className="BaseModal-title">
            Requires authentication due to regional regulations.
          </h3>
          <br />

          {custService}
        </>
      )

    default:
      return <div />
  }
}

export const timeAsDropdownItem = (date: Date) => {
  let title, value;
  const timeString = `${moment(date).format(FORMAT_H_MMA)} :abbr`;
  const filter = TIME_FILTER.filter((item: DropdownItem) => (
    item.title === timeString
  ))[0];

  // NOTE: Handle time values from classes not created in Scheduler
  if (!filter) {
    title = timeString;
    value = "0";
  } else {
    title = filter.title;
    value = filter.value;
  }

  return {
    title: title.replace(':abbr', timezoneAbbreviation(date)),
    value,
  };
}

export const timePeriod = (date: Date, time: ClassTimeOption) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const HOUR_MORNING = 0;
  const HOUR_NOON = 12;
  const HOUR_AFTERNOON = 16;
  const HOUR_EVENING = 17;
  const HOUR_EVENING_END = 23;

  switch (time) {
    case ClassTimeOption.MORNING:
      return (hour >= HOUR_MORNING && hour < HOUR_NOON) && minutes > 1;
    case ClassTimeOption.AFTERNOON:
      return hour >= HOUR_NOON && hour <= HOUR_AFTERNOON;
    case ClassTimeOption.EVENING:
      return (hour >= HOUR_EVENING && hour <= HOUR_EVENING_END) || (hour == 0 && minutes == 0);
    default:
      return true;
  }
}

export const timeSelections = (selections: DropdownItem[]) => {
  const items: DropdownItem[] = [];

  selections.forEach((item: DropdownItem) => {
    const title = item.title.replace(':abbr', timezoneAbbreviation(new Date()));

    items.push({ ...item, title });
  });

  return items;
}

export const timezoneAbbreviation = (date: Date, timezone?: string | undefined) => (
  timezone ? moment(date).tz(timezone).zoneName() : moment(date).zoneName()
)

export const toggleZendeskScript = () => {
  const scriptId = 'ze-snippet';
  const { authentication: { accessToken } } = store.getState();

  if (!accessToken) {
    var elem = document.getElementById(scriptId);

    if (elem && elem.parentElement) {
      elem.parentElement.removeChild(elem);
    }

    return;
  }

  const { zendeskKey } = practiceConstants();
  const script = document.createElement("script");

  script.id = scriptId;
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;

  document.body.appendChild(script);
}

export const tooltipComponent = (
  text: string,
  children: React.ReactNode,
  direction: Placement = 'top',
) => (
  <OverlayTrigger overlay={<Tooltip id={text}>{text}</Tooltip>}>
    <>{children}</>
  </OverlayTrigger>
)
