import ReactPixel from 'react-facebook-pixel';

/**
 * Sends a Google Analytics event.
 *
 * @param {string} event
 * @param {any} params
 */
export const fbPixelTrackEvent = (event: string, params: any = {}) => {
  // @ts-ignore
  if (process.env.NODE_ENV === 'production' && window.fbq) {
    ReactPixel.track(event, params);
  }
}

export const fbPixelTrackPageViewEvent = () => {
  // @ts-ignore
  if (process.env.NODE_ENV === 'production' && window.fbq) {
    ReactPixel.pageView();
  }
}

/**
 * Sends a Google Analytics event.
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const gtmEvent = (category: string, action: string, label: string) => {
  // @ts-ignore
  const hasGa = typeof window.ga !== 'undefined';

  if (!hasGa) {
    return;
  }

  // @ts-ignore
  const ga = window.ga;
  const tracker = ga.getAll()[0];

  if (tracker) {
    tracker.send('event', category, action, label);
  }
}
