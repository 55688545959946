import React from 'react';

import './index.scss';

interface Props {
  total: number;
  from?: number;
  to?: number;
}

const TotalResults = (props: Props) => (
  <div className="TotalResults-resultsInfo">
    <label className="TotalResults-infoLabel">
      {props.total} result{props.total > 1 ? 's' : ''}
    </label>

    {props.from && props.to &&
      <label className="TotalResults-subInfoLabel">
        Showing {props.from} - {props.to}
      </label>
    }
  </div>
)

export default TotalResults;
