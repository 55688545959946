import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './index.scss';

interface Props {
  noText?: boolean;
  text?: string;
  className?: string;
  color?: string;
}

export default function Spinner(props: Props) {
  const { color, noText, text, className } = props;
  const style = noText ? { marginRight: 0 } : {};
  const p = color ? { color } : {};

  return (
    <div className={className ? `spinner ${className}` : 'spinner'}>
      <FontAwesomeIcon
        spin
        icon={faSpinner}
        style={style}
        {...p} />

      {!noText && (text || 'Please wait...')}
    </div>
  );
}
