import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Col, Row } from 'react-flexbox-grid';
import InputMask from 'react-input-mask';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import {
  faFileAlt,
  faFileInvoiceDollar,
  faClipboard,
  faTrash,
  faEdit,
  faUsers,
  faCalendar,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import ContractsAPIClient from '../../../api/contracts';

import AppNav from '../../../components/AppNav';
import BaseModal from '../../../components/BaseModal';
import CustomButton from '../../../components/CustomButton';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';

import { ContractData, ContractMilestone } from '../../../types/contracts';

import { loaderComponent, practiceConstants } from '../../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_MM_DD_YYYY,
  FORMAT_YYYY_MM_DD,
  ROUTES,
  VALIDATION_CONFIRM_EMAIL,
  VALIDATION_FORMAT,
  VALIDATION_REQUIRED,
} from '../../../utils/constants';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from '../../../utils/formatting';
import { DropdownItem } from '../../../utils/types';

import AddMilestoneModal from './Modal';

const countriesRegions = require('country-region-data');

const DIETITIAN_FEE = 0;
const TIMEOUT = 2000;

enum FormKeys {
  CompanyName = 'name',
  ContactPersonName = 'contact_person',
  MobilePhone = 'mobile_phone',
  EmailAddress = 'email_address',
  ConfirmEmailAddress = 'confirm_email_address',
  DateEstablished = 'date_established',
  StreetAddress = 'street_address',
  ApartmentUnitNo = 'unit_no',
  City = 'city',
  State = 'state',
  ZipCode = 'zip_code',
  Country = 'country',
  DietitianFee = 'dietitian_fee',
}

type ColumnData = {
  capitalize?: boolean;
  key: string;
  label: string;
  placeholder: string;
  mask?: string;
  type: string;
}

const countries = countriesRegions.map(item => ({
  id: item.countryShortCode,
  title: item.countryName,
  value: item.countryName
}));

const getStatesByCountry = (code: string) => {
  let states = countriesRegions
    .filter(item => item.countryShortCode == code)[0]
    .regions
    .map(item => ({
      id: item.shortCode,
      title: item.name,
      value: item.name,
    }));

  if (code == "US") {
    states = states.slice(0, -3);
  }

  return states;
}

interface Props extends RouteComponentProps { }

export default function ContractDetails(props: Props) {
  const api = new ContractsAPIClient(BASE_URL_INGENUITY);
  const contractId = props.match.params['id'];
  const { prevPath } = props.location.state as any;
  const { className } = practiceConstants();

  let labelNode: HTMLLabelElement | null = null;

  // States
  const [country, setCountry] = useState(countries[0]);
  const [contract, setContract] = useState<ContractData>();
  const [selectedMilestone, setSelectedMilestone] = useState<ContractMilestone>();
  const [states, setStates] = useState<DropdownItem[]>(getStatesByCountry(country.id!));
  const [state, setState] = useState(states[0]);
  const [showAddMilestone, setShowAddMilestone] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteContractModal, setShowDeleteContractModal] = useState(false);
  const [showDeleteMilestoneModal, setShowDeleteMilestoneModal] = useState(false);

  // Form States
  const [formData, setFormData] = useState<{ [id: string]: any }>({
    [FormKeys.CompanyName]: '',
    [FormKeys.ContactPersonName]: '',
    [FormKeys.MobilePhone]: '',
    [FormKeys.EmailAddress]: '',
    [FormKeys.ConfirmEmailAddress]: '',
    [FormKeys.DateEstablished]: '',
    [FormKeys.StreetAddress]: '',
    [FormKeys.City]: '',
    [FormKeys.ZipCode]: '',
    [FormKeys.DietitianFee]: DIETITIAN_FEE,
  });
  const [formErrors, setFormErrors] = useState<{ [id: string]: string }>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingMilestone, setIsDeletingMilestone] = useState(false);
  const [isEditing, setIsEditing] = useState(_.isUndefined(contractId));
  const [isInitial, setIsInitial] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [response, setResponse] = useState<string>();

  // Dataset states
  const [isFetching, setIsFetching] = useState(false);
  const [milestones, setMilestones] = useState<ContractMilestone[]>([]);

  useEffect(() => {
    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (!contractId) {
      return;
    }

    setIsFetching(true);
    api.fetchContractDetails(contractId)
      .then(response => {
        setIsFetching(false);
        setContract(response);
        setFormData({
          ...formData,
          [FormKeys.CompanyName]: response.name || '',
          [FormKeys.ContactPersonName]: response.contactPerson || '',
          [FormKeys.MobilePhone]: response.phone || '',
          [FormKeys.EmailAddress]: response.email || '',
          [FormKeys.ConfirmEmailAddress]: response.email || '',
          [FormKeys.DateEstablished]: moment(response.date).format(FORMAT_YYYY_MM_DD) || '',
          [FormKeys.StreetAddress]: response.streetAddress || '',
          [FormKeys.ApartmentUnitNo]: response.unitNo || '',
          [FormKeys.City]: response.city || '',
          [FormKeys.ZipCode]: response.zipCode || '',
          [FormKeys.DietitianFee]: response.dietitianFee || 0,
        });

        // Milestones
        setMilestones(response.milestones.filter(e => e.id != '-1'));

        if (response.country && response.state) {
          const country = countries.filter(item => item.title == response.country)[0];
          const states = getStatesByCountry(country.id!);
          const state = states.filter(item => item.title == response.state)[0] || states[0];

          setCountry(country);
          setStates(states);
          setState(state);
        }
      })
      .catch(() => {
        setIsFetching(false);
      })
  }, [])

  const isInputInvalid = (key: string) => !_.isEmpty(formErrors[key]) && !isInitial;

  const validateForm = (payload) => {
    let errors = formErrors;
    let isValid = true;
    const optionalFields = [
      FormKeys.ApartmentUnitNo.toString(),
      FormKeys.Country.toString(),
      FormKeys.State.toString(),
    ];

    Object.keys(FormKeys).map((k: string) => {
      const key = FormKeys[k];
      const value = payload[key];
      const isConfirmEmail = key == FormKeys.ConfirmEmailAddress;
      let error = !value || value && value.length == 0 ? VALIDATION_REQUIRED : '';
      let regex: RegExp | undefined;

      error = optionalFields.includes(key) ? '' : error;

      switch (key) {
        case FormKeys.EmailAddress:
        case FormKeys.ConfirmEmailAddress:
          const email = payload[FormKeys.EmailAddress];
          const confirmEmail = payload[FormKeys.ConfirmEmailAddress];

          if (isConfirmEmail && (confirmEmail && confirmEmail.length > 0)) {
            error = email != confirmEmail ? VALIDATION_CONFIRM_EMAIL : error;
          }

          regex = /^\S+@\S+$/;

          break;

        case FormKeys.MobilePhone:
          regex = /^(\d{3})[-](\d{3})[-](\d{4})/;

          break;

        case FormKeys.State:
          if (formErrors[key] && formErrors[key].length > 0) {
            error = formErrors[key];
          }

          break;

        case FormKeys.DietitianFee:
          error = parseFloat(value) == 0 ? VALIDATION_REQUIRED : '';

          break;

        default:
          break;
      }

      if (regex) {
        error = value && value.length > 0 && !regex.test(value) ? VALIDATION_FORMAT : error;
      }

      if (error) {
        isValid = false;
      }

      errors[key] = error;
    });

    setFormErrors({ ...formErrors, ...errors });
    setIsInitial(false);

    return isValid;
  }

  const onAssignRef = (ref: HTMLLabelElement | null, key: string) => {
    const error = formErrors[key];

    if (key == FormKeys.CompanyName) {
      labelNode = ref;
    }

    if (!error && (labelNode && labelNode == ref)) {
      labelNode = null;
    } else if (error && !labelNode) {
      labelNode = ref;
    }
  }

  const onCloseModal = () => {
    setSelectedMilestone(undefined);
    setShowAddMilestone(false);
  }

  const onDeleteContractModal = () => {
    if (!contract) {
      return;
    }

    setShowDeleteContractModal(false);
    setIsDeleting(true);
    setIsProcessing(true);
    api.deleteContract(contractId)
      .then(r => {
        setIsProcessing(false);
        setResponse(r);

        setTimeout(() => {
          onExitPage();
        }, TIMEOUT);
      })
      .catch(() => {
        setIsProcessing(false);
        setResponse(undefined);
      });
  }

  const onDeleteMilestoneModal = () => {
    if (!selectedMilestone) {
      return;
    }

    setShowDeleteMilestoneModal(false);
    setIsDeletingMilestone(true);
    api.deleteContractMilestone(contractId, selectedMilestone.id)
      .then(() => {
        const m = milestones.filter(item => item.id != selectedMilestone.id);

        setMilestones(m);
        setIsDeletingMilestone(false);
        setSelectedMilestone(undefined);
      })
      .catch(() => {
        setIsDeletingMilestone(false);
        setSelectedMilestone(undefined);
      });
  }

  const onExitPage = () => {
    props.history.push(prevPath || ROUTES.SuperuserContracts);
  }

  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (validateForm(formData)) {
      setIsProcessing(true);

      let m: any = {};
      let payload: any = Object.assign(formData, {
        country: country.title,
        state: state.title,
      });

      for (const item in milestones) {
        const milestone = milestones[item];

        m[milestone.id] = {
          description: milestone.description,
          claims_amount: milestone.claimAmount,
          dietitian_fee: milestone.dietitianPayment,
        };
      }

      payload['milestones'] = m;

      const response = contractId ?
        api.updateContract(contractId, {...payload}) :
        api.createContract(payload);

      response
        .then(response => {
          const r = `Successfully ${contractId ? 'updated' : 'created'} contract for ${response.name}.`;

          setIsProcessing(false);
          setResponse(r);
          setTimeout(() => {
            setIsDeleting(false);
            onExitPage();
          }, TIMEOUT);
        })
        .catch(() => {
          setResponse(undefined);
          setIsProcessing(false);
        })
    }
  }

  const renderInfo = () => (
    <div className={`${className} border-bottom border-top`}>
      {!isFetching && contract ?
        <Table className="info-container">
          <thead>
            <tr className="header">
              <td colSpan={20}>
                <div className="row">
                  <label className="title">{contract.name}</label>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <FontAwesomeIcon icon={faCalendar} />
                Contract Started
              </th>
              <th>
                <FontAwesomeIcon icon={faUsers} />
                Total Participants
              </th>
              <th>
                <FontAwesomeIcon icon={faUsers} />
                Active Classes
              </th>
              <th>
                <FontAwesomeIcon icon={faUsers} />
                Finished Classes
              </th>
            </tr>
            <tr>
              <td className="data">
                {moment(contract.date).format(FORMAT_MM_DD_YYYY)}
              </td>
              <td className="data">
                <strong>{formatNumber(contract.totalParticipants)}</strong>
              </td>
              <td className="data">
                <strong>{formatNumber(contract.activeClasses)}</strong>
              </td>
              <td className="data">
                <strong>{formatNumber(contract.finishedClasses)}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        :
        <div className="info-container loader">
          <Spinner />
        </div>
      }
    </div>
  )

  const renderFirstRow = (data?: ColumnData[]) => {
    let items: ColumnData[] = [
      {
        capitalize: true,
        key: FormKeys.CompanyName,
        label: 'Company Name',
        placeholder: 'Fruit Street',
        type: 'text'
      },
      {
        capitalize: true,
        key: FormKeys.ContactPersonName,
        label: 'Contact Person Name',
        placeholder: 'John Doe',
        type: 'text'
      },
      {
        key: FormKeys.MobilePhone,
        label: 'Contact Number',
        placeholder: '000-000-0000',
        type: 'text',
        mask: '999-999-9999'
      },
    ];

    if (data) {
      items = data!;
    }

    return (
      <Row className="Payment-row" between="xs">
        {items.map((item: ColumnData, index: number) => {
          const value = formData[item.key] || '';
          const inputProps = {
            className: classnames([
              'Payment-input',
              isInputInvalid(item.key) ? 'is-invalid' : '',
              item.capitalize ? 'capitalize' : '',
            ]),
            disabled: !isEditing || contractId && item.key == FormKeys.CompanyName,
            mask: '',
            name: item.key,
            placeholder: item.placeholder,
            type: item.type,
            value,
            onChange: onInputValueChange,
            autoComplete: 'off',
          }
          const error = formErrors[item.key];

          if (item.mask) {
            inputProps['mask'] = item.mask!;
            inputProps['maskChar'] = '';
          }

          return (
            <Col
              key={index}
              className="Payment-column"
              xs={12} md={12 / items.length}>
              <label
                className="Payment-label"
                htmlFor={item.key}
                ref={ref => onAssignRef(ref, item.key)}>
                {item.label}
              </label>
              <InputMask id={item.key} {...inputProps} />
              <div className="Payment-label is-invalid">
                {error}
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }

  const renderSecondRow = () => {
    const data: ColumnData[] = [
      {
        key: FormKeys.EmailAddress,
        label: 'Email Address',
        placeholder: 'johnsmith@gmail.com',
        type: 'email'
      },
      {
        key: FormKeys.ConfirmEmailAddress,
        label: 'Confirm Email',
        placeholder: 'johnsmith@gmail.com',
        type: 'email'
      },
      {
        key: FormKeys.DateEstablished,
        label: 'Date Established',
        placeholder: 'yyyy-mm-dd',
        mask: '9999-99-99',
        type: 'text'
      },
    ];

    return renderFirstRow(data);
  }

  const renderThirdRow = () => {
    const data: ColumnData[] = [
      {
        key: FormKeys.StreetAddress,
        label: 'Street Address (No PO Boxes)',
        placeholder: 'Street Address',
        type: 'text'
      },
      {
        key: FormKeys.ApartmentUnitNo,
        label: 'Unit No.',
        placeholder: 'Unit No.',
        type: 'text'
      },
      {
        key: FormKeys.City,
        label: 'City',
        placeholder: 'City',
        type: 'text'
      },
    ];

    return renderFirstRow(data);
  }

  const renderFourthRow = () => (
    <Row
      className="Payment-row"
      between="xs">
      <Col className="Payment-column" xs={12} md={4}>
        <label htmlFor={FormKeys.Country} className="Payment-label">
          Country
        </label>
        <Dropdown
          hasSearch
          disabled={!isEditing}
          items={countries}
          selectedItem={country}
          onItemSelection={selection => {
            const states = getStatesByCountry(selection.id!);

            setCountry(selection);
            setStates(states);
            setState(states[0]);
          }}
        />
      </Col>
      <Col className="Payment-column" xs={12} md={4}>
        <label htmlFor={FormKeys.State} className="Payment-label">
          State
        </label>
        <Dropdown
          hasSearch
          disabled={!isEditing}
          hasError={isInputInvalid(FormKeys.State)}
          items={states}
          selectedItem={state}
          onItemSelection={item => setState(item)}
        />
        <div className="Payment-label is-invalid">{formErrors[FormKeys.State]}</div>
      </Col>
      <Col className="Payment-column" xs={12} md={4}>
        <label
          className="Payment-label"
          htmlFor={FormKeys.ZipCode}
          ref={ref => onAssignRef(ref, FormKeys.ZipCode)}>
          Zip Code
        </label>
        <input
          autoComplete="off"
          id={FormKeys.ZipCode}
          className={classnames([
            'Payment-input',
            isInputInvalid(FormKeys.ZipCode) ? 'is-invalid' : '',
          ])}
          disabled={!isEditing}
          name={FormKeys.ZipCode}
          type="text"
          value={formData[FormKeys.ZipCode]}
          onChange={onInputValueChange}
        />
        <div className="Payment-label is-invalid">{formErrors[FormKeys.ZipCode]}</div>
      </Col>
    </Row>
  )

  const renderFifthRow = () => (
    <Row
      className="Payment-row"
      between="xs">
      <Col className="Payment-column" xs={12} md={5}>
        <label htmlFor={FormKeys.DietitianFee} className="Payment-label">
          Dietitian Fee
        </label>
        <span className="info">
          Amount paid to dietitians per session conducted.
        </span>
        <input
          autoComplete="off"
          id={FormKeys.DietitianFee}
          className={classnames([
            'Payment-input',
            isInputInvalid(FormKeys.DietitianFee) ? 'is-invalid' : '',
          ])}
          disabled={!isEditing}
          name={FormKeys.DietitianFee}
          type="number"
          value={formData[FormKeys.DietitianFee]}
          onChange={onInputValueChange}
        />
        <div className="Payment-label is-invalid">
          {formErrors[FormKeys.DietitianFee]}
        </div>
      </Col>
      <Col className="Payment-column" xs={12} md={7} />
    </Row>
  )

  const renderMilestoneActions = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={1}>
          <h3 className="primary-title">Milestones</h3>
        </Col>
        <Col xs={12} lg={11} className="controls">
          <CustomButton
            primary
            title="Add Milestone"
            onClick={() => setShowAddMilestone(true)} />
        </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (milestones && milestones.length == 0) {
      return (
        <div className="empty-section text-muted">
          <label>No milestones yet.</label>
        </div>
      )
    }

    return (
      <Table className="data-list">
        <thead>
          <tr>
            <th className="fixed-100">No.</th>
            <th>
              <FontAwesomeIcon icon={faFileAlt} />
              Description
            </th>
            <th className="fixed-200">
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
              Claim Amount
            </th>
            <th className="fixed-200">
              <FontAwesomeIcon icon={faClipboard} />
              Dietitian Payment
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {milestones.map((item: ContractMilestone) => {
            let dietitianPayment = <>{formatCurrency(item.dietitianPayment)}</>;

            if (item.dietitianPayment == 0) {
              dietitianPayment = <strong>N/A</strong>;
            } else if (item.dietitianPayment <= 1 && item.dietitianPayment > 0) {
              dietitianPayment = (
                <div>
                  {formatPercent(item.dietitianPayment)}&nbsp;

                  <strong>
                    ({formatCurrency(item.claimAmount * item.dietitianPayment)})
                  </strong>
                </div>
              );
            }

            return (
              <tr key={item.id}>
                <td>
                  <strong>{item.id}</strong>
                </td>
                <td className={!item.description ? 'no-data' : ''}>
                  {item.description || 'No description'}
                </td>
                <td>{formatCurrency(item.claimAmount)}</td>
                <td>{dietitianPayment}</td>
                <td className="actions">
                  <div>
                    {(isDeletingMilestone && selectedMilestone && selectedMilestone.id == item.id) ?
                      <FontAwesomeIcon spin icon={faSpinner} /> :
                      <>
                        <span onClick={() => {
                          setSelectedMilestone(item);
                          setShowDeleteMilestoneModal(true);
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                        <span onClick={() => {
                          setSelectedMilestone(item);
                          setShowAddMilestone(true);
                        }}>
                          <FontAwesomeIcon icon={faEdit} />
                        </span>
                      </>
                    }
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  const renderAddMilestoneModal = () => (
    <AddMilestoneModal
      id={selectedMilestone ? selectedMilestone.id : `${milestones.length + 1}`}
      contract={contract}
      milestone={selectedMilestone}
      toDisplay={showAddMilestone}
      onCancel={onCloseModal}
      onClose={onCloseModal}
      onConfirm={(e, item) => {
        if (selectedMilestone) {
          const index = _.findIndex(milestones, (b) => b.id === item.id);
          const m = [...milestones];

          if (~index) {
            m[index] = item;

            setMilestones(m);
          }
        } else {
          setMilestones([...milestones, item]);
        }

        onCloseModal();
      }}
    />
  )

  const renderCancelModal = () => (
    <BaseModal
      cancelTitle="No"
      title={`Exit ${contractId ? 'Update' : 'Create'} Contract`}
      toDisplay={showCancelModal}
      onCancel={() => setShowCancelModal(false)}
      onClose={() => setShowCancelModal(false)}
      onConfirm={onExitPage}>
      <label className="ModalLabel">
        All unsaved progress will be lost. Continue?
      </label>
    </BaseModal>
  )

  const renderDeleteContractModal = () => {
    let props: any = {
      confirmTitle: 'Ok',
      onConfirm: () => setShowDeleteContractModal(false),
      onClose: () => setShowDeleteContractModal(false),
    };
    const isUnable = contract && (contract.activeClasses > 0);
    const unableComponent = (
      <>
        <label className="ModalLabel">
          You can no longer delete a contract when there are classes on going.
        </label>
        <p>
          If you want to inquire regarding this delete request,<br />
          please email <a href="mailto:support@fruitstreet.com">support@fruitstreet.com</a>.
        </p>
      </>
    );

    if (!isUnable) {
      props = {
        ...props,
        confirmTitle: 'Yes',
        onCancel: () => setShowDeleteContractModal(false),
        onConfirm: onDeleteContractModal,
      };
    }

    return (
      <BaseModal
        {...props}
        cancelTitle="No"
        title="Delete Contract"
        toDisplay={showDeleteContractModal && !_.isUndefined(contract)}>
        {contract && (isUnable ? unableComponent : (
          <label className="ModalLabel">
            Are you sure you want to delete {contract.name}?
          </label>
        ))}
      </BaseModal>
    )
  }

  const renderDeleteMilestoneModal = () => (
    <BaseModal
      cancelTitle="No"
      title="Delete Milestone"
      toDisplay={showDeleteMilestoneModal && !_.isUndefined(selectedMilestone)}
      onCancel={() => setShowDeleteMilestoneModal(false)}
      onClose={() => setShowDeleteMilestoneModal(false)}
      onConfirm={onDeleteMilestoneModal}>
      <>
        <label className="ModalLabel">
          Are you sure you want to remove Milestone {selectedMilestone!.id}?
        </label>
        <p>
          If you want to inquire regarding this delete request,<br />
          please email <a href="mailto:support@fruitstreet.com">support@fruitstreet.com</a>.
        </p>
      </>
    </BaseModal>
  )

  const renderContractActions = () => {
    if (isEditing) {
      return (
        <div className="header-actions-section">
          <CustomButton
            title="Cancel"
            onClick={() => setShowCancelModal(true)}
          />
          <CustomButton
            primary
            disabled={isProcessing}
            title="Save Contract"
            onClick={onSubmit}
          />
        </div>
      )
    }

    return (
      <div className="header-actions-section">
        <CustomButton
          title="Edit"
          onClick={() => setIsEditing(true)}
        />
        <CustomButton
          danger
          title="Delete Contract"
          onClick={() => setShowDeleteContractModal(true)}
        />
      </div>
    )
  }

  const renderResponseModal = () => (
    <BaseModal
      title={`${isDeleting ? 'Delete' : (contractId ? 'Update' : 'Create')} Contract`}
      toDisplay={!_.isUndefined(response)}>
      <label className="ModalLabel">
        {response || ''}
      </label>
    </BaseModal>
  )

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack={contractId}
          backHandler={() => {
            if (isEditing) {
              setShowCancelModal(true);
            } else {
              props.history.push(prevPath);
            }
          }}
          titleLg={6}
          title={contractId ? 'Contract Details' : 'Create Contract'}>
          {renderContractActions()}
        </Header>

        {contractId && renderInfo()}

        {showCancelModal && renderCancelModal()}
        {(showDeleteContractModal && contract) && renderDeleteContractModal()}
        {(showDeleteMilestoneModal && selectedMilestone) && renderDeleteMilestoneModal()}
        {showAddMilestone && renderAddMilestoneModal()}
        {!_.isUndefined(response) && renderResponseModal()}

        {isProcessing && loaderComponent()}

        {isFetching ?
          <div className="center-container">
            <Spinner />
          </div> :
          <>
            <form>
              <div className="Payment-form">
                {renderFirstRow()}
                {renderSecondRow()}
                {renderThirdRow()}
                {renderFourthRow()}
                {renderFifthRow()}
              </div>
            </form>

            {contractId &&
              <>
                {renderMilestoneActions()}
                {renderList()}
              </>
            }
          </>
        }

      </Container>
    </>
  )
}
