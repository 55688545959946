import React, { ReactNode } from 'react';

import placeholder from '../../assets/images/placeholder.jpg';

import { practiceConstants } from '../../utils';
import { Participant as ParticipantType } from '../../utils/types';

import './index.scss';

interface Props {
  participant?: ParticipantType;
  children?: ReactNode;
}

export default function Participant(props: Props) {
  const { participant, children } = props;

  return (
    <div className={`participant ${practiceConstants().className}`}>
      <img className="participant-avatar" src={placeholder} />
      <div className="participant-name">
        {participant ? participant.fullName : ''}
      </div>
      <div className="participant-actions">
        {children || ''}
      </div>
    </div>
  );
}
