import {
  ActionType,
  createAsyncAction,
  createAction,
} from 'typesafe-actions';

import { EligibilityPayload } from '../../utils/types';

export enum Types {
  CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY',
  CHECK_ELIGIBILITY_SUCCESS = 'CHECK_ELIGIBILITY_SUCCESS',
  CHECK_ELIGIBILITY_FAILURE = 'CHECK_ELIGIBILITY_FAILURE',
  CLEAR_FORM = 'CLEAR_FORM',
}

export type ReducerActionType = ActionType<
  | typeof checkEligibility
  | typeof clearForm
>

export const checkEligibility = createAsyncAction(
  Types.CHECK_ELIGIBILITY,
  Types.CHECK_ELIGIBILITY_SUCCESS,
  Types.CHECK_ELIGIBILITY_FAILURE
)<EligibilityPayload, { [id: string]: string }, { [id: string]: string }>();

export const clearForm = createAction(Types.CLEAR_FORM, resolve => {
  return () => resolve();
});
