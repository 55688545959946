import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { isWeekend } from 'date-fns';
import Container from 'react-bootstrap/Container';
import { RouteComponentProps } from 'react-router';
import LinkedInTag from 'react-linkedin-insight';

import ParticipantAPIClient from '../../api/participant';

import AppNav from '../../components/AppNav';
import BaseModal from '../../components/BaseModal';
import Dropdown from '../../components/Dropdown';
import NoResults from '../../components/NoResults';
import Paginate from '../../components/Paginate';
import ParticipantClassCard from '../../components/ParticipantClassCard';
import Spinner from '../../components/Spinner';

import {
  isEligibleForSignup,
  quizModalComponent,
  timePeriod,
} from '../../utils';
import { ANALYTICS, CREATE_MESSAGE_FAILURE } from '../../utils/constants';
import { fbPixelTrackPageViewEvent } from '../../utils/tracking';
import {
  ClassItem,
  ClassDayOption,
  ClassTimeOption,
  DropdownItem,
} from '../../utils/types';

import './index.scss';

interface Props extends RouteComponentProps {}

export default function ParticipantClassSearch(props: Props | any) {
  const { location } = props;
  const api = new ParticipantAPIClient();

  const dayOptions: DropdownItem[] = useMemo(() => ([
    { value: ClassDayOption.WEEKDAY, title: 'Weekday' },
    { value: ClassDayOption.WEEKEND, title: 'Weekend' },
    { value: '', title: 'Show All' }
  ]), []);

  const timeOptions: DropdownItem[] = useMemo(() => ([
    { value: ClassTimeOption.MORNING, title: 'Morning' },
    { value: ClassTimeOption.AFTERNOON, title: 'Afternoon' },
    { value: ClassTimeOption.EVENING, title: 'Evening' },
    { value: '', title: 'Show All' },
  ]), []);

  const [isFetching, setIsFetching] = useState(true);
  const [classes, setClasses] = useState<ClassItem[]>([]);
  const [dayFilter, setDayFilter] = useState('');
  const [timeFilter, setTimeFilter] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  // Set states from passed props
  useEffect(() => {
    const { day, time, hasClasses } = location.state;

    if (hasClasses) {
      setDayFilter(day);
      setTimeFilter(time);
    }
  }, [location.state.day, location.state.time]);

  // Fetch classes from the API
  useEffect(() => {
    const [isValid] = isEligibleForSignup(3);

    if (!isValid) {
      setShowModal(true);

      return;
    }

    fbPixelTrackPageViewEvent();

    // Initialize LinkedIn Insight
    LinkedInTag.init(ANALYTICS.Insight);
    api.fetchClasses()
      .then((c) => {
        setIsFetching(false);
        setClasses(c);
      })
      .catch(() => {
        setIsFetching(false);
        setClasses([]);
      });
  }, []);

  const filteredClasses = useMemo(() => {
    return classes
      .filter(c => moment(c.date).isSameOrAfter(new Date()))
      .filter(c => {
        switch (dayFilter) {
          case ClassDayOption.WEEKDAY:
            return !isWeekend(c.date);
          case ClassDayOption.WEEKEND:
            return isWeekend(c.date);
          default:
            return true;
        }
      })
      .filter(c => timePeriod(c.date, (timeFilter as ClassTimeOption)))
      .filter(c => c.participants.length < c.maxParticipants);
  }, [classes, dayFilter, timeFilter]);

  return (
    <>
      <AppNav hideMenu />
      <Container>
        {showModal && quizModalComponent(showModal)}
        {showErrorModal && (
          <BaseModal
            title="Signup"
            toDisplay={showErrorModal}
            onConfirm={() => setShowErrorModal(false)}
            confirmTitle="Ok">
            <label className="ModalLabel">
              {CREATE_MESSAGE_FAILURE}
            </label>
          </BaseModal>
        )}

        <h1 className="class-search-title">Pick a Class</h1>
        <div className="class-search-info">
          Please pick a day and time that would be convenient for you to video chat from your
          mobile phone or computer with your dietitian on the majority of weeks.
          Classes meet weekly at the beginning of the program.
          Use the filters on the top right to pick which day of the week you prefer
          and if you prefer morning, afternoons, or evenings!
        </div>

        <Row className="class-filters">
          <Col xs={12} lg={1}>
            <h3 className="class-filters-title">Filters</h3>
          </Col>
          <Col xs={12} lg={{ span: 5, offset: 6 }} className="class-filter-controls">
            <div className="form-group">
              <label>Day</label>
              <Dropdown
                selectedItem={dayOptions.find(o => o.value === dayFilter)!}
                items={dayOptions}
                onItemSelection={(i) => {
                  setCurrentPage(0);
                  setDayFilter(i.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Time</label>
              <Dropdown
                selectedItem={timeOptions.find(o => o.value === timeFilter)!}
                items={timeOptions}
                onItemSelection={(i) => {
                  setCurrentPage(0);
                  setTimeFilter(i.value)
                }}
              />
            </div>
          </Col>
        </Row>

        <div className="classes">
          {!isFetching
            ? <>
                {filteredClasses.length > 0 ?
                  <Paginate
                    currentPage={currentPage}
                    items={filteredClasses}
                    perPage={5}
                    onChangePage={p => setCurrentPage(p)}
                    renderItem={c =>
                      <ParticipantClassCard
                        key={c.id}
                        item={c}
                        disabled={disabled}
                        isSelected={c.id == selectedId}
                        onSignup={(id) => {
                          setDisabled(true);
                          setSelectedId(id);
                        }}
                        onError={() => {
                          setDisabled(false);
                          setSelectedId('');
                          setShowErrorModal(true);
                        }} />
                    }
                  />
                : <NoResults subject="classes" />
                }
              </> :
              <div className="QuizAnalytics-centerContainer">
                <Spinner text="Fetching classes..." />
              </div>
            }
        </div>
      </Container>
    </>
  );
}
