import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import {
  toast,
  Slide,
  ToastContainer,
} from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
import moment from 'moment-timezone';

import Login from './containers/Login';

import SuperuserHome from './containers/SuperuserHome';
import SuperuserClassDetails from './containers/SuperuserClassDetails';
import SuperuserAnalyticsTracking from './containers/SuperuserAnalyticsTracking';
import SuperuserMilestoneAnalytics from './containers/SuperuserAnalyticsTracking/MilestoneAnalytics';
import SuperuserSignupAnalytics from './containers/SuperuserAnalyticsTracking/SignupAnalytics';
import SuperuserWeightLossAnalytics from './containers/SuperuserAnalyticsTracking/WeightLossAnalytics';
import SuperuserQuizAnalytics from './containers/SuperuserAnalyticsTracking/QuizAnalytics';
import SuperuserAttendanceAnalytics from './containers/SuperuserAnalyticsTracking/AttendanceAnalytics';
import SuperuserMilestoneTracking from './containers/SuperuserAnalyticsTracking/MilestoneTracking';
import SuperuserSignupTracking from './containers/SuperuserAnalyticsTracking/SignupTracking';
import SuperuserWeightLossTracking from './containers/SuperuserAnalyticsTracking/WeightLossTracking';
import SuperuserDietitianPayment from './containers/SuperuserAnalyticsTracking/DietitianPayments';
import SuperuserDietitianPaymentDetails from './containers/SuperuserAnalyticsTracking/DietitianPayments/Details';
import SuperuserUnitEconomics from './containers/SuperuserAnalyticsTracking/UnitEconomics';
import SuperuserUnitEconomicsDetails from './containers/SuperuserAnalyticsTracking/UnitEconomics/Details';
import SuperuserEligibilityErrors from './containers/SuperuserAnalyticsTracking/EligibilityErrors';
import SuperuserConsumerPayment from './containers/SuperuserAnalyticsTracking/ConsumerPayments';
import SuperuserConsumerPaymentDetails from './containers/SuperuserAnalyticsTracking/ConsumerPayments/Details';
// import SuperuserActiveUsers from './containers/SuperuserAnalyticsTracking/ActiveUsers';
import SuperuserEmployerAnalytics from './containers/SuperuserAnalyticsTracking/EmployerAnalytics';
import SuperuserContracts from './containers/Contracts';
import SuperuserContractDetails from './containers/Contracts/Details';
import SuperuserAutoScheduling from './containers/SuperuserAutoScheduling';
import SuperuserMonthlyInvoice from './containers/SuperuserAnalyticsTracking/MonthlyInvoices';
import SuperuserMonthlyInvoiceDetails from './containers/SuperuserAnalyticsTracking/MonthlyInvoices/Details';
import SuperuserDoctors from './containers/Doctors';
import SuperuserDoctorDetails from './containers/Doctors/Details';

import DietitianClasses from './containers/DietitianClasses';
import DietitianClassDetail from './containers/DietitianClassDetail';

import ParticipantWizard from './containers/ParticipantWizard';
import ParticipantClassSearch from './containers/ParticipantClassSearch';
import ParticipantEligibility from './containers/ParticipantEligibility';
import ParticipantPayment from './containers/ParticipantPayment';

import DataExplorer from './containers/DataExplorer';
import RedirectPage from './containers/RedirectPage';

import { store, persistor } from './redux';

import * as serviceWorker from './serviceWorker';

import { toggleZendeskScript } from './utils';
import {
  ANALYTICS,
  ROUTES,
  QUIZ_URL,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
} from './utils/constants';
import { UserType } from './utils/types';

import 'react-toastify/dist/ReactToastify.css';
import 'typeface-nunito';

import '../src/index.scss';

Sentry.init({dsn: SENTRY_DSN, environment: SENTRY_ENVIRONMENT});

const onBeforeLift = () => {
  toggleZendeskScript();
}

const PrivateRoute = ({ component: Component, type, ...rest }) => {
  const { accessToken, user } = store.getState().authentication;
  const isAuthenticated = (accessToken && user && user.type == type);

  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ?
        <Component {...props} /> : <Redirect to={ROUTES.Login} />
      )}
    />
  )
}

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    // Global setting of timezone
    moment.tz.setDefault(moment.tz.guess(true));

    if (isProduction) {
      // Initialize FB Pixel
      const options = { autoConfig: true, debug: false };

      ReactPixel.init(ANALYTICS.FBPixel, undefined, options);
    }
  }, [])

  return (
    <div>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={onBeforeLift}
        >
          <Router basename={isProduction ? 'laravel' : ''}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (isProduction) {
                    window.location.replace(QUIZ_URL);
                  }

                  return null;
                }}
              />

              <Route exact path="/login" component={Login} />

              <PrivateRoute
                exact
                path={ROUTES.SuperuserDashboard}
                component={SuperuserHome}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserCreateClass}
                component={SuperuserClassDetails}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserClassDetails}
                component={SuperuserClassDetails}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserAnalyticsTracking}
                component={SuperuserAnalyticsTracking}
                type={UserType.SUPERUSER} />

              <PrivateRoute
                exact
                path={ROUTES.SuperuserMilestoneAnalytics}
                component={SuperuserMilestoneAnalytics}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserSignupAnalytics}
                component={SuperuserSignupAnalytics}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserWeightLossAnalytics}
                component={SuperuserWeightLossAnalytics}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserQuizAnalytics}
                component={SuperuserQuizAnalytics}
                type={UserType.SUPERUSER} />
              {/* <PrivateRoute
              exact
              path={ROUTES.SuperuserAttendanceAnalytics}
              component={SuperuserAttendanceAnalytics}
              type={UserType.SUPERUSER} /> */}
              <PrivateRoute
                exact
                path={ROUTES.SuperuserMilestoneTracking}
                component={SuperuserMilestoneTracking}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserSignupTracking}
                component={SuperuserSignupTracking}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserWeightLossTracking}
                component={SuperuserWeightLossTracking}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserDietitianPayments}
                component={SuperuserDietitianPayment}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserDietitianPaymentDetails}
                component={SuperuserDietitianPaymentDetails}
                type={UserType.SUPERUSER} />
              {/* <PrivateRoute
              exact
              path={ROUTES.SuperuserUnitEconomics}
              component={SuperuserUnitEconomics}
              type={UserType.SUPERUSER} />
            <PrivateRoute
              exact
              path={ROUTES.SuperuserUnitEconomicsDetails}
              component={SuperuserUnitEconomicsDetails}
              type={UserType.SUPERUSER} /> */}
              <PrivateRoute
                exact
                path={ROUTES.SuperuserEligibilityErrors}
                component={SuperuserEligibilityErrors}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserConsumerPayments}
                component={SuperuserConsumerPayment}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserConsumerPaymentDetails}
                component={SuperuserConsumerPaymentDetails}
                type={UserType.SUPERUSER} />
              {/* <PrivateRoute
                exact
                path={ROUTES.SuperuserActiveUsers}
                component={SuperuserActiveUsers}
                type={UserType.SUPERUSER} /> */}
              <PrivateRoute
                exact
                path={ROUTES.SuperuserEmployerAnalytics}
                component={SuperuserEmployerAnalytics}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserContracts}
                component={SuperuserContracts}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserCreateContractDetails}
                component={SuperuserContractDetails}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserUpdateContractDetails}
                component={SuperuserContractDetails}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserMonthlyInvoices}
                component={SuperuserMonthlyInvoice}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserMonthlyInvoiceDetails}
                component={SuperuserMonthlyInvoiceDetails}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserDoctors}
                component={SuperuserDoctors}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserCreateDoctorDetails}
                component={SuperuserDoctorDetails}
                type={UserType.SUPERUSER} />
              <PrivateRoute
                exact
                path={ROUTES.SuperuserUpdateDoctorDetails}
                component={SuperuserDoctorDetails}
                type={UserType.SUPERUSER} />

              {/* <PrivateRoute
                exact
                path={ROUTES.SuperuserAutoScheduling}
                component={SuperuserAutoScheduling}
                type={UserType.SUPERUSER} /> */}

              <PrivateRoute
                exact
                path={ROUTES.DietitianClasses}
                component={DietitianClasses}
                type={UserType.DIETITIAN} />
              <PrivateRoute
                exact
                path={ROUTES.DietitianClassDetails}
                component={DietitianClassDetail}
                type={UserType.DIETITIAN} />

              <Route
                exact
                path={ROUTES.ParticipantClasses}
                component={ParticipantClassSearch} />
              <Route
                exact
                path={ROUTES.ParticipantEligibility}
                component={ParticipantEligibility} />
              <Route
                exact
                path={ROUTES.ParticipantWizard}
                component={ParticipantWizard} />
              <Route
                exact
                path={ROUTES.ParticipantPayment}
                component={ParticipantPayment} />

              <PrivateRoute
                exact
                path={ROUTES.DataExplorer}
                component={DataExplorer}
                type={UserType.SUPERUSER} />

              <Route component={RedirectPage} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>

      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        autoClose={3000}
        transition={Slide}
        bodyClassName="Toast"
      />
    </div>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
