import { toast } from 'react-toastify';
import axios from 'axios';

import { practiceConstants } from '../utils';

import {
  Contract,
  ContractData,
  ContractMilestone,
} from '../types/contracts';

import APIClient, { autoClose } from '.';

export default class ConsumerPaymentsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchContracts = (
    queryString: string
  ): Promise<[Contract[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/company${queryString}`;
        const response = await this.instance.get(url);
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const list: Contract[] = data.map((item: any): Contract => ({
          id: item.id,
          name: item.name,
          date: new Date(item.contract_started),
          totalParticipants: item.participants,
          activeClasses: item.active_classes,
          finishedClasses: item.finished_classes
        }));

        resolve([list, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchContractDetails = (id: string): Promise<ContractData> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/company/${id}`;
        const response = await this.instance.get(url);
        const detailsResponse = await this.instance.get(`/company/stats?company=${id}`);
        const data = response.data.data;
        const details = detailsResponse.data.data;
        const item: ContractData = {
          id: data.id,
          name: data.name,
          contactPerson: data.contact_person,
          phone: data.mobile_phone,
          email: data.email_address,
          date: new Date(details.contract_started),
          streetAddress: data.street_address,
          unitNo: data.unit_no,
          city: data.city,
          state: data.state,
          zipCode: data.zip_code,
          country: data.country,
          dietitianFee: data.dietitian_fee,
          milestones: Object.keys(data.milestones).map((key: string): ContractMilestone => ({
            id: key,
            description: data.milestones[key].description,
            claimAmount: parseFloat(data.milestones[key].claims_amount),
            dietitianPayment: parseFloat(data.milestones[key].dietitian_fee),
          })),
          totalParticipants: details.participants,
          activeClasses: details.active_classes,
          finishedClasses: details.finished_classes,
        };

        resolve(item);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  createContract = (payload: any): Promise<ContractData> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.instance.post('/company', payload);

        resolve(response.data.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  updateContract = (id: string, payload: any): Promise<ContractData> => (
    new Promise(async (resolve, reject) => {
      try {
        delete payload.name;

        const response = await this.instance.patch(`/company/${id}`, payload);

        resolve(response.data.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  deleteContract = (id: string): Promise<any> => (
    new Promise(async (resolve, reject) => {
      try {
        await this.instance.delete(`/company/${id}`);

        resolve('Successfully deleted contract.');
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  createContractMilestone = (payload: any): Promise<any> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.instance.post('/company/milestone', payload);

        resolve(response.data.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  deleteContractMilestone = (company: string, id: string): Promise<any> => (
    new Promise(async (resolve, reject) => {
      try {
        await this.instance.delete(`/company/${company}/${id}`);

        resolve('Successfully deleted contract milestone.');
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
