import React, { useState } from 'react';

import moment from 'moment-timezone';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import isUndefined from 'lodash-es/isUndefined';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faUsers,
  faCalendarDay,
  faCalendarCheck,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';

import Button from '../../components/Button';
import BaseModal from '../../components/BaseModal';

import { store } from '../../redux';

import { practiceConstants } from '../../utils';
import {
  FORMAT_DDDD,
  FORMAT_H_MMA,
  FORMAT_MMMM_Do_YYYY,
  CLASS_STATUS,
} from '../../utils/constants';
import { ClassItem } from '../../utils/types';

import './index.scss';

interface Props {
  busy: boolean;
  item: ClassItem;
  claimed: boolean;
  withViewParticipants?: boolean;
  onClaim?: (classItem: ClassItem) => void;
  onUnclaim?: (classItem: ClassItem) => void;
}

export default function DietitianClassCard(props: Props) {
  const { item, claimed, withViewParticipants, onClaim, onUnclaim } = props;
  const classDate = moment(item.date);
  const now = moment();

  const [showModal, setShowModal] = useState(false);
  const [showUnclaimModal, setShowUnclaimModal] = useState(false);

  const claimClass = () => {
    if (!isUndefined(onClaim)) {
      onClaim(item);
    }
  };

  const unclaimClass = () => {
    setShowUnclaimModal(false);

    if (!isUndefined(onUnclaim)) {
      onUnclaim(item);
    }
  }

  const { busy } = props;
  const { name } = item;
  const status = item.status.value;
  const userId = store.getState().authentication.user!.id;
  const isAvailable = status == CLASS_STATUS.Open;
  const isOwned = claimed && item.dietitianId == userId;
  const isPrev = moment(item.date).isSameOrBefore(new Date());

  return (
    <div className={practiceConstants().className}>
      <div className={claimed ? "class claimed" : "class"}>
        <div className="class-header">
          <h2 className="class-code">
            {name.indexOf('Class ') > -1 ? name : `Class ${name}`}
          </h2>
          <time dateTime={classDate.toISOString()} className="class-time">
            <FontAwesomeIcon icon={faBullhorn} />
            This class start{now.to(classDate).indexOf('ago') > -1 ? 'ed' : 's'} <strong>{now.to(classDate)}</strong>
          </time>
        </div>
        <Row className="class-details">
          <Col xs={12} lg={3} className="class-detail-item">
            <h3 className="class-detail-title">
              <FontAwesomeIcon icon={faCalendar} />
              First Day of Class
            </h3>
            <span className="class-highlight">
              {classDate.format(FORMAT_MMMM_Do_YYYY)}
            </span>
          </Col>
          <Col xs={12} lg={2} className="class-detail-item">
            <h3 className="class-detail-title">
              <FontAwesomeIcon icon={faCalendarDay} />
              Meets Every
            </h3>
            <span className="class-highlight">
              {moment(classDate).format(FORMAT_DDDD)}
            </span>
          </Col>
          <Col xs={12} lg={2} className="class-detail-item">
            <h3 className="class-detail-title">
              <FontAwesomeIcon icon={faClock} />
              Class Time
            </h3>
            <span className="class-highlight">
              {classDate.format(FORMAT_H_MMA)}
            </span>
            &nbsp;
            {moment.tz.zone(moment.tz.guess())!.abbr(classDate.unix())}
          </Col>
          <Col xs={12} lg={2} className="class-detail-item">
            <h3 className="class-detail-title">
              <FontAwesomeIcon icon={faUsers} />
              Participants
            </h3>
            <span className="class-highlight">
              {item.participants.length}/{item.maxParticipants}
            </span>
          </Col>
          <Col xs={12} lg={3} className="class-detail-item">
            <h3 className="class-detail-title">
              <FontAwesomeIcon icon={faCalendarCheck} />
              Status
            </h3>
            <span className="class-status" style={{ backgroundColor: item.status.color }}>
              {item.status.value == CLASS_STATUS.Open ? 'Available' : item.status.value}
            </span>
          </Col>
        </Row>
        <div className="class-actions">
          {claimed
            ? <Button
                disabled={!isOwned || isPrev}
                loading={busy}
                variant={!isOwned ? "outline-success" : "success"}
                onClick={() => {
                  if (item.participants.length === 0) {
                    setShowUnclaimModal(true);
                  } else {
                    setShowModal(true);
                  }
                }}>
                Unclaim
              </Button>
            : <Button
                // disabled={!isAvailable || (hasDietitians && (item.maxDietitians! >= item.dietitianIds!.length))}
                disabled={!isAvailable}
                variant="primary"
                loading={busy}
                onClick={claimClass}
              >
                Claim Class
              </Button>}
          { withViewParticipants === true
            ? <LinkContainer
                to={{
                  pathname: `/dietitian/classes/${item.id}`,
                  state: { class: item },
                }}
              >
                <Button variant="primary">View Participants</Button>
              </LinkContainer>
            : null }
        </div>
      </div>
      <BaseModal
        toDisplay={showModal}
        title="Unclaim Class"
        confirmTitle="Ok"
        className="class-unclaim-error"
        onClose={() => setShowModal(false)}
        onConfirm={() => setShowModal(false)}
      >
        <h3 className="BaseModal-title">
          There are already participants signed up for the class.
          You can no longer unclaim this class.
        </h3>
        <p>
          If you want to inquire regarding this cancellation request,<br />
          please email <a href="mailto:support@fruitstreet.com">support@fruitstreet.com</a>.
        </p>
      </BaseModal>
      <BaseModal
        toDisplay={showUnclaimModal}
        title="Unclaim Class"
        confirmTitle="Confirm"
        cancelTitle="Cancel"
        onConfirm={unclaimClass}
        onCancel={() => setShowUnclaimModal(false)}
        onClose={() => setShowUnclaimModal(false)}
      >
        <label className="ModalLabel">
          Are you sure you want to unclaim this class?
        </label>
      </BaseModal>
    </div>
  );
}
