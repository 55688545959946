import React, { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import isUndefined from 'lodash-es/isUndefined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import MilestoneDropdown from '../../../components/MilestoneDropdown';

import { MilestoneData, MilestoneStatus } from '../../../types/milestones';

import { FORMAT_MM_DD_YYYY, CLIENT_TYPE, ROUTES } from '../../../utils/constants';
import { practiceConstants } from '../../../utils';

import ExpandedRow from '../ExpandedRow';

import './index.scss';
import { useLocation } from 'react-router';

interface Props {
  isMilestonesOnly?: boolean;
  participant: MilestoneData;
  disabled?: boolean;
  onStatusChange?: (milestone: number, status: MilestoneStatus) => Promise<void>;
  onDeleteRow?: (participant: MilestoneData) => void;
  onErrorClick?: (participant: MilestoneData, milestone: number) => void;
}

export default function MilestoneTrackingRow(props: Props) {
  const {
    disabled,
    isMilestonesOnly,
    participant,
    onStatusChange,
    onDeleteRow,
    onErrorClick
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleErrorClick = (milestone: number) => {
    if (!isUndefined(onErrorClick)) {
      onErrorClick(participant, milestone);
    }
  };

  const tdStyle = { verticalAlign: 'middle' };
  const { practiceName } = practiceConstants();
  const location = useLocation();

  return (
    <>
      <tr
        className={classnames(
          'milestone-tracking__row',
          { 'milestone-tracking__row--expanded': !isCollapsed }
        )}
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{ 'cursor': 'pointer' }}
      >
      { location.pathname != ROUTES.SuperuserMilestoneAnalytics &&
        <td className="milestone-tracking__cell" style={tdStyle}>
          {(participant.sponsorId !== null)
            ? participant.sponsorId
            : 'Not Issued'}
        </td>}
        <td className="milestone-tracking__cell" style={tdStyle}>
          {participant.lastName}
        </td>
        <td className="milestone-tracking__cell" style={tdStyle}>
          {participant.firstName}
        </td>
        <td className="milestone-tracking__cell" style={tdStyle}>
          {moment(participant.signupDate).format(FORMAT_MM_DD_YYYY)}
        </td>
        <td className="milestone-tracking__cell" style={tdStyle}>
          <MilestoneDropdown
            id={`${participant.id}-m1`}
            disabled={disabled || false}
            paymentValue={participant.paymentOne}
            type={participant.milestoneOne}
            value={participant.milestoneOne}
            onChange={s => onStatusChange!(1, s)}
            onErrorClick={() => handleErrorClick(1)}
          />
        </td>
        {!isMilestonesOnly &&
          <td className="milestone-tracking__cell" style={tdStyle}>
            <MilestoneDropdown
              id={`${participant.id}-ds`}
              disabled={disabled}
              type='scale'
              value={participant.deviceScale}
              onChange={s => onStatusChange!(10, s)}
            />
          </td>
        }
        <td className="milestone-tracking__cell" style={tdStyle}>
          <MilestoneDropdown
            id={`${participant.id}-m2`}
            disabled={disabled || false}
            paymentValue={participant.paymentTwo}
            type={participant.milestoneTwo}
            value={participant.milestoneTwo}
            onChange={s => onStatusChange!(2, s)}
            onErrorClick={() => handleErrorClick(2)}
          />
        </td>
        {!isMilestonesOnly &&
          <td className="milestone-tracking__cell" style={tdStyle}>
            <MilestoneDropdown
              id={`${participant.id}-df`}
              disabled={disabled}
              type='fitbit'
              value={participant.deviceFitbit}
              onChange={s => onStatusChange!(20, s)}
            />
          </td>
        }
        <td className="milestone-tracking__cell" style={tdStyle}>
          <MilestoneDropdown
            id={`${participant.id}-m3`}
            disabled={disabled || false}
            paymentValue={participant.paymentThree}
            type={participant.milestoneThree}
            value={participant.milestoneThree}
            onChange={s => onStatusChange!(3, s)}
            onErrorClick={() => handleErrorClick(3)}
          />
        </td>
        <td className="milestone-tracking__cell" style={tdStyle}>
          <MilestoneDropdown
            id={`${participant.id}-m4`}
            disabled={disabled || false}
            paymentValue={participant.paymentFour}
            type={participant.milestoneFour}
            value={participant.milestoneFour}
            onChange={s => onStatusChange!(4, s)}
            onErrorClick={() => handleErrorClick(4)}
          />
        </td>
        <td className="milestone-tracking__cell actions" style={tdStyle}>
          <div>
            <FontAwesomeIcon
              icon={isCollapsed ? faAngleDown : faAngleUp}
              style={{ marginRight: 0, width: 14 }}
            />
          </div>
        </td>
      </tr>

      {!isCollapsed ?
        <ExpandedRow
          item={participant}
          readOnly={disabled}
          onDelete={item => {
            if (onDeleteRow) {
              onDeleteRow(item)
            }
          }}
        /> : null}
    </>
  );
}
