import React, { ReactNode, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';
import _ from 'lodash';

import { practiceConstants } from '../../utils';

import './index.scss';

interface Props {
  children?: ReactNode;
  cancelTitle?: string;
  cancelClass?: string;
  confirmTitle?: string;
  confirmClass?: string;
  onCancel?: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onClose?: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onConfirm?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  title: string;
  toDisplay: boolean;
  className?: string;
  lg?: boolean;
  disabled?: boolean;
  loading?: boolean;
  footerButton?: ReactNode;
}

const BaseModal = (props: Props) => {
  const { title, toDisplay, cancelClass, className, lg, disabled, loading } = props;

  useEffect(() => {
    if (toDisplay) {
      disableBodyScroll();
    }

    return () => enableBodyScroll();
  })

  const onClose = () => {
    if (!loading && props.onClose) {
      props.onClose();
    }
  }

  return (
    // <Modal
    //   centered
    //   backdrop="static"
    //   className={practiceConstants().className}
    //   dialogClassName={classnames(
    //     'BaseModal-container',
    //     className || '',
    //     lg ? 'lg' : ''
    //   )}
    //   show={toDisplay}
    //   onHide={onClose}>
    //   <Modal.Header closeButton={!_.isUndefined(props.onClose)}>
    //     <Modal.Title>{title}</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body className="BaseModal-body">
    //     {props.children}
    //   </Modal.Body>
    //   <Modal.Footer>
    //     {props.onCancel && (
    //       <button
    //         disabled={disabled || loading}
    //         type="button"
    //         className={classnames(
    //           'btn btn-secondary BaseModal-cancelButton',
    //           cancelClass? cancelClass: '',
    //         )}
    //         onClick={props.onCancel}>
    //         {props.cancelTitle ? props.cancelTitle! : 'No'}
    //       </button>
    //     )}

    //     {props.onConfirm && (
    //       <button
    //         disabled={disabled || loading}
    //         type="button"
    //         className="btn btn-primary BaseModal-continueButton"
    //         onClick={props.onConfirm}>
    //         {loading
    //           ? <FontAwesomeIcon icon={faSpinner} spin />
    //           : props.confirmTitle ? props.confirmTitle! : "Yes"}
    //       </button>
    //     )}
    //   </Modal.Footer>
    // </Modal>

    // Prev. implementation
    <div className={toDisplay ? 'BaseModal-overlay' : 'BaseModal-overlay hidden'}>
      <div
        className={classnames(
          practiceConstants().className,
          'BaseModal-wrapper',
          `animation${!toDisplay ? 'Exit' : ''}`,
          `${className || ''} ${lg ? 'lg' : ''}`
        )}>
        <div className="BaseModal-header">
          <label>{title}</label>

          {props.onClose && (
            <span className="BaseModal-closeButton" onClick={e => {
              if (!loading && props.onClose) {
                props.onClose(e);
              }
            }}>
              <FontAwesomeIcon icon={faTimes} />
            </span>)
          }
        </div>
        <div className="BaseModal-body">
          {props.children}
        </div>
        <div
          className="BaseModal-footer"
          style={{ justifyContent: props.footerButton ? 'space-between' : 'center' }}>
          {props.footerButton}

          <div className="actionButtons">
            {props.onCancel && (
              <button
                disabled={loading}
                type="button"
                className={`btn btn-secondary BaseModal-cancelButton ${cancelClass ? cancelClass : ''}`}
                onClick={props.onClose}>
                {props.cancelTitle ? props.cancelTitle! : "No"}
              </button>
            )}

            {props.onConfirm && (
              <button
                disabled={disabled || loading}
                type="button"
                className="btn btn-primary BaseModal-continueButton"
                onClick={props.onConfirm}>
                {loading
                  ? <><FontAwesomeIcon icon={faSpinner} spin /></>
                  : props.confirmTitle ? props.confirmTitle! : "Yes"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BaseModal;
