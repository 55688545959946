import React from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faTimes,
  faDownload,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import Button from '../../../../components/Button';

import { FORMAT_MMMM_D_YYYY } from '../../../../utils/constants';
import { formatPercent } from '../../../../utils/formatting';

import { EmployerStatCardData } from '../Card';

import './index.scss';

interface Props {
  data: EmployerStatCardData;
  disabled?: boolean;
  asHeader?: boolean;
  isExporting?: boolean;
  isFetching?: boolean;
  onClick: (
    data: EmployerStatCardData,
    event?: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  onExportClick?: () => void;
}

export default function EmployerWeightLossStatCard(props: Props) {
  const {
    asHeader,
    data,
    disabled,
    isExporting,
    isFetching,
    onClick,
    onExportClick,
  } = props;
  const { type, columns, start_date, end_date }  = data;

  return (
    <Table
      className="EmployerWeightLossStatCard-container"
      style={{ opacity: isFetching ? 0.5 : 1 }}>
      <tbody>
        <tr>
          <th colSpan={12}>
            <div className="header">
              <label className="title">
                {type}{asHeader && ` (${moment(start_date).format(FORMAT_MMMM_D_YYYY)} - ${moment(end_date).format(FORMAT_MMMM_D_YYYY)})`}
              </label>

              {!asHeader ?
                <div className="buttons">
                  <Button
                    disabled={disabled || isExporting}
                    variant="primary"
                    onClick={() => {
                      if (onExportClick) {
                        onExportClick();
                      }
                    }}>
                    {isExporting ?
                      <FontAwesomeIcon style={{ marginRight: 0 }} icon={faSpinner} spin /> :
                      <FontAwesomeIcon className="download-icon" icon={faDownload} />
                    }
                  </Button>
                  <Button
                    disabled={disabled}
                    variant="primary"
                    onClick={() => onClick(data)}>
                    <FontAwesomeIcon icon={faChartLine} />

                    View Trend
                  </Button>
                </div> :
                <span className="BaseModal-closeButton" onClick={() => onClick(data)}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              }
            </div>
          </th>
        </tr>
        <tr className="header">
          {columns.slice(0, 7).map((item, index) => (
            <th key={index.toString()}>
              <strong><FontAwesomeIcon icon={item.icon} />{item.header}</strong>
            </th>
          ))}
        </tr>
        <tr className="main-data">
          {columns.slice(0, 7).map((item, index) => (
            <td key={index.toString()} className="primary">
              {index == 0 ? item.value :
                (item.value == '-' ? '-' : formatPercent(parseFloat(item.value), true))}
            </td>
          ))}
        </tr>
        <tr className="header">
          {columns.slice(7, 14).map((item, index) => (
            <th key={index.toString()}>
              <strong><FontAwesomeIcon icon={item.icon} />{item.header}</strong>
            </th>
          ))}
        </tr>
        <tr className="main-data">
          {columns.slice(7, 14).map((item, index) => (
            <td key={index.toString()} className="primary">
              {item.value == '-' ? '-' : formatPercent(parseFloat(item.value), true)}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  )
}
