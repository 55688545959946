import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from '../api/participant';

import { checkEligibility, Types } from '../redux/actions/ParticipantEligibility';

export function* checkEligibilitySaga(
  action: ReturnType<typeof checkEligibility.request>
): Generator {
  try {
    const client = new API();
    const response = yield call(client.checkEligibility, action.payload);

    // @ts-ignore
    yield put(checkEligibility.success(response));
  } catch (error) {
    yield put(checkEligibility.failure(error));
  }
}

export default [
  takeLatest(Types.CHECK_ELIGIBILITY, checkEligibilitySaga),
]
