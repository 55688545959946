import React, { useMemo, useState, useEffect } from 'react';

import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { isWeekend } from 'date-fns';
import { LinkContainer } from 'react-router-bootstrap';

import ParticipantAPIClient from '../../api/participant';

import BaseModal from '../../components/BaseModal';
import ParticipantClassCard from '../../components/ParticipantClassCard';
import Spinner from '../../components/Spinner';

import { timePeriod } from '../../utils';
import {
  CREATE_MESSAGE_FAILURE,
  ROUTES,
  SESSION_KEY_CLASS_PREFERENCE,
} from '../../utils/constants';
import {
  ClassDayOption,
  ClassTimeOption,
  ClassItem,
} from '../../utils/types';

type Props = Readonly<{
  day: ClassDayOption;
  time: ClassTimeOption;
}>

export default function ClassSelect(props: Props) {
  const { day, time } = props;
  const api = new ParticipantAPIClient();

  const [isFetching, setIsFetching] = useState(true);
  const [classes, setClasses] = useState<ClassItem[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  // Fetches classes from the API
  useEffect(() => {
    sessionStorage.setItem(
      SESSION_KEY_CLASS_PREFERENCE,
      JSON.stringify({ choices: [day, time] })
    );

    api.fetchClasses()
      .then((c) => {
        setClasses(c);
        setIsFetching(false);
      })
      .catch(() => {
        setClasses([]);
        setIsFetching(false);
      });
  }, []);

  // Filter classes to match provided day & time selections
  const filteredClasses = useMemo<ClassItem[]>(() => {
    return classes
      .filter(c => moment(c.date).isSameOrAfter(new Date()))
      .filter(c => {
        switch (day) {
          case ClassDayOption.WEEKDAY:
            return !isWeekend(c.date);
          case ClassDayOption.WEEKEND:
            return isWeekend(c.date);
          default:
            return true;
        }
      })
      .filter(c => timePeriod(c.date, time))
      .filter(c => c.participants.length < c.maxParticipants)
      .slice(0, 2); // Show the first 3 results
  }, [classes, day, time]);

  const renderSignupModal = (showModal: boolean) => (
    <BaseModal
      title="Signup"
      toDisplay={showModal}
      onConfirm={() => setShowModal(false)}
      confirmTitle="Ok">
      <label className="ModalLabel">
        {CREATE_MESSAGE_FAILURE}
      </label>
    </BaseModal>
  )

  const renderWelcomeModal = (showModal: boolean) => (
    <BaseModal
      title="Welcome to Fruit Street"
      toDisplay={showModal}
      onConfirm={() => setShowWelcomeModal(false)}
      confirmTitle="Ok">
      <>
        <label className="ModalLabel">
          Pick a day and time that would be convenient for you to meet with your dietitian.
        </label>
        <br />
        <br />
        <label className="ModalLabel-info">
          <span className="strong">There are 26 classes in this 1-year program.&nbsp;</span>
          Class will always meet on the same day and time (for example 5PM on Tuesday).
          In the beginning of the program, classes meet every week for 16 weeks.
          After that, class is every 2 to 3 weeks.
        </label>
      </>
    </BaseModal>
  )

  const hasFilteredClasses = filteredClasses.length > 0;

  return (
    <div className="wizard class-select">
      <p>Here are some online diabetes prevention classes starting soon:</p>

      {showWelcomeModal && renderWelcomeModal(showWelcomeModal)}
      {showModal && renderSignupModal(showModal)}

      <div className="wizard-class-options">
        {!isFetching
          ? <>
              {hasFilteredClasses ? filteredClasses.map(i =>
                <ParticipantClassCard
                  key={i.id}
                  item={i}
                  disabled={disabled}
                  isSelected={i.id == selectedId}
                  onSignup={(id) => {
                    setDisabled(true);
                    setSelectedId(id);
                  }}
                  onError={() => {
                    setDisabled(false);
                    setSelectedId('')
                    setShowModal(true);
                  }}
                />
              ) : (
                <div className="wizard-no-results">
                  <p className="info">No results</p>
                </div>
              )}

              {hasFilteredClasses && <p>Do none of these work for you?</p>}

              <LinkContainer
                to={{
                  pathname: ROUTES.ParticipantClasses,
                  state: { day, time, hasClasses: filteredClasses.length > 0 },
                }}
              >
                <Button variant="primary" size="lg">See more classes</Button>
              </LinkContainer>
            </>
          :
            <div className="QuizAnalytics-centerContainer">
              <Spinner text="Fetching matching classes..." />
            </div>
          }
      </div>
    </div>
  );
}
