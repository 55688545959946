import { ActionType, createAction } from 'typesafe-actions';

export enum Types {
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  STORE_FILTERS = 'STORE_FILTERS',
  STORE_PAGE = 'STORE_PAGE',
}

export type ReducerActionType = ActionType<
  | typeof clearFilters
  | typeof storeFilters
  | typeof storePage
>

export const clearFilters = createAction(Types.CLEAR_FILTERS, resolve => {
  return () => resolve();
});

export const storeFilters = createAction(Types.STORE_FILTERS, resolve => {
  return (
    filterDay: string,
    filterTime: string,
    filterStatus: string,
    searchText: string = '',
    startDate?: Date,
    endDate?: Date,
  ) => resolve({
    filterDay,
    filterTime,
    filterStatus,
    searchText,
    startDate,
    endDate,
  });
})

export const storePage = createAction(Types.STORE_PAGE, resolve => {
  return (page: number) => resolve(page);
});
