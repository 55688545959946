import React, { useState, useEffect } from 'react';
import moment from 'moment';

import DietitianPaymentsAPIClient from '../../../../api/dietitian-payments';
import EmployerAnalyticsAPIClient from '../../../../api/employer-analytics';

import BaseModal from '../../../../components/BaseModal';

import { downloadCSV, downloadPDF } from '../../../../utils';
import { BASE_URL_INGENUITY, FORMAT_MMM_YYYY } from '../../../../utils/constants';
import { DropdownItem, ExportType as Type } from '../../../../utils/types';

import './index.scss';

interface Props {
  data: ExportData;
  title: string;
  toDisplay: boolean;
  onClose: () => void;
}

export type ExportData = {
  id?: string;
  employer: DropdownItem;
  date: Date;
  items?: ExportItem[];
};

export type ExportItem = {
  title: string;
  value: string;
};

export default function ExportModal(props: Props) {
  const { data, title, toDisplay, onClose } = props;
  const { employer, date, items } = data;
  const api = new EmployerAnalyticsAPIClient(BASE_URL_INGENUITY);
  const paymentsApi = new DietitianPaymentsAPIClient(BASE_URL_INGENUITY);
  const endDate =  moment(date).endOf('month').toDate();
  const checkboxes = [{ title: 'Select All', value: 'all' }, ...(items || [])];

  // States
  const [hasNoFilters, setHasNoFilters] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selected, setSelected] = useState(checkboxes[0].value);
  const [values, setValues] = useState(checkboxes.map(() => true));

  useEffect(() => {
    setHasNoFilters(false);
    setSelected(checkboxes[0].value);
    setValues(checkboxes.map(() => true));
  }, [items])

  const payloadForType = (): {
    endpoint: string,
    key: string,
    value: string | number[] | undefined
  } => {
    const value = selected === checkboxes[0].value ? undefined : selected;

    switch (title) {
      case Type.PreDiabetes:
        return {
          endpoint: 'quiz-results',
          key: 'risk_assessment',
          value: selected,
        };

      case Type.Enrollment:
        return { endpoint: 'enrollment-results', key: 'gender', value };

      case Type.Milestone:
      case Type.Claims:
        return {
          endpoint: title == Type.Milestone ? 'milestone-summary' : 'claim-reports',
          key: 'milestone',
          value: values
            .map((item, index) => item ? index : -1)
            .filter(item => item > 0)
        };

      case Type.Eligibility:
        return { endpoint: 'eligibility-summary', key: 'status', value };

      default:
        return { endpoint: '', key: '', value: '' };
    }
  }

  const onCheckboxSelect = (index: number) => {
    let hasSelectedAll = true;
    const v = [...values];

    if (index > 0 && v[0]) {
      // Uncheck and check selected one
      for (let i = 1; i < v.length; i++) {
        v[i] = false;
      }
    }

    v[index] = !v[index];

    if (index == 0) {
      // Check if 'Select All'
      for (let i = 1; i < v.length; i++) {
        v[i] = v[0]; // Select or deselect
      }
    } else if (v[0] && !v[index]) {
      // Deselects the 'Select All' if one or more items are deselected
      v[0] = false;
    } else {
      // Select the 'Select All' once all items are selected
      for (let i = 1; i < v.length; i++) {
        if (!v[i]) {
          hasSelectedAll = false;
        }
      }

      v[0] = hasSelectedAll;
    }

    const answerIndex = v[0] ? 0 : index;

    setHasNoFilters(v.filter((value) => value == true).length == 0)
    setSelected(checkboxes[answerIndex].value);
    setValues(v);
  }

  const onExportData = () => {
    const { endpoint, key, value } = payloadForType();

    setIsProcessing(true);
    api.exportEmployerStatus(
      employer.value,
      endpoint,
      key,
      value,
      date,
      endDate,
    ).then(response => {
      const valString = !value ? '-all' : (value instanceof Array ? '' : `-${value}`);
      const filename = `${moment(date).format('MMM-YYYY')}-${endpoint}${valString}.csv`;

      downloadCSV(response.data, filename);
      setIsProcessing(false);
      onClose();
    }).catch(() => {
      setIsProcessing(false);
    });
  }

  const onExportInvoice = () => {
    setIsProcessing(true);
    paymentsApi.exportMonthlyInvoice(data.id!, values)
      .then(response => {
        downloadPDF(response, `${moment(date).format(FORMAT_MMM_YYYY)} Sharecare Invoice.pdf`);
        setIsProcessing(false);
        onClose();
      })
      .catch(() => {
        setIsProcessing(false);
      });
  }

  return (
    <BaseModal
      cancelTitle="Cancel"
      confirmTitle="Export"
      disabled={hasNoFilters}
      footerButton={<div style={{ width: 1 }} />}
      loading={isProcessing}
      title={`Export (${title})`}
      toDisplay={toDisplay}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={() => {
        if (title === Type.MonthlyInvoice) {
          onExportInvoice();
          return;
        }

        onExportData();
      }}
    >
      <div className="ExportModal-container">
        <div className="info">
          <div>
            <label className="bold">Employer</label>
            <label>{employer.title}</label>
          </div>
          <div>
            <label className="bold">Month</label>
            <label>{moment(date).format(FORMAT_MMM_YYYY)}</label>
          </div>
        </div>

        <div className="selection">
          <label className="bold">
            Please select items to export:
          </label>

          {checkboxes.map((item: ExportItem, index: number) => (
            <div className="checkbox" key={index.toString()}>
              <input
                checked={values[index]}
                defaultValue={item.value}
                id={item.value}
                type="checkbox"
                onChange={() => onCheckboxSelect(index)}
              />
              <label className={`info${index == 0 ? ' bold' : ''}`} htmlFor={item.value}>
                {item.title}
              </label>
            </div>
          ))}
        </div>
      </div>
    </BaseModal>
  )
}
