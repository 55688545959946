import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';
import moment from 'moment';

import SignupTrackingAPIClient from '../../../api/signup-tracking';

import AppNav from '../../../components/AppNav';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import NoResults from '../../../components/NoResults';
import SearchInput from '../../../components/SearchInput';
import Spinner from '../../../components/Spinner';
import TotalResults from '../../../components/TotalResults';

import { useAPIPagination } from '../../../hooks/pagination';
import useDebounce from '../../../hooks/useDebounce';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import {
  SignupTrackingFilter as Filter,
  UserData,
} from '../../../types/signup-tracking';

import { practiceConstants, queryStringParams } from '../../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_YYYY_MM_DD,
  PER_PAGE,
  RISK,
  ROUTES,
} from '../../../utils/constants';

import SignupTrackingList from './List';

interface Props extends RouteComponentProps { }

export default function SignupTracking(props: Props) {
  const RISK_FILTER = [
    { title: RISK.High, value: 'high' },
    { title: RISK.Low, value: 'low' },
    { title: 'Show All', value: '' },
  ];
  const SHOW_ALL = { title: Filter.All.toString(), value: Filter.All.toString().toLowerCase() };
  const STEPS = [
    { title: Filter.Landing.toString(), value: 'landing' },
    { title: Filter.Quiz.toString(), value: 'quiz' },
    { title: Filter.Eligibility.toString(), value: 'eligibility' },
    { title: Filter.Scheduler.toString(), value: 'scheduler' },
    { title: Filter.SignedUp.toString(), value: 'signup' },
    SHOW_ALL,
  ];

  const api = new SignupTrackingAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className, defaultEmployer, employers } = practiceConstants();

  // Filter states
  const [employer, setEmployer] = useState(employers.filter(e => (
    e.value == query[QueryKeys.Company]
  ))[0] || defaultEmployer);
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );
  const [riskFilter, setRiskFilter] = useState(RISK_FILTER.filter(e => (
    e.value == query[QueryKeys.Risk]
  ))[0] || RISK_FILTER[RISK_FILTER.length - 1]);
  const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');
  const [stepFilter, setStepFilter] = useState(STEPS.filter(e => (
    e.value == query[QueryKeys.Step]
  ))[0] || SHOW_ALL);

  // Dataset states
  const [currentEmployer, setCurrentEmployer] = useState(employer);
  const [isFetching, setIsFetching] = useState(true);
  const [users, setUsers] = useState<UserData[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const queryString = useQueryString({
    [QueryKeys.Company]: employer.value,
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Search]: debouncedSearchTerm,
    [QueryKeys.Risk]: riskFilter.value.length > 0 ? riskFilter.value : undefined,
    [QueryKeys.Step]: stepFilter.value != SHOW_ALL.value ? stepFilter.value : undefined,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchSignupTracking(queryString, true)
      .then(response => {
        const [list, i, j, t] = response;

        setCurrentEmployer(employer);
        setIsFetching(false);
        setUsers(list);
        setFrom(i);
        setTo(j);
        setTotal(t);
      })
      .catch(() => {
        setCurrentEmployer(employer);
        setIsFetching(false);
        setUsers([]);
        setFrom(0);
        setTo(0);
        setTotal(0);
      });
  }, [employer, currentPage, debouncedSearchTerm, stepFilter, riskFilter, startDate, endDate]);

  const onChangeText = text => {
    setIsFetching(true);
    setCurrentPage(0);
    setSearchFilter(text);
  }

  const renderFilters = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={1}>
          <h3 className="primary-title">Filters</h3>
        </Col>
        <Col xs={12} lg={11} className="controls">
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Landing Date"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0]);
              setEndDate(dates[dates.length - 1]);
            }}
            onStartDateSelection={date => {
              setCurrentPage(0);
              setStartDate(date);
            }}
            onEndDateSelection={date => {
              setCurrentPage(0);
              setEndDate(date);
            }}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
          <Dropdown
            items={STEPS}
            selectedItem={stepFilter}
            title="Finished"
            onItemSelection={(item) => {
              setCurrentPage(0);
              setStepFilter(item);
            }} />
          <Dropdown
            items={RISK_FILTER}
            selectedItem={riskFilter}
            title="Risk"
            onItemSelection={(item) => {
              setCurrentPage(0);
              setRiskFilter(item);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderEmployer = () => (
    <div className={`employer-section ${className}`}>
      <Row>
        <Col xs={12} lg={3}>
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={item => setEmployer(item)}
          />
        </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <SignupTrackingList
          currentPage={currentPage}
          employer={currentEmployer}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={pagedUsers}
          setIsFetching={setIsFetching}
          setCurrentPage={setCurrentPage} />
      </>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Signup Tracking">
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Name, Email"
            onChangeText={onChangeText} />
        </Header>

        {renderFilters()}
        {renderEmployer()}

        {(isFetching && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> :
          renderList()
        }
      </Container>
    </>
  );
}
