import React, { ReactNode, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import isUndefined from 'lodash-es/isUndefined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { practiceConstants } from '../../utils';

import './index.scss';

interface Props extends RouteComponentProps {
  title: string;
  titleXs?: number;
  titleMd?: number;
  titleLg?: number;
  className?: string;
  children?: ReactNode;
  hasBack?: boolean;
  backUrl?: string;
  backHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Header = (props: Props) => {
  const { title, titleXs, titleMd, titleLg, className: c, children, hasBack, backHandler } = props;

  const actionXs = useMemo(() => isUndefined(titleXs) ? 12 : 12 - titleXs, [titleXs]);
  const actionMd = useMemo(() => isUndefined(titleMd) ? 6 : 12 - titleMd, [titleMd]);
  const actionLg = useMemo(() => isUndefined(titleLg) ? 8 : 12 - titleLg, [titleLg]);
  const className = c ? `page-header ${c}` : 'page-header';
  const onClick = backHandler || (() => (
    props.backUrl ? props.history.push(props.backUrl) : props.history.goBack()
  ));

  return (
    <div className={`${className} ${practiceConstants().className}`}>
      <Row>
        <Col
          xs={titleXs || 12} md={titleMd || 6} lg={titleLg || 4}
          style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
          {hasBack && (
            <Button
              type="button"
              className="page-header__back-btn"
              onClick={onClick}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </Button>
          )}

          <h1 className="page-header-title" style={{ marginBottom: 0, }}>
            {title}
          </h1>
        </Col>
        <Col xs={actionXs} md={actionMd} lg={actionLg} className="page-header-actions">
          {children || null}
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Header);
