import { toast } from 'react-toastify';

import ApiRequest from './api-request';
import {DropdownItem} from "../utils/types";

export class SharecareSponsorsRequest extends ApiRequest {
    async send(): Promise<{
        items: DropdownItem[]
    }> {
        const url = `/sharecare-sponsor-ids`;

        return this
            .get(this.backendApiUrl(url))
            .then((resp) => {
                const items = resp.data.map((item: any): DropdownItem => ({
                    title: item.sponsor_id,
                    value: item.sponsor_id
                }));

                return { items };
            })
            .catch(error => {
                toast.error(error.response.data.message || error.response.message);

                return { items: [] }
            })
    }
}

export class OtherSponsorsRequest extends ApiRequest {
    async send(): Promise<{
        items: DropdownItem[]
    }> {
        const url = `/other-sponsor-ids`;

        return this
            .get(this.backendApiUrl(url))
            .then((resp) => {
                const items = resp.data.map((item: any): DropdownItem => ({
                    title: item.sponsor_id,
                    value: item.sponsor_id
                }));

                return { items };
            })
            .catch(error => {
                toast.error(error.response.data.message || error.response.message);

                return { items: [] }
            })
    }
}
