import React, { ReactNode } from 'react';

import BsButton from 'react-bootstrap/Button';
import { faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './index.scss';

type Props = {
  variant: 'primary' | 'secondary' | 'success' | 'danger' | 'outline-success';
  type?: 'submit' | 'button' | 'reset';
  size?: 'sm' | 'lg';
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  children?: ReactNode;
  outline?: boolean;
  onClick?: () => void;
  icon?: IconDefinition;
}

export default function Button(props: Props) {
  const {
    variant,
    type,
    size,
    loading,
    loadingText,
    disabled,
    children,
    onClick,
    icon,
  } = props;

  return (
    <BsButton
      type={type}
      variant={variant}
      size={size}
      disabled={disabled || loading || false}
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} />}
      {loading
        ? <><FontAwesomeIcon icon={faSpinner} spin /> {loadingText || 'Please wait'}</>
        : children}
    </BsButton>
  );
}
