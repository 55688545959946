import { useMemo } from 'react';

enum QueryKeys {
  Page = 'page',
  FromDate = 'from_date',
  ToDate = 'to_date',
  Search = 'search',
  Order = 'order',
  Period = 'period',
  Milestone = 'milestone',
  Company = 'company',
  Type = 'type',
  Month = 'month',
  Year = 'year',
  Risk = 'risk_assessment',
  Step = 'step',
  MilestoneOne = 'milestone_one',
  MilestoneTwo = 'milestone_two',
  MilestoneThree = 'milestone_three',
  MilestoneFour = 'milestone_four',
  Fitbit = 'fitbit',
  SponsorID = 'sponsor_id',
  Rows = 'rows',
  GroupBy = 'group_by',
  Date = 'date',
  View = 'view',
}

const replaceQueryParam = (url: string, key: string, value: any) => {
  const regex = new RegExp('(?<=[?|&])(' + key + '=)[^\&]+', 'i');

  return url.replace(regex, key + '=' + value);
}

const useQueryString = (queryDict: { [id: string]: any | undefined }): string => (
  useMemo(() => {
    const keys = Object.keys(queryDict);
    let queryString = '';

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = queryDict[key];

      if (!value) {
        continue;
      }

      const keyValue = `${key}=${encodeURIComponent(value)}`;

      queryString = i == 0 ? `?${keyValue}` : `${queryString}&${keyValue}`;
    }

    return queryString;
  }, [queryDict])
)

export {
  replaceQueryParam,
  useQueryString,
  QueryKeys
}
