import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { StateType } from 'typesafe-actions';

import AuthenticationReducer from './Authentication';
import DietitianReducer from './Dietitian';
import ParticipantEligibilityReducer from './ParticipantEligibility';
import SuperUserClassDetailsReducer from './SuperuserClassDetails';
import SuperuserHomeReducer from './SuperuserHome';

const authPersistConfig = {
  key: 'authentication',
  storage,
  whitelist: ['accessToken', 'refreshToken', 'user', 'client']
}

const reducers = combineReducers({
  authentication: persistReducer(authPersistConfig, AuthenticationReducer),
  dietitian: DietitianReducer,
  participantEligibility: ParticipantEligibilityReducer,
  superuserClassDetails: SuperUserClassDetailsReducer,
  superuserHome: SuperuserHomeReducer,
});

export type RootState = StateType<typeof reducers>;

export default reducers;
