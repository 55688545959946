import * as React from 'react';
import moment from 'moment';
import Calendar, { CalendarTileProperties } from 'react-calendar/dist/entry.nostyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, IconDefinition } from '@fortawesome/free-solid-svg-icons'

import { practiceConstants } from '../../utils';
import {
  FORMAT_DD,
  FORMAT_MMM_YYYY,
  FORMAT_MM_DD_YYYY,
} from '../../utils/constants';
import { DateRange } from '../../utils/types';

import './index.scss';
import '../../calendar.scss';

interface Props {
  availableDates?: DateRange;
  currentDate?: Date;
  dateFormat: string;
  disabled?: boolean;
  errorMessage?: string;
  hasButton: boolean;
  icon?: IconDefinition;
  isInstant?: boolean;
  style?: React.CSSProperties,
  tileDisabled?: (props: CalendarTileProperties & { activeStartDate: Date }) => boolean;
  top?: boolean;
  onDateSelection: (OnChangeDateCallback) => void;
}

interface State {
  displayCalendar: boolean;
  isEditing: boolean;
  selectedDate?: Date;
}

class DateDropdown extends React.Component<Props, State> {
  static defaultProps: Props = {
    dateFormat: FORMAT_MM_DD_YYYY,
    hasButton: true,
    onDateSelection:() => {},
  }

  private node: HTMLDivElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      displayCalendar: false,
      isEditing: false,
    };

    document.addEventListener('mousedown', this.handleClick, false);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { currentDate } = nextProps;
    const { isEditing, selectedDate } = prevState;

    if (currentDate) {
      // const isInitial = !selectedDate && currentDate != selectedDate;

      return {
        ...prevState,
        // selectedDate: isInitial ? currentDate : selectedDate,
        isEditing: false,
        selectedDate: !isEditing ? currentDate : selectedDate,
      }
    }

    return null;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node) {
      return;
    }

    if (!this.node.contains(e.target) && this.state.displayCalendar) {
      this.toggleDropdownMenu();
    }
  }

  toggleDropdownMenu = () => {
    const { disabled } = this.props;
    const { displayCalendar } = this.state;

    if (disabled) return;

    this.setState({ displayCalendar: !displayCalendar });
  }

  onChangeDate = (date: Date | Date[]) => {
    const { isInstant } = this.props;
    const selectedDate = date as Date;

    if (isInstant) {
      this.props.onDateSelection(selectedDate || new Date());
      this.toggleDropdownMenu();
    } else {
      this.setState({ isEditing: true, selectedDate });
    }
  }

  onDateSelection = (event) => {
    event.preventDefault();

    const { selectedDate } = this.state;

    this.props.onDateSelection(selectedDate || new Date());
    this.toggleDropdownMenu();
  }

  renderCalendar = () => {
    let props = {};
    const { availableDates, isInstant, tileDisabled, top } = this.props;

    if (availableDates) {
      props = { minDate: availableDates[0] }

      if (availableDates[1]) {
        props['maxDate'] = availableDates[1];
      }
    }

    if (tileDisabled) {
      props['tileDisabled'] = tileDisabled;
    }

    return (

      <div
        className="DateDropdown-dateContainer"
        style={{ top: top ? -300 : 50, paddingBottom: isInstant ? 10 : 0 }}>
        <Calendar
          className="reactCalendar"
          calendarType="US"
          formatMonthYear={(locale, date) => moment(date).format(FORMAT_MMM_YYYY)}
          formatShortWeekday={(locale, date) => moment(date).format(FORMAT_DD)}
          onChange={this.onChangeDate}
          returnValue="start"
          value={this.state.selectedDate || new Date()}
          {...props}
        />

        {!isInstant &&
          <button
            className="DateDropdown-calendarButton"
            onClick={this.onDateSelection}>
            OK
          </button>
        }
      </div>
    )
  }

  render() {
    const {
      currentDate,
      dateFormat,
      disabled,
      errorMessage,
      hasButton,
      icon,
      style,
    } = this.props;
    const { displayCalendar } = this.state;
    const date = currentDate ? moment(currentDate).format(dateFormat) : dateFormat;
    let buttonClass = `DateDropdown-button${disabled ? "Disabled" : ""}`;

    if (errorMessage) {
      buttonClass = `${buttonClass} is-invalid`;
    }

    return (
      <div
        className={`DateDropdown-dropdown ${practiceConstants().className}`}
        ref={node => this.node = node}>
        <div>
          <div
            className={buttonClass}
            onClick={this.toggleDropdownMenu}
            style={style}>
            {date}

            {hasButton && (
              <FontAwesomeIcon
                className={`DateDropdown-icon${disabled ? "Disabled" : ""}`}
                icon={icon || faCalendar} />
            )}
          </div>

          {errorMessage && (
            <label className="DateDropdown-errorLabel">
              {errorMessage}
            </label>
          )}
        </div>

        {displayCalendar && this.renderCalendar()}
      </div>
    );
  }
}

export default DateDropdown;
