export enum MilestoneStatus {
  NotAchieved = 'not_achieved',
  Achieved = 'achieved',
  Claimed = 'claim_submitted',
  Paid = 'paid',
  PaidInFull = 'paid_in_full',
  Rejected = 'rejected',
  Error = 'error',
  Denied = 'denied',
  Adjudicated = 'adjudicated',
  Acknowledged = 'acknowledged',
}

export enum ScaleStatus {
  Submitted = 'submitted',
  Fulfilled = 'fulfilled',
  Delivered = 'delivered',
  NotIssued = 'not_issued',
}

export enum FitbitStatus {
  NotIssued = 'not_issued',
  CurrentlyInactive = 'currently_inactive',
  Issued = 'issued',
  Connected = 'connected'
}

export enum PurpleCarrotStatus {
  Issued = 'purple_carrot_issued',
}

export enum SponsorID {
  RHODE_ISLAND = 'Rhode Island',
  SELF_PAY = 'Self-Pay',
  LEGACY = 'Legacy',
  NotIssued = 'not_issued',
  WALGREENS = 'Walgreens'
}

export enum SharecareSponsorID {
    ER_DELTAAIR = 'ER_DELTAAIR',
    ER_GENERA = 'ER_GENERA',
    ER_SHARECARE = 'ER_SHARECARE',
    HP_SCCAREFIRST = 'HP_SCCAREFIRST',
    HP_HMSA = 'HP_HMSA'
}

export type MilestoneAnalyticsData = {
  total: number;
  milestoneOne: number,
  milestoneTwo: number,
  milestoneThree: number,
  milestoneFour: number,
}

export type MilestoneAnalyticsPayload = {
  employer: string;
  search: string;
  one: string;
  two: string;
  three: string;
  four: string;
  page: number;
  rows?: number;
}

export type MilestoneData = {
  id: string;
  classId?: string;
  participantId: string;
  participantExternalId: string;
  firstName: string;
  lastName: string;
  email: string;
  signupDate: Date,
  healthPlanId?: string;
  insurance?: string;
  milestoneOne: MilestoneStatus,
  milestoneOneDate?: Date,
  paymentOne?: number,
  deviceScale: MilestoneStatus,
  milestoneTwo: MilestoneStatus,
  milestoneTwoDate?: Date,
  paymentTwo?: number,
  deviceFitbit: FitbitStatus | PurpleCarrotStatus,
  milestoneThree: MilestoneStatus,
  milestoneThreeDate?: Date,
  paymentThree?: number,
  milestoneFour: MilestoneStatus,
  milestoneFourDate?: Date,
  paymentFour?: number,
  birthdate: Date;
  gender: string;
  address: string;
  apartmentNo?: string;
  city: string;
  state: string;
  zipCode: string;
  dietitian: string;
  sponsorId?: SponsorID | null;
}
