import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import { practiceConstants } from '../utils';
import { FORMAT_MMMM_YYYY, FORMAT_YYYY_MM } from '../utils/constants';

import {
  DietitianPaymentData,
  DietitianPaymentUserData,
  DietitianPaymentSessionData
} from '../types/dietitian-payments';

import APIClient, { autoClose } from '.';

export default class DietitianPaymentsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchDietitianPayments = (
    isSharecare: boolean,
    queryString: string,
  ): Promise<[DietitianPaymentData[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/${isSharecare ? 'monthly-invoice' : 'dietitian-payments'}${queryString}`;
        const response = await this.instance.get(url);
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const list = data.map((item: any): DietitianPaymentData => {
          const data: DietitianPaymentData = {
            id: item.id,
            participants: item.participants,
            period: item.period,
            total: 0,
            status: item.status,
          };

          if (item.dietitian) {
            data['dietitianId'] = item.dietitian.id;
            data['name'] = `${item.dietitian.first_name} ${item.dietitian.last_name}`;
          } else if (isSharecare) {
            data['name'] = moment(item.period).format(FORMAT_MMMM_YYYY);
          }

          if (item.milestones) {
            data.milestones = {
              one: item.milestones['1'] || 0,
              two: item.milestones['2'] || 0,
              three: item.milestones['3'] || 0,
              four: item.milestones['4'] || 0,
            };

            data.total = item.milestones.total || 0;
          } else if (item.sessions) {
            data.sessions = { count: item.sessions.count || 0 };
            data.total = item.sessions.total || 0;
          }

          return data;
        })

        resolve([list, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchDietitianPaymentInfo = (
    isSharecare: boolean,
    id: string,
    company?: string,
    year?: string,
    type?: string,
  ): Promise<DietitianPaymentData> => (
    new Promise(async (resolve, reject) => {
      try {
        const endpoint = isSharecare ? 'monthly-invoice' : 'dietitian-payments';
        let url = `/${endpoint}/${id}`;

        if (company && year) {
          url = `/${endpoint}/year/details?company=${company}&dietitian_id=${id}&year=${year}&type=${type}`;
        }

        if (isSharecare && company && year) {
          url = `/${endpoint}/${company}/${year}`;
        }

        const response = await this.instance.get(url);
        const item = response.data.data;
        const data: DietitianPaymentData = {
          id: item.id,
          name: '',
          participants: item.participants,
          period: item.period,
          total: 0,
          status: item.status,
        };

        if (item.dietitian) {
          data['dietitianId'] = item.dietitian.id;
          data['name'] = `${item.dietitian.first_name} ${item.dietitian.last_name}`;
        } else if (isSharecare) {
          data['name'] = moment(item.period).format(FORMAT_MMMM_YYYY);
        }

        if (item.milestones) {
          data.milestones = {
            one: item.milestones['1'] || 0,
            two: item.milestones['2'] || 0,
            three: item.milestones['3'] || 0,
            four: item.milestones['4'] || 0,
          };

          data.total = item.milestones.total || 0;
        } else if (item.sessions) {
          data.sessions = { count: item.sessions.count || 0 };
          data.total = item.sessions.total || 0;
        }

        resolve(data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchDietitianPaymentInfoPeriodFilter = (
    dietitianId: number,
    type: string,
    company: string,
    year: string,
    month?: string
  ): Promise<DietitianPaymentData> => (
    new Promise(async (resolve, reject) => {
      try {
        const endpoint = 'dietitian-payments';
        let url = `/${endpoint}/year/details?company=${company}&dietitian_id=${dietitianId}&year=${year}&type=${type}`;

        if (year && month) {
          url = `/${endpoint}/payments?company=${company}&dietitian_id=${dietitianId}&month=${moment(year + "-" + month).format(FORMAT_YYYY_MM)}&type=${type}`;
        }

        const response = await this.instance.get(url);
        const item = response.data.data;
        const data: DietitianPaymentData = {
          id: item.id,
          name: moment(item.period).format(FORMAT_MMMM_YYYY),
          participants: item.participants,
          period: item.period,
          total: 0,
          status: item.status,
        };

        if (item.dietitian) {
          data['dietitianId'] = item.dietitian.id;
          data['name'] = `${item.dietitian.first_name} ${item.dietitian.last_name}`;
        }

        if (item.milestones) {
          data.milestones = {
            one: item.milestones['1'] || 0,
            two: item.milestones['2'] || 0,
            three: item.milestones['3'] || 0,
            four: item.milestones['4'] || 0,
          };

          data.total = item.milestones.total || 0;
        } else if (item.sessions) {
          data.sessions = { count: item.sessions.count || 0 };
          data.total = item.sessions.total || 0;
        }

        resolve(data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const hasNoData = error.response.status == 404;

          if (hasNoData) {
            toast.error('Not found under the specified payment period.');
          } else {
            toast.error(error.response.data.message || error.response.message, { autoClose });
          }
          reject({ hasNoData });
        }
      }
    })
  )

  private fetchDietitianPaymentInfoList = (
    root: string,
    isSharecare: boolean,
    id: string,
    page: number,
    milestone: number,
    order: string,
    year?: string,
  ): Promise<Promise<AxiosResponse<any>>> => (
    new Promise((resolve, reject) => {
      try {
        const endpoint = isSharecare ? 'monthly-invoice' : 'dietitian-payments';
        const idKey = isSharecare ? 'monthly_invoice_id' : 'dietitian_payment_id';
        const rootUrl = `/${endpoint}/${root}`;
        let url = `${rootUrl}?${idKey}=${id}&order=${order}&page=${page}`;

        if (year) {
          url = `${rootUrl}/${id}/${year}?order=${order}&page=${page}`;
        }

        if (milestone > 0) {
          url = `${url}&milestone=${milestone}`;
        }

        if (isSharecare && year) {
          url = `${rootUrl}/${year}?order=${order}&page=${page}`;
        }

        resolve(this.instance.get(url));
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchDietitianPaymentParticipantsList = (
    isSharecare: boolean,
    id: string,
    page: number,
    milestone: number,
    order: string,
    year?: string,
  ): Promise<[DietitianPaymentUserData[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.fetchDietitianPaymentInfoList(
          'participants',
          isSharecare,
          id,
          page,
          milestone,
          order,
          year,
        );
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const items = data.map((item): DietitianPaymentUserData => ({
          id: item.id,
          lastName: item.last_name,
          firstName: item.first_name,
          employer: item.employer,
          insurance: item.insurance,
          achieved: item.achieved && new Date(item.achieved),
          milestone: item.milestone,
          healthPlanId: item.health_plan_id,
          paymentDue: parseFloat(item.payment_due),
        }));

        resolve([items, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchDietitianPaymentSessionsList = (
    isSharecare: boolean,
    id: string,
    page: number,
    order: string,
    year?: string,
  ): Promise<[DietitianPaymentSessionData[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.fetchDietitianPaymentInfoList(
          'sessions',
          isSharecare,
          id,
          page,
          -1,
          order,
          year,
        );
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const items = response.data.data.map((item): DietitianPaymentSessionData => ({
          className: item.class_name,
          sessionName: item.session_name,
          startDate: new Date(item.session_start),
          endDate: new Date(item.session_end),
        }));

        resolve([items, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  updateDietitianPaymentStatus = async (
    isSharecare: boolean,
    id: string,
    status: string,
    type: string
  ) => {
    const endpoint = isSharecare ? 'monthly-invoice' : 'dietitian-payments';

    let url = `${endpoint}/${id}`;

    return this.instance.patch(url, { status, type });
  }

  exportMonthlyInvoice = (id: string, milestones: boolean[]): Promise<any> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/monthly-invoice/export?monthly_invoice_id=${id}`;

        for (let i = 0; i < milestones.length; i++) {
          if (i == 0) {
            continue;
          }

          if (milestones[i]) {
            url = `${url}&milestone[]=${i}`;
          }
        }

        const response = await this.instance.get(url, { responseType: 'blob' });

        resolve(response.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
