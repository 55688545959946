import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

import SuperuserAPIClient from '../../api/superuser';

import { BASE_URL_INGENUITY } from '../../utils/constants';

import styles from './index.module.scss';

export default function DataExplorer() {
  const api = new SuperuserAPIClient(BASE_URL_INGENUITY);
  const stringifyJSON = (json: any) => JSON.stringify(json, undefined, 2);

  // States
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState(stringifyJSON({'query': 'select '}));
  const [result, setResult] = useState('');

  const onClick = () => {
    setIsFetching(true);
    api.queryDataExplorer(query)
      .then(response => {
        setIsFetching(false);
        setResult(stringifyJSON(response))
      })
      .catch(() => {
        setIsFetching(false);
      })
  }

  const onQueryClick = () => setQuery(stringifyJSON({ 'query': 'select ' }));

  const onAPIClick = () => setQuery(stringifyJSON({ 'api': '' }));

  return (
    <Container className={styles.container}>
      <div className={styles.queryContainer}>
        <textarea
          className={styles.textarea}
          name="query"
          value={query}
          onChange={event => setQuery(event.target.value)} />
        <div className={styles.row}>
          <button
            className={styles.button}
            disabled={isFetching}
            onClick={onQueryClick}>
            Query
          </button>
          <button
            className={styles.button}
            disabled={isFetching}
            onClick={onAPIClick}>
            API
          </button>
        </div>
      </div>

      <button
        className={styles.button}
        disabled={isFetching}
        name="fetch"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={isFetching ? faSpinner : faAngleRight} spin={isFetching} />
      </button>

      <textarea
        disabled
        className={styles.textarea}
        value={result}
        onChange={event => setQuery(event.target.value)}
      />
    </Container>
  )
}
