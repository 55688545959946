import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faCalendar,
  faCheckCircle,
  faEnvelope,
  faTimesCircle,
  faUser,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import _ from 'lodash';

import Pagination from '../../../../components/Pagination';

import {
  SignupTrackingFilter as Filter,
  UserData,
} from '../../../../types/signup-tracking';

import {
  hasEligibilityCheck,
  timezoneAbbreviation,
  tooltipComponent,
  scrollToNode,
} from '../../../../utils';
import {
  FORMAT_MM_DD_YYYY,
  INFO_QUIZ,
  INFO_ELIGIBLE_GREEN,
  INFO_SCHEDULED,
  INFO_SIGNEDUP,
  INFO_LANDING,
} from '../../../../utils/constants';
import { DropdownItem } from '../../../../utils/types';

import './index.scss';

interface Props {
  currentPage: number;
  employer: DropdownItem,
  numPages: number;
  pagedUsers: any[];
  isDropOff?: boolean;
  isFetching?: boolean;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
}

export default function List(props: Props) {
  const isEligible = (user: UserData) => (
    _.isNull(user.eligible) ? user.hasFinishedEligibility : user.eligible
  );

  const renderLanding = (user: UserData) => {
    const {
      hasFinishedLanding: landing,
      hasFinishedQuiz: quiz,
      lastQuizItem
    } = user;

    if (!landing) {
      return <div />
    }

    const isLandingOnly = landing && !quiz && lastQuizItem;

    return (
      <td>
        <FontAwesomeIcon icon={faCheckCircle} />
        {isLandingOnly && <strong> - Q{lastQuizItem}</strong>}
      </td>
    )
  }

  const renderDataCells = (user: UserData) => {
    if (props.isDropOff) {
      return <td>{user.phone}</td>
    }

    return (
      <>
        {renderLanding(user)}
        <td>
          {user.hasFinishedQuiz && <FontAwesomeIcon icon={faCheckCircle} />}
        </td>
        {hasEligibilityCheck(props.employer.value) &&
          <td className={!isEligible(user) ? 'red' : 'green'}>
            {user.hasFinishedEligibility && (
              <FontAwesomeIcon icon={!isEligible(user) ? faTimesCircle : faCheckCircle} />
            )}
          </td>
        }
        <td>
          {user.hasFinishedScheduler && <FontAwesomeIcon icon={faCheckCircle} />}
        </td>
        <td>
          {user.hasFinishedSignedUp && <FontAwesomeIcon icon={faCheckCircle} />}
        </td>
        <td>
          {user.hasFinishedQuiz && (
            <label className={`risk ${user.isLowRisk ? "low" : "high"}`}>
              {user.isLowRisk ? "Low" : "High"}
            </label>
          )}
        </td>
      </>
    )
  }

  const renderHeaders = () => {
    if (props.isDropOff) {
      return (
        <th>
          <FontAwesomeIcon icon={faPhone} /> Phone
        </th>
      )
    }

    return (
      <>
        {tooltipComponent(INFO_LANDING, <th>{Filter.Landing}</th>, 'left')}
        {tooltipComponent(INFO_QUIZ, <th>{Filter.Quiz}</th>, 'left')}
        {hasEligibilityCheck(props.employer.value) &&
          tooltipComponent(INFO_ELIGIBLE_GREEN, <th>{Filter.Eligibility}</th>, 'left')}
        {tooltipComponent(INFO_SCHEDULED, <th>{Filter.Scheduler}</th>, 'left')}
        {tooltipComponent(INFO_SIGNEDUP, <th>{Filter.SignedUp}</th>, 'left')}

        <th>Risk</th>
      </>
    )
  }

  const renderList = () => (
    <Table className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faUser} />Name
          </th>
          <th>
            <FontAwesomeIcon icon={faEnvelope} />Email
          </th>
          <th>
            <FontAwesomeIcon icon={faCalendar} />Date
          </th>

          {renderHeaders()}
        </tr>
      </thead>
      <tbody>
        {props.pagedUsers.map((user: UserData, index: number) =>
          <tr key={user.id} id={`SignupTracker-row${index}`}>
            <th>{user.name}</th>
            <td>{user.email}</td>
            <td>
              {moment(user.date).format(FORMAT_MM_DD_YYYY)} <strong>{timezoneAbbreviation(user.date)}</strong>
            </td>

            {renderDataCells(user)}
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('SignupTracker-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
