import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faCalendar,
  faDollarSign,
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Pagination from '../../../../../components/Pagination';

import { ConsumerPayment } from '../../../../../types/consumer-payments';

import { scrollToNode } from '../../../../../utils';
import { FORMAT_MM_DD_YYYY } from '../../../../../utils/constants';
import { formatCurrency } from '../../../../../utils/formatting';

import './index.scss';

interface Props {
  currentPage: number;
  isFetching?: boolean;
  numPages: number;
  pagedPayments: any[];
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
}

export default function List(props: Props) {
  const renderList = () => (
    <Table responsive className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faCalendar} />
            Payment  Date
          </th>
          <th>
            <FontAwesomeIcon icon={faDollarSign} />
            Amount Paid
          </th>
          <th>
            <FontAwesomeIcon icon={faFileInvoice} />
            Payment Status
          </th>
        </tr>
      </thead>
      <tbody>
        {props.pagedPayments.map((item: ConsumerPayment, index: number) =>
          <tr id={`ConsumerPaymentDetails-row${index}`} key={index.toString()}>
            <td className="fixed-200">{moment(item.date).format(FORMAT_MM_DD_YYYY)}</td>
            <td className="fixed-200">{formatCurrency(item.amount)}</td>
            <td className="status">{item.status}</td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('ConsumerPaymentDetails-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
