import * as React from 'react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { CalendarTileProperties } from 'react-calendar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDay,
  faCheck,
  faClock,
  faFileAlt,
  faTimes,
  faAsterisk,
} from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash';

import AppNav from '../../components/AppNav';
import AddDietitianInput from '../../components/AddDietitianInput';
import AddParticipantInput from '../../components/AddParticipantInput';
import BaseModal from '../../components/BaseModal';
import ClassSessionRow from '../../components/ClassSessionRow';
import CustomButton from '../../components/CustomButton';
import DateDropdown from '../../components/DateDropdown';
import Dropdown from '../../components/Dropdown';
import Header from '../../components/Header';
import ParticipantRow from '../../components/ParticipantRow';
import Spinner from '../../components/Spinner';

import { RootState } from '../../redux/reducers';
import {
  clearForm,
  createClass,
  fetchClassDetails,
  updateClass,
  deleteClass,
} from '../../redux/actions/SuperuserClassDetails';
import { ReducerStateType } from '../../redux/reducers/SuperuserClassDetails';

import {
  filterParticipants,
  generateSessionTimestamp,
  generateSessionTimestamps,
  getClassSessionsByDateAndTime,
  groupParticipants,
  loaderComponent,
  timeSelections,
  timezoneAbbreviation,
  practiceConstants,
} from '../../utils';
import getEnvCreds from '../../utils/apiconstants';
import {
  CLASS_STATUS,
  CLASS_STATUS_LIST,
  DAYS_FILTER,
  FORMAT_DDDD,
  MIN_PARTICIPANTS,
  MAX_PARTICIPANTS,
  ROUTES,
  TIME_FILTER,
  VALIDATION_MAX_PARTICIPANTS,
  VALIDATION_REQUIRED,
  VALIDATION_MIN_PARTICIPANTS,
  VALIDATION_PARTICIPANTS,
} from '../../utils/constants';
import {
  CreateClassPayload,
  Dietitian,
  DropdownItem,
  Participant,
  Session,
  SessionTimestamp,
  UpdateClassPayload,
} from '../../utils/types';

import './index.scss';

const COLUMN = 3;

enum Updates {
  ClassSessions = 'Sessions',
  MaxParticipants = 'Max # of participants',
  MinParticipants = 'Min. # of participants',
  Participants = 'Participants',
  Dietitian = 'Dietitian',
}

type DispatchPropTypes = Readonly<{
  fetchClassDetails: (id: string) => void;
  clearForm: (retain: boolean | undefined) => void;
  createClass: (payload: CreateClassPayload) => void;
  deleteClass: (id: string) => void;
  updateClass: (payload: UpdateClassPayload) => void;
}>;

export type FormErrors = {
  name?: string,
  maxParticipants?: string,
  minParticipants?: string,
  participants?: string,
  startDate?: string,
};

type UpdatedDietitian = {
  add?: number[],
  remove?: number[]
};

type UpdatedParticipants = {
  add?: number[],
  remove?: number[]
};

interface Props extends RouteComponentProps, ReducerStateType, DispatchPropTypes {}

interface State {
  allParticipants: Participant[];
  allSessions: Session[];
  formErrors?: FormErrors,
  isInitial: boolean;
  isLoading: boolean;
  participants: Participant[];
  selectedName: string;
  selectedDate?: Date;
  selectedDay: DropdownItem;
  selectedDietitian?: Dietitian;
  selectedMinParticipants: number;
  selectedMaxParticipants: number;
  selectedTime: DropdownItem;
  toShowAttendeesModal: boolean;
  toShowDeleteModal: boolean;
  toShowModal: boolean;
  toShowUpdateModal: boolean;
  updatedDietitian?: UpdatedDietitian;
  updatedParticipants?: UpdatedParticipants;
  updatedSessions?: { update?: SessionTimestamp[], new?: SessionTimestamp[] };
  updatesTracker?: { [id: string]: boolean };
}

class ClassDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { title, value } = TIME_FILTER[0];
    const selectedTime = {
      title: title.replace(':abbr', timezoneAbbreviation(new Date())),
      value
    };

    this.state = {
      allParticipants: props.classDetails ? props.classDetails.participants : [],
      allSessions: [],
      isInitial: true,
      isLoading: false,
      participants: [],
      selectedName: '',
      selectedDate: undefined,
      selectedDay: DAYS_FILTER[0],
      selectedMinParticipants: MIN_PARTICIPANTS,
      selectedMaxParticipants: MAX_PARTICIPANTS,
      selectedTime,
      toShowAttendeesModal: false,
      toShowDeleteModal: false,
      toShowModal: false,
      toShowUpdateModal: false,
    }
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { classDetails } = nextProps;
    const { isInitial } = prevState;

    if (classDetails && isInitial) {
      const selectedDay = DAYS_FILTER.filter((item: DropdownItem) => (
        item.title === moment(classDetails!.date).format(FORMAT_DDDD)
      ))[0];
      const sessions = classDetails.sessions;

      return {
        ...prevState,
        allParticipants: filterParticipants(prevState.allParticipants, classDetails!.participants),
        allSessions: sessions,
        isInitial: false,
        participants: classDetails!.participants,
        selectedDate: classDetails!.date,
        selectedDay,
        selectedDietitian: classDetails!.dietitian,
        selectedMinParticipants: classDetails!.minParticipants,
        selectedMaxParticipants: classDetails!.maxParticipants,
        selectedName: classDetails!.name!,
        selectedTime: classDetails!.time,
        // updatedSessions: {
        //   update: generateSessionTimestamps(classDetails!.sessions!, classDetails)
        // },
      }
    }

    return null;
  }

  componentDidMount() {
    const id = this.props.match.params['id'];

    if (id) {
      this.props.fetchClassDetails(id);
    }
  }

  componentDidUpdate() {
    const { isSuccess } = this.props;

    this.automaticDismissPage(isSuccess && isSuccess == true);
  }

  componentWillUnmount = () => {
    this.props.clearForm(false);
  }

  automaticDismissPage = (isSuccess: boolean | undefined) => {
    if (isSuccess) {
      setTimeout(() => {
        this.props.history.push(ROUTES.SuperuserDashboard);
      }, 2000);
    }
  }

  generateUpdatedSessions = (sessions: Session[]) => {
    const { classDetails } = this.props;
    let data: { update: SessionTimestamp[], new?: SessionTimestamp[] };
    let currentCount = 0;
    let newSessions: Session[] = [];
    let updatedSessions: Session[] = sessions;

    if (classDetails && classDetails.sessions) {
      updatedSessions = [];

      try {
        sessions.forEach((item: Session, index: number) => {
          let session: Session = {
            ...item,
            id: classDetails.sessions![index].id
          };

          currentCount = index;

          updatedSessions.push(session);
        });
      } catch (error) {
        for (let index = currentCount + 1; index < sessions.length; index++) {
          newSessions.push(sessions[index]);
        }
      }
    }

    data = { update: generateSessionTimestamps(updatedSessions, classDetails) };

    if (newSessions.length > 0) {
      data = { ...data, new: generateSessionTimestamps(newSessions) };
    }

    return { updatedSessions, data };
  }

  getAllParticipants = () => {
    let { allParticipants, participants } = this.state;

    allParticipants = filterParticipants(allParticipants, participants);

    return allParticipants.sort((item1: Participant, item2: Participant) => (
      item1.fullName.localeCompare(item2.fullName)
    ));
  }

  handleAddParticipant = (participant: Participant) => {
    let {
      allParticipants,
      participants,
      updatedParticipants,
      updatesTracker,
    } = this.state;

    // Only add if the participant isn't on the array yet.
    if (participants.findIndex(p => p.id === participant.id) < 0) {
      participants.push(participant);
    }

    allParticipants = filterParticipants(allParticipants, participants);

    const newState = {
      allParticipants,
      participants,
      updatesTracker: { ...updatesTracker, [Updates.Participants]: true }
    };
    let add = [participant.id];

    if (updatedParticipants) {
      if (updatedParticipants.add) {
        add = Array.from(new Set([...add, ...updatedParticipants.add]));
      }

      if (updatedParticipants.remove) {
        const remove = updatedParticipants.remove.filter(val => !add.includes(val));

        if (remove.length == 0) {
          newState['updatedParticipants'] = { add }
        } else {
          newState['updatedParticipants'] = { add, remove }
        }

        newState['updatedParticipants'] = { ...updatedParticipants, add }
      } else {
        newState['updatedParticipants'] = { add };
      }
    } else {
      newState['updatedParticipants'] = { add };
    }

    this.setState(newState);
  }

  handleClassSessionUpdate = (session: Session) => {
    console.log(`${session.name} date changed to ${session.date}`);

    let update: SessionTimestamp[] = [];
    const { classDetails } = this.props;
    const {
      allSessions,
      updatedSessions,
      updatesTracker,
    } = this.state;
    const sessions = allSessions.map((i: Session) => generateSessionTimestamp(i, classDetails));

    sessions.forEach((item: SessionTimestamp) => {
      if (item.id == session.id) {
        const timestamp: SessionTimestamp = generateSessionTimestamp(session, classDetails);

        update.push(timestamp);
      }
    });

    if (updatedSessions && updatedSessions.update) {
      update = [...updatedSessions.update, ...update];
    }

    this.setState({
      updatedSessions: { update },
      updatesTracker: { ...updatesTracker, [Updates.ClassSessions]: true }
    });
  }

  handleDateSelection = (date: Date) => {
    const { selectedTime, updatesTracker } = this.state;
    const day = DAYS_FILTER.filter((item: DropdownItem) => (
      item.title === moment(date).format(FORMAT_DDDD)
    ))[0];
    let sessions = getClassSessionsByDateAndTime(date, selectedTime);
    const { data: updatedSessions, updatedSessions: allSessions } = this.generateUpdatedSessions(sessions);

    this.setState({
      allSessions,
      selectedDate: date,
      selectedDay: day,
      updatedSessions,
      updatesTracker: { ...updatesTracker, [Updates.ClassSessions]: true }
    });
  }

  handleDaySelection = (item: DropdownItem) => {
    this.setState({ selectedDay: item });
  }

  handleDeleteParticipant = (participant: Participant) => {
    let { allParticipants, updatedParticipants } = this.state;
    const { participants: p, updatesTracker } = this.state;
    const participants = p.filter((item: Participant) => item.id !== participant.id);

    allParticipants = allParticipants.concat([participant]);

    const newState = {
      allParticipants,
      participants,
      updatesTracker: { ...updatesTracker, [Updates.Participants]: true }
    };
    let remove = [participant.id];

    if (updatedParticipants) {
      if (updatedParticipants.remove) {
        remove = Array.from(new Set([...remove, ...updatedParticipants.remove]));
      }

      if (updatedParticipants.add) {
        const add = updatedParticipants.add.filter(val => !remove.includes(val));

        if (add.length == 0) {
          newState['updatedParticipants'] = { remove }
        } else {
          newState['updatedParticipants'] = { add, remove }
        }
      } else {
        newState['updatedParticipants'] = { remove };
      }
    } else {
      newState['updatedParticipants'] = { remove };
    }

    this.setState(newState);
  }

  handleDietitianSelection = (dietitian: Dietitian) => {
    const { updatesTracker } = this.state;
    const newState = {
      selectedDietitian: dietitian,
      updatesTracker: { ...updatesTracker, [Updates.Dietitian]: true }
    };

    if (this.props.classDetails) {
      let data: any = { add: [dietitian.id] };
      const { selectedDietitian, updatedDietitian } = this.state;

      if (selectedDietitian) {
        data = { ...data, remove: [selectedDietitian!.id] }
      } else {
        if (updatedDietitian && updatedDietitian.remove) {
          data = { ...data, remove: updatedDietitian.remove }
        }
      }

      newState['updatedDietitian'] = data;
    }

    this.setState(newState);
  }

  handleDietitianRemove = (dietitian: Dietitian) => {
    if (this.props.classDetails) {
      this.setState({
        selectedDietitian: undefined,
        updatedDietitian: { remove: [dietitian.id] },
        updatesTracker: { ...this.state.updatesTracker, [Updates.Dietitian]: true }
      });
    }
  }

  handleMaxParticipantsSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selectedMaxParticipants: parseInt(event.target.value),
      updatesTracker: { ...this.state.updatesTracker, [Updates.MaxParticipants]: true }
    });
  }

  handleMinParticipantsSelection = (event: React.ChangeEvent<HTMLInputElement>) => (
    this.setState({
      selectedMinParticipants: parseInt(event.target.value),
      updatesTracker: { ...this.state.updatesTracker, [Updates.MinParticipants]: true }
    })
  )

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedName: event.target.value });
  }

  handleTimeSelection = (item: DropdownItem) => {
    let newState = {
      selectedTime: item,
      updatesTracker: { ...this.state.updatesTracker, [Updates.ClassSessions]: true }
    };
    const { selectedDate } = this.state;

    if (selectedDate) {
      const date = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate());
      let sessions = getClassSessionsByDateAndTime(date, item);
      const { data } = this.generateUpdatedSessions(sessions);

      newState['allSessions'] = sessions;
      newState['updatedSessions'] = data;
    }

    this.setState(newState);
  }

  isDisabled = () => {
    const { classDetails } = this.props;

    if (classDetails) {
      return (
        classDetails!.status == CLASS_STATUS_LIST[CLASS_STATUS.Completed] ||
        classDetails!.status == CLASS_STATUS_LIST[CLASS_STATUS.Cancelled]
      );
    }

    return false;
  }

  isFormValid = () => {
    const {
      selectedMinParticipants,
      selectedMaxParticipants,
      selectedDate,
      participants,
    } = this.state;
    const formErrors: FormErrors = {};

    // if (!selectedName || selectedName && selectedName.length == 0) {
    //   formErrors.name = VALIDATION_REQUIRED;
    // }

    if (!selectedDate) {
      formErrors.startDate = VALIDATION_REQUIRED;
    }

    if (!selectedMinParticipants) {
      formErrors.minParticipants = VALIDATION_PARTICIPANTS;
    }

    if (!selectedMaxParticipants) {
      formErrors.maxParticipants = VALIDATION_PARTICIPANTS;
    }

    if ((selectedMinParticipants && selectedMaxParticipants) && selectedMinParticipants > selectedMaxParticipants) {
      formErrors.minParticipants = VALIDATION_MIN_PARTICIPANTS;
    } else if (participants && participants.length > selectedMaxParticipants) {
      formErrors.participants = VALIDATION_MAX_PARTICIPANTS;
    }

    this.setState({ formErrors });

    return _.isUndefined(formErrors) || _.isEmpty(formErrors);
  }

  onBack = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();

    this.setState({ toShowModal: false });
    this.props.history.push(ROUTES.SuperuserDashboard);
    // setTimeout(() => {
    //   this.props.history.push(ROUTES.SuperuserDashboard);
    // }, 250);
  }

  onCreateClassClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();

    if (!this.isFormValid()) {
      return;
    }

    const {
      allSessions,
      participants,
      selectedDietitian,
      selectedMinParticipants,
      selectedMaxParticipants,
      updatedDietitian,
      updatedParticipants,
      updatedSessions,
    } = this.state;

    let sessions: SessionTimestamp[] = generateSessionTimestamps(allSessions, this.props.classDetails);
    const ENV_CREDS = getEnvCreds();
    const firstSession: SessionTimestamp = sessions[0];
    const lastSession: SessionTimestamp = sessions[sessions.length - 1];
    const payload: CreateClassPayload = {
      start: firstSession.start,
      end: lastSession.end,
      min_participants: selectedMinParticipants,
      max_participants: selectedMaxParticipants,
      account_code: ENV_CREDS.ACCOUNT_CODE,
      // room_code: ENV_CREDS.ACCOUNT_CODE,
    };

    if (this.props.classDetails) {
      payload['id'] = this.props.classDetails.id;

      if (updatedSessions) {
        payload['sessions'] = updatedSessions;
      }

      if (updatedDietitian) {
        payload['host_ids'] = updatedDietitian;
      }

      if (updatedParticipants) {
        payload['participant_ids'] = updatedParticipants;
      }

      this.props.updateClass(payload);
    } else {
      payload['sessions'] = sessions;

      if (selectedDietitian) {
        payload['host_ids'] = [selectedDietitian.id];
      }

      if (participants.length > 0) {
        payload['participant_ids'] = participants.map((item: Participant) => item.id);
      }

      this.props.createClass(payload);
    }
  }

  onDeleteClass = (event: React.MouseEvent<HTMLSpanElement>) => {
    const { classDetails } = this.props;

    event.preventDefault();

    if (classDetails) {
      this.setState({ toShowDeleteModal: false });
      this.props.deleteClass(classDetails.id);
    }
  }

  onExitPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // const { classDetails, history } = this.props;

    // if (classDetails && classDetails.status.value == CLASS_STATUS.InProgress) {
    //   history.push(ROUTES.SuperuserDashboard);

    //   return;
    // }

    this.setState({ toShowModal: true });
  }

  onResponseModalAction = () => {
    const { isSuccess } = this.props;

    this.props.clearForm(true);
    this.automaticDismissPage(isSuccess && isSuccess == true);
  }

  renderClassSessions = (sessions: Session[]) => {
    const sessionDates: Date[] = sessions.map((item: Session) => item.date);

    return (
      <section className={practiceConstants().className}>
        <h4 className="class-detail-sectiontitle primary-label">Class Schedule</h4>
        <div className="class-detail-schedule">
          <Table className="class-detail-schedule">
            <thead>
              <tr>
                <th className="class-detail-schedule-id">Session No.</th>
                <th className="class-detail-schedule-name">
                  <FontAwesomeIcon icon={faFileAlt} />
                  Session Name
                </th>
                <th>
                  <FontAwesomeIcon icon={faCalendarDay} />
                  Date
                </th>
                <th>
                  <FontAwesomeIcon icon={faCalendarDay} />
                  Day
                </th>
                <th>
                  <FontAwesomeIcon icon={faClock} />
                  Time
                </th>
                <th>
                  <FontAwesomeIcon icon={faCheck} />
                  Present
                </th>
                <th>
                  <FontAwesomeIcon icon={faTimes} />
                  Absent
                </th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((item, index) => (
                <ClassSessionRow
                  disabled={this.isDisabled()}
                  key={index + 1}
                  index={index}
                  sessionDates={sessionDates}
                  sessionDetails={item}
                  onSessionDateChanged={this.handleClassSessionUpdate} />
              ))}
            </tbody>
          </Table>
        </div>
      </section>
    )
  }

  renderColumns = (list: Participant[]) => (
    list.map((item: Participant, index: number) => (
      <Col
        key={item.id}
        className="ClassDetails-column"
        xs={4}>
        <ParticipantRow
          disabled={this.isDisabled()}
          participant={item}
          onClick={this.handleDeleteParticipant} />
      </Col>
    ))
  )

  renderFilters = () => {
    const {
      formErrors,
      selectedDate,
      selectedDay,
      selectedMinParticipants,
      selectedMaxParticipants,
      selectedTime,
    } = this.state;
    const hasMinError = formErrors && formErrors.minParticipants;
    const hasMaxError = formErrors && formErrors.maxParticipants;

    return (
      <div className="filters-section">
        <Row>
          <Col
            xs={12}
            lg={3}
            className="ClassDetails-column">
            <label className="ClassDetails-label">
              First day of Class <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <DateDropdown
              disabled={this.isDisabled()}
              errorMessage={formErrors && formErrors.startDate}
              currentDate={selectedDate}
              onDateSelection={this.handleDateSelection}
              tileDisabled={(props: CalendarTileProperties & { activeStartDate: Date }) => {
                const { date } = props;

                return date.getTime() < new Date().getTime();
              }} />
          </Col>
          <Col
            xs={12}
            lg={3}
            className="ClassDetails-column">
            <label className="ClassDetails-label">
              Meets Every <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <Dropdown
              disabled
              items={DAYS_FILTER}
              selectedItem={selectedDay}
              onItemSelection={this.handleDaySelection} />
          </Col>
          <Col
            xs={12}
            lg={2}
            className="ClassDetails-column">
            <label className="ClassDetails-label">
              Time <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <Dropdown
              disabled={this.isDisabled()}
              items={timeSelections(TIME_FILTER)}
              selectedItem={selectedTime}
              onItemSelection={this.handleTimeSelection} />
          </Col>
          <Col
            xs={12}
            lg={2}
            className="ClassDetails-column">
            <label
              className="ClassDetails-label"
              style={{ marginBottom: 5 }}>
              Min. # of Participants <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <input
              disabled={this.isDisabled()}
              onChange={this.handleMinParticipantsSelection}
              placeholder="0"
              type="number"
              className={`ClassDetails-input${hasMinError ? ' is-invalid' : ''}`}
              value={selectedMinParticipants.toString()} />

            {hasMinError && (
              <label className="ClassDetails-errorLabel">
                {formErrors!.minParticipants!}
              </label>
            )}
          </Col>
          <Col
            xs={12}
            lg={2}
            className="ClassDetails-column">
            <label
              className="ClassDetails-label"
              style={{ marginBottom: 5 }}>
              Max # of Participants <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <input
              disabled={this.isDisabled()}
              placeholder="0"
              onChange={this.handleMaxParticipantsSelection}
              type="number"
              className={`ClassDetails-input${hasMaxError ? ' is-invalid' : ''}`}
              value={selectedMaxParticipants.toString()} />

            {hasMaxError && (
              <label className="ClassDetails-errorLabel">
                {formErrors!.maxParticipants!}
              </label>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  renderHeader = () => (
    <Header
      // hasBack
      title={this.props.classDetails ? `Class ID: ${this.props.classDetails!.name}` : 'Create Class'}
      titleLg={6}
      backHandler={this.onExitPage}>
      <div className="header-actions-section">
        <CustomButton
          title="Cancel"
          onClick={this.onExitPage} />
        <CustomButton
          primary
          disabled={true}
          //disabled={!(this.props.classDetails && (this.props.classDetails && this.props.classDetails.participants.length == 0))}
          title="Delete Class"
          onClick={() => this.setState({ toShowDeleteModal: true })} />
        <CustomButton
          primary
          type="submit"
          disabled={true}
          //disabled={this.props.isProcessing}
          title={`${this.props.classDetails ? 'Update' : 'Publish'} Class`}
          onClick={(e) => {
            e.preventDefault();

            if (this.props.classDetails && this.state.updatesTracker && this.isFormValid()) {
              this.setState({ toShowUpdateModal: true });

              return;
            }

            this.onCreateClassClick(e);
          }} />
      </div>
    </Header>
  )

  renderList = (list: Participant[]) => {
    const groupedList = groupParticipants(list, COLUMN);

    return (
      <section className={`class-detail-section ${practiceConstants().className}`} style={{ marginBottom: '1rem' }}>
        <h4 className="class-detail-sectiontitle primary-label">Participants</h4>

        {list.length > 0 ?
          <div className="class-detail-schedule">
            {groupedList.map((l: Participant[], index: number) => (
              <Row
                key={index}
                className="ClassDetails-row">
                {this.renderColumns(l)}
              </Row>
            ))}
          </div> :
          <div className="text-muted" style={{ height: 85 }}>
            No participants yet.
          </div>
        }
      </section>
    )
  }

  render() {
    const {
      classDetails,
      isLoading,
      isProcessing,
      isSuccess,
      message,
    } = this.props;
    const {
      allSessions,
      formErrors,
      participants,
      selectedDate,
      selectedDietitian,
      toShowDeleteModal,
      toShowModal,
      toShowUpdateModal,
    } = this.state;
    const isDisabled = this.isDisabled();
    const hasResponse = isSuccess != undefined && message;
    const hasSuccessResponse = hasResponse && isSuccess! == true;
    const props = !hasSuccessResponse ? {
      confirmTitle: 'Ok',
      onConfirm: this.onResponseModalAction
    } : {};

    return (
      <>
        <AppNav />

        {isLoading ?
          <div className="center-container">
            <Spinner />
          </div> :
          <Container className="superuser padding-bottom">
            {isProcessing && loaderComponent()}

            <form>
              {this.renderHeader()}
              {this.renderFilters()}

              <BaseModal
                cancelTitle="No"
                title={`Exit ${classDetails ? 'Update' : 'Create'} Class`}
                toDisplay={toShowModal}
                onCancel={() => this.setState({ toShowModal: false })}
                onClose={() => this.setState({ toShowModal: false })}
                onConfirm={this.onBack}>
                <label className="ModalLabel">
                  All unsaved progress will be lost. Continue?
                </label>
              </BaseModal>

              {hasResponse &&
                <BaseModal
                  title="Class Action"
                  toDisplay={true}
                  {...props}>
                  <label className="ModalLabel">
                    {message}
                  </label>
                </BaseModal>
              }

              <BaseModal
                cancelTitle="Cancel"
                confirmTitle="Delete"
                onCancel={() => this.setState({ toShowDeleteModal: false })}
                onClose={() => this.setState({ toShowDeleteModal: false })}
                onConfirm={this.onDeleteClass}
                title="Delete Class"
                toDisplay={toShowDeleteModal}>
                <label className="ModalLabel">
                  Are you sure you want to delete this class?
                </label>
              </BaseModal>

              <BaseModal
                cancelTitle="Cancel"
                confirmTitle="Update"
                onCancel={() => this.setState({ toShowUpdateModal: false })}
                onClose={() => this.setState({ toShowUpdateModal: false })}
                onConfirm={(e) => {
                  this.setState({ toShowUpdateModal: false });
                  this.onCreateClassClick(e);
                }}
                title="Update Class"
                toDisplay={toShowUpdateModal}>
                <label className="ModalLabel">Update this class with the following details:</label>

                {this.state.updatesTracker &&
                  <ul style={{ marginBottom: 0, marginTop: 10 }}>
                    {Object.keys(this.state.updatesTracker).map((item, i) => (
                      <li style={{ textAlign: 'left' }} key={i.toString()}>{item}</li>
                    ))}
                  </ul>
                }
              </BaseModal>

              {/* <Grid className="ClassDetails-dietitianPickerContainer">
                <h4 className="ClassDetails-dietitianPickerTitle">Dietitian</h4>
                <DietitianPicker
                  dietitian={selectedDietitian}
                  onSelectDietitian={this.handleDietitianSelection}
                />
              </Grid> */}

              <AddDietitianInput
                disabled={isDisabled}
                dietitian={selectedDietitian}
                onSelectDietitian={this.handleDietitianSelection}
                onRemoveDietitian={this.handleDietitianRemove} />

              <hr />

              {!isDisabled && (
                <AddParticipantInput
                  errorMessage={formErrors && formErrors.participants}
                  onAddParticipant={this.handleAddParticipant}
                  addedParticipants={this.state.participants} />
              )}

              {this.renderList(participants)}

              {selectedDate && this.renderClassSessions(allSessions)}
            </form>
          </Container>
        }
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => state.superuserClassDetails;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearForm: (retain: boolean) => dispatch(clearForm(retain)),
  createClass: (payload: CreateClassPayload) => dispatch(createClass.request(payload)),
  deleteClass: (id: string) => dispatch(deleteClass.request(id)),
  fetchClassDetails: (id: string) => dispatch(fetchClassDetails.request(id)),
  updateClass: (payload: UpdateClassPayload) => dispatch(updateClass.request(payload))
} as DispatchPropTypes)

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetails);
