import React from 'react';
import Table from 'react-bootstrap/Table';
import isUndefined from 'lodash-es/isUndefined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser } from '@fortawesome/free-solid-svg-icons';

import Pagination from '../../components/Pagination';

import { MilestoneData, MilestoneStatus } from '../../types/milestones';
import { scrollToNode, practiceConstants } from '../../utils';
import { CLIENT_TYPE, ROUTES } from '../../utils/constants';

import styles from './index.module.scss';
import MilestoneTrackingRow from './Row';
import { useLocation } from 'react-router';

interface Props {
  adding?: boolean;
  isFetching?: boolean;
  isMilestonesOnly?: boolean;
  viewing?: boolean
  currentPage: number;
  numPages: number;
  pagedUsers: MilestoneData[];
  claimAmounts: { [id: string]: number },
  onStatusChange?: (
    participantId: string,
    milestone: number,
    status: MilestoneStatus
  ) => Promise<void>;
  onDeleteRow?: (participant: MilestoneData) => void;
  onErrorClick?: (participant: MilestoneData, milestone: number) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
}

export default function MilestoneTrackingList(props: Props) {
  const {
    isFetching,
    isMilestonesOnly,
    viewing,
    pagedUsers,
    onStatusChange,
    onDeleteRow,
    onErrorClick,
  } = props;

  const { practiceName } = practiceConstants();
  const location = useLocation();

  const renderList = () => (
    <Table className={`data-list${isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
        {location.pathname != ROUTES.SuperuserMilestoneAnalytics &&
          <th className={styles.cellHead}>
            Origin ID
          </th>}
          <th id="MilestoneTracking-header" className={styles.cellHead}>
            <FontAwesomeIcon icon={faUser} className={styles.cellHeadIcon} />
            Last Name
          </th>
          <th className={styles.cellHead}>
            <FontAwesomeIcon icon={faUser} className={styles.cellHeadIcon} />
            First Name
          </th>
          <th className={styles.cellHead}>
            <FontAwesomeIcon icon={faCalendar} className={styles.cellHeadIcon} />
            Landing Date
          </th>
          <th className={styles.cellHead}>Milestone 1</th>
          {!isMilestonesOnly && <th className={styles.cellHead}>Scale</th>}
          <th className={styles.cellHead}>Milestone 2</th>
          {!isMilestonesOnly && <th className={styles.cellHead}>fitbit{(practiceName == CLIENT_TYPE.ShareCare) ? '/Purple Carrot' : null }</th>}
          <th className={styles.cellHead}>Milestone 3</th>
          <th className={styles.cellHead}>Milestone 4</th>
          <th className={styles.cellHead}></th>
        </tr>
      </thead>
      <tbody>
        {pagedUsers.map((user) =>
          <MilestoneTrackingRow
            disabled={viewing || isFetching}
            isMilestonesOnly={isMilestonesOnly}
            key={user.id}
            participant={user}
            onStatusChange={(milestone, status) => {
              if (!isUndefined(onStatusChange)) {
                return onStatusChange(user.id, milestone, status);
              }

              return Promise.resolve();
            }}
            onDeleteRow={item => {
              if (onDeleteRow) {
                onDeleteRow(item);
              }
            }}
            onErrorClick={(participant, milestone) => {
              if (!isUndefined(onErrorClick)) {
                onErrorClick(participant, milestone);
              }
            }} />
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('MilestoneTracking-header');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
