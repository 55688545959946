import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { practiceConstants } from '../../utils';

import './index.scss';

interface Props {
  onChangeText?: (text: string, event?: React.FormEvent<HTMLInputElement>) => void;
  placeholder: string;
  defaultValue?: string;
}

class SearchInput extends React.Component<Props> {
  render() {
    const { onChangeText, placeholder, defaultValue } = this.props;
    const props = {
      className: 'SearchInput-input',
      placeholder,
      type: 'text',
      defaultValue,
      autoComplete: 'off',
    };

    if (onChangeText) {
      props['onChange'] = (e) => onChangeText(e.target.value, e);
    }

    return (
      <Form className={`SearchInput-container ${practiceConstants().className}`} inline>
        <Form.Group className="SearchInput-group" controlId="search">
          <Form.Label className="SearchInput-label">Search</Form.Label>
          <InputGroup className="SearchInput-inputgroup">
            <Form.Control {...props} type="search"/>
            <InputGroup.Append>
              <Button className="SearchInput-button">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form>
    );
  }
}

export default SearchInput;
