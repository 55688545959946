import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';

import DoctorsAPIClient from '../../api/doctors';

import AppNav from '../../components/AppNav';
import Button from '../../components/Button';
import DateDropdownGroup from '../../components/DateDropdownGroup';
import Header from '../../components/Header';
import NoResults from '../../components/NoResults';
import SearchInput from '../../components/SearchInput';
import Spinner from '../../components/Spinner';
import TotalResults from '../../components/TotalResults';

import { useAPIPagination } from '../../hooks/pagination';
import useDebounce from '../../hooks/useDebounce';
import { useQueryString, QueryKeys } from '../../hooks/useURLQuery';

import { Doctor } from '../../types/doctors';

import { practiceConstants, queryStringParams } from '../../utils';
import {
  BASE_URL_INGENUITY,
  PER_PAGE,
  ROUTES,
  FORMAT_YYYY_MM_DD,
} from '../../utils/constants';

import DoctorsList from './List';

interface Props extends RouteComponentProps {}

export default function Doctors(props: Props) {
  const api = new DoctorsAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { className } = practiceConstants();

  // Filter states
  const [searchFilter, setSearchFilter] = useState(query[QueryKeys.Search] || '');
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );

  // Dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [users, setUsers] = useState<Doctor[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Search]: debouncedSearchTerm,
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(queryString);
    api.fetchDoctors(queryString)
      .then(response => {
        const [list, i, j, t] = response;

        setIsFetching(false);
        setUsers(list);
        setFrom(i);
        setTo(j);
        setTotal(t);
      })
      .catch(() => {
        setIsFetching(false);
        setUsers([]);
        setFrom(0);
        setTo(0);
        setTotal(0);
      })
  }, [currentPage, startDate, endDate, debouncedSearchTerm])

  const onChangeText = text => {
    setIsFetching(true);
    setCurrentPage(0);
    setSearchFilter(text);
  }

  const renderFilters = () => (
    <div className={`filters-section ${className}`}>
      <Row>
        <Col xs={12} lg={4}>
          <Button
            variant="primary"
            onClick={() => {
              const { pathname, search } = props.location;
              const prevPath = `${pathname}${search}`;
              const url = ROUTES.SuperuserCreateDoctorDetails;

              props.history.push(url, { prevPath });
            }}
          >
            Create New Record
          </Button>
          {/* <Button variant="primary">
            <FontAwesomeIcon icon={faEnvelope} />Send Referral Links
          </Button> */}
        </Col>
        <Col xs={12} lg={7} className="controls">
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Date"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0])
              setEndDate(dates[dates.length - 1])
            }}
            onStartDateSelection={date => {
              setCurrentPage(0);
              setStartDate(date);
            }}
            onEndDateSelection={date => {
              setCurrentPage(0);
              setEndDate(date);
            }}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderList = () => {
    if (isFetching && pagedUsers.length == 0) {
      return (
        <div className="center-container">
          <Spinner />
        </div>
      )
    }

    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <DoctorsList
          currentPage={currentPage}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={pagedUsers}
          onRowSelect={doctor => {
            const { id } = doctor;
            const { pathname, search } = props.location;
            const prevPath = `${pathname}${search}`;
            const url = ROUTES.SuperuserUpdateDoctorDetails.replace(':id', id);

            props.history.push(url, { id, prevPath });
          }}
          setIsFetching={setIsFetching}
          setCurrentPage={setCurrentPage}
        />
      </>
    )
  }

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backHandler={() => props.history.push(ROUTES.SuperuserAnalyticsTracking)}
          title="Doctors"
          titleLg={6}>
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Doctor Name"
            onChangeText={onChangeText} />
        </Header>

        {renderFilters()}
        {renderList()}
      </Container>
    </>
  )
}
