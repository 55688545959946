import React, { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';
import {
  defaults,
  Bar,
  Line,
} from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import moment from 'moment';

import { practiceConstants } from '../../../../utils';
import { FORMAT_MMMM_D_YYYY, US_STATES_SELECTIONS } from '../../../../utils/constants';
import { formatCurrency, formatPercent } from '../../../../utils/formatting';
import { ExportType as StatType, DropdownItem } from '../../../../utils/types';

import EmployerStatCard, { EmployerStatCardData } from '../Card';
import EmployerWeightLossStatCard from '../WeightLoss';

import './index.scss';
import '../../../../components/BaseModal/index.scss';

type Legend = { color: string; title: string };

interface Props {
  className?: string;
  headerData: EmployerStatCardData;
  toDisplay: boolean;
  type?: 'bar' | 'line';
  onClose: () => void;
}

export const BLUE = '#5BD8FC';
export const GREEN = '#8FE6A4';
export const RED = '#FC8476';
export const YELLOW = '#FED88B';

export default function ChartModal(props: Props) {
  const { className, headerData, toDisplay, onClose } = props;
  const { chartData, columns, start_date, end_date, statesData: sd, type } = headerData;

  let statesData: DropdownItem[] = [];

  useEffect(() => {
    if (toDisplay) {
      disableBodyScroll();
    }

    return () => enableBodyScroll();
  })

  const chartForTypeWithOptions = (options: ChartOptions) => {
    switch (type) {
      case StatType.Claims:
      case StatType.WeightLoss:
        return (
          <Line
            redraw
            data={dataForType()}
            height={100}
            legend={{ display: false }}
            options={options} />
        )

      default:
        return (
          <Bar
            redraw
            data={dataForType()}
            height={100}
            legend={{ display: false }}
            options={options} />
        )
    }
  }

  const chartOptionsForType = (): ChartOptions => {
    const tickStyle = {
      fontColor: '#34495E',
      fontFamily: 'Open Sans, Helvetica',
      fontSize: 10,
    };
    const lineChartOptions: ChartOptions = {
      legend: { display: false },
      elements: {
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [{ ticks: tickStyle }],
        yAxes: [{ ticks: tickStyle }],
      },
    };

    switch (type) {
      case StatType.Claims:
        return {
          ...lineChartOptions,
          scales: {
            yAxes: [{
              ticks: {
                stepSize: 5000,
                callback: val => val === 0 ? '' : formatCurrency(val),
              }
            }]
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: item => formatCurrency(parseFloat(item.value!)),
            }
          }
        }

      case StatType.WeightLoss: {
        const limit = 7;

        return {
          ...lineChartOptions,
          scales: {
            yAxes: [{
              ticks: {
                min: limit * -1,
                max: limit,
                stepSize: limit / 2,
                callback: (val: string) => formatPercent(parseFloat(val), true),
              }
            }]
          }
        }
      }


      default:
        return {
          animation: {
            easing: 'easeInOutQuad',
            duration: 500
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              }
            }],
            yAxes: [{
              ticks: {
                display: false,
                min: 0,
              },
              gridLines: {
                display: false,
                drawBorder: false
              }
            }]
          },
          tooltips: {
            displayColors: false,
          }
        };
    }
  }

  const chartTitleForType = () => {
    switch (type) {
      case StatType.PreDiabetes:
        return 'Quiz Results Within The Past 6 Months';

      case StatType.Enrollment:
        return 'Top 10 States With Highest Enrollees';

      case StatType.WeightLoss:
        if (start_date && end_date) {
          return `${type} Starting ${moment(start_date).format(FORMAT_MMMM_D_YYYY)} - ${moment(end_date).format(FORMAT_MMMM_D_YYYY)}`
        }
        break;
      default:
        return `${type} Within The Past 6 Months`;
    }
  }

  const dataForType = () => {
    // const labels = ['Dec 2018', 'Jan 2019', 'Feb 2019', 'Mar 2019', 'April 2019', 'May 2019'];

    switch (type) {
      case StatType.Claims: {
        const { labels, datasets: ds } = chartData!;
        const datasets = [{
          ...ds[0],
          fill: false,
          stack: 'stack',
          borderColor: GREEN,
          borderWidth: 2,
          pointBackgroundColor: 'white',
          pointBorderColor: GREEN,
        }, {
          ...ds[1],
          fill: false,
          stack: 'stack',
          borderColor: RED,
          borderWidth: 2,
          pointBackgroundColor: 'white',
          pointBorderColor: RED,
        }, {
          ...ds[2],
          fill: false,
          stack: 'stack',
          borderColor: YELLOW,
          borderWidth: 2,
          pointBackgroundColor: 'white',
          pointBorderColor: YELLOW,
        }, {
          ...ds[3],
          fill: false,
          stack: 'stack',
          borderColor: BLUE,
          borderWidth: 2,
          pointBackgroundColor: 'white',
          pointBorderColor: BLUE,
        }];

        return { labels, datasets };
      }

      case StatType.Enrollment: {
        if (sd) {
          statesData = enrollmentPerStateData(sd);
        }

        return chartData!;
      }

      case StatType.WeightLoss: {
        const list = [...columns.slice(1)];
        const labels = list.map(item => item.header);
        const data = list.map(item => parseFloat(item.value));
        const datasets = [{
          borderColor: GREEN,
          borderWidth: 2,
          data,
          fill: false,
          pointBackgroundColor: 'white',
          pointBorderColor: GREEN,
        }];

        return { labels, datasets };
      }

      default: {
        const { labels, datasets } = chartData!;

        return { labels, datasets: datasets.map(item => ({ ...item, stack: 'stack' })) };
      }
    }
  }

  const enrollmentPerStateData = (data: { [id: string]: number }) => (
    US_STATES_SELECTIONS.map((item: DropdownItem): DropdownItem => {
      const value = data[item.value] ? `${data[item.value]}` : "0";

      return { title: item.title, value };
    })
  )

  const legendForType = (): Legend[] | null => {
    switch (type) {
      case StatType.PreDiabetes:
        return [
          { color: RED, title: 'High Risk' },
          { color: GREEN, title: 'Low Risk' },
        ];

      case StatType.Milestone:
      case StatType.Claims:
        return [
          { color: GREEN, title: 'Milestone 1' },
          { color: RED, title: 'Milestone 2' },
          { color: YELLOW, title: 'Milestone 3' },
          { color: BLUE, title: 'Milestone 4' },
        ];

      case StatType.Eligibility:
        return [
          { color: RED, title: 'Failed Eligibility' },
          { color: GREEN, title: 'Successful Eligibility' },
        ];

      case StatType.WeightLoss:
        return [
          { color: GREEN, title: 'Ave. Weight Loss'}
        ];

      case StatType.Enrollment:
      default:
        return null;
    }
  }

  const renderEmployeesPerState = () => (
    <div className="EmployerAnalyticsModal-listContainer">
      {Array.from(Array(6).keys()).map((_, i) => {
        const limit = 10;

        return (
          <div key={i.toString()} className="column">
            <ul>
              {Array.from(
                statesData
                  .slice(
                    (i == 0 ? i : i * limit), // start
                    (i == 0 ? limit : limit * (i + 1))) // end
                  ).map((item: DropdownItem, index: number) => (
                    <li key={item.id || index.toString()}>
                      <div>
                        <label className="state">{item.title}</label>
                        <label>{item.value}</label>
                      </div>
                    </li>
                  ))}
            </ul>
          </div>
        )
      })}
    </div>
  )

  const renderGraph = () => {
    const chartOptions: ChartOptions = chartOptionsForType();

    // Set defaults
    defaults['global']['defaultFontFamily'] = 'Open Sans';

    return (
      <div className="EmployerAnalyticsModal-graph">
        <label>{chartTitleForType()}</label>

        {chartForTypeWithOptions(chartOptions)}
      </div>
    )
  }

  const renderFooter = () => {
    const legends = legendForType();

    return (
      <div className="EmployerAnalyticsModal-footer">
        {legends ? (
          <div className="SignupAnalytics-legendGroup">
            {legends.map((item: Legend, index: number) => renderLegend(item, index))}
          </div>
        ) : <div style={{ width: 50 }} />}

        <button
          type="button"
          className="btn btn-primary BaseModal-continueButton"
          onClick={props.onClose}>
          Ok
        </button>
      </div>
    )
  }

  const renderLegend = (legend: Legend, index: number) => (
    <div key={index.toString()} className="SignupAnalytics-legend">
      <div className="color" style={{ backgroundColor: legend.color }} />
      <strong>{legend.title}</strong>
    </div>
  )

  return (
    <div className={toDisplay ? 'BaseModal-overlay' : 'BaseModal-overlay hidden'}>
      <div
        className={classnames(
          practiceConstants().className,
          'BaseModal-wrapper xl',
          `animation${!toDisplay ? 'Exit' : ''}`,
          className
        )}>
        {type == StatType.WeightLoss ?
          <EmployerWeightLossStatCard
            asHeader
            data={headerData}
            onClick={() => onClose()}
          /> :
          <EmployerStatCard
            asHeader
            data={headerData}
            onClick={() => onClose()}
          />
        }

        {renderGraph()}
        {type == StatType.Enrollment && renderEmployeesPerState()}
        {renderFooter()}
      </div>
    </div>
  )
}
