import { toast } from 'react-toastify';
import axios from 'axios';

import { Doctor, DoctorParticipant } from '../types/doctors';

import { practiceConstants } from '../utils';

import APIClient, { autoClose } from '.';

export default class DoctorsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchDoctors = (
    queryString: string,
  ): Promise<[Doctor[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/doctor${queryString}`;
        const response = await this.instance.get(url);
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const items: Doctor[] = data.map((item: any): Doctor => ({
          id: item['id'],
          firstName: item['first_name'],
          lastName: item['last_name'],
          email: item['email'],
          phoneNumber: item['phone'],
          website: item['website'],
          streetAddress: item['street_address'],
          city: item['city'],
          state: item['state'],
          zip: item['zip_code'],
          specialty: item['med_specialty'],
          referralUrl: data['referral_url'],
          quizLink: item['quiz_link'],
        }));

        resolve([items, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchDoctorDetails = (id: string): Promise<Doctor> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.instance.get(`/doctor/${id}`);
        const data = response.data.data;
        const item: Doctor = {
          id: data['id'],
          firstName: data['first_name'],
          lastName: data['last_name'],
          email: data['email'],
          phoneNumber: data['phone'],
          website: data['website'],
          streetAddress: data['street_address'],
          city: data['city'],
          state: data['state'],
          zip: data['zip_code'],
          specialty: data['med_specialty'],
          referralUrl: data['referral_url'],
          quizLink: data['quiz_link'],
        };

        resolve(item);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchDoctorParticipants = (
    id: string,
    page: number
  ): Promise<[DoctorParticipant[], number, number, number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/doctor/${id}/participants?page=${page}`;
        const response = await this.instance.get(url);
        const { data, meta } = response.data;
        const { from, to, total } = meta;
        const items: DoctorParticipant[] = data.map(item => ({
          id: item['id'],
          firstName: item['first_name'],
          lastName: item['last_name'],
          email: item['email'],
          date: new Date(item['date']),
          riskAssessment: item['risk_assessment'],
        }));

        resolve([items, from, to, total]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  createDoctorRecord = (payload: any): Promise<string> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.instance.post('/doctor', payload);
        const data = response.data;

        resolve(`Successfully created record of ${data['first_name']} ${data['last_name']}.`);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject(error.response.data.errors || {});
        }
      }
    })
  )

  updateDoctorRecord = (id: string, payload: any): Promise<string> => (
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.instance.patch(`/doctor/${id}`, payload);
        const data = response.data.data;

        resolve(`Successfully updated record of ${data['first_name']} ${data['last_name']}.`);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  deleteDoctorRecord = (id: string): Promise<string> => (
    new Promise(async (resolve, reject) => {
      try {
        await this.instance.delete(`/doctor/${id}`);
        resolve('Successfully deleted record.');
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
