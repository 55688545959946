import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import { isWeekend } from 'date-fns';
import Container from 'react-bootstrap/Container';
import isUndefined from 'lodash-es/isUndefined';

import DietitianAPIClient from '../../api/dietitian';

import AppNav from '../../components/AppNav';
import BaseModal from '../../components/BaseModal';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import Dropdown from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import SearchInput from '../../components/SearchInput';
import DateDropdown from '../../components/DateDropdown';
import DietitianClassCard from '../../components/DietitianClassCard';
import NoResults from '../../components/NoResults';

import usePagination from '../../hooks/pagination';

import { store } from '../../redux';
import { storeFilters, storePage } from '../../redux/actions/Dietitian';
import { ReducerStateType } from '../../redux/reducers/Dietitian';

import { classItemStatus, practiceConstants } from '../../utils';
import {
  CLASS_STATUS,
  FORMAT_DDDD,
  FORMAT_H_MMA,
} from '../../utils/constants';
import {
  ClassItem,
  ClassDayOption,
  ClassTimeOption,
  ClassStatus2,
  DropdownItem,
} from '../../utils/types';

import './index.scss';

interface Props extends ReducerStateType {
  userId: number;
}

export default function DietitianClasses(props: Props) {
  const { userId } = props;
  const api = new DietitianAPIClient();

  const dayOptions: DropdownItem[] = useMemo(() => ([
    { value: ClassDayOption.WEEKDAY, title: 'Weekday' },
    { value: ClassDayOption.WEEKEND, title: 'Weekend' },
    { value: '', title: 'Show All' }
  ]), []);

  const timeOptions: DropdownItem[] = useMemo(() => ([
    { value: ClassTimeOption.MORNING, title: 'Morning' },
    { value: ClassTimeOption.AFTERNOON, title: 'Afternoon' },
    { value: ClassTimeOption.EVENING, title: 'Evening' },
    { value: '', title: 'Show All' },
  ]), []);

  const statusOptions: DropdownItem[] = useMemo(() => ([
    { value: ClassStatus2.INPROGRESS, title: 'In Progress' },
    { value: ClassStatus2.COMPLETED, title: 'Completed' },
    { value: ClassStatus2.AVAILABLE, title: 'Available' },
    { value: CLASS_STATUS.Claimed, title: 'Claimed' },
    { value: CLASS_STATUS.All, title: CLASS_STATUS.All },
  ]), []);

  // Filter states
  const [startDateFilter, setStartDateFilter] = useState(props.startDate);
  const [endDateFilter, setEndDateFilter] = useState(props.endDate);
  const [dayFilter, setDayFilter] = useState(props.filterDay);
  const [timeFilter, setTimeFilter] = useState(props.filterTime);
  const [statusFilter, setStatusFilter] = useState(props.filterStatus);
  const [searchFilter, setSearchFilter] = useState(props.searchText);

  // Claim states
  const [canClaim, setCanClaim] = useState(true);
  const [selectedItem, setSelectedItem] = useState<ClassItem | undefined>();
  const [isProcessing, setIsProcessing] = useState(false);

  // Class dataset states
  const [isFetching, setIsFetching] = useState(true);
  const [classes, setClasses] = useState<ClassItem[]>([]);
  const filteredClasses = useMemo<ClassItem[]>(() => {
    if (isUndefined(startDateFilter) && isUndefined(endDateFilter)) {
      return classes;
    }

    let filtered = classes
      .filter(c => {
        let condition = moment(c.date).isSameOrAfter(startDateFilter);

        if (endDateFilter) {
          const eDate = moment(endDateFilter).endOf('day').toDate();
          condition = condition && moment(c.date).isSameOrBefore(eDate);
        }

        return condition;
      })
      .filter(c => {
        if (statusFilter == CLASS_STATUS.All) {
          return true;
        }

        if (statusFilter == ClassStatus2.AVAILABLE) {
          return c.status.value == CLASS_STATUS.Open;
        }

        return statusFilter == c.status.value;
      })
      .filter(c => {
        switch (dayFilter) {
          case ClassDayOption.WEEKDAY:
            return !isWeekend(c.date);
          case ClassDayOption.WEEKEND:
            return isWeekend(c.date);
          default:
            return true;
        }
      })
      .filter(c => {
        const hour = c.date.getHours();

        switch (timeFilter) {
          case ClassTimeOption.MORNING:
            return hour < 12;
          case ClassTimeOption.AFTERNOON:
            return hour >= 12 && hour < 18;
          case ClassTimeOption.EVENING:
            return hour >= 18;
          default:
            return true;
        }
      })
      .filter(c => {
        if (isUndefined(c.name)) {
          return false;
        }

        c.name = `${c.name}`;

        return c.name.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0;
      })
      .filter(c => !c.dietitianId || (c.dietitianId && c.dietitianId == userId));

    return filtered;
  }, [classes, startDateFilter, endDateFilter, dayFilter, timeFilter, statusFilter, searchFilter]);

  // Pagination
  const [currentPage, numPages, pagedClasses, setCurrentPage] = usePagination(filteredClasses, 5);

  // Fetch list of classes
  useEffect(() => {
    setIsFetching(true);
    api.fetchClasses().then((classes) => {
      // NOTE: For hiding unowned classes with same sched with ownedd
      // const ownedClasses = classes.filter(c => (
      //   !isUndefined(c.dietitianId) && c.dietitianId == userId
      // ));
      // const unownedClasses = classes.filter(c => {
      //   if (isUndefined(c.dietitianId)) {
      //     return true;
      //   }

      //   return c.dietitianId != userId;
      // });
      // const filteredClasses = unownedClasses.filter(c => {
      //   for (const item of ownedClasses) {
      //     if (item.date.getTime() == c.date.getTime()) {
      //       return false;
      //     }
      //   }

      //   return true;
      // });
      // const sortedClasses = [...ownedClasses, ...filteredClasses]
      //   .sort((a, b) => b.date.getTime() < a.date.getTime() ? 1 : -1);

      // setClasses(sortedClasses);
      setClasses(classes);
      setIsFetching(false);
    })
    .catch(() => {
      setClasses([]);
      setIsFetching(false);
    });
  }, []);

  // Store filters
  useEffect(() => {
    store.dispatch(storeFilters(
      dayFilter,
      timeFilter,
      statusFilter,
      searchFilter,
      startDateFilter,
      endDateFilter
    ))
  }, [startDateFilter, endDateFilter, dayFilter, timeFilter, statusFilter, searchFilter])

  // Cross-match props and state currentPage
  useEffect(() => {
    if (currentPage !== props.currentPage) {
      setCurrentPage(props.currentPage);
    }
  }, [])

  // Callback for claiming a class
  const claimClass = async (item: ClassItem) => {
    let isSame = false;

    setSelectedItem(item);

    for (const classItem of classes) {
      if ((classItem.dietitianId && classItem.dietitianId == userId) &&
          (classItem.date.getTime() == item.date.getTime())) {
        isSame = true;

        setCanClaim(false);

        break;
      }
    }

    if (isSame) {
      return;
    }

    setIsProcessing(true);
    api.claimClass(item.id)
      .then(() => {
        const index = classes.findIndex(c => c.id === item.id);
        const updatedClasses = classes.slice(0);

        if (index >= 0) {
          const classItem: ClassItem = updatedClasses[index];

          classItem.dietitianId = userId;
          classItem.status = classItemStatus(classItem);
        }

        setClasses(updatedClasses);
        setIsProcessing(false);
        toast.success('Class claimed.');
      })
      .catch(error => {
        setIsProcessing(false);
        toast.error(error.response.data.message);
      });
  };

  // Callback for unclaiming class
  const unclaimClass = async (item: ClassItem) => {
    const response = api.unclaimClass(item.id);

    setSelectedItem(item);
    setIsProcessing(true);
    response
      .then(() => {
        const index = classes.findIndex(c => c.id === item.id);
        const updatedClasses = classes.slice(0);

        if (index >= 0) {
          const classItem: ClassItem = updatedClasses[index];

          delete classItem.dietitianId;
          classItem.status = classItemStatus(classItem);
        }

        setClasses(updatedClasses);
        setIsProcessing(false);
        toast.success('Class unclaimed.');
      })
      .catch(error => {
        setIsProcessing(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <AppNav />

      <Container className="superuser Container-padding-bottom">

        <Header
          titleLg={6}
          title="Pick a Class"
        >
          <SearchInput
            defaultValue={searchFilter}
            placeholder="Type Class ID"
            onChangeText={text => {
              setCurrentPage(0);
              setSearchFilter(text);
              store.dispatch(storePage(0));
            }}
          />
        </Header>

        {(!canClaim && selectedItem) &&
          <BaseModal
            title={`${moment(selectedItem.date).format(FORMAT_DDDD)} - ${moment(selectedItem.date).format(FORMAT_H_MMA)}`}
            toDisplay={!canClaim}
            confirmTitle="Ok"
            onConfirm={() => setCanClaim(true)}>
            <label className="ModalLabel">
              You already have a class on this day and time. Please choose another class.
            </label>
          </BaseModal>
        }

        <div className={`dietitian-filters ${practiceConstants().className}`}>
          <Row>
            <Col xs={12} lg={1}>
              <h3 className="dietitian-filters-title">Filters</h3>
            </Col>
            <Col xs={12} lg={11} className="dietitian-filter-controls">
              <div className="form-group">
                <label>Start Date</label>
                <div className="dietitian-date-filters">
                  <DateDropdown
                    isInstant
                    currentDate={startDateFilter}
                    onDateSelection={d => {
                      setCurrentPage(0);
                      setStartDateFilter(d);
                      store.dispatch(storePage(0));
                    }}
                  />
                  <DateDropdown
                    isInstant
                    currentDate={endDateFilter}
                    onDateSelection={d => {
                      setCurrentPage(0);
                      setEndDateFilter(d);
                      store.dispatch(storePage(0));
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label id="dayFilterLabel">Day</label>
                <Dropdown
                  items={dayOptions}
                  selectedItem={dayOptions.find(o => o.value === dayFilter)!}
                  onItemSelection={i => {
                    setCurrentPage(0);
                    setDayFilter(i.value);
                    store.dispatch(storePage(0));
                  }}
                />
              </div>

              <div className="form-group">
                <label id="dayFilterTime">Time</label>
                <Dropdown
                  items={timeOptions}
                  selectedItem={timeOptions.find(o => o.value === timeFilter)!}
                  onItemSelection={i => {
                    setCurrentPage(0);
                    setTimeFilter(i.value);
                    store.dispatch(storePage(0));
                  }}
                />
              </div>

              <div className="form-group">
                <label id="dayFilterStatus">Status</label>
                <Dropdown
                  items={statusOptions}
                  selectedItem={statusOptions.find(o => o.value === statusFilter)!}
                  onItemSelection={i => {
                    setCurrentPage(0);
                    setStatusFilter(i.value);
                    store.dispatch(storePage(0));
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>

        <section className="dietitian-results" style={{ paddingBottom: 50 }}>
          {!isFetching
            ? <>
                {pagedClasses.length > 0 ?
                  <>
                    <div className="classes">
                      {pagedClasses.map(c =>
                        <DietitianClassCard
                          key={c.id}
                          item={c}
                          busy={isProcessing && selectedItem!.id == c.id}
                          claimed={!isUndefined(c.dietitianId)}
                          // claimed={!isUndefined(c.dietitianId) ? c.dietitianId === userId : false}
                          onClaim={claimClass}
                          onUnclaim={unclaimClass}
                          withViewParticipants
                        />)}
                    </div>
                    <div className="pagination-wrap">
                      <Pagination
                        pages={numPages}
                        currentPage={currentPage}
                        onChangePage={p => {
                          setCurrentPage(p);
                          store.dispatch(storePage(p));
                        }}
                      />
                    </div>
                  </>
                : <NoResults subject="classes" />
              }
            </>
            :
            <div className="QuizAnalytics-centerContainer">
              <Spinner />
            </div>
          }
        </section>
      </Container>
    </>
  );
}
