import { getType } from 'typesafe-actions';

import {
  logout,
  setUser,
  updateUser,
  changeClient,
  clearUser,
  processLogin,
  refreshAuthTokens,
  ReducerActionType,
} from '../actions/Authentication';

import {
  CLIENT_TYPE,
  CLIENT_FRUITSTREET,
  CLIENT_SHARECARE,
} from './../../utils/constants';
import {
  Client,
  User,
  UserType,
} from '../../utils/types';

export type ReducerStateType = Readonly<{
  accessToken?: string;
  isProcessing: boolean;
  isSuccess?: boolean;
  message: string;
  refreshToken?: string;
  user?: User;
  client: Client;
}>

const INITIAL_STATE: ReducerStateType = {
  client: CLIENT_FRUITSTREET,
  isProcessing: false,
  message: '',
}

const reducers = (
  state: ReducerStateType = INITIAL_STATE,
  action: ReducerActionType
) => {
  switch (action.type) {
    case getType(processLogin.request):
      return {
        ...state,
        isProcessing: true,
        isSuccess: undefined,
        message: ''
      }

    case getType(processLogin.success): {
      const { accessToken, refreshToken, user } = action.payload;

      return {
        ...state,
        accessToken,
        isProcessing: false,
        isSuccess: true,
        message: '',
        refreshToken,
        user
      }
    }

    case getType(processLogin.failure):
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        message: action.payload
      }

    case getType(setUser):
    case getType(refreshAuthTokens):
      return {
        ...state,
        ...action.payload
      }

    case getType(logout):
    case getType(clearUser):
      return INITIAL_STATE;

    case getType(updateUser): {
      const user = action.payload;
      let newState = {
        ...state,
        user: action.payload,
        client: CLIENT_FRUITSTREET,
      }

      switch (user.type) {
        case UserType.SUPERUSER:
        case UserType.DIETITIAN: {
          const account = user.account;

          if (account && account === CLIENT_SHARECARE.value) {
            newState = { ...newState, client: CLIENT_SHARECARE };
          }

          break;
        }
        default:
          break;
      }

      return newState;
    }

    case getType(changeClient): {
      switch (action.payload) {
        case CLIENT_TYPE.ShareCare:
          return { ...state, client: CLIENT_SHARECARE };

        default:
          return { ...state, client: CLIENT_FRUITSTREET };
      }
    }

    default:
      return state;
  }
}

export default reducers;
