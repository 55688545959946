import {
  Claim,
  FetchClaimPayload,
  ResendClaimPayload,
} from '../types/claims';

import ApiRequest from './api-request';

export class FetchClaimRequest extends ApiRequest {
  async send(payload: FetchClaimPayload): Promise<Claim> {
    const { patientId, milestone } = payload;
    const url = `/participants/${patientId}/milestones/${milestone}/claim`;

    return this
      .get(this.backendApiUrl(url))
      .then((resp) => {
        const data = resp.data.data;

        return {
          id: data.id,
          status: data.status,
          amount: parseFloat(data.amount),
          error: data.error,
        };
      });
  }
}

export class ResendClaimRequest extends ApiRequest {
  async send(payload: ResendClaimPayload): Promise<Claim> {
    const { patientId, milestone, patient } = payload;

    const participantUrl = this.backendApiUrl(`/signup-tracking/${patientId}`);
    const participantBody = {
      first_name: patient.firstName,
      last_name: patient.lastName,
    };

    const claimUrl = this.backendApiUrl(`/participants/${patientId}/milestones/${milestone}/resend-claim`);

    return Promise.all([
      this.patch(participantUrl, participantBody),
      this.post(claimUrl),
    ]).then((res) => {
      const data = res[1].data.data;

      return {
        id: data.id,
        status: data.status,
        amount: parseFloat(data.amount),
        error: data.error,
      };
    });
  }
}
