import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faTrophy,
  faMoneyCheck,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import DietitianPaymentsAPIClient from '../../../../api/dietitian-payments';

import AppNav from '../../../../components/AppNav';
import Button from '../../../../components/Button';
import Dropdown from '../../../../components/Dropdown';
import Header from '../../../../components/Header';
import NoResults from '../../../../components/NoResults';
import PaymentDropdown from '../../../../components/PaymentDropdown';
import Spinner from '../../../../components/Spinner';
import TotalResults from '../../../../components/TotalResults';

import { useAPIPagination } from '../../../../hooks/pagination';

import {
  DietitianPaymentData,
  DietitianPaymentUserData,
  DietitianPaymentSessionData,
} from '../../../../types/dietitian-payments';

import { practiceConstants } from '../../../../utils';
import {
  BASE_URL_INGENUITY,
  CLIENT_TYPE,
  FORMAT_MMMM,
  MONTHS_FILTER,
  PER_PAGE,
  YEARS_FILTER,
} from '../../../../utils/constants';
import { formatCurrency, formatNumber } from '../../../../utils/formatting';
import { ExportType, PaymentStatus } from '../../../../utils/types';

import ExportModal from '../../EmployerAnalytics/ExportModal';

import DietitianPaymentDetailsList from './List';
import DietitianPaymentDetailsSessionsList from './SessionsList';

interface Props extends RouteComponentProps {}

export default function DietitianPaymentDetails(props: Props | any) {
  const MILESTONES_FILTER = [
    { title: 'Milestone One', value: '1' },
    { title: 'Milestone Two', value: '2' },
    { title: 'Milestone Three', value: '3' },
    { title: 'Milestone Four', value: '4' },
  ];
  const SHOW_ALL = { title: 'Show All', value: '0' };
  const STATUS_FILTER = [
    { title: 'Paid', value: PaymentStatus.Paid },
    { title: 'Unpaid', value: PaymentStatus.Unpaid },
  ];
  const PERIOD_FILTER = [
    { title: 'Month', value: 'month' },
    { title: 'Year', value: 'year' },
  ];

  const api = new DietitianPaymentsAPIClient(BASE_URL_INGENUITY);
  const { className, practiceName, defaultEmployer } = practiceConstants();
  const isSharecare = practiceName === CLIENT_TYPE.ShareCare;
  const prevPath = props.location.state['prevPath'];
  const type = props.location.state['type'];
  const userId = props.match.params['id'];
  const dietitianId = props.location.state['dietitianId'];
  const period = props.match.params['period'] as string;
  const company = props.match.params['company'] as string;

  const periodYear = new Date(period).getFullYear();

  // Filter states
  const [isDescending, setIsDescending] = useState(true);
  const [milestone, setMilestone] = useState(SHOW_ALL);
  const [isYear, setIsYear] = useState(period.split('-').length == 1);
  const [newPeriod, setNewPeriod] = useState(period);
  const [periodFilter, setPeriodFilter] = useState(PERIOD_FILTER[isYear ? 1 : 0]);
  const [month, setMonth] = useState(MONTHS_FILTER.filter(item => (
    item.title == moment(new Date()).format(FORMAT_MMMM)
    ))[0]);
  const [year, setYear] = useState(YEARS_FILTER[0]);
  const [hasNoData, setHasNoData] = useState(false);

  // Export states
  const [toDisplayExportModal, setToDisplayExportModal] = useState(false);

  // Dataset states
  const [currentItem, setCurrentItem] = useState<DietitianPaymentData>();
  const [disabled, setDisabled] = useState(false);
  const [isFetchingInfo, setIsFetchingInfo] = useState(true);
  const [isFetchingList, setIsFetchingList] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [users, setUsers] = useState<DietitianPaymentUserData[]>([]);
  const [sessions, setSessions] = useState<DietitianPaymentSessionData[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedItems,
    setCurrentPage
  ] = useAPIPagination(
    currentItem && currentItem.milestones ? users :
    (currentItem && currentItem.sessions ? sessions : []),
    PER_PAGE,
    total
  );

  useEffect(() => {
    if (period) {
      var datePeriod = new Date(period);

      if (isYear) {
        for (let i = 0; i < YEARS_FILTER.length; i++) {
          if (YEARS_FILTER[i].value == datePeriod.getFullYear().toString()) {
            setYear(YEARS_FILTER[i]);
            break;
          }
        }

        setMonth(MONTHS_FILTER[0]);
      } else {
        for (let i = 0; i < MONTHS_FILTER.length; i++) {
          if (MONTHS_FILTER[i].value == (+datePeriod.getMonth() + 1).toString()) {
            setMonth(MONTHS_FILTER[i]);
            break;
          }
        }

        setYear(YEARS_FILTER[0]);
      }
    }
  }, [])

  useEffect(() => {
    api.fetchDietitianPaymentInfo(
      false,
      userId,
      company,
      isYear ? newPeriod : undefined,
      type
    )
      .then(response => {
        setDisabled(false);
        setIsFetchingInfo(false);
        setCurrentItem(response);
      })
      .catch(() => {
        setDisabled(true);
        setIsFetchingInfo(false);
        setCurrentItem(undefined);
      })
  }, [])

  useEffect(() => {
    if (currentItem && currentItem.milestones) {
      setIsFetchingList(true);
      api.fetchDietitianPaymentParticipantsList(
        false,
        (isYear ? currentItem.dietitianId! : currentItem.id),
        currentPage + 1,
        parseInt(milestone.value),
        isDescending ? 'desc' : 'asc',
        isYear ? newPeriod : undefined
      )
        .then(response => {
          const [list, i, j, t] = response;

          setIsFetchingList(false);
          setUsers(list);
          setFrom(i);
          setTo(j);
          setTotal(t);
        })
        .catch(() => {
          setIsFetchingList(false);
          setUsers([]);
          setFrom(0);
          setTo(0);
          setTotal(0);
        });
    }
  }, [currentPage, milestone, currentItem, isDescending])

  useEffect(() => {
    if (currentItem && currentItem.sessions) {
      setIsFetchingList(true);
      api.fetchDietitianPaymentSessionsList(
        false,
        (isYear ? currentItem.dietitianId! : currentItem.id),
        currentPage + 1,
        isDescending ? 'desc' : 'asc',
        isYear ? newPeriod : undefined
      )
        .then(response => {
          const [list, i, j, t] = response;

          setIsFetchingList(false);
          setSessions(list);
          setFrom(i);
          setTo(j);
          setTotal(t);
        })
        .catch(() => {
          setIsFetchingList(false);
          setSessions([]);
          setFrom(0);
          setTo(0);
          setTotal(0);
        });
    }
  }, [currentPage, currentItem, isDescending])

  const onPeriodFilter = (year: string, month?: string) => {
    if (company) {
      api.fetchDietitianPaymentInfoPeriodFilter(
        dietitianId,
        type,
        company,
        year,
        month)
        .then(response => {
          setNewPeriod(month ? year + "-" + month : year);
          setHasNoData(false);
          setDisabled(false);
          setIsFetchingInfo(false);
          setCurrentItem(response);
        })
        .catch((error) => {
          setHasNoData(error.hasNoData);
          setDisabled(true);
          setIsFetchingInfo(false);
        })
    }
  }

  const onSubmit = (status: string) => {
    if (!currentItem) {
      return;
    }

    setIsProcessing(true);
    api.updateDietitianPaymentStatus(isSharecare, currentItem.id, status, type)
      .then(() => {
        currentItem.status = status;

        setCurrentItem(currentItem);
        setIsProcessing(false);
        toast.success('Successfully updated payment status.');
      })
      .catch(() => {
        setIsProcessing(false);
        toast.error('Failed to update payment status.');
      })
  }

  const renderFilterList = () => {
    const rowStyle = { justifyContent: 'space-between' };

    return (
      <div className={`filters-section ${className}`}>
        <Row style={rowStyle}>
          <Col xs={12} lg={2}>
            <h3 className="primary-title">Filters</h3>
          </Col>
          <Col xs={12} lg={10} className="controls">
            <Dropdown
              items={PERIOD_FILTER}
              selectedItem={periodFilter}
              title="Payment Period"
              onItemSelection={(item) => {
                setPeriodFilter(item);

                if (item.value == PERIOD_FILTER[0].value) {
                  setIsYear(false);
                } else {
                  setIsYear(true);
                }
              }} />
            {periodFilter.value == "month" ?
              <Dropdown
                items={MONTHS_FILTER}
                selectedItem={month}
                title="Month"
                onItemSelection={(item) => {
                  setCurrentPage(0);
                  setIsFetchingInfo(true)
                  setMonth(item);

                  onPeriodFilter(periodYear.toString(), item.value);
                }} /> :
              <Dropdown
                items={YEARS_FILTER}
                selectedItem={year}
                title="Year"
                onItemSelection={(item) => {
                  setCurrentPage(0);
                  setIsFetchingInfo(true)
                  setYear(item);

                  onPeriodFilter(item.value);
                }} />
            }
            {type != 'session' &&
              <Dropdown
                items={[...MILESTONES_FILTER, SHOW_ALL]}
                selectedItem={milestone}
                title="Show"
                onItemSelection={item => {
                  setCurrentPage(0);
                  setIsFetchingList(true);
                  setMilestone(item);
                }} />
            }
          </Col>
        </Row>
      </div>
    )
  }

  const renderInfo = () => {
    const name = currentItem ? currentItem.name : '-';
    const participants = currentItem ? currentItem.participants : 0;
    const periodString = currentItem ? currentItem.period : '-';
    const total = currentItem ? currentItem.total : 0;

    const headerByType = () => {
      if (currentItem && currentItem.milestones) {
        return (
          <>
            <th className="fit padding-right">
              <FontAwesomeIcon icon={faTrophy} />&nbsp;
              Milestone 1
            </th>
            <th className="fit padding-right">
              <FontAwesomeIcon icon={faTrophy} />&nbsp;
              Milestone 2
            </th>
            <th className="fit padding-right">
              <FontAwesomeIcon icon={faTrophy} />&nbsp;
              Milestone 3
            </th>
            <th className="fit padding-right">
              <FontAwesomeIcon icon={faTrophy} />&nbsp;
              Milestone 4
            </th>
          </>
        )
      }

      if (currentItem && currentItem.sessions) {
        return (
          <th className="fit padding-right">
            <FontAwesomeIcon icon={faUsers} />&nbsp;
            Sessions
          </th>
        )
      }

      return <></>
    }

    const rowByType = () => {
      if (currentItem && currentItem.milestones) {
        return (
          <>
            <td className="fit padding-right">
              {formatCurrency(currentItem.milestones.one)}
            </td>
            <td className="fit padding-right">
              {formatCurrency(currentItem.milestones.two)}
            </td>
            <td className="fit padding-right">
              {formatCurrency(currentItem.milestones.three)}
            </td>
            <td className="fit padding-right">
              {formatCurrency(currentItem.milestones.four)}
            </td>
          </>
        )
      }

      if (currentItem && currentItem.sessions) {
        return (
          <td className="fit padding-right">
            {formatNumber(currentItem.sessions.count)}
          </td>
        )
      }

      return <></>
    }

    return (
      <div className={`${className} border-top`}>
        {!isFetchingInfo ?
          <Table className="info-container">
            <thead>
              <tr className="header">
                <td colSpan={20}>
                  <div className="row">
                    <label className="title">{name} - Dietitian Payment History</label>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <>
                {(currentItem && currentItem.milestones) &&
                  <th className="fit padding-right">
                    <FontAwesomeIcon icon={faUsers} />&nbsp;
                    Participants
                  </th>
                }

                <th className="fit padding-right">
                  <FontAwesomeIcon icon={faMoneyCheck} />&nbsp;
                  Payment Period
                </th>
                </>

                {headerByType()}
                <th className="fit padding-right">Total</th>
                <th>Status</th>
              </tr>
              <tr>
                <>
                {(currentItem && currentItem.milestones) && <td>{participants}</td>}

                <td>{periodString}</td>
                </>
                {rowByType()}
                <td className="fit padding-right">{formatCurrency(total)}</td>
                <td>
                  <PaymentDropdown
                    processing={isProcessing}
                    items={STATUS_FILTER}
                    selectedItem={currentItem ?
                      STATUS_FILTER.filter(c => c.value == currentItem.status)[0] :
                      STATUS_FILTER[0]
                    }
                    viewOnly={isYear || disabled}
                    onItemSelection={item => onSubmit(item.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table> :
          <div className="info-container loader">
            <Spinner />
          </div>
        }
      </div>
    )
  }

  const renderList = () => {
    if ((pagedItems && pagedItems.length == 0) || hasNoData) {
      return <NoResults />
    }

    if (currentItem && currentItem.milestones) {
      return (
        <>
          <TotalResults
            total={total}
            from={from}
            to={to}
          />
          <DietitianPaymentDetailsList
            currentPage={currentPage}
            isDescending={isDescending}
            isFetching={isFetchingList}
            isSharecare={isSharecare}
            numPages={numPages}
            pagedUsers={pagedItems}
            showAll={(milestone.value == SHOW_ALL.value) && !isFetchingList}
            setIsDescending={setIsDescending}
            setIsFetching={setIsFetchingList}
            setCurrentPage={setCurrentPage}
            setUsers={setUsers}
          />
        </>
      )
    }

    if (currentItem && currentItem.sessions) {
      return (
        <>
          <TotalResults
            total={total}
            from={from}
            to={to}
          />
          <DietitianPaymentDetailsSessionsList
            currentPage={currentPage}
            isDescending={isDescending}
            isFetching={isFetchingList}
            numPages={numPages}
            pagedSessions={pagedItems}
            setIsDescending={setIsDescending}
            setIsFetching={setIsFetchingList}
            setCurrentPage={setCurrentPage}
            setSessions={setSessions}
          />
        </>
      )
    }

    return <></>
  }

  return (
    <>
      <AppNav />

      <Container
        className={classnames(
          'superuser padding-bottom',
          className
        )}
      >
        <Header titleLg={6} title={`By ${type == 'session' ? 'Sessions' : 'Participants'}`}>
          <div className="header-actions-section">
            {isSharecare &&
              <Button
                disabled={isFetchingInfo && isFetchingList}
                variant="primary"
                onClick={() => setToDisplayExportModal(true)}
              >
                <FontAwesomeIcon className="download-icon" icon={faDownload} />
              </Button>
            }

            <LinkContainer to={prevPath}>
              <Button variant="primary">Go back to list</Button>
            </LinkContainer>
          </div>
        </Header>

        {(toDisplayExportModal && currentItem) &&
          <ExportModal
            data={{
              id: userId,
              employer: defaultEmployer,
              date: new Date(period),
              items: MILESTONES_FILTER
            }}
            title={ExportType.MonthlyInvoice}
            toDisplay={toDisplayExportModal}
            onClose={() => setToDisplayExportModal(false)}
          />
        }

        {renderInfo()}
        {renderFilterList()}
        {(isFetchingList && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> : renderList()}
      </Container>
    </>
  );
}
