import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames';

import { practiceConstants } from '../../utils';
import { DropdownItem } from '../../utils/types';

import './index.scss';

interface Props {
  disabled?: boolean;
  hasError?: boolean;
  hasSearch?: boolean;
  items: DropdownItem[];
  selectedItem: DropdownItem;
  style?: React.CSSProperties;
  title?: string;
  onItemSelection: (item: DropdownItem) => void;
}

interface State {
  displayMenu: boolean;
  items: DropdownItem[];
  searchText: string;
}

class Dropdown extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      displayMenu: false,
      items: props.items,
      searchText: '',
    }
  }

  private node: HTMLDivElement | null = null;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { items, searchText } = prevState;

    if ((nextProps.items != items) && searchText.length == 0) {
      return {
        ...prevState,
        items: nextProps.items
      }
    }

    return null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node) {
      return;
    }

    if (!this.node.contains(e.target) && this.state.displayMenu) {
      this.toggleDropdownMenu();
    }
  }

  toggleDropdownMenu = () => {
    const { disabled } = this.props;
    const { displayMenu } = this.state;

    if (disabled) return;

    this.setState({
      displayMenu: !displayMenu,
      searchText: '',
    });
  }

  onDropdownSelection = (item: DropdownItem) => {
    this.props.onItemSelection(item);
    this.toggleDropdownMenu();
  }

  onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    const items = this.props.items.filter(item => (
      item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    ));

    this.setState({ items, searchText });
  }

  renderList = (items: DropdownItem[]) => (
    <ul className="Dropdown-list">
      {(this.props.hasSearch && this.props.items.length >= 10) &&
        <li id="search">
          <input
            autoComplete="off"
            autoFocus
            id="search"
            placeholder="Search"
            name="search"
            type="text"
            value={this.state.searchText}
            onChange={this.onSearch}
          />
        </li>
      }
      {
        items.map((item: DropdownItem, index: number) => {
          const { selectedItem } = this.props;
          const isSame: boolean =  item.title === selectedItem.title;
          const props = isSame ? { className: 'active' } : {};

          return (
            <li
              key={item.id || index}
              onClick={() => this.onDropdownSelection(item)}
              {...props}>
              <label>
                {item.title}
              </label>
            </li>
          )
        })
      }
    </ul>
  )

  render() {
    const {
      disabled,
      hasError,
      selectedItem,
      title,
      style,
    } = this.props;
    const { displayMenu, items } = this.state;
    const onClickHandler = this.toggleDropdownMenu;

    return (
      <div
        className={`ml-auto Dropdown-dropdown ${practiceConstants().className}`}
        style={style}
        ref={node => this.node = node}>
        <div className="Dropdown-info">
          {title && (
            <label className="Dropdown-infoLabel">
              {title}
            </label>
          )}

          <div
            className={classnames(
              `Dropdown-button${disabled ? "Disabled" : ""}`,
              hasError ? 'has-error' : '',
            )}
            onClick={onClickHandler}>
            {selectedItem.title}

            <FontAwesomeIcon
              className={`Dropdown-icon${disabled ? "Disabled" : ""}`}
              icon={faCaretDown} />
          </div>
        </div>

        {displayMenu && this.renderList(items)}
      </div>
    );
  }
}

export default Dropdown;
