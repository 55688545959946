import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  faBuilding,
  faCalendar,
  faFileMedical,
  faUser,
  faCaretDown,
  faCaretUp,
  faIdCard,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Pagination from '../../../../../components/Pagination';

import { DietitianPaymentUserData } from '../../../../../types/dietitian-payments';

import { practiceConstants, scrollToNode } from '../../../../../utils';
import { FORMAT_MM_DD_YYYY } from '../../../../../utils/constants';
import { formatCurrency } from '../../../../../utils/formatting';

import './index.scss';

interface Props {
  currentPage: number;
  isDescending: boolean;
  isFetching?: boolean;
  isSharecare?: boolean;
  numPages: number;
  pagedUsers: any[];
  showAll?: boolean;
  setIsDescending: (value: boolean) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setUsers: (value: React.SetStateAction<DietitianPaymentUserData[]>) => void;
}

export default function List(props: Props) {
  const {
    isDescending,
    isFetching,
    isSharecare,
    pagedUsers,
    setIsDescending,
  } = props;
  const achievedDate = (user: DietitianPaymentUserData) => {
    if (!user.achieved) {
      return <span>-</span>
    }

    const date = moment(user.achieved).format(FORMAT_MM_DD_YYYY);

    if (props.showAll) {
      return <span><strong>M{user.milestone}</strong> - {date}</span>
    }

    return <span>{date}</span>
  }

  const employer = (user: DietitianPaymentUserData) => {
    const { employers } = practiceConstants();
    const e = employers.filter(item => item.value == user.employer)[0];

    return e ? e.title : user.employer;
  }

  const renderList = () => (
    <Table
      className="DietitianPaymentDetails-list"
      style={{ opacity: isFetching ? 0.5 : 1 }}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faUser} />
            Last Name
          </th>
          <th>
            <FontAwesomeIcon icon={faUser} />
            First Name
          </th>
          <th className="button fit padding-right" onClick={() => setIsDescending(!isDescending)}>
            <FontAwesomeIcon icon={faCalendar} />Achieved&nbsp;
            <FontAwesomeIcon icon={isDescending ? faCaretDown : faCaretUp} />
          </th>
          <th>Payment Due</th>
        </tr>
      </thead>
      <tbody>
        {pagedUsers.map((user: DietitianPaymentUserData, index: number) =>
          <tr id={`DietitianPaymentParticipants-row${index}`} key={`${user.id}-${index}`}>
            <td className="fit padding-right">{user.lastName}</td>
            <td className="fit padding-right">{user.firstName}</td>
            <td className="fit padding-right">{achievedDate(user)}</td>
            <td>{formatCurrency(user.paymentDue)}</td>
          </tr>
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('DietitianPaymentParticipants-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
