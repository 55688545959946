import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

import { QuizAnalyticsData, QuizAnalyticsDropOffData } from '../types/quiz-analytics';

import { practiceConstants } from '../utils';
import { FORMAT_YYYY_MM_DD } from '../utils/constants';

import APIClient, { autoClose } from '.';

export default class QuizAnalyticsAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  fetchQuizAnalytics = (
    company: string,
    from?: Date,
    to?: Date
  ): Promise<QuizAnalyticsData> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/signup-tracking/stats?company=${company}`;

        if (from) {
          url = `${url}&from_date=${moment(from).format(FORMAT_YYYY_MM_DD)}`;
        }

        if (to) {
          url = `${url}&to_date=${moment(to).format(FORMAT_YYYY_MM_DD)}`;
        }

        const response = await this.instance.get(url);
        const data = response.data;
        const quizAnalytics = {
          total: data.total,
          highRisk: {
            count: data.high_risk.count,
            quiz: data.high_risk.finished_quiz,
            eligible: data.high_risk.finished_eligibility,
            scheduled: data.high_risk.finished_scheduler,
            signedUp: data.high_risk.finished_signup,
          },
          lowRisk: {
            count: data.low_risk.count,
            quiz: data.low_risk.finished_quiz,
            eligible: data.low_risk.finished_eligibility,
            scheduled: data.low_risk.finished_scheduler,
            signedUp: data.low_risk.finished_signup,
          }
        };

        resolve(quizAnalytics);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchQuizAnalyticsDropOff = (
    company: string,
    from?: Date,
    to?: Date
  ): Promise<QuizAnalyticsDropOffData> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/signup-tracking/stats?company=${company}`;

        if (from) {
          url = `${url}&from_date=${moment(from).format(FORMAT_YYYY_MM_DD)}`;
        }

        if (to) {
          url = `${url}&to_date=${moment(to).format(FORMAT_YYYY_MM_DD)}`;
        }

        url = `${url}&drop_off=1`;

        const response = await this.instance.get(url);
        const data = response.data;
        const stats: QuizAnalyticsDropOffData = {
          total: data.total,
          q1: data.q_1,
          q2: data.q_2,
          q2a: data.q_2a,
          q2b: data.q_2b,
          q3: data.q_3,
          q4: data.q_4,
          q5: data.q_5,
          q6: data.q_6,
          q7: data.q_7,
          q8: data.q_8,
        };

        resolve(stats);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
