import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { withRouter, RouteComponentProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCarrot,
  faClipboard,
  faTrophy,
  faUsers,
  faWeight,
  faSearchDollar,
  faUserCheck,
  IconDefinition,
  faExclamation,
  faUser,
  faFile,
  faStethoscope,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import AppNav from '../../components/AppNav';
import Header from '../../components/Header';

import { practiceConstants } from '../../utils';
import { ROUTES, CLIENT_TYPE } from '../../utils/constants';

import './index.scss';

type CardData = {
  title: string;
  info: string;
  icon: IconDefinition;
  variant: 'normal' | 'dark';
  link?: ROUTES;
}

interface Props extends RouteComponentProps {}

const SuperuserAnalyticsTracking = (props: Props) => {
  const { className: practiceClassName, practiceName } = practiceConstants();
  const analyticsItems = (): (CardData | undefined)[] => {
    const milestoneAnalytics: CardData = {
      title: 'Milestone Analytics',
      info: 'See visualizations for milestone completion rates.',
      link: ROUTES.SuperuserMilestoneAnalytics,
      variant: 'normal',
      icon: faTrophy,
    };
    const signupAnalytics: CardData = {
      title: 'Signup Analytics',
      info: 'See signup analytics for different contracts.',
      link: ROUTES.SuperuserSignupAnalytics,
      variant: 'normal',
      icon: faUsers
    };
    const weightLossAnalytics: CardData = {
      title: 'Weight Loss Analytics',
      info: 'See graphs of weight loss outcomes.',
      link: ROUTES.SuperuserWeightLossAnalytics,
      variant: 'normal',
      icon: faWeight,
    };
    const quizAnalytics: CardData = {
      title: 'Quiz Analytics',
      info: 'See results of the risk quiz by contract.',
      link: ROUTES.SuperuserQuizAnalytics,
      variant: 'normal',
      icon: faClipboard,
    };
    const attendanceAnalytics: CardData = {
      title: 'Attendance Analytics',
      info: 'See attendance of employees.',
      link: ROUTES.SuperuserAttendanceAnalytics,
      variant: 'normal',
      icon: faUserCheck,
    };
    const activeUsers: CardData = {
      title: 'Active Users',
      info: 'See attendance of employees.',
      link: ROUTES.SuperuserActiveUsers,
      variant: 'normal',
      icon: faUserCheck,
    };
    const employerAnalytics: CardData = {
      title: 'Employer Analytics',
      info: 'See employer trends.',
      link: ROUTES.SuperuserEmployerAnalytics,
      variant: 'normal',
      icon: faUserCheck,
    };

    switch (practiceName) {
      case CLIENT_TYPE.ShareCare:
        return [
          milestoneAnalytics,
          signupAnalytics,
          // weightLossAnalytics,
          // attendanceAnalytics,
        ];

      default:
        return [
          milestoneAnalytics,
          signupAnalytics,
          weightLossAnalytics,
          quizAnalytics,
          // attendanceAnalytics,
          // activeUsers,
          employerAnalytics,
        ];
    }
  }

  const trackingItems = (): (CardData | undefined)[] => {
    const milestoneTracking: CardData = {
      title: 'Milestone Tracking',
      info: 'Track milestone completion for participants.',
      link: ROUTES.SuperuserMilestoneTracking,
      variant: 'dark',
      icon: faTrophy,
    };
    const signupTracking: CardData = {
      title: 'Signup Tracking',
      info: 'Track sign up rates for different contracts.',
      link: ROUTES.SuperuserSignupTracking,
      variant: 'dark',
      icon: faUsers,
    };
    const weightLossTracking: CardData = {
      title: 'Weight Loss Tracking',
      info: 'Track weight loss tracking charts.',
      link: ROUTES.SuperuserWeightLossTracking,
      variant: 'dark',
      icon: faWeight,
    };
    const dietitianPayments: CardData = {
      title: 'Dietitian Payments',
      info: 'Track how much dietitians are owed and what they have been paid.',
      link: ROUTES.SuperuserDietitianPayments,
      variant: 'dark',
      icon: faCarrot,
    };
    const monthlyInvoice: CardData = {
      title: 'Monthly Invoice',
      info: 'Track monthly invoice.',
      link: ROUTES.SuperuserMonthlyInvoices,
      variant: 'dark',
      icon: faWeight,
    };
    const unitEconomics: CardData = {
      title: 'Unit Economics',
      info: 'Track the revenue for every class.',
      link: ROUTES.SuperuserUnitEconomics,
      variant: 'dark',
      icon: faSearchDollar,
    };
    const consumerPayment: CardData = {
      title: 'Consumer Payments',
      info: 'Track payments of consumers.',
      link: ROUTES.SuperuserConsumerPayments,
      variant: 'dark',
      icon: faUser,
    };
    const contracts: CardData = {
      title: 'Contracts',
      info: 'Track and create contracts for every employer.',
      link: ROUTES.SuperuserContracts,
      variant: 'dark',
      icon: faFile,
    };
    const eligibilityErrors: CardData = {
      title: 'Eligibility Errors',
      info: 'Track the errors from eligibility check.',
      link: ROUTES.SuperuserEligibilityErrors,
      variant: 'dark',
      icon: faExclamation,
    };
    const doctors: CardData = {
      title: 'Doctors',
      info: 'Manage list of doctors and their referral links.',
      link: ROUTES.SuperuserDoctors,
      variant: 'dark',
      icon: faStethoscope,
    };

    switch (practiceName) {
      case CLIENT_TYPE.ShareCare:
        return [
          milestoneTracking,
          // weightLossTracking,
          monthlyInvoice,
          dietitianPayments,
          contracts,
        ];

      default:
        return [
          milestoneTracking,
          signupTracking,
          weightLossTracking,
          dietitianPayments,
          // unitEconomics,
          consumerPayment,
          contracts,
          doctors,
          eligibilityErrors,
        ];
    }
  };

  const renderCard = (index: number, data?: CardData) => {
    if (data) {
      return (
        <button
          disabled={!data.link}
          key={data.title}
          className="SuperuserAnalytics-card"
          onClick={(event) => {
            event.preventDefault();
            props.history.push(data.link!);
          }}>

          <div className={`SuperuserAnalytics-icon ${data.variant}`}>
            <FontAwesomeIcon icon={data.icon} />
          </div>

          <div className="info">
            <label className="strong">{data.title}</label>
            <label>{data.info}</label>
          </div>
        </button>
      )
    }

    return <div key={`empty-${index}`} className="SuperuserAnalytics-card empty" />
  }

  const buttonRows = (type: 'analytics' | 'tracking') => {
    const items = type == 'analytics' ? analyticsItems() : trackingItems();
    const limit = 5;
    const rows = Math.ceil(items.length / limit);
    const itemsAtRow = (items, row: number, limit: number): JSX.Element[] => {
      var elements: JSX.Element[] = [];
      const offset = limit * row
      const length = limit + offset;

      for (var i = 0 + offset; i < length; i++) {
        elements.push(renderCard(i, items[i]))
      }

      return elements;
    }

    if (rows == 1) {
      return (
        <div className="SuperuserAnalytics-container centered">
          {itemsAtRow(items, 0, limit)}
        </div>
      )
    }

    return _.range(rows).map((_, index) => (
      <div key={`${type}-${index}`} className="SuperuserAnalytics-container centered">
        {itemsAtRow(items, index, limit)}
      </div>
    ))
  }

  return (
    <>
      <AppNav />

      <Container className={`superuser ${practiceClassName}`}>
        <Header title="Analytics" titleLg={6} />

        {buttonRows('analytics')}

        <Header title="Tracking" titleLg={6} />

        {buttonRows('tracking')}

        <div style={{ height: 100 }} />
      </Container>
    </>
  )
}

export default withRouter(SuperuserAnalyticsTracking);
