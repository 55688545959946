import { useEffect } from 'react';

import ApiRequest from '../api/api-request';

/**
 * Sends an API request using request classes.
 * Automatically cancels the previous request on cleanup.
 * 
 * sender arg should be a function that receives an instance of the first
 * argument and then sends the request. It will be called again if any of deps changes.
 * 
 * Use [] as deps to run a request once.
 * 
 * Usage:
 *   useAPI(FetchUserRequest, (request) => request.send({ id: 12 }))
 * 
 * @param type API request class
 * @param sender callback function that sends the request
 * @param deps dependencies
 */
export default function useAPI<T extends ApiRequest>(
  type: { new(): T ;},
  sender: (request: T) => void,
  deps?: any[],
) {
  const request = new type();
  return useEffect(() => {
    sender(request);
    return () => request.cancel();
  }, deps)
}
