import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import AppNav from '../../components/AppNav';
import CustomButton from '../../components/CustomButton';
import DateDropdownGroup from '../../components/DateDropdownGroup';
import Dropdown from '../../components/Dropdown';
import Header from '../../components/Header';
import SearchInput from '../../components/SearchInput';
import Spinner from '../../components/Spinner';
import SuperuserClassList from '../../components/SuperuserClassList';

import { RootState } from '../../redux/reducers';
import {
  fetchClasses,
  filterClasses,
  sortClasses,
} from '../../redux/actions/SuperuserHome';
import { ReducerStateType } from '../../redux/reducers/SuperuserHome';

import { practiceConstants } from '../../utils';
import {
  CLASS_STATUS_FILTER,
  DAYS_FILTER,
  ROUTES,
} from '../../utils/constants';
import {
  ClassItem,
  ClassSort,
  DropdownItem,
} from '../../utils/types';

type DispatchPropTypes = Readonly<{
  fetchClasses: () => void;
  filterClasses: (
    filterDay: DropdownItem,
    filterStatus: DropdownItem,
    search: string,
    startDate?: Date,
    endDate?: Date
  ) => void;
  sortClasses: (key: ClassSort, isDescending: boolean) => void;
}>;

interface Props extends RouteComponentProps, ReducerStateType, DispatchPropTypes {}

interface State {
  searchText: string;
  selectedDayFilter: DropdownItem;
  selectedStartDateFilter?: Date;
  selectedEndDateFilter?: Date;
  selectedStatusFilter: DropdownItem;
}

class SuperuserHome extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: props.searchText,
      selectedDayFilter: props.filterDay,
      selectedStatusFilter: props.filterStatus,
      selectedStartDateFilter: props.startDate,
      selectedEndDateFilter: props.endDate,
    };
  }

  componentDidMount() {
    this.props.fetchClasses();
  }

  componentDidUpdate(_, prevState: State) {
    const {
      searchText,
      selectedDayFilter,
      selectedStatusFilter,
      selectedEndDateFilter,
      selectedStartDateFilter,
    } = this.state;
    const filterDay = selectedDayFilter;
    const filterStatus = selectedStatusFilter;

    if (prevState.searchText != searchText ||
        prevState.selectedDayFilter.value != filterDay.value ||
        prevState.selectedStatusFilter.value != filterStatus.value ||
        prevState.selectedEndDateFilter != selectedEndDateFilter ||
        prevState.selectedStartDateFilter != selectedStartDateFilter) {
      this.props.filterClasses(
        filterDay,
        filterStatus,
        searchText,
        selectedStartDateFilter,
        selectedEndDateFilter
      );
    }
  }

  handleActionButtonClick = (classDetails: ClassItem) => {
    const path = ROUTES.SuperuserClassDetails.replace(':id', `${classDetails.id}`);

    this.props.history.push(path);
  }

  handleDayFilterSelection = (item: DropdownItem) => {
    this.setState({ selectedDayFilter: item });
  }

  handleStartDateFilterSelection = (date: Date) => {
    this.setState({ selectedStartDateFilter: date });
  }

  handleEndDateFilterSelection = (date: Date) => {
    this.setState({ selectedEndDateFilter: date });
  }

  handleResetDatesFilterSelection = () => {
    this.setState({
      selectedStartDateFilter: undefined,
      selectedEndDateFilter: undefined,
    })
  }

  handleSearchFilter = (searchText: string) => {
    this.setState({ searchText });
  }

  handleStatusFilterSelection = (item: DropdownItem) => {
    this.setState({ selectedStatusFilter: item });
  }

  renderFilters = () => (
    <div className={`filters-section ${practiceConstants().className}`}>
      <Row>
        <Col xs={12} lg={2}>
          <CustomButton
            primary
            title="Create Class"
            onClick={() => this.props.history.push(ROUTES.SuperuserCreateClass)}
            disabled={true}
          />
        </Col>
        <Col xs={12} lg={10} className="controls">
          <DateDropdownGroup
            currentEndDate={this.props.endDate}
            currentStartDate={this.props.startDate}
            isInstant
            onDatesSelection={dates => {
              this.setState({
                selectedStartDateFilter: dates[0],
                selectedEndDateFilter: dates[dates.length - 1]
              })
            }}
            onStartDateSelection={this.handleStartDateFilterSelection}
            onEndDateSelection={this.handleEndDateFilterSelection}
            onResetDatesSelection={this.handleResetDatesFilterSelection}
          />
          <Dropdown
            items={DAYS_FILTER}
            selectedItem={this.props.filterDay}
            title="Day"
            onItemSelection={this.handleDayFilterSelection}
          />
          <Dropdown
            items={CLASS_STATUS_FILTER}
            selectedItem={this.props.filterStatus}
            title="Status"
            onItemSelection={this.handleStatusFilterSelection}
          />
        </Col>
      </Row>
    </div>
  )

  render() {
    const {
      filteredClasses,
      isLoading,
      sortClasses,
    } = this.props;

    return (
      <>
        <AppNav />

        <Container className="superuser padding-bottom">
          <Header title="All Classes" titleLg={6}>
            <SearchInput
              defaultValue={this.props.searchText}
              placeholder=""
              onChangeText={this.handleSearchFilter} />
          </Header>

          {this.renderFilters()}

          {isLoading ?
            <div className="center-container">
              <Spinner />
            </div> :
            <SuperuserClassList
              classes={filteredClasses}
              isDescending={this.props.isDescending}
              sortKey={this.props.sortKey}
              onRowClick={this.handleActionButtonClick}
              setIsDescending={sortClasses}
            />
          }
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => state.superuserHome;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchClasses: () => dispatch(fetchClasses.request()),
  filterClasses: (
    filterDay: DropdownItem,
    filterStatus: DropdownItem,
    search: string,
    startDate?: Date,
    endDate?: Date
  ) => dispatch(filterClasses(
    filterDay,
    filterStatus,
    search,
    startDate,
    endDate,
  )),
  sortClasses: (key: ClassSort, isDescending: boolean) => dispatch(sortClasses(key, isDescending)),
} as DispatchPropTypes);

export default connect(mapStateToProps, mapDispatchToProps)(SuperuserHome);
