import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Doughnut, defaults } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import {
  faCheckCircle,
  faTimesCircle,
  faUser,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'

import QuizAnalyticsAPIClient from '../../../api/quiz-analytics';
import SignupTrackingAPIClient from '../../../api/signup-tracking';

import AppNav from '../../../components/AppNav';
import DateDropdownGroup from '../../../components/DateDropdownGroup';
import Dropdown from '../../../components/Dropdown';
import Header from '../../../components/Header';
import NoResults from '../../../components/NoResults';
import Spinner from '../../../components/Spinner';
import TotalResults from '../../../components/TotalResults';

import { useAPIPagination } from '../../../hooks/pagination';
import { useQueryString, QueryKeys } from '../../../hooks/useURLQuery';

import { SignupTrackingFilter as Filter, UserData } from '../../../types/signup-tracking';

import {
  abbreviateNumber,
  hasEligibilityCheck,
  practiceConstants,
  queryStringParams,
} from '../../../utils';
import {
  BASE_URL_INGENUITY,
  RISK,
  ROUTES,
  PER_PAGE,
  FORMAT_YYYY_MM_DD,
} from '../../../utils/constants';
import { formatNumber } from '../../../utils/formatting';
import { QuizAnalyticsData, QuizAnalyticsDropOffData } from '../../../types/quiz-analytics';

import SignupTrackingList from '../SignupTracking/List';

import './index.scss';

type StatColumnData = {
  color: string;
  icon: IconDefinition;
  title: string;
  value: number;
  percentage?: number;
}

type StatRowData = {
  title: string;
  count: number;
  quiz: number;
  eligible: number;
  scheduled: number;
  signedUp: number;
}

interface Props extends RouteComponentProps { }

export default function QuizAnalytics(props: Props) {
  const SHOW_ALL = { title: Filter.All.toString(), value: Filter.All.toString().toLowerCase() };
  const STEPS = [
    { title: Filter.Landing.toString(), value: 'landing' },
    { title: Filter.Quiz.toString(), value: 'quiz' },
    { title: Filter.Eligibility.toString(), value: 'eligibility' },
    { title: Filter.Scheduler.toString(), value: 'scheduler' },
    { title: Filter.SignedUp.toString(), value: 'signup' },
    SHOW_ALL,
  ];
  const VIEW_RISK = { title: 'Risk', value: 'risk' };
  const VIEW_DROP_OFF = { title: 'Drop-off', value: 'drop-off' };

  const signupTrackingApi = new SignupTrackingAPIClient(BASE_URL_INGENUITY);
  const api = new QuizAnalyticsAPIClient(BASE_URL_INGENUITY);
  const query = queryStringParams(props.location.search);
  const queryPage = query[QueryKeys.Page] ? +query[QueryKeys.Page] - 1 : 0;
  const { chartColors, className, defaultEmployer, employers } = practiceConstants();

  // States
  const [employer, setEmployer] = useState(
    employers.filter(e => e.value == query[QueryKeys.Company])[0] || defaultEmployer
  );
  const [currentEmployer, setCurrentEmployer] = useState(employer);
  const [stepFilter, setStepFilter] = useState(STEPS.filter(e => (
    e.value == query[QueryKeys.Step]
  ))[0] || SHOW_ALL);
  const [viewFilter, setViewFilter] = useState(
    query[QueryKeys.View] && query[QueryKeys.View] == VIEW_DROP_OFF.value ? VIEW_DROP_OFF : VIEW_RISK
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    query[QueryKeys.FromDate] && new Date(query[QueryKeys.FromDate])
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    query[QueryKeys.ToDate] && new Date(query[QueryKeys.ToDate])
  );

  const isDropOff = viewFilter.value == VIEW_DROP_OFF.value;
  const toDisplayEligible = hasEligibilityCheck(employer.value);

  // Dataset states
  const initialChartData: QuizAnalyticsData = {
    total: 0,
    highRisk: {
      count: 0,
      quiz: 0,
      eligible: 0,
      scheduled: 0,
      signedUp: 0
    },
    lowRisk: {
      count: 0,
      quiz: 0,
      eligible: 0,
      scheduled: 0,
      signedUp: 0
    }
  };
  const initialChartDropOffData: QuizAnalyticsDropOffData = {
    total: 0,
    q1: 0,
    q2: 0,
    q2a: 0,
    q2b: 0,
    q3: 0,
    q4: 0,
    q5: 0,
    q6: 0,
    q7: 0,
    q8: 0,
  };

  const [chartData, setChartData] = useState<QuizAnalyticsData>();
  const [chartDropOffData, setChartDropOffData] = useState<QuizAnalyticsDropOffData>();
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingChart, setIsFetchingChart] = useState(true);
  const [users, setUsers] = useState<UserData[]>([]);

  // Pagination
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [
    currentPage,
    numPages,
    pagedUsers,
    setCurrentPage
  ] = useAPIPagination(users, PER_PAGE, total, queryPage);

  const queryString = useQueryString({
    [QueryKeys.Page]: currentPage + 1,
    [QueryKeys.Company]: employer.value,
    [QueryKeys.FromDate]: startDate && moment(startDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.ToDate]: endDate && moment(endDate).format(FORMAT_YYYY_MM_DD),
    [QueryKeys.Step]: isDropOff ? undefined : (stepFilter.value != SHOW_ALL.value ? stepFilter.value : undefined),
  });

  useEffect(() => {
    setCurrentPage(queryPage);

    return () => {
      api.unsubscribe();
      signupTrackingApi.unsubscribe();
    }
  }, [])

  useEffect(() => {
    setIsFetching(true);
    props.history.replace(`${queryString}&${QueryKeys.View}=${viewFilter.value}`);
    signupTrackingApi.fetchSignupTracking(queryString, true, isDropOff)
      .then(response => {
        const [list, i, j, t] = response;

        setIsFetching(false);
        setUsers(list);
        setFrom(i);
        setTo(j);
        setTotal(t);
      })
      .catch(() => {
        setIsFetching(false);
        setUsers([]);
        setFrom(0);
        setTo(0);
        setTotal(0);
      });
  }, [employer, currentPage, stepFilter, startDate, endDate, viewFilter]);

  useEffect(() => {
    setIsFetchingChart(true);
    props.history.replace(`${queryString}&${QueryKeys.View}=${viewFilter.value}`);

    if (isDropOff) {
      api.fetchQuizAnalyticsDropOff(employer.value, startDate, endDate)
        .then(response => {
          setIsFetchingChart(false);
          setChartDropOffData(response);
          setCurrentEmployer(employer);
        })
        .catch(() => {
          setIsFetchingChart(false);
          setChartDropOffData(initialChartDropOffData);
          setCurrentEmployer(employer);
        })
    } else {
      api.fetchQuizAnalytics(employer.value, startDate, endDate)
        .then(response => {
          setIsFetchingChart(false);
          setChartData(response);
          setCurrentEmployer(employer);
        })
        .catch(() => {
          setIsFetchingChart(false);
          setChartData(initialChartData);
          setCurrentEmployer(employer);
        })
    }
  }, [employer, startDate, endDate, viewFilter])

  const renderFilterChart = () => (
    <div className={`filters-section border-bottom ${className}`}>
      <Row>
        <Col xs={12} lg={2}>
          <h3 className="primary-title">Filter Chart</h3>
        </Col>
        <Col xs={12} lg={10} className="controls">
          <Dropdown
            disabled={employers.length <= 1}
            items={employers}
            selectedItem={employer}
            title="Employer"
            onItemSelection={item => {
              setCurrentPage(0);
              setEmployer(item);
            }} />
          <DateDropdownGroup
            currentEndDate={endDate}
            currentStartDate={startDate}
            isInstant
            label="Range"
            onDatesSelection={dates => {
              setCurrentPage(0);
              setStartDate(dates[0])
              setEndDate(dates[dates.length - 1])
            }}
            onStartDateSelection={date => {
              setCurrentPage(0);
              setStartDate(date);
            }}
            onEndDateSelection={date => {
              setCurrentPage(0);
              setEndDate(date);
            }}
            onResetDatesSelection={() => {
              setCurrentPage(0);
              setStartDate(undefined);
              setEndDate(undefined);
            }} />
          <Dropdown
            items={[VIEW_RISK, VIEW_DROP_OFF]}
            selectedItem={viewFilter}
            title="View"
            onItemSelection={item => {
              setCurrentPage(0);
              setViewFilter(item);
            }} />
        </Col>
      </Row>
    </div>
  )

  const renderFilterList = () => (
    <div className={`filters-section list ${className}`}>
      <Row>
        <Col xs={12} lg={2}>
          <h3 className="primary-title">
            Filter List
            </h3>
        </Col>
        {!isDropOff &&
          <Col xs={12} lg={8} className="controls">
            <Dropdown
              items={STEPS}
              selectedItem={stepFilter}
              title="Finished"
              onItemSelection={(item) => {
                setCurrentPage(0);
                setStepFilter(item);
              }} />
          </Col>
        }
      </Row>
    </div>
  )

  const getGraphData = () => {
    const labels = (
      !isDropOff ? [RISK.High, RISK.Low] :
      ['Q1', 'Q2', 'Q2a', 'Q2b', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8']
    );
    let datasets = {
      data: [100],
      backgroundColor: ['#BBBBBB'],
      // borderColor: ['#AAAAAA'],
      // borderWidth: 1,
    };
    let total = 0;

    if (isDropOff && chartDropOffData && chartDropOffData.total > 0) {
      const { q1, q2, q2a, q2b, q3, q4, q5, q6, q7, q8, total: t } = chartDropOffData;

      datasets = {
        data: [q1, q2, q2a, q2b, q3, q4, q5, q6, q7, q8],
        backgroundColor: chartColors,
        // borderColor: ['#AAAAAA'],
        // borderWidth: 1,
      };
      total = t;
    } else if (!isDropOff && chartData && chartData.total > 0) {
      const { highRisk, lowRisk, total: t } = chartData;

      datasets = {
        data: [highRisk.count, lowRisk.count],
        backgroundColor: ['#FC8476', '#8FE6A4'],
        // borderColor: ['#D97165', '#7BC68D'],
        // borderColor: ['#AAAAAA'],
        // borderWidth: 1,
      };
      total = t;
    }

    return { data: { labels, datasets: [datasets] }, total };
  }

  const renderGraph = () => {
    if (isFetchingChart) {
      return (
        <div className="QuizAnalytics-graphContainer">
          <div className="loader">
            <Spinner />
          </div>
        </div>
      )
    }

    const { data, total } = getGraphData();
    const chartOptions: ChartOptions = {
      animation: {
        easing: 'easeInOutQuad',
        duration: 500
      },
      maintainAspectRatio: true,
      responsive: true,
      tooltips: {
        displayColors: false,
      }
    };

    // Set defaults
    defaults['global']['defaultFontFamily'] = 'Open Sans';

    return (
      <div className="QuizAnalytics-graphContainer" style={{ opacity: isFetchingChart ? 0.5 : 1 }}>
        <div className="graph">
          <Doughnut
            data={data}
            width={200}
            legend={{ display: false }}
            options={chartOptions}
          />
          <div className="centerInfo">
            <label style={{ fontSize: total == 0 ? 22 : 39 }}>
              {abbreviateNumber(total) || 'No Data'}
            </label>

            {(total && total > 0) ? <label>Total</label> : <label />}
          </div>
        </div>
        <div className="graphInfo">
          <label className="header">{currentEmployer.title}</label>

          {renderGraphInfo()}
          {!isDropOff && chartData && (
            <div style={{ paddingRight: 30 }}>
              <Table>
                <tbody>
                  {renderStatsRow("red", { title: RISK.High, ...chartData.highRisk })}
                  {renderStatsRow("green", { title: RISK.Low, ...chartData.lowRisk })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderGraphInfo = () => {
    const { chartColors, primaryColor } = practiceConstants();

    if (chartData && !isDropOff) {
      return (
        <div className="totalContainer">
          {renderStatColumn({
            color: primaryColor,
            title: 'Total',
            icon: faUsers,
            value: chartData.total
          })}
          {renderStatColumn({
            color: '#EB3939',
            title: RISK.High,
            icon: faTimesCircle,
            value: chartData.highRisk.count,
            percentage: (
              chartData.highRisk.count == 0 ? 0 :
              Math.floor((chartData.highRisk.count / chartData.total) * 100)
            ),
          })}
          {renderStatColumn({
            color: '#53CB7A',
            title: RISK.Low,
            icon: faCheckCircle,
            value: chartData.lowRisk.count,
            percentage: (
              chartData.lowRisk.count == 0 ? 0 :
              Math.floor((chartData.lowRisk.count / chartData.total) * 100)
            ),
          })}
        </div>
      )
    } else if (chartDropOffData && isDropOff) {
      const { q1, q2, q2a, q2b, q3, q4, q5, q6, q7, q8, total } = chartDropOffData;
      const data = [
        { title: 'Question 1', value: q1 },
        { title: 'Question 2', value: q2 },
        { title: 'Question 2a', value: q2a },
        { title: 'Question 2b', value: q2b },
        { title: 'Question 3', value: q3 },
        { title: 'Question 4', value: q4 },
        { title: 'Question 5', value: q5 },
        { title: 'Question 6', value: q6 },
        { title: 'Question 7', value: q7 },
        { title: 'Question 8', value: q8 },
      ];

      return (
        <>
          <div className="totalContainer">
            {data.slice(0, 5).map((item, index) => (
              renderStatColumn({
                color: chartColors[index],
                title: item.title,
                icon: faUser,
                value: item.value,
                percentage: (
                  item.value == 0 ? 0 :
                  Math.floor((item.value / total) * 100)
                )
              }, 'sm')
            ))}
          </div>
          <div className="totalContainer">
            {data.slice(5, 10).map((item, index) => (
              renderStatColumn({
                color: chartColors[index],
                title: item.title,
                icon: faUser,
                value: item.value,
                percentage: (
                  item.value == 0 ? 0 :
                  Math.floor((item.value / total) * 100)
                )
              }, 'sm')
            ))}
          </div>
        </>
      )
    }
  }

  const renderList = () => {
    if (pagedUsers && pagedUsers.length == 0) {
      return <NoResults />
    }

    return (
      <>
        <TotalResults
          total={total}
          from={from}
          to={to}
        />
        <SignupTrackingList
          currentPage={currentPage}
          employer={currentEmployer}
          isDropOff={isDropOff}
          isFetching={isFetching}
          numPages={numPages}
          pagedUsers={pagedUsers}
          setIsFetching={setIsFetching}
          setCurrentPage={setCurrentPage}
        />
      </>
    )
  }

  const renderStatColumn = (data: StatColumnData, size?: 'sm') => (
    <div key={data.title} className="statsColumn">
      <label>
        <FontAwesomeIcon icon={data.icon} />
        &nbsp;&nbsp;{data.title}
      </label>
      <label className={`value${size ? '-sm' : ''}`} style={{ color: data.color }}>
        {formatNumber(data.value)} {data.percentage ? `(${data.percentage}%)` : ''}
      </label>
    </div>
  )

  const renderStatsRow = (className: string, data: StatRowData) => (
    <tr>
      <td style={{ paddingLeft: 0 }}>
        <label style={{ width: 85 }}>
          <FontAwesomeIcon icon={className == "red" ? faTimesCircle : faCheckCircle} />
          &nbsp;&nbsp;{data.title}
        </label>
        <label className={className}>{`(${formatNumber(data.count)})`}</label>
      </td>

      <td style={{ paddingLeft: 0 }}>
        <label>{Filter.Quiz}</label>&nbsp;
        <label className={className}>{`(${formatNumber(data.quiz)})`}</label>
      </td>

      {toDisplayEligible && (
        <td style={{ paddingLeft: 0 }}>
          <label>{Filter.Eligibility}</label>&nbsp;
          <label className={className}>{`(${formatNumber(data.eligible)})`}</label>
        </td>
      )}

      <td style={{ paddingLeft: 0 }}>
        <label>{Filter.Scheduler}</label>&nbsp;
        <label className={className}>{`(${formatNumber(data.scheduled)})`}</label>
      </td>

      <td style={{ paddingLeft: 0 }}>
        <label>{Filter.SignedUp}</label>&nbsp;
        <label className={className}>{`(${formatNumber(data.signedUp)})`}</label>
      </td>
    </tr>
  )

  return (
    <>
      <AppNav />

      <Container className="superuser padding-bottom">
        <Header
          hasBack
          backUrl={ROUTES.SuperuserAnalyticsTracking}
          titleLg={6}
          title="Quiz Analytics"
        />

        {renderFilterChart()}
        {renderGraph()}
        {renderFilterList()}

        {(isFetching && users.length == 0) ?
          <div className="center-container">
            <Spinner />
          </div> : renderList()
        }
      </Container>
    </>
  )
}
