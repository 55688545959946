import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone';

import { timezoneAbbreviation } from '../../utils';
import {
  FORMAT_DDDD,
  FORMAT_H_MM_A,
  FORMAT_MM_DD_YYYY,
  VALIDATION_NO_DIETITIAN,
} from '../../utils/constants';
import { ClassItem } from '../../utils/types';

import './index.scss';

interface Props {
  index: number,
  classDetails: ClassItem,
  onActionClick: (classDetails: ClassItem) => void;
}

class SuperuserClassRow extends React.Component<Props> {
  getData = (details: ClassItem) => {
    const name = details.name;
    const datetime = moment(details.date).format(`${FORMAT_MM_DD_YYYY} - ${FORMAT_H_MM_A}`);
    const timezone = timezoneAbbreviation(details.date);
    const day = moment(details.date).format(FORMAT_DDDD);
    const participants = `${details.participants.length}/${details.maxParticipants}`;
    const dietitian = (details.dietitian ? details.dietitian.fullName : 'Unassigned' );
    const status = details.status.title;
    const isScheduled = details.isScheduled;

    return {
      name,
      datetime,
      timezone,
      day,
      participants,
      dietitian,
      status,
      isScheduled
    }
  }

  getStyles = (details: ClassItem) => ({
    row: { height: '100%' },
    statusLabel: {
      backgroundColor: details.status.color,
      borderRadius: 2,
      color: 'white',
      fontSize: details ? 10 : 14,
      padding: details ? 3 : 0,
      paddingLeft: details ? 5 : 0,
      paddingRight: details ? 5 : 0,
    },
    title: {
      backgroundColor: '#F2F2F2',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    }
  })

  onActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { classDetails, onActionClick } = this.props;

    if (onActionClick && classDetails) {
      onActionClick(classDetails);
    }
  }

  render() {
    const {
      name,
      datetime,
      timezone,
      day,
      participants,
      dietitian,
      status,
    } = this.getData(this.props.classDetails);
    const styles = this.getStyles(this.props.classDetails);

    return (
      <tr id={`SuperuserClass-row${this.props.index}`}>
        <th className="contain-text-250">
          {name.indexOf('Class ') > -1 ? name : `Class ${name}`}
        </th>
        <td>{datetime} <strong>{timezone}</strong></td>
        <td>{day}</td>
        <td>{participants}</td>
        <td>
          <label className={`SuperuserClassRow-label${dietitian == VALIDATION_NO_DIETITIAN ? 'Italic' : ''}`}>
            {dietitian}
          </label>
        </td>
        <td>
          <label className="SuperuserClassRow-labelBold" style={styles.statusLabel}>
            {status}
          </label>
        </td>
        <td className="actions">
          <div>
            <span onClick={this.onActionClick}>
              <FontAwesomeIcon icon={faEdit} />
            </span>
          </div>
        </td>
      </tr>
    )
  }
}

export default SuperuserClassRow;
