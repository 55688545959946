import React, { useMemo } from 'react';
import BsPagination from 'react-bootstrap/Pagination';

import { practiceConstants } from '../../utils';

import './index.scss';

type Props = {
  breakpoint?: number;
  currentPage: number;
  pages: number;
  onChangePage: (page: number) => void;
}

export default function Pagination(props: Props) {
  const { breakpoint, currentPage, pages, onChangePage } = props;

  const pageArr = useMemo(() => {
    const arr: number[] = [];

    if (pages > 10) {
      const limit = breakpoint || 10;
      const keys = Array.from(Array(limit).keys());

      // First n pages
      if (currentPage < limit) {
        arr.push(...keys);
        arr.push(-1);
        arr.push(pages - 1);
      // Last n pages
      } else if (currentPage > pages - (limit + 1)) {
        arr.push(0);
        arr.push(-1);
        arr.push(...keys.reverse().map(i => pages - (i + 1)));
      // Middle pages
      } else {
        arr.push(0);
        arr.push(-2);
        arr.push(currentPage - 4);
        arr.push(currentPage - 3);
        arr.push(currentPage - 2);
        arr.push(currentPage - 1);
        arr.push(currentPage);
        arr.push(currentPage + 1);
        arr.push(currentPage + 2);
        arr.push(currentPage + 3);
        arr.push(currentPage + 4);
        arr.push(-3);
        arr.push(pages - 1);
      }
    } else {
      for (let i = 0; i < pages; i++) {
        arr.push(i);
      }
    }
    return arr;
  }, [pages, currentPage]);

  if (pages === 0) {
    return null;
  }

  return (
    <div className={practiceConstants().className}>
      <BsPagination className="pagination-wrap">
        <BsPagination.Prev
          disabled={currentPage === 0}
          onClick={(e) => {
            e.preventDefault();
            onChangePage(currentPage - 1)
          }}
        />
        {pageArr.map(p =>
          <BsPagination.Item
            key={p}
            disabled={currentPage === p || p < 0}
            className={currentPage === p ? 'page-item--current' : ''}
            onClick={(e) => {
              e.preventDefault();
              onChangePage(p)
            }}
          >
            {p >= 0 ? p + 1 : '...'}
          </BsPagination.Item>
        )}
        <BsPagination.Next
          disabled={currentPage === pages - 1 && pages > 0}
          onClick={(e) => {
            e.preventDefault();
            onChangePage(currentPage + 1)
          }}
        />
      </BsPagination>
    </div>
  );
}
