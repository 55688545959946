import React from 'react';
import Table from 'react-bootstrap/Table';
import { faMoneyCheck, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Pagination from '../../../../components/Pagination';

import { DietitianPaymentData } from '../../../../types/dietitian-payments';

import { scrollToNode } from '../../../../utils';

import MonthlyInvoiceRow from './Row';

interface Props {
  viewOnly?: boolean;
  currentPage: number;
  isFetching?: boolean;
  numPages: number;
  pagedUsers: DietitianPaymentData[];
  type: string;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  setIsFetching: (value: React.SetStateAction<boolean>) => void;
  setUsers: (value: React.SetStateAction<DietitianPaymentData[]>) => void;
  onItemSelection: (item: DietitianPaymentData) => void;
}

export default function List(props: Props) {
  const { type } = props;

  const renderList = () => (
    <Table className={`data-list${props.isFetching ? ' fetching' : ''}`}>
      <thead>
        <tr>
          <th>
            <FontAwesomeIcon icon={faUsers} />
            Participants
          </th>
          <th>
            <FontAwesomeIcon icon={faMoneyCheck} />
            Period
          </th>
          <th>Milestone 1</th>
          <th>Milestone 2</th>
          <th>Milestone 3</th>
          <th>Milestone 4</th>
          <th>Total</th>
          <th>Status</th>
          <th style={{ width: 50 }}></th>
        </tr>
      </thead>
      <tbody>
        {props.pagedUsers.map((user: DietitianPaymentData, index: number) =>
          <MonthlyInvoiceRow
            key={user.id}
            index={index}
            item={user}
            type={type}
            viewOnly={props.viewOnly}
            onPaymentStatusChange={item => {
              props.pagedUsers[index] = item;

              props.setUsers(props.pagedUsers);
            }}
            onSelect={item => {
              if (props.isFetching) {
                return;
              }

              props.onItemSelection(item);
            }} />
        )}
      </tbody>
    </Table>
  )

  return (
    <>
      {renderList()}

      <div className="pagination-wrap">
        <Pagination
          pages={props.numPages}
          currentPage={props.currentPage}
          onChangePage={p => {
            scrollToNode('DietitianPayment-row0');
            props.setIsFetching(true);
            props.setCurrentPage(p);
          }} />
      </div>
    </>
  )
}
