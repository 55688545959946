import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './index.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick:(event: React.MouseEvent<HTMLButtonElement>) => void;
  primary?: boolean;
  danger?: boolean;
  type?: 'submit' | 'button' | 'reset',
  title: string;
}

class CustomButton extends React.Component<Props> {
  render() {
    const {
      type,
      className,
      disabled,
      isLoading,
      onClick,
      primary,
      danger,
      title,
    } = this.props;
    let classes = 'CustomButton-container btn';
    let style = {};

    if (primary) {
      classes += ' btn-primary';
    } else if (danger) {
      classes += ' btn-danger';
    } else {
      classes += ' btn-secondary';
    }

    if (isLoading || disabled) {
      classes += ' disabled';
    }

    if (className) {
      classes += ` ${className}`;
    }

    return (
      <button
        type={type || 'button'}
        className={classes.trim()}
        disabled={isLoading || disabled}
        onClick={onClick}
        style={{ ...style, lineHeight: 0 }}>
        {isLoading ?
          <FontAwesomeIcon
            style={{ marginRight: 0 }}
            icon={faSpinner}
            spin
          /> : title
        }
      </button>
    );
  }
}

export default CustomButton;
