import moment from 'moment';
import _ from 'lodash';

import {
  ClassStatus,
  Client,
  DropdownItem,
  SessionSlide,
} from './types';

export enum ANALYTICS {
  FBPixel = '943123585737485',
  Insight = '42856',
}

export enum CLASS_STATUS {
  InProgress = 'In Progress',
  Completed = 'Completed',
  Open = 'Open',
  Cancelled = 'Cancelled',
  Full = 'Full/Starting Soon',
  All = 'Show All',
  Claimed = 'Claimed'
}

export enum CLIENT_TYPE {
  FruitStreet = 'Fruit Street',
  ShareCare = 'Sharecare',
}

export enum DAYS {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  WeekendsOnly = 'Weekends Only',
  WeekdaysOnly = 'Weekdays Only',
  All = 'Show All',
}

export enum MILESTONE_KEYS {
  One = 'milestoneOne',
  Two = 'milestoneTwo',
  Three = 'milestoneThree',
  Four = 'milestoneFour',
}

export enum RISK {
  High = 'High Risk',
  Low = 'Low Risk'
}

export enum ROUTES {
  Login = '/login',

  SuperuserDashboard = '/superuser/classes/',
  SuperuserCreateClass =  '/superuser/classes/new/',
  SuperuserClassDetails = '/superuser/classes/update/:id/',

  SuperuserAnalyticsTracking = '/superuser/analytics-tracking',
  SuperuserMilestoneAnalytics = '/superuser/analytics-tracking/milestone-analytics',
  SuperuserSignupAnalytics = '/superuser/analytics-tracking/signup-analytics',
  SuperuserWeightLossAnalytics = '/superuser/analytics-tracking/weight-loss-analytics',
  SuperuserQuizAnalytics = '/superuser/analytics-tracking/quiz-analytics',
  SuperuserAttendanceAnalytics = '/superuser/analytics-tracking/attendance-analytics',
  SuperuserActiveUsers = '/superuser/analytics-tracking/active-users',
  SuperuserEmployerAnalytics = '/superuser/analytics-tracking/employer-analytics',

  SuperuserMilestoneTracking = '/superuser/analytics-tracking/milestone-tracking',
  SuperuserSignupTracking = '/superuser/analytics-tracking/signup-tracking',
  SuperuserWeightLossTracking = '/superuser/analytics-tracking/weight-loss-tracking',
  SuperuserDietitianPayments = '/superuser/analytics-tracking/dietitian-payments',
  SuperuserDietitianPaymentDetails = '/superuser/analytics-tracking/dietitian-payments/:company/:id/:period',
  SuperuserUnitEconomics = '/superuser/analytics-tracking/unit-economics',
  SuperuserUnitEconomicsDetails = '/superuser/analytics-tracking/unit-economics/:id',
  SuperuserConsumerPayments = '/superuser/analytics-tracking/consumer-payments',
  SuperuserConsumerPaymentDetails = '/superuser/analytics-tracking/consumer-payments/:id',
  SuperuserContracts = '/superuser/analytics-tracking/contracts',
  SuperuserCreateContractDetails = '/superuser/analytics-tracking/contracts/new',
  SuperuserUpdateContractDetails = '/superuser/analytics-tracking/contracts/:id',
  SuperuserEligibilityErrors = '/superuser/analytics-tracking/eligibility-errors',
  SuperuserMonthlyInvoices = '/superuser/analytics-tracking/monthly-invoices',
  SuperuserMonthlyInvoiceDetails = '/superuser/analytics-tracking/monthly-invoices/:id/:period',
  SuperuserDoctors = '/superuser/analytics-tracking/doctors',
  SuperuserCreateDoctorDetails = '/superuser/analytics-tracking/doctors/new',
  SuperuserUpdateDoctorDetails = '/superuser/analytics-tracking/doctors/:id',

  SuperuserAutoScheduling = '/superuser/scheduling/',

  DataExplorer = '/superuser/data-explorer/ic6UyWcZUgmeUz0/',

  DietitianClasses = '/dietitian/classes/',
  DietitianClassDetails = '/dietitian/classes/:id/',

  ParticipantClasses = '/participant/classes/',
  ParticipantEligibility = '/participant/eligibility/',
  ParticipantWizard = '/participant/wizard/',
  ParticipantPayment = '/participant/payment/',
}

export enum STRIPE_STATUS {
  Succeeded = 'succeeded',
  PaymentMethod = 'requires_payment_method',
  Action = 'requires_action',
  None = 'none',
};

// VSee API
// export const ACCOUNT_CODE = process.env.REACT_APP_VSEE_ACCOUNT_CODE || '';
// export const API_KEY = process.env.REACT_APP_VSEE_API_KEY || '';
export const BASE_URL = process.env.REACT_APP_VSEE_API_URL || '';
export const BASE_URL_SHARECARE = process.env.REACT_APP_VSEE_SHARECARE_API_URL || '';
export const SIGNUP_URL = process.env.REACT_APP_VSEE_SIGNUP_URL || '';

// Laravel backend
export const BASE_URL_INGENUITY = `${process.env.REACT_APP_FRUITSTREET_APP_URL}/api` || '';
export const QUIZ_URL = `${process.env.REACT_APP_FRUITSTREET_APP_URL}/quiz/walgreens` || '';
export const QUIZ_URL_GENERAL = `${process.env.REACT_APP_FRUITSTREET_APP_URL}/sign-up` || '';

// Stripe
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';

// Zendesk
export const ZENDESK_FS_KEY = '352cb8ce-2c12-41a5-a28a-65d1bb537bd5';
export const ZENDESK_SHARECARE_KEY = 'b2c5179c-0f27-4b71-87bb-4e820f2890e4';

// Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT || '';

export const CLASS_STATUS_LIST: ClassStatus = {
  [CLASS_STATUS.InProgress]: {
    color: '#53CB7A',
    title: CLASS_STATUS.InProgress,
    value: CLASS_STATUS.InProgress,
  },
  [CLASS_STATUS.Completed]: {
    color: '#FF7151',
    title: CLASS_STATUS.Completed,
    value: CLASS_STATUS.Completed,
  },
  [CLASS_STATUS.Open]: {
    color: '#39C8EB',
    title: CLASS_STATUS.Open,
    value: CLASS_STATUS.Open,
  },
  [CLASS_STATUS.Claimed]: {
    color: '#39C8EB',
    title: CLASS_STATUS.Claimed,
    value: CLASS_STATUS.Claimed,
  },
  [CLASS_STATUS.Cancelled]: {
    color: '#B11F3B',
    title: CLASS_STATUS.Cancelled,
    value: CLASS_STATUS.Cancelled,
  },
  [CLASS_STATUS.Full]: {
    color: '#B1B1B1',
    title: CLASS_STATUS.Full,
    value: CLASS_STATUS.Full,
  }
};

export const CLASS_STATUS_FILTER: DropdownItem[] = [
  { title: CLASS_STATUS.InProgress, value: CLASS_STATUS.InProgress },
  { title: CLASS_STATUS.Completed, value: CLASS_STATUS.Completed },
  { title: CLASS_STATUS.Open, value: CLASS_STATUS.Open },
  { title: CLASS_STATUS.Cancelled, value: CLASS_STATUS.Cancelled },
  { title: CLASS_STATUS.Full, value: CLASS_STATUS.Full },
  { title: CLASS_STATUS.All, value: CLASS_STATUS.All },
];

export const CLIENT_FRUITSTREET: Client = {
  name: CLIENT_TYPE.FruitStreet,
  value: 'fruitstreet',
  primaryColor: '#3D98F2',
};

export const CLIENT_SHARECARE: Client = {
  name: CLIENT_TYPE.ShareCare,
  value: 'sharecare',
  primaryColor: '#1ABA9C',
};

export const DAYS_FILTER: DropdownItem[] = [
  { title: DAYS.Monday, value: DAYS.Monday },
  { title: DAYS.Tuesday, value: DAYS.Tuesday },
  { title: DAYS.Wednesday, value: DAYS.Wednesday },
  { title: DAYS.Thursday, value: DAYS.Thursday },
  { title: DAYS.Friday, value: DAYS.Friday },
  { title: DAYS.Saturday, value: DAYS.Saturday },
  { title: DAYS.Sunday, value: DAYS.Sunday },
  { title: DAYS.WeekendsOnly, value: DAYS.WeekendsOnly },
  { title: DAYS.WeekdaysOnly, value: DAYS.WeekdaysOnly },
  { title: DAYS.All, value: DAYS.All },
];

export const FORMAT_D = 'D';
export const FORMAT_DD = 'dd';
export const FORMAT_DDD = 'ddd';
export const FORMAT_DDDD = 'dddd';
export const FORMAT_H_MMA = 'h:mmA';
export const FORMAT_H_MM_A = 'h:mm A';
export const FORMAT_MM_DD = 'MM/DD';
export const FORMAT_MM_DD_YYYY = 'MM/DD/YYYY';
export const FORMAT_MM_DD_YYYY_DASH = 'MM-DD-YYYY';
export const FORMAT_MMM_DD = 'MMM DD';
export const FORMAT_MMMM = 'MMMM';
export const FORMAT_MMM = 'MMM';
export const FORMAT_MMM_YYYY = 'MMM YYYY';
export const FORMAT_MMMM_YYYY = 'MMMM YYYY';
export const FORMAT_MMMM_D = 'MMMM D';
export const FORMAT_MMMM_D_YYYY = 'MMMM D, YYYY';
export const FORMAT_MMMM_Do_YYYY = 'MMMM Do, YYYY';
export const FORMAT_YYYY = 'YYYY';
export const FORMAT_YYYY_MM = 'YYYY-MM';
export const FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const MESSAGE_ELIGIBILITY_SUCCESS = 'We have confirmed your eligibility for the Diabetes Prevention Program.';
export const MESSAGE_ELIGIBILITY_FAILURE = 'Sorry, we are unable to confirm your eligibility at this time.';

export const MESSAGE_QUIZ_VALIDATION = 'Take the quiz first for you to be able to access the list of classes.';

export const MIN_PARTICIPANTS = 5;
export const MAX_PARTICIPANTS = 10;

export const MONTHS_FILTER = moment.months().map((item, index) => ({
  title: item, value: (index + 1).toString()
}));

export const INFO_LANDING = 'No info';
export const INFO_QUIZ = 'The participant completed the entire risk quiz and received a result';
export const INFO_ELIGIBLE_GREEN = 'The user entered their health plan ID information and was eligible';
export const INFO_ELIGIBLE_RED = 'The user entered their health plan ID information and was not eligible';
export const INFO_SCHEDULED = 'The user picked a class';
export const INFO_SIGNEDUP = 'The user took the quiz, was high risk, eligible, picked a class, created an account, and a scale was sent to them';

export const PER_PAGE = 15;
export const PER_PAGE_SELECTIONS = [25, 50, 100, 250, 500];

export const SESSION_KEY_STAGE_TRACKING = 'trackingId';
export const SESSION_KEY_CONTRACT_ID = 'contractId';
export const SESSION_KEY_URL_ORIGIN = 'urlOrigin';
export const SESSION_KEY_IS_NOT_COMPANY_SPONSORED = 'isNotCompanySponsored';
export const SESSION_KEY_MOBILE_SIGNUP_ENDPOINT = 'endpoint';
export const SESSION_KEY_QUIZ_ANSWERS = 'quizAnswers';
export const SESSION_KEY_ELIGIBILITY = 'eligibility';
export const SESSION_KEY_CLASS_PREFERENCE = 'classPreference';

export const URL_ORIGIN_SIGN_UP = 'sign-up';
export const URL_ORIGIN_WALGREENS = 'walgreens';
export const URL_ORIGIN_WALGREENS_WEIGHTLOSS = 'walgreens-weightloss';

export const YEARS_FILTER = _.range(new Date().getFullYear(), 1969).map(item => ({
  title: item.toString(), value: item.toString()
}));

export const weekMap = {
  4: 'weekFour',
  8: 'weekEight',
  12: 'weekTwelve',
  16: 'weekSixteen',
  20: 'weekTwenty',
  24: 'weekTwentyFour',
  28: 'weekTwentyEight',
  32: 'weekThirtyTwo',
  36: 'weekThirtySix',
  40: 'weekForty',
  44: 'weekFortyFour',
  48: 'weekFortyEight',
  52: 'weekFiftyTwo',
};

export const SESSION_SLIDES: SessionSlide[] = [
  {
    classDay: 1,
    number: 1,
    name: 'Introduction to the DPP',
  },
  {
    classDay: 8,
    number: 2,
    name: 'Eat Well to Prevent T2',
  },
  {
    classDay: 15,
    number: 3,
    name: 'Track Your Food',
  },
  {
    classDay: 22,
    number: 4,
    name: 'Get Active to Prevent T2',
  },
  {
    classDay: 29,
    number: 5,
    name: 'Track Your Activity',
  },
  {
    classDay: 36,
    number: 6,
    name: 'Get More Active',
  },
  {
    classDay: 43,
    number: 7,
    name: 'Burn More Calories Than You Take In',
  },
  {
    classDay: 50,
    number: 8,
    name: 'Shop and Cook to Prevent T2',
  },
  {
    classDay: 57,
    number: 9,
    name: 'Manage Stress',
  },
  {
    classDay: 64,
    number: 10,
    name: 'Get Support',
  },
  {
    classDay: 71,
    number: 11,
    name: 'Friends & Family Session 1',
  },
  {
    classDay: 78,
    number: 12,
    name: 'Cope with Triggers',
  },
  {
    classDay: 92,
    number: 13,
    name: 'Keep Your Heart Healthy',
  },
  {
    classDay: 106,
    number: 14,
    name: 'Take Charge of Your Thoughts',
  },
  {
    classDay: 120,
    number: 15,
    name: 'Find Time for Fitness',
  },
  {
    classDay: 134,
    number: 16,
    name: 'Eat Well Away from Home',
  },
  {
    classDay: 148,
    number: 17,
    name: 'Stay Motivated to Prevent T2',
  },
  {
    classDay: 162,
    number: 18,
    name: 'When Weight Loss Stalls',
  },
  {
    classDay: 176,
    number: 19,
    name: 'Take a Fitness Break',
  },
  {
    classDay: 190,
    number: 20,
    name: 'Friends & Family Session 2',
  },
  {
    classDay: 204,
    number: 21,
    name: 'Get Enough Sleep',
  },
  {
    classDay: 232,
    number: 22,
    name: 'Stay Active Away from Home',
  },
  {
    classDay: 260,
    number: 23,
    name: 'More About T2',
  },
  {
    classDay: 288,
    number: 24,
    name: 'More About Carbs',
  },
  {
    classDay: 316,
    number: 25,
    name: 'Have Healthy Food You Enjoy',
  },
  {
    classDay: 344,
    number: 26,
    name: 'Get Back on Track & Prevent T2 for Life',
  },
];

export const TIME_FILTER: DropdownItem[] = [
  { title: `12:00AM :abbr`, value: '0' },
  { title: `12:30AM :abbr`, value: '0.5' },
  { title: `1:00AM :abbr`, value: '1' },
  { title: `1:30AM :abbr`, value: '1.5' },
  { title: `2:00AM :abbr`, value: '2' },
  { title: `2:30AM :abbr`, value: '2.5' },
  { title: `3:00AM :abbr`, value: '3' },
  { title: `3:30AM :abbr`, value: '3.5' },
  { title: `4:00AM :abbr`, value: '4' },
  { title: `4:30AM :abbr`, value: '4.5' },
  { title: `5:00AM :abbr`, value: '5' },
  { title: `5:30AM :abbr`, value: '5.5' },
  { title: `6:00AM :abbr`, value: '6' },
  { title: `6:30AM :abbr`, value: '6.5' },
  { title: `7:00AM :abbr`, value: '7' },
  { title: `7:30AM :abbr`, value: '7.5' },
  { title: `8:00AM :abbr`, value: '8' },
  { title: `8:30AM :abbr`, value: '8.5' },
  { title: `9:00AM :abbr`, value: '9' },
  { title: `9:30AM :abbr`, value: '9.5' },
  { title: `10:00AM :abbr`, value: '10' },
  { title: `10:30AM :abbr`, value: '10.5' },
  { title: `11:00AM :abbr`, value: '11' },
  { title: `11:30AM :abbr`, value: '11.5' },
  { title: `12:00PM :abbr`, value: '12' },
  { title: `12:30PM :abbr`, value: '12.5' },
  { title: `1:00PM :abbr`, value: '13' },
  { title: `1:30PM :abbr`, value: '13.5' },
  { title: `2:00PM :abbr`, value: '14' },
  { title: `2:30PM :abbr`, value: '14.5' },
  { title: `3:00PM :abbr`, value: '15' },
  { title: `3:30PM :abbr`, value: '15.5' },
  { title: `4:00PM :abbr`, value: '16' },
  { title: `4:30PM :abbr`, value: '16.5' },
  { title: `5:00PM :abbr`, value: '17' },
  { title: `5:30PM :abbr`, value: '17.5' },
  { title: `6:00PM :abbr`, value: '18' },
  { title: `6:30PM :abbr`, value: '18.5' },
  { title: `7:00PM :abbr`, value: '19' },
  { title: `7:30PM :abbr`, value: '19.5' },
  { title: `8:00PM :abbr`, value: '20' },
  { title: `8:30PM :abbr`, value: '20.5' },
  { title: `9:00PM :abbr`, value: '21' },
  { title: `9:30PM :abbr`, value: '21.5' },
  { title: `10:00PM :abbr`, value: '22' },
  { title: `10:30PM :abbr`, value: '22.5' },
  { title: `11:00PM :abbr`, value: '23' },
  { title: `11:30PM :abbr`, value: '23.5' },
]

export const TIME_GROUP_FILTER: DropdownItem[] = [
  { title: 'Show All', value: '' },
  { title: 'Morning', value: 'M' },
  { title: 'Afternoon', value: 'A' },
  { title: 'Evening', value: 'E' },
];

export const US_STATES_SELECTIONS: DropdownItem[] = [
  { value: 'AL', title: 'Alabama' },
  { value: 'AK', title: 'Alaska' },
  { value: 'AS', title: 'American Samoa' },
  { value: 'AZ', title: 'Arizona' },
  { value: 'AR', title: 'Arkansas' },
  { value: 'CA', title: 'California' },
  { value: 'CO', title: 'Colorado' },
  { value: 'CT', title: 'Connecticut' },
  { value: 'DE', title: 'Delaware' },
  { value: 'DC', title: 'District Of Columbia' },
  { value: 'FM', title: 'Federated States Of Micronesia' },
  { value: 'FL', title: 'Florida' },
  { value: 'GA', title: 'Georgia' },
  { value: 'GU', title: 'Guam' },
  { value: 'HI', title: 'Hawaii' },
  { value: 'ID', title: 'Idaho' },
  { value: 'IL', title: 'Illinois' },
  { value: 'IN', title: 'Indiana' },
  { value: 'IA', title: 'Iowa' },
  { value: 'KS', title: 'Kansas' },
  { value: 'KY', title: 'Kentucky' },
  { value: 'LA', title: 'Louisiana' },
  { value: 'ME', title: 'Maine' },
  { value: 'MH', title: 'Marshall Islands' },
  { value: 'MD', title: 'Maryland' },
  { value: 'MA', title: 'Massachusetts' },
  { value: 'MI', title: 'Michigan' },
  { value: 'MN', title: 'Minnesota' },
  { value: 'MS', title: 'Mississippi' },
  { value: 'MO', title: 'Missouri' },
  { value: 'MT', title: 'Montana' },
  { value: 'NE', title: 'Nebraska' },
  { value: 'NV', title: 'Nevada' },
  { value: 'NH', title: 'New Hampshire' },
  { value: 'NJ', title: 'New Jersey' },
  { value: 'NM', title: 'New Mexico' },
  { value: 'NY', title: 'New York' },
  { value: 'NC', title: 'North Carolina' },
  { value: 'ND', title: 'North Dakota' },
  { value: 'MP', title: 'Northern Mariana Islands' },
  { value: 'OH', title: 'Ohio' },
  { value: 'OK', title: 'Oklahoma' },
  { value: 'OR', title: 'Oregon' },
  { value: 'PW', title: 'Palau' },
  { value: 'PA', title: 'Pennsylvania' },
  { value: 'PR', title: 'Puerto Rico' },
  { value: 'RI', title: 'Rhode Island' },
  { value: 'SC', title: 'South Carolina' },
  { value: 'SD', title: 'South Dakota' },
  { value: 'TN', title: 'Tennessee' },
  { value: 'TX', title: 'Texas' },
  { value: 'UT', title: 'Utah' },
  { value: 'VT', title: 'Vermont' },
  { value: 'VI', title: 'Virgin Islands' },
  { value: 'VA', title: 'Virginia' },
  { value: 'WA', title: 'Washington' },
  { value: 'WV', title: 'West Virginia' },
  { value: 'WI', title: 'Wisconsin' },
  { value: 'WY', title: 'Wyoming' },
];

export const CREATE_MESSAGE_SUCCESS = 'Class successfully created';
export const CREATE_MESSAGE_FAILURE = 'Encountered an issue while processing your request';
export const UPDATE_MESSAGE_SUCCESS = 'Class successfully updated';

export const DELETE_MESSAGE_SUCCESS = 'Class successfully deleted';

export const NO_CLASSES_MESSAGE = "We do not currently have any classes available that meet this criteria. Please select a new criteria to see other available classes.";

export const VALIDATION_CONFIRM_EMAIL = 'Email addresses do not match';
export const VALIDATION_FORMAT = 'Format is invalid';
export const VALIDATION_MAX_PARTICIPANTS = 'No. of selected participants should not exceed Max # of Participants';
export const VALIDATION_MIN_PARTICIPANTS = 'Should not exceed Max # of Participants';
export const VALIDATION_NO_DIETITIAN = 'Unassigned';
export const VALIDATION_PARTICIPANTS = 'Should be at least one (1)';
export const VALIDATION_REQUIRED = 'Field is required';

export const VERSION = '2.20.12';
