import { CLIENT_TYPE } from './constants';

export type DateRange = {
  0?: Date,
  1?: Date,
}

export enum MilestoneStatus {
  NotAchieved = 'Not Achieved',
  Achieved = 'Achieved',
  ClaimSubmitted = 'Claim Submitted',
  Paid = 'Paid in Full',
  Rejected = 'Rejected',
  Pending = 'Acknowledged',
}

export enum PaymentStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
}

export enum UserType {
  SUPERUSER = 'superuser',
  DIETITIAN = 'dietitian',
  PARTICIPANT = 'participant',
}

export type ClassItem = {
  id: string;
  name: string;
  date: Date;
  endDate: Date;
  time: DropdownItem;
  participants: Participant[];
  minParticipants: number;
  maxParticipants: number;
  dietitian?: Dietitian;
  dietitianId?: number;
  status: ClassStatusItem;
  isScheduled: boolean;
  sessions?: Session[];

  dietitians?: Dietitian[];
  dietitianIds?: number[];
  maxDietitians?: number;
}

export enum ClassSort {
  DateTime = 'Date & Time',
  Day = 'Day',
  Size = 'Participants',
  MaxSize = 'Max # of Participants',
  Dietitian = 'Dietitian',
}

export type ClassStatusItem = {
  color: string;
  title: string;
  value: string;
}

export type ClassStatus = {
  [id: string]: ClassStatusItem
}

export type CreateClassPayload = { [id: string]: any }
export type UpdateClassPayload = { [id: string]: any }

export enum ClassDayOption {
  WEEKDAY = 'weekday',
  WEEKEND = 'weekend',
  ANY = '',
}

export enum ClassTimeOption {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
  ANY = '',
}

export enum ClassStatus2 {
  INPROGRESS = 'in_progress',
  COMPLETED = 'completed',
  AVAILABLE = 'available',
}

export type EligibilityPayload = {
  participant_id: string;
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email_address: string;
  confirm_email_address: string;
  birthdate?: string;
  street_address: string;
  apartment_unit_no?: string;
  city: string;
  state: string;
  zip_code: string;
  member_id?: string;
}

export type LoginPayload = { username: string, password: string }

export type Dietitian = {
  id: number;
  avatar?: string;
  fullName: string;
  email: string;
  username: string;
  vseeid: string;
  bio?: string[];
}

export type DropdownItem = {
  id?: string;
  title: string;
  value: string;
}

export type Participant = {
  id: number;
  avatar?: string;
  fullName: string;
  email: string;
  username: string;
  vseeid: string;
}

export type SearchPayload = {
  query: string;
  page: number;
}

export type Session = {
  id: number;
  // slide: SessionSlide;
  name: string;
  date: Date;
  availableRange?: DateRange;
  // day: string;
  // time: string;
  attendees?: Participant[];
  absent?: Participant[];
}

export type SessionSlide = {
  classDay: number;
  number: number;
  name: string;
}

export type SessionTimestamp = {
  start: number;
  end: number;
  name: string;
  id?: number;
};

export type User = {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  vseeid: string;
  email: string;
  active: boolean;
  title: string;
  suffix: string;
  tos: boolean;
  status: number;
  phone: string;
  subtype: string;
  streetAddr: string;
  state: string;
  city: string;
  zip: string;
  country: string;
  timezone: string;
  emailVerified: boolean;
  roles?: any;
  type: UserType;
  account?: string;
  isClinicAdmin: boolean;
}

export type WeightLossData = {
  name: string;
  signupDate: Date;
  employer: string;
  monthOne: number;
  monthTwo: number;
  monthThree: number;
  monthFour: number;
  monthFive: number;
  monthSix: number;
  monthSeven: number;
  monthEight: number;
  monthNine: number;
  monthTen: number;
  monthEleven: number;
  monthTwelve: number;
  birthdate: Date;
  gender: string;
  address: string;
  apartmentNo: string;
  city: string;
  state: string;
  zipCode: string;
  dietitian: string;
  classId: string;
  email: string;
}

export interface WeightLossAnalyticsSummary {
  company: string;
  numParticipants: number;
  weeks: {
    4: number;
    8: number;
    12: number;
    16: number;
    20: number;
    24: number;
    28: number;
    32: number;
    36: number;
    40: number;
    44: number;
    48: number;
    52: number;
  },
}

export type WeightLossAnalyticsData = {
  name: string;
  dietitian: string;
  signupDate: Date;
  startingWeight: number;
  currentWeight: number;
  weekFour: number;
  weekEight: number;
  weekTwelve: number;
  weekSixteen: number;
  weekTwenty: number;
  weekTwentyFour: number;
  weekTwentyEight: number;
  weekThirtyTwo: number;
  weekThirtySix: number;
  weekForty: number;
  weekFortyFour: number;
  weekFortyEight: number;
  weekFiftyTwo: number;
}

export type UnitEconomicData = {
  id: string;
  classId: string;
  startDate: Date;
  dietitian: string;
  pokitdok: number;
  dietitianPayment: number;
  scale: number;
  fitbit: number;
  netRevenue: number;
}

export type UnitEconomicUser = {
  name: string;
  milestoneOne: number;
  milestoneTwo: number;
  milestoneThree: number;
  milestoneFour: number;
  scale: number;
  fitbit: number;
}

export type Client = {
  name: CLIENT_TYPE;
  value: string;
  primaryColor: string;
}

export type StripePlan = {
  id: string;
  amount: number;
  interval: 'month' | 'year';
  isActive: boolean;
}

export type StripeVoucher = {
  id: string;
  discountValue: number;
  name: string;
  isPercent: boolean;
  isValid: boolean;
}

export enum ExportType {
  PreDiabetes = 'Pre-diabetes Quiz Results',
  Enrollment = 'Enrollment Results',
  Milestone = 'Milestone Summary',
  Claims = 'Claims Report',
  Eligibility = 'Eligibility Summary',
  WeightLoss = 'Weight Loss Outcomes',
  MonthlyInvoice = 'Monthly Invoice',
}
