import React, { useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import MonthlyInvoicesAPIClient from '../../../../../api/monthly-invoices';

import PaymentDropdown from '../../../../../components/PaymentDropdown';

import { DietitianPaymentData } from '../../../../../types/dietitian-payments';

import { BASE_URL_INGENUITY } from '../../../../../utils/constants';
import { formatCurrency, formatNumber } from '../../../../../utils/formatting';
import { PaymentStatus } from '../../../../../utils/types';

import './index.scss';

interface Props {
  index: number,
  item: DietitianPaymentData,
  viewOnly?: boolean;
  type: string;
  onPaymentStatusChange: (item: DietitianPaymentData) => void;
  onSelect: (item: DietitianPaymentData) => void;
}

export default function MonthlyInvoiceRow(props: Props) {
  const STATUS_FILTER = [
    { title: 'Paid', value: PaymentStatus.Paid },
    { title: 'Unpaid', value: PaymentStatus.Unpaid },
  ];

  const api = new MonthlyInvoicesAPIClient(BASE_URL_INGENUITY);
  const tdStyle = { borderTop: 0 };
  const { type } = props;

  // States
  const [currentItem, setCurrentItem] = useState(props.item);
  const [isProcessing, setIsProcessing] = useState(false);

  const rowByType = () => {
    if (currentItem.milestones) {
      return (
        <>
          <td style={tdStyle}>{currentItem.participants}</td>
          <td style={tdStyle}>{currentItem.period}</td>
          <td style={tdStyle}>{formatCurrency(currentItem.milestones.one)}</td>
          <td style={tdStyle}>{formatCurrency(currentItem.milestones.two)}</td>
          <td style={tdStyle}>{formatCurrency(currentItem.milestones.three)}</td>
          <td style={tdStyle}>{formatCurrency(currentItem.milestones.four)}</td>
        </>
      )
    }

    if (currentItem.sessions) {
      return (
        <>
          <td style={tdStyle}>{currentItem.period}</td>
          <td style={tdStyle}>{formatNumber(currentItem.sessions.count)}</td>
        </>
      )
    }
  }

  const onSubmit = (status: string) => {
    setIsProcessing(true);

    api.updateMonthlyInvoiceStatus(props.item.id, status, type)
      .then(() => {
        currentItem.status = status;

        setCurrentItem(currentItem);
        setIsProcessing(false);
        toast.success('Successfully updated payment status.');
      })
      .catch(() => {
        setIsProcessing(false);
        toast.error('Failed to update payment status.');
      })
  }

  return (
    <tr
      id={`DietitianPayment-row${props.index}`}
      className="clickable"
      onClick={() => {
        props.onSelect(props.item);
        // if (!props.viewOnly) {
        //   props.onSelect(props.item);
        // }
      }}
    >
      {rowByType()}
      <td style={tdStyle}>{formatCurrency(currentItem.total)}</td>
      <td style={tdStyle}>
        <PaymentDropdown
          items={STATUS_FILTER}
          processing={isProcessing}
          selectedItem={STATUS_FILTER.filter(c => c.value == currentItem.status)[0]}
          viewOnly={props.viewOnly}
          onItemSelection={item => onSubmit(item.value)}
        />
      </td>
      <td className="actions">
        {/* {!props.viewOnly && <FontAwesomeIcon icon={faAngleRight} />} */}
        <div>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </td>
    </tr>
  )
}
