import { toast } from 'react-toastify';
import { isEmpty, isUndefined } from 'lodash-es';
import moment from 'moment';
import axios from 'axios';

import {
  MilestoneData,
  MilestoneStatus,
  MilestoneAnalyticsData,
  MilestoneAnalyticsPayload,
} from '../types/milestones';

import {
  classItemStatus,
  dataToModel,
  timeAsDropdownItem,
  practiceConstants,
} from '../utils';
import {
  BASE_URL_INGENUITY,
  CLASS_STATUS,
  CLASS_STATUS_LIST,
  UPDATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  MIN_PARTICIPANTS,
  MAX_PARTICIPANTS,
  DELETE_MESSAGE_SUCCESS,
  FORMAT_YYYY_MM_DD,
  MILESTONE_KEYS as Keys,
} from '../utils/constants';
import {
  ClassItem,
  CreateClassPayload,
  Participant,
  Dietitian,
  SearchPayload,
  Session,
  UpdateClassPayload,
  WeightLossAnalyticsData,
  UnitEconomicData,
  UnitEconomicUser,
} from '../utils/types';

import getEnvCreds from '../utils/apiconstants';

import APIClient from './index';

const autoClose = 2000;

export default class SuperuserAPIClient {
  private instance: APIClient;

  constructor(url?: string) {
    let baseUrl = url;

    if (!baseUrl) {
      baseUrl = practiceConstants().baseURL;
    }

    this.instance = new APIClient(baseUrl);
  }

  unsubscribe = () => this.instance.unsubscribe();

  createClass = (params: CreateClassPayload): Promise<string> => (
    new Promise(async (resolve, reject) => {
      console.log('posting...', params);

      // API for some reason won't accept host IDs in the body.
      // So we create an additional request to set the host and participants
      // @todo Is there a better way of generating the payload without deleting keys?
      const hostIds = params.host_ids;
      const participantIds = params.participant_ids;

      delete params.host_ids;
      delete params.participant_ids

      params.max_host = 1;

      const client = new APIClient(BASE_URL_INGENUITY);

      try {
        const newClass = await client.post('/classes', params);
        await this.instance.post(`/classes/${newClass.data.data.id}`, {
          host_ids: { add: hostIds },
          participant_ids: { add: participantIds },
        });

        resolve(CREATE_MESSAGE_SUCCESS);
      } catch (e) {
        reject(e.response.data.message || e.response.message);
      }
    })
  )

  deleteClass = (id: string): Promise<string> => (
    new Promise(async (resolve, reject) => {
      try {
        await this.instance.delete(`/classes/${id}`);

        resolve(DELETE_MESSAGE_SUCCESS);
      } catch (e) {
        reject(e.response.data.message || e.response.message);
      }
    })
  )

  updateClass = (params: UpdateClassPayload): Promise<string> => (
    new Promise(async (resolve, reject) => {
      const id = params.id;

      delete params.id;

      console.log('updating...', params);

      this.instance.post(`/classes/${id}`, params)
        .then(() => resolve(UPDATE_MESSAGE_SUCCESS))
        .catch(e => {
          let message = CREATE_MESSAGE_FAILURE;

          if (e.response.data) {
            message = e.response.data.message;
          }

          reject(message);
        });
    })
  )

  fetchClassDetails = (id: string): Promise<ClassItem> => (
    new Promise(async (resolve, reject) => {
      try {
        const classDetails = await this.instance.get(`/classes/${id}`);
        const sessions = await this.instance.get(`/classes/${id}/sessions?field_list=full`);
        const data = classDetails.data['data'];
        const date = new Date(data['start'] * 1000);
        const classItem: ClassItem = {
          id: data['id'],
          name: data['name'],
          date,
          endDate: new Date(data['end'] * 1000),
          isScheduled: false,
          participants: [],
          minParticipants: data['min_participants'] || MIN_PARTICIPANTS,
          maxParticipants: data['max_participants'] || MAX_PARTICIPANTS,
          time: timeAsDropdownItem(date),
          status: CLASS_STATUS_LIST[CLASS_STATUS.Open],
          sessions: data.sessions.map((session: any, index: number): Session => {
            const date = new Date(session.start * 1000);

            return {
              id: parseInt(session.id),
              date,
              // day: format(date, 'dddd'),
              // time: format(date, 'h:mm a'),
              name: !isUndefined(session.name) ? session.name : '',
              // slide: {
              //   classDay: session.start,
              //   number: index + 1,
              //   name: !isUndefined(session.name) ? session.name : '',
              // },
            };
          }),
        };

        if (sessions.data.data) {
          classItem.sessions = sessions.data.data
            .map(item => ({
              id: parseInt(item.id),
              name: item.name,
              date: new Date(item.start * 1000)
            }))
            .sort((item1: Session, item2: Session) => (
              moment(item1.date).isBefore(item2.date) ? -1 : 1
            ));
        }

        if (data.hosts && data.hosts.length > 0) {
          classItem.dietitian = dataToModel<Dietitian>(data.hosts[0]);
        }

        if (data.participants && data.participants.length > 0) {
          classItem.participants = data.participants.map(item => ({
            id: parseInt(item.id),
            fullName: item.full_name,
            email: item.email,
            username: item.username,
            vseeid: item.vseeid,
          }));
        }

        classItem.status = classItemStatus(classItem);

        resolve(classItem);
      } catch(error){
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchClasses = (): Promise<ClassItem[]> => (
    new Promise((resolve, reject) => {
      const ENV_CREDS = getEnvCreds();
      const account_code = ENV_CREDS.ACCOUNT_CODE;
      this.instance.get(`/classes?field_list=start,end,min_participants,max_participants,name,participants,hosts&limit=500&account_code=${account_code}`)
      .then((response) => {
        const data = response.data['data'];
        let classes: ClassItem[] =  data.map((item) => {
          const date = new Date(item['start'] * 1000);
          const endDate = new Date(item['end'] * 1000);
          const classItem: ClassItem = {
            id: item['id'],
            date,
            endDate,
            name: item['name'].toString() || '',
            participants: item['participants'].map((u: any) => ({
              id: parseInt(u.id),
              name: u.full_name,
              fullName: u.full_name,
              email: u.email,
              username: u.username,
              vseeid: parseInt(u.id),
            })),
            isScheduled: false,
            minParticipants: item['min_participants'] || MIN_PARTICIPANTS,
            maxParticipants: item['max_participants'] || MAX_PARTICIPANTS,
            time: timeAsDropdownItem(date),
            status: CLASS_STATUS_LIST[CLASS_STATUS.Open],
          };

          if (item.hosts && item.hosts.length > 0) {
            // @todo Eventually move to using .dietitians to support multiple hosts.
            classItem.dietitian = dataToModel<Dietitian>(item.hosts[0]);
            classItem.dietitians = item.hosts.map((h: any) => dataToModel<Dietitian>(h));
          }

          classItem.status = classItemStatus(classItem);

          return classItem;
        });

        resolve(classes);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      });
    })
  )

  fetchMilestoneTracking = (employer: string, queryString: string): Promise<[
    MilestoneData[],
    number,
    number,
    number,
    { [id: string] : number }
  ]> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = `/milestone-tracking${queryString}`;
        const amountsUrl = `/company?search=${encodeURIComponent(employer)}`;
        const response = await this.instance.get(url);
        const amountsResponse = await this.instance.get(amountsUrl);

        let total = 0;
        let to = 0;
        let from = 0;
        let result: MilestoneData[] = [];
        let updatedResult: MilestoneData[] = [];
        let amounts: any = {};

        if (response.data.data) {
          total = response.data.meta.total;
          to = response.data.meta.to;
          from = response.data.meta.from;

          response.data.data
            // Disable for now as some records don't have an external_id
            // .filter(item => item.participant.external_id)
            .forEach(async (item) => {
              const milestoneData: MilestoneData = {
                id: item.participant.id,
                classId: '',
                participantId: item.participant.id,
                participantExternalId: item.participant.external_id,
                firstName: item.participant.first_name,
                lastName: item.participant.last_name,
                email: item.participant.email,
                signupDate: new Date(item.participant.signup_date),
                healthPlanId: item.participant.health_plan_id || '',
                insurance: item.participant.insurance || '',
                milestoneOne: item.milestone_one,
                milestoneOneDate: item.achieved_date_one && new Date(item.achieved_date_one),
                paymentOne: parseFloat(item.payment_one),
                deviceScale: item.device_scale,
                milestoneTwo: item.milestone_two,
                milestoneTwoDate: item.achieved_date_two && new Date(item.achieved_date_two),
                paymentTwo: parseFloat(item.payment_two),
                deviceFitbit: item.device_fitbit,
                milestoneThree: item.milestone_three,
                milestoneThreeDate: item.achieved_date_three && new Date(item.achieved_date_three),
                paymentThree: parseFloat(item.payment_three),
                milestoneFour: item.milestone_four,
                milestoneFourDate: item.achieved_date_four && new Date(item.achieved_date_four),
                paymentFour: parseFloat(item.payment_four),
                birthdate: new Date(),
                gender: '',
                address: '',
                apartmentNo: '',
                city: '',
                state: '',
                zipCode: '',
                dietitian: ''
              };

              result.push(milestoneData);
            });
        }

        if (!isUndefined(amountsResponse.data.data) &&
            !isEmpty(amountsResponse.data.data)) {
          amounts = amountsResponse.data.data[0];
        }

        updatedResult = result;

        const fetchUserInfo = async (list): Promise<[
          MilestoneData[],
          number,
          number,
          number,
          { [id: string]: number }
        ]> => {
          // NOTE: To fetch all data at once
          // for (const item of result) {
          //   const r = await this.fetchMilestoneUserInfo(item);

          //   updatedResult.push(r);
          // }

          return [updatedResult, from, to, total, {
            [Keys.One]: parseFloat(amounts.milestone_one_amount),
            [Keys.Two]: parseFloat(amounts.milestone_two_amount),
            [Keys.Three]: parseFloat(amounts.milestone_three_amount),
            [Keys.Four]: parseFloat(amounts.milestone_four_amount),
          }];
        }

        resolve(fetchUserInfo(result));
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchMilestoneUserInfo = (data: MilestoneData): Promise<MilestoneData> => (
    new Promise(async (resolve, reject) => {
      try {
        let dietitian = '';

        // If there is no external ID, search using the email instead
        let externalId = data.participantExternalId;

        if (!externalId) {
          const resp = await this.instance.get(`/users?type=member&q=${encodeURIComponent(data.email)}`);

          if (resp.data.data.length > 0) {
            externalId = resp.data.data[0].id;
          } else {
            throw new Error(`Cannot find equivalent external ID for the given email: ${data.email}.`);
          }
        }

        const { data: { data: result } }: any = await this.instance.get(`/users/${externalId}`);

        if (result.providers && result.providers.length > 0) {
          const { data: { data: r } }: any = await this.instance.get(`/users/${result.providers[result.providers.length - 1]}`);

          dietitian = r.full_name;
        }

        const userInfo: MilestoneData = {
          ...data,
          classId: '',
          firstName: result.first_name,
          lastName: result.last_name,
          email: result.email,
          birthdate: new Date(result.dob),
          gender: result.gender,
          address: result.street_addr,
          apartmentNo: '',
          city: result.city,
          state: result.state,
          zipCode: result.zip,
          dietitian,
        };

        resolve(userInfo);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  createMilestoneRecord = (
    id: number,
    email: string
  ): Promise<MilestoneData> => (
    new Promise(async (resolve, reject) => {
      try {
        const url = '/milestone-tracking/';
        const response = await this.instance.post(url, { external_id: id, email });
        const item = response.data.data
        const firstName = item.participant.first_name;
        const lastName = item.participant.last_name;
        const milestoneData: MilestoneData = {
          id: item.id,
          classId: '',
          participantId: item.participant.id,
          participantExternalId: item.participant.external_id,
          firstName,
          lastName,
          email: '',
          signupDate: new Date(item.achieved_date_one),
          healthPlanId: item.participant.health_plan_id || '',
          insurance: item.participant.insurance || '',
          milestoneOne: item.milestone_one,
          deviceScale: item.device_scale,
          milestoneTwo: item.milestone_two,
          deviceFitbit: item.device_fitbit,
          milestoneThree: item.milestone_three,
          milestoneFour: item.milestone_four,
          birthdate: new Date(),
          gender: '',
          address: '',
          apartmentNo: '',
          city: '',
          state: '',
          zipCode: '',
          dietitian: ''
        };

        resolve(milestoneData);
      } catch (error) {
        reject(error);
      }
    })
  )

  /**
   * Updates a single milestone status.
   *
   * @returns Promise resolved to the new milestone status.
   */
  updateMilestone = async (
    participantId: string,
    milestone: number,
    status: MilestoneStatus
  ): Promise<MilestoneStatus> => {
    const url = `/milestone-tracking/${participantId}`;
    const statusMap = {
      1: 'milestone_one',
      2: 'milestone_two',
      3: 'milestone_three',
      4: 'milestone_four',
      10: 'device_scale',
      20: 'device_fitbit',
    };
    const statusKey = statusMap[milestone];
    const data = {};

    data[statusKey] = status;

    return this.instance
      .patch(url, data)
      .then((resp) => resp.data[statusKey]);
  }

  fetchMilestoneAnalytics = (
    company: string,
    from?: Date,
    to?: Date
  ): Promise<MilestoneAnalyticsData> => (
    new Promise(async (resolve, reject) => {
      try {
        let url = `/milestone-tracking/stats?company=${company}`;

        if (from) {
          url = `${url}&from_date=${moment(from).format(FORMAT_YYYY_MM_DD)}`;
        }

        if (to) {
          url = `${url}&to_date=${moment(to).format(FORMAT_YYYY_MM_DD)}`;
        }

        const response = await this.instance.get(url);
        const data = response.data;
        const milestoneAnalyticsData = {
          total: data.milestone.total,
          milestoneOne: data.milestone.milestone_one,
          milestoneTwo: data.milestone.milestone_two,
          milestoneThree: data.milestone.milestone_three,
          milestoneFour: data.milestone.milestone_four,
        };

        resolve(milestoneAnalyticsData);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  /**
   * Requests a milestone tracking record to be deleted.
   *
   * @returns Promise resolved to TRUE when delete succeeds.
   */
  deleteMilestone = async (participantId: string): Promise<boolean> => {
    const url = `/milestone-tracking/${participantId}`;

    return this.instance
      .delete(url)
      .then(() => true);
  }

  fetchAttendanceAnalytics = (
    company: string,
    from?: Date,
    to?: Date
  ): Promise<[WeightLossAnalyticsData[], number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const data: WeightLossAnalyticsData = {
          name: 'Some User',
          dietitian: 'Dietitian',
          signupDate: new Date(),
          startingWeight: 180,
          currentWeight: 150,
          weekFour: 1.10,
          weekEight: 2.10,
          weekTwelve: 3.40,
          weekSixteen: 5.50,
          weekTwenty: 5.70,
          weekTwentyFour: 6.00,
          weekTwentyEight: 7.10,
          weekThirtyTwo: 7.60,
          weekThirtySix: 8.15,
          weekForty: 8.63,
          weekFortyFour: 8.91,
          weekFortyEight: 8.99,
          weekFiftyTwo: 8.99
        };

        resolve([[data, data], 2]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchUnitEconomics = async (
    page: number,
    company: string
  ): Promise<[UnitEconomicData[], number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const item: UnitEconomicData = {
          id: 'xddas',
          classId: '0058',
          startDate: new Date(),
          dietitian: 'John Smith',
          pokitdok: 4500,
          dietitianPayment: 4500,
          scale: 4500,
          fitbit: 4500,
          netRevenue: 4500,
        };
        const items = [item, item];

        resolve([items, items.length]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchUnitEconomicsDetailsInfo = (id: string): Promise<UnitEconomicData> => (
    new Promise(async (resolve, reject) => {
      try {
        const item: UnitEconomicData = {
          id: 'xddas',
          classId: '0058',
          startDate: new Date(),
          dietitian: 'John Smith',
          pokitdok: 4500,
          dietitianPayment: 4500,
          scale: 4500,
          fitbit: 4500,
          netRevenue: 4500,
        };

        resolve(item);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  fetchUnitEconomicsDetailsList = (page: number): Promise<[UnitEconomicUser[], number]> => (
    new Promise(async (resolve, reject) => {
      try {
        const item: UnitEconomicUser = {
          name: 'Some User',
          milestoneOne: 4500,
          milestoneTwo: 4500,
          milestoneThree: 4500,
          milestoneFour: 4500,
          scale: 4500,
          fitbit: 4500,
        };
        const items = [item, item];

        resolve([items, items.length]);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  searchParticipants = (payload: SearchPayload): Promise<Participant[]> => (
    new Promise(async (resolve, reject) => {
      try {
        const keyword = encodeURIComponent(payload.query);
        const url = `/users?type=member&q=${keyword}&page=${payload.page}&limit=25`;
        const result = await this.instance.get(url);
        const results = result.data.data.map((i: any): Participant => ({
          id: parseInt(i.id),
          fullName: i.full_name,
          email: i.email,
          username: i.username,
          vseeid: i.vseeid,
        }));

        resolve(results);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  searchDietitians = async (payload: SearchPayload): Promise<Dietitian[]> => (
    new Promise(async (resolve, reject) => {
      try {
        const keyword = encodeURIComponent(payload.query);
        const url = `/users?type=provider&q=${keyword}&page=${payload.page}&limit=25`;
        const result = await this.instance.get(url);
        const results = result.data.data.map((i: any): Dietitian => ({
          id: parseInt(i.id),
          fullName: i.full_name,
          email: i.email,
          username: i.username,
          vseeid: i.vseeid,
        }));

        resolve(results);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )

  updateUserSignupTracking = (id: string, params: any) => (
    this.instance.patch(`/signup-tracking/${id}`, params)
  )

  queryDataExplorer = (query: string) => (
    new Promise(async (resolve, reject) => {
      try {
        const payload = JSON.parse(query);
        const response = await this.instance.post('/ic6UyWcZUgmeUz0', payload);

        resolve(response);
      } catch (error) {
        if (!axios.isCancel(error)) {
          const message = error.response.data.message || error.response.message;

          toast.error(message, { autoClose });
          reject();
        }
      }
    })
  )
}
