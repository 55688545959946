import { connect } from 'react-redux';

import DietitianClasses from './component';

const mapStateToProps = (state: any) => ({
  userId: state.authentication.user.id,
  ...state.dietitian,
});

export default connect(mapStateToProps)(DietitianClasses);
