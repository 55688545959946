import React from 'react';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { ClassDayOption } from '../../utils/types';

interface Props {
  onDaySelect: (day: ClassDayOption) => void;
}

export default function DaySelector(props: Props) {
  const { onDaySelect } = props;

  return (
    <div className="wizard centered">
      <h2 className="wizard-title">
        Pick a day to video chat with your dietitian
      </h2>
      <div>
        <p>Do you prefer to meet on a weekly basis on a</p>
        <div className="d-block d-md-none d-lg-none d-xl-none">
          <DropdownButton title="Select day" size="lg" id="daySelect">
            <Dropdown.Item onClick={() => onDaySelect(ClassDayOption.WEEKDAY)}>Weekday</Dropdown.Item>
            <Dropdown.Item onClick={() => onDaySelect(ClassDayOption.WEEKEND)}>Weekend</Dropdown.Item>
            <Dropdown.Item onClick={() => onDaySelect(ClassDayOption.ANY)}>Any Day</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="d-none d-md-block d-lg-block d-xl-block">
          <Button
            variant="primary"
            size="lg"
            onClick={() => onDaySelect(ClassDayOption.WEEKDAY)}
          >
            weekday
          </Button>,
          <Button
            variant="primary"
            size="lg"
            onClick={() => onDaySelect(ClassDayOption.WEEKEND)}
          >
            weekend
          </Button>, or
          <Button
            variant="primary"
            size="lg"
            onClick={() => onDaySelect(ClassDayOption.ANY)}
          >
            any day
          </Button>
          ?
        </div>
      </div>
    </div>
  );
}
