import {
  useState,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';

import { ClassSort } from '../utils/types';

export default function usePagination(
  dataset: any[],
  perPage: number,
  sortKey?: ClassSort,
  isDescending?: boolean,
): [number, number, any[], Dispatch<SetStateAction<number>>] {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const numPages = useMemo(() => Math.ceil(dataset.length / perPage), [dataset]);
  const items = useMemo<any[]>(() => {
    const start = currentPage * perPage;
    const end = start + perPage;

    return dataset.slice(start, end);
  }, [dataset, currentPage, sortKey, isDescending]);

  return [currentPage, numPages, items, setCurrentPage];
}

export function useAPIPagination(
  dataset: any[],
  perPage: number,
  totalCount: number,
  currPage?: number,
): [number, number, any[], Dispatch<SetStateAction<number>>] {
  const [currentPage, setCurrentPage] = useState<number>(currPage || 0);
  const numPages = useMemo(() => Math.ceil(totalCount / perPage), [dataset, totalCount]);

  return [currentPage, numPages, dataset, setCurrentPage];
}
