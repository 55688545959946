import React, { useState } from 'react';

import moment from 'moment-timezone';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import isUndefined from 'lodash-es/isUndefined';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import API from '../../api/superuser';

import BaseModal from '../../components/BaseModal';

import {
  isAuthenticated,
  isEligibleForSignup,
  practiceConstants,
} from '../../utils';
import {
  BASE_URL_INGENUITY,
  FORMAT_DDDD,
  FORMAT_MMMM_Do_YYYY,
  SESSION_KEY_CLASS_PREFERENCE,
  SESSION_KEY_CONTRACT_ID,
  SESSION_KEY_IS_NOT_COMPANY_SPONSORED,
  SESSION_KEY_ELIGIBILITY,
  SESSION_KEY_QUIZ_ANSWERS,
  SESSION_KEY_STAGE_TRACKING,
  SESSION_KEY_MOBILE_SIGNUP_ENDPOINT,
  SESSION_KEY_URL_ORIGIN,
  SIGNUP_URL,
} from '../../utils/constants';
import { ClassItem } from '../../utils/types';

import './index.scss';

interface Props {
  isSelected: boolean;
  item: ClassItem;
  disabled?: boolean;
  onSignup: (id: string) => void;
  onError: (error: string) => void;
}

export default function ClassCard(props: Props) {
  const { item } = props;
  const classDate = moment(item.date);
  const now = moment();

  // TODO: temporarily while we have no API access
  const [enrolled, setEnrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const availableSlots = item.maxParticipants - item.participants.length;
  let slotsClass = 'green';

  if (availableSlots < 3) {
    slotsClass = 'red';
  } else if (availableSlots < 6) {
    slotsClass = 'yellow';
  }

  const getSignupData = (id: string) => {
    let [isValid, quizAnswers, eligibility] = isEligibleForSignup(3);
    const isNotSponsored = parseInt(sessionStorage.getItem(SESSION_KEY_IS_NOT_COMPANY_SPONSORED) || '0') > 0;

    if (!isValid) {
      return null;
    }

    const gender = quizAnswers.gender == 'Male' ? 'male' : 'female';
    const isFemale = gender === 'female';
    const { 'height-feet': feet, 'height-inch': inch, weight } = quizAnswers;
    const heightWeight = `${feet}"${inch}' ${weight}lbs`;
    const response = {
      question_1: quizAnswers.age,
      question_2: quizAnswers.gender,
      question_2a: isFemale ? quizAnswers['gender-f'] : 'n/a',
      question_2b: isFemale ? quizAnswers.pregnant : 'n/a',
      question_3: quizAnswers.race,
      question_4: heightWeight,
      question_5: quizAnswers.family,
      question_6: quizAnswers.health,
      question_7: quizAnswers.exercises,
      question_8: quizAnswers.professional,
    };

    return {
      email: quizAnswers.email,
      first_name: quizAnswers.name,
      last_name: quizAnswers.lastName,
      dob: eligibility.birthdate,
      gender,
      street_addr: eligibility.street_address,
      city: eligibility.city,
      state: eligibility.state,
      zip: eligibility.zip_code,
      phone: quizAnswers.phoneNumber || eligibility.mobile_phone,
      extra: {
        contract_id: sessionStorage.getItem(SESSION_KEY_CONTRACT_ID),
        quiz: { response },
        insurance: {
          subscriber_id: isNotSponsored ? '' : eligibility.member_id,
          payer_id: isNotSponsored ? '' : process.env.REACT_APP_POKITDOK_TRADING_PARTNER,
          payer_name: isNotSponsored ? '' : process.env.REACT_APP_POKITDOK_TRADING_PARTNER_NAME
        },
        class_preference: {
          choices: [id],
        },
      }
    };
  }

  const signupForClass = async (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.preventDefault();

    const payload = getSignupData(id);

    if (!payload) {
      return;
    }

    // Make sure we don't join full class.
    if (item.participants.length >= item.maxParticipants) {
      return;
    }

    props.onSignup(item.id);

    const client = new API(BASE_URL_INGENUITY);
    const trackingId = sessionStorage.getItem(SESSION_KEY_STAGE_TRACKING) || '';

    await client.updateUserSignupTracking(trackingId, {
      finished_scheduler: true,
    });

    // NOTE: Add participant id on payload
    const base64 = btoa(JSON.stringify({ ...payload, 'tracking_id': trackingId }));
    const endpoint = sessionStorage.getItem(SESSION_KEY_MOBILE_SIGNUP_ENDPOINT);
    let url = `?value=${base64}`;

    if (endpoint) {
      url = `${url}&endpoint=${endpoint}`;
    }

    // Clear items
    sessionStorage.removeItem(SESSION_KEY_STAGE_TRACKING);
    sessionStorage.removeItem(SESSION_KEY_CONTRACT_ID);
    sessionStorage.removeItem(SESSION_KEY_URL_ORIGIN);
    sessionStorage.removeItem(SESSION_KEY_IS_NOT_COMPANY_SPONSORED);
    sessionStorage.removeItem(SESSION_KEY_MOBILE_SIGNUP_ENDPOINT);
    sessionStorage.removeItem(SESSION_KEY_QUIZ_ANSWERS);
    sessionStorage.removeItem(SESSION_KEY_ELIGIBILITY);
    sessionStorage.removeItem(SESSION_KEY_CLASS_PREFERENCE);

    window.location.href = `${SIGNUP_URL}${url}`;
  }

  return (
    <div className={practiceConstants().className}>
      <div className="class with-dietitian">
        <div className="class-info">
          <div className="class-header">
            <time dateTime={classDate.toISOString()} className="class-time">
              <FontAwesomeIcon icon={faCalendar} />
              This class starts <strong>{now.to(classDate)}</strong>
            </time>
          </div>
          <Row className="class-details">
            <Col xs={12} lg={3} className="class-detail-item">
              <h3 className="class-detail-title">
                <FontAwesomeIcon icon={faCalendar} />
                First Day of Class
              </h3>
              <span className="class-highlight">{classDate.format(FORMAT_MMMM_Do_YYYY)}</span>
            </Col>
            <Col xs={12} lg={2} className="class-detail-item">
              <h3 className="class-detail-title">
                <FontAwesomeIcon icon={faCalendar} />
                Class Day
              </h3>
              <span className="class-highlight">{classDate.format(FORMAT_DDDD)}</span>
            </Col>
            <Col xs={12} lg={7} className="class-detail-item">
              <h3 className="class-detail-title">
                <FontAwesomeIcon icon={faClock} />
                Class Time
              </h3>
              <span className="class-highlight">
                {classDate.format('h:mmA')}
              </span>
              &nbsp;
              {moment.tz.zone(moment.tz.guess())!.abbr(classDate.unix())}
            </Col>
          </Row>

          {!isUndefined(item.dietitian)
            ? <div className="class-dietitian">
                <div className="class-dietitian-header">
                  <img src="http://placehold.it/128x128" alt="Test" className="class-dietitian-photo" />
                  <div className="class-dietitian-info">
                    Dietitian teaching this class
                    <h4 className="class-dietitian-name">{item.dietitian!.fullName}</h4>
                  </div>
                </div>
                <ul className="class-dietitian-bio">
                  <li>Graduated from New York University</li>
                  <li>10 Years of experience as a Dietitian</li>
                  <li>Helped over 1,530 people lose 5% of their weight</li>
                </ul>
              </div>
            : null}
          <div className="class-actions">
            {!isAuthenticated() && <Button
              disabled={props.disabled || item.participants.length >= item.maxParticipants}
              onClick={e => signupForClass(e, item.id)}
              variant="primary">
              {props.disabled && props.isSelected ? 'Signing up...' : 'Sign up for class'}
            </Button>}
          </div>
        </div>
      </div>

      {showModal
        ? <BaseModal
            toDisplay={true}
            className="cancel-enrollment"
            title="Cancel Enrollment"
            cancelTitle="No"
            confirmTitle="Yes"
            cancelClass="btn"
            confirmClass="btn primary"
            onCancel={() => setShowModal(false)}
            onClose={() => setShowModal(false)}
            onConfirm={() => {
              setShowModal(false);
              setEnrolled(false);
            }}
          >
            <p>You are cancelling your enrollment for
              <strong className="cancel-enrollment-class">
                Class {item.id.toString()}
              </strong><br />
              Do you wish to proceed?
            </p>
          </BaseModal>
        : null
      }
    </div>
  );
}
